import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moneyBackImg from '../../assests/frontend/assets/images/icon-mb.png';
import Copyright from '../Copyright';


function CreateMembership() {
    return (
        <>
            <div className='membershipPlanPage'>
                <div className='container'>
                    <div className='memberCardGrp d-flex'>
                        {/* MemberCard Begin  */}
                        <div className='memberCard'>
                            <div className="bestBadge">
                                <img src="https://wholesalehealthclub.com/wp-content/themes/artemis-swp/assets/images/best-value-img.png" alt="Best" />
                            </div>
                            <div className="card-body">
                                <div className="tagName">
                                    <div className="tagPrice">
                                        <h3>Sapphire</h3>
                                        <h4>$12.77 <span>/MO</span></h4>
                                    </div>
                                    <img src="https://wholesalehealthclub.com/wp-content/themes/artemis-swp/assets/images/platinam.png" />
                                </div>

                                <div className="tagBody">
                                    <h5>
                                        EVERYTHING YOU <br />
                                        GET IN SAPPHIRE <br />
                                        <b>MEMBERSHIP:</b>
                                    </h5>
                                    <p>For every 5 bottles purchased get one free</p>
                                    <p>1 referral gets you 1 month free</p>
                                    <p>BONUS eBooks:</p>
                                    <div className="gift-img">
                                        <img src="https://wholesalehealthclub.com/wp-content/themes/artemis-swp/assets/images/ebook-group.png" alt="Gift Image" />
                                        <span className="valueImg">
                                            <img src="https://wholesalehealthclub.com/wp-content/themes/artemis-swp/assets/images/valued-237.png" alt="" />
                                        </span>
                                    </div>
                                </div>
                                <div className="tagAction">
                                    <Link to="/order-step-1" className='btn buyNow_btn'>Buy Now</Link>
                                    <img src="https://wholesalehealthclub.com/wp-content/themes/artemis-swp/assets/images/card.png" alt='#' />
                                    <img src="https://wholesalehealthclub.com/wp-content/themes/artemis-swp/assets/images/sec.png" alt='#' />
                                </div>

                                <div className="guranteeMedia media align-items-center text-left">
                                    <img className="guranteeImgIcon" src={moneyBackImg} alt="Money Back Icon" />
                                    <div className="media-body">
                                        <h5 className="m-0">100% 30 day money back guarantee</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* MemberCard End  */}

                    </div>
                </div>
            </div>


            <section className="main moneyBack-dark">
                <div className="container">
                    <div className="card-group align-items-sm-center">
                        <img className="moneyBack-img" src={moneyBackImg} alt="Money Back Icon" />
                        <div className="media-body">
                            <h5>100% 30 DAY MONEY BACK GUARANTEE</h5>
                            <p>Wholesale Health Club comes with a 30 Day, 100% Money Back Guarantee. That means if you change your mind about this decision at any point in the next two months all you
                                need to do is email us, and we'll refund your purchase.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* Footer Begin  */}
            <Copyright/>
            {/* Footer End  */}
        </>
    )
}

export default CreateMembership