import React, { useState, useEffect} from "react";
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'

function FaqCategory(){
    const [show, setShow] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [id, setId] = useState("");
    const handleClose = () => { 
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }
    const [FaqCategoryList, setFaqCategoryList] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [pending, setPending] = useState(true);
    const [details, setDetails] = useState([]);

    function getFaqCategoryList() {
        axios.get(`api/listFaqCategory`).then(res => {
            setFaqCategoryList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    useEffect(() => {
        getFaqCategoryList();
    }, []);


    useEffect(() => {
        const result = FaqCategoryList?.filter(faqCategoryName => {
            return faqCategoryName.faq_category_name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    function showSingleData(id) {
        //console.log(id);
        axios.get(`api/detailsFaqCategory/${id}`).then(res => {
            setDetails(res.data.data);
            setId(id);
            handleShow();
        })
    }

    function deleteFaqCategory(id) {
        console.log(id);
        Swal.fire({
            title: 'Are you sure you want to delete this faq category?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteFaqCategory/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getFaqCategoryList();
                })
            }
        })
    }

    const formSubmit = (data) => {
        let category_name = data.category_name;
        const formdata = new FormData();
        formdata.append('category_name', category_name);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/createFaqCategory`, formdata).then(res => {
            console.log(res);
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getFaqCategoryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    const formUpdate = (data) => {
        const formdata = new FormData();
        formdata.append('category_name', data.category_name);
        axios.post(`api/updateFaqCategory/${id}`, formdata).then(res => {
            console.log(res);
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getFaqCategoryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function createFaqCategoryModal() {
        setDetails([]);
        setId("");
        handleShow();
    }

    const columns = [
        {
            name: "Category Name",
            selector: row => row.faq_category_name
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary'><FontAwesomeIcon icon={faGear} onClick={() => { showSingleData(row.id) }} title="Edit Bonus" /></button>
                    <button className='btn btn-danger'><FontAwesomeIcon icon={faDeleteLeft} onClick={() => { deleteFaqCategory(row.id) }} title="Delect Bonus" /></button>
                </>
            )
        }
    ]

    return (
        <div>
            <div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Formik
                            initialValues={{ category_name: details.faq_category_name}}
                            validationSchema={Yup.object({
                                category_name: Yup.string().required('Category name is required'),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                // alert(JSON.stringify(values, null, 2));
                                {(id) ? formUpdate(values) : formSubmit(values); }
                            }}
                        >
                            <Form className='bonusForm'>
                                <Modal.Header closeButton>
                                    <Modal.Title>FAQ Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='container'>
                                        <div className='row justify-content-center'>
                                            <div className='col-md-12'>
                                                <div className='card'>
                                                    <div className='card-body' style={{ minHeight: 'auto', overflowY: 'scroll' }}>
                                                        <div className='form-group md-3'>
                                                            <label> Category Name </label>
                                                            <Field type="text" name="category_name" className="form-control" />
                                                            <CustomErrorMsg name="category_name" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Formik>
                    </Modal>
                </div>
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">FAQ Category List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={createFaqCategoryModal}>
                                    Create FAQ Category
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="No Data Available" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" className={search && search.length > 0 ? 'form-control serachInput' : 'form-control serachInput d-none'} placeholder="Search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqCategory;