import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import { MultiSelect } from "react-multi-select-component";
import CheckPermission from '../frontend/auth/CheckPermission';

function FunnelFlowList() {
    CheckPermission()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pending, setPending] = useState(true);
    const [search, setSearch] = useState("");
    const [searchUser, setSearchUser] = useState([]);
    function addCheckout() {
        navigate('/admin/funnelflow')
    }
    async function getFunnelList() {
        axios.get(`api/list-flow`).then(res => {
            //console.warn(res.data.data);
            setData(res.data.data);
            setSearchUser(res.data.data)

            setPending(false);
        })

    }
    const columns = [
        {
            name: "Name",
            cell: row => (
                <span>{row.funnel_name}</span>
            )
        },
        {
            name: "Lander",
            cell: row => (
                <span>{row.lander.lander_name}</span>
            )
        },
        {
            name: "Checkout",
            cell: row => (
                <span>{row.checkout.funnel_name}</span>
            )
        },
        {
            name: "Upsell 1",
            cell: row => (
                <span>{row.upsell_1 != null ? row.upsell_one.upsell_name : 'NA'}</span>
            )
        },
        {
            name: "Upsell 2",
            cell: row => (
                <span>{row.upsell_2 != null ? row.upsell_two.upsell_name : 'NA'}</span>
            )
        },
        {
            name: "Upsell 3",
            cell: row => (
                <span>{row.upsell_3 != null ? row.upsell_three.upsell_name : 'NA'}</span>
            )
        },
        {
            name: "Upsell 4",
            cell: row => (
                <span>{row.upsell_4 != null ? row.upsell_four.upsell_name : 'NA'}</span>
            )
        },
        {
            name: "Upsell 5",
            cell: row => (
                <span>{row.upsell_5 != null ? row.upsell_five.upsell_name : 'NA'}</span>
            )
        },
        {
            name: "Confirmation",
            cell: row => (
                <span>{row.confirmation.confirmation_name}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <a style={{ marginRight: 1 + 'em' }} target='_blank' className={row.lander.id!='27'?'btn btn-primary':'d-none'} href={'/' + row.lander.lander_slug} ><FontAwesomeIcon icon={faEye} /></a>
                    <a style={{ marginRight: 1 + 'em' }} target='_blank' className={row.lander.id=='27'?'btn btn-primary':'d-none'} href={'/' + row.checkout.funnel_slug} ><FontAwesomeIcon icon={faEye} /></a>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { navigate('/admin/funnelflowedit?FunnelId=' + row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteFunnel(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getFunnelList()
    }, []);

    function deleteFunnel(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Checkout Funnel Flow Setup?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`api/remove-flow/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getFunnelList();
                })
            }
        })
    }


    useEffect(() => {
        const result = data.filter(country => {
            return country.funnel_name.toLowerCase().match(search.toLowerCase());
        })
        setSearchUser(result);
    }, [search]);

    return (
        <div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Funnel Flow List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={addCheckout}>
                                    Create Funnel Flow
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="No Funnels Available" //or your component
                                    columns={columns}
                                    data={searchUser}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FunnelFlowList