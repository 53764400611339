import React, { useState, useEffect} from "react";
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'

function FaqQueries() {
    const [show, setShow] = useState(false);
    const [id, setId] = useState("");
    const handleClose = () => { 
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }
    const [faqQueryList, setFaqQueryList] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [pending, setPending] = useState(true);
    const [details, setDetails] = useState([]);
    const [FaqCategoryList, setFaqCategoryList] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    const getCategoryList = () => {
        axios.get(`api/listFaqCategory`).then(res => {
            setFaqCategoryList(res.data.data);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    /*const getFaqSubCategoryList = () => {
        axios.get(`api/listFaqSubCategory`).then(res => {
            setFaqSubCategoryList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }*/

    function getFaqQueryList() {
        axios.get(`api/listFaqQuery`).then(res => {
            setFaqQueryList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    useEffect(() => {
        getFaqQueryList();
        getCategoryList();
    }, []);

    useEffect(() => {
        const result = faqQueryList?.filter(faqQuery => {
            return faqQuery.subject.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    function showSingleData(id) {
        //console.log(id);
        axios.get(`api/detailsFaqQuery/${id}`).then(res => {
            setDetails({
                faq_category_name: res.data.data.faq_category_id,
                sub_category_name: res.data.data.faq_sub_category_id,
                subject: res.data.data.subject,
                description: res.data.data.description
            });
            setId(id);
            handleShow();
        })
    }

    const formSubmit = (data) => {
        let category_name = data.category_name;
        let sub_category_name = data.sub_category_name === undefined ? 0 : data.sub_category_name;
        let subject = data.subject;
        let description = data.description;
        const formdata = new FormData();
        formdata.append('category_name', category_name);
        formdata.append('sub_category_name', sub_category_name);
        formdata.append('subject', subject);
        formdata.append('description', description);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/createFaqQuery`, formdata).then(res => {
            console.log(res);
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getFaqQueryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function deleteFaqQuery(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this faq?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteFaqQuery/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getFaqQueryList();
                })
            }
        })
    }
    

    const columns = [
        {
            name: "Category Name",
            selector: row => row.faq_category_name
        },
        {
            name: "Subject",
            selector: row => row.subject
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary'><FontAwesomeIcon icon={faGear} onClick={() => { showSingleData(row.id) }} title="Edit Bonus" /></button>
                    <button className='btn btn-danger'><FontAwesomeIcon icon={faDeleteLeft} onClick={() => { deleteFaqQuery(row.id) }} title="Delect Bonus" /></button>
                </>
            )
        }
    ]

    const formUpdate = (data) => {
        const formdata = new FormData();
        formdata.append('category_name', data.category_name);
        formdata.append('sub_category_name', data.sub_category_name === undefined ? 0 : data.sub_category_name);
        formdata.append('subject', data.subject);
        formdata.append('description', data.description);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/updateFaqQuery/${id}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getFaqQueryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function createFaqModal() {
        setDetails([]);
        setId("");
        handleShow();
    }

    const handleCategoryChange = (event) => {
        const category_id = event.target.value;
        axios.get(`api/get-sub-category/${category_id}`).then(res => {
            if(res.data.status === true) {
                setSubCategory(res.data.data);
            }
        })
    };


    return (
        <div>
            <div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Formik
                            initialValues={{ 
                                category_name : details.faq_category_name,
                                sub_category_name : details.sub_category_name,
                                subject : details.subject,
                                description : details.description
                            }}
                            validationSchema={Yup.object({
                                category_name: Yup.string().required('Category name is required'),
                                subject: Yup.string().required('Subject is required'),
                                description: Yup.string().required('Description is required')
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                // alert(JSON.stringify(values, null, 2));
                                {(id) ? formUpdate(values) : formSubmit(values); }
                            }}
                        >
                            {(formProps) => (
                                <Form className='bonusForm'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>FAQ Category</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <div className='row justify-content-center'>
                                                <div className='col-md-12'>
                                                    <div className='card'>
                                                        <div className='card-body' style={{ minHeight: 'auto', overflowY: 'scroll' }}>
                                                            <div className='form-group md-3'>
                                                                <label> Category </label>
                                                                <Field 
                                                                    as="select" 
                                                                    name="category_name" 
                                                                    className="form-control"
                                                                    onChange={(event) => {
                                                                        formProps.handleChange(event);
                                                                        handleCategoryChange(event); // Call the custom function here if needed
                                                                    }}
                                                                >
                                                                    <option  value="">Select category type</option>
                                                                    {FaqCategoryList ? FaqCategoryList.map(faqCategory => {
                                                                        return (
                                                                            <option value={faqCategory.id} key={faqCategory.id}>
                                                                                {faqCategory.faq_category_name}
                                                                            </option>
                                                                        );
                                                                    }) : ''}
                                                                </Field>
                                                                <CustomErrorMsg name="category_name" />
                                                            </div>
                                                            <div className="form-group md-3">
                                                                <label>Sub Category:</label>
                                                                <Field
                                                                    name="sub_category_name"
                                                                    as="select"
                                                                    className="form-control"
                                                                >
                                                                    {subCategory ? subCategory.map(faqSubCategoryList => {
                                                                        return (
                                                                            <option value={faqSubCategoryList.id} key={faqSubCategoryList.id}>
                                                                                {faqSubCategoryList.sub_category_name}
                                                                            </option>
                                                                        );
                                                                    }) : ''}
                                                                </Field>
                                                            </div>
                                                            <div className="form-group md-3">
                                                                <label>Subject</label>
                                                                <Field 
                                                                    as="textarea" 
                                                                    name="subject" 
                                                                    className="form-control" 
                                                                >
                                                                </Field>
                                                                <CustomErrorMsg name="subject" />
                                                            </div>
                                                            <div className="form-group md-3">
                                                                <label>Description</label>
                                                                <Field 
                                                                    as="textarea" 
                                                                    name="description" 
                                                                    className="form-control" 
                                                                >
                                                                </Field>
                                                                <CustomErrorMsg name="description" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type='submit'>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">FAQ List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={createFaqModal}>
                                    Create FAQ
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="No Data Available" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" className={search && search.length > 0 ? 'form-control serachInput' : 'form-control serachInput d-none'} placeholder="Search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqQueries;