import React, { useState, useEffect } from 'react';

function Pagination({ currentPage, totalPages, onPageChange }) {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className="pagination cust-pagination">
                {pageNumbers.map((number) => (
                <li
                    key={number}
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                >
                    <button
                        className="page-link"
                        onClick={() => onPageChange(number)}
                    >
                        {number}
                    </button>
                </li>
                ))}
            </ul>
        </nav>
    ) ;
}

export default Pagination;