import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6

import { Link } from 'react-router-dom';
import '../../assests/funnel/assets/css/checkout14.css';
import {BASE_URL} from '../Config';

// Images 
import siteSecurity from '../../assests/funnel/assets/images/checkout14/site-security-img.png';
import pro1 from '../../assests/funnel/assets/images/checkout14/blueforrestbeauty-productlineup.png';
import pro2 from '../../assests/funnel/assets/images/checkout14/tcl.png';
import pro3 from '../../assests/funnel/assets/images/checkout14/blueforrestbeauty-productlineup.png';
import postalLogo from '../../assests/funnel/assets/images/checkout14/postal-logos.png';
import badge1 from '../../assests/funnel/assets/images/checkout14/security-badge-1.png';
import badge2 from '../../assests/funnel/assets/images/checkout14/security-badge-2.png';
import badge3 from '../../assests/funnel/assets/images/checkout14/security-badge-3.png';
import badge4 from '../../assests/funnel/assets/images/checkout14/security-badge-4.png';
import paymentMulti from '../../assests/funnel/assets/images/checkout14/payment_multi_secure.jpg';
import guaranteed from '../../assests/funnel/assets/images/checkout14/guaranteed.png';
import paymentMultiCards from '../../assests/funnel/assets/images/checkout14/payment_multi_cards.png';
import paymentForm from '../../assests/funnel/assets/images/checkout14/payment_form_top_bg.png';
import securityIcon1 from '../../assests/funnel/assets/images/checkout14/privacy-verified.jpg';
import securityIcon2 from '../../assests/funnel/assets/images/checkout14/certified-by.jpg';
import securityIcon3 from '../../assests/funnel/assets/images/checkout14/security-verified.jpg';
import securityIcon4 from '../../assests/funnel/assets/images/checkout14/business-verified.jpg';
import securityIcon5 from '../../assests/funnel/assets/images/checkout14/visa-mc-discover-new.png';
import securityIcon6 from '../../assests/funnel/assets/images/checkout14/postal-logos_ZKYhlZD.png';
import securityIcon7 from '../../assests/funnel/assets/images/checkout14/shop-online-lock.png';


import Product1 from '../../assests/admin/assets/img/payment_multi_btls_5.png';
import Product2 from '../../assests/admin/assets/img/payment_multi_btls_3.png';
import Product3 from '../../assests/admin/assets/img/payment_multi_btls_1.png';
import IconStisfaction from '../../assests/admin/assets/img/icon-satisfaction.png';
import IconDelivery from '../../assests/admin/assets/img/icon-delivery.png';
import PostalLogo from '../../assests/admin/assets/img/postal-logos.png';
import SecurityBadge1 from '../../assests/admin/assets/img/security-badge-1.png';
import SecurityBadge2 from '../../assests/admin/assets/img/security-badge-2.png';
import SecurityBadge3 from '../../assests/admin/assets/img/security-badge-3.png';
import SecurityBadge4 from '../../assests/admin/assets/img/security-badge-4.png';
import FormTop from '../../assests/admin/assets/img/payment_form_top_bg.png';
import PaymentCards from '../../assests/admin/assets/img/payment_multi_cards.png';
import PaymentButton from '../../assests/admin/assets/img/payment_button.png';
import paymentMultiSecure from '../../assests/admin/assets/img/payment_multi_secure.jpg';
import Guaranteed from '../../assests/admin/assets/img/guaranteed.png';
import Arrow2 from '../../assests/admin/assets/img/arrow2.png';
import privacyVerified from '../../assests/admin/assets/img/privacy-verified.jpg';
import CertifiedBy from '../../assests/admin/assets/img/certified-by.jpg';
import SecurityVerified from '../../assests/admin/assets/img/security-verified.jpg';
import BusinessVerified from '../../assests/admin/assets/img/business-verified.jpg';
import Visa from '../../assests/admin/assets/img/visa-mc-discover-new.png';
import PostalLogo2 from '../../assests/admin/assets/img/postal-logos_ZKYhlZD.png';
import OnlineLock from '../../assests/admin/assets/img/shop-online-lock.png';
import CheckPermission from '../frontend/auth/CheckPermission';
import CTE from "react-click-to-edit"

import checkoutProduct from '../../assests/funnel/assets/images/product-checkout.png';
import plusIcon from '../../assests/funnel/assets/images/plus-icon.png';
import visa from '../../assests/funnel/assets/images/visa.png';
import mastercard from '../../assests/funnel/assets/images/mastercard.png';
import discover from '../../assests/funnel/assets/images/discover.png';
import amex from '../../assests/funnel/assets/images/amex.png';
import orSecureicons from '../../assests/funnel/assets/images/or-secureicons.jpg';
import securedBy from '../../assests/funnel/assets/images/secured-by.png';
import symantec from '../../assests/funnel/assets/images/symantec.png';
import satisfactionSeal from '../../assests/funnel/assets/images/satisfaction-seal.png';
import customerServiceSeal from '../../assests/funnel/assets/images/customer-service-seal.png';
import Copyright from '../Copyright';


function Template() {
    CheckPermission()
    let isDisabled = 'package-container';
    const navigate = useNavigate();
    const [products, setproducts] = useState([]);
    const [errors, setErros] = useState({});
    const [url, setURL] = useState();
    const [lander, setLander] = useState();
    const [show, setShow] = useState("0");
    const [productImg1, setProductimg1] = useState(Product1);
    const [productImg2, setProductimg2] = useState(Product2);
    const [productImg3, setProductimg3] = useState(Product3);

    const tmeChange = (e) => {
        setShow(e.target.value)
        if (e.target.value == 1) {
            setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: 'BUY 3  BOTTLES – GET 2 FREE' })
            setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: 'BUY 2 BOTTLES – GET 1 FREE ' })
            setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: 'BUY 2 BOTTLES – GET 1 FREE ' })

            setProductimg1(Product1)
            setProductimg2(Product2)
            setProductimg3(Product3)

            setproductName1('3 Month Neuro Tech IQ Pack');
            setproductPrice1(99);
            setproductName2('3 Month Neuro Tech IQ Pack');
            setproductPrice2(99);
            setproductName3('3 Month Neuro Tech IQ Pack');
            setproductPrice3(99);

        }

        if (e.target.value == 3) {


            setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: 'Skincare Package 1' })
            setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: 'Skincare Package 2' })


            setProductimg1(pro1)
            setProductimg2(pro2)
            setProductimg3(pro3)

            setproductName1('Blue Forrest 4 Pack Skin Products');
            setproductPrice1(80);
            setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: 'Skincare Package 3' })
            setproductName2('Complete Life 4 Pack Skin Products');
            setproductPrice2(80);
            setproductName3('CSapphire Natural 3 Pack Skin Products');
            setproductPrice3(60);


        }
        // console.log(show)
    }
    function getProductList() {
        axios.get(`api/listMappedProductForFunnel`).then(res => {

            setproducts(res.data.data);
        })
    }
    useEffect(() => { getProductList(); }, []);


    // console.log(productImg1)

    let display = "top-terms open"
    const [productName1, setproductName1] = useState('3 Month Neuro Tech IQ Pack');
    const [productName2, setproductName2] = useState('3 Month Neuro Tech IQ Pack');
    const [productName3, setproductName3] = useState('3 Month Neuro Tech IQ Pack');

    const [productPrice1, setproductPrice1] = useState('99');
    const [productPrice2, setproductPrice2] = useState('99');
    const [productPrice3, setproductPrice3] = useState('99');

    const [CheckoutFunnelInstance, setCheckoutFunnelInstance] = useState({
        funnel_name: "",
        template_id: "1",
        lander_page: "",
        lander_page_url: "",
        first_product_label: "BUY 3  BOTTLES – GET 2 FREE",
        first_product_shipping_id: "Free Shipping!",
        first_product_id: "0",
        first_product_name: "3 Month Neuro Tech IQ Pack",
        first_product_retail: "266",
        first_product_price: "99",
        first_product_save: "170",
        first_product_each: "33.66",
        second_product_label: "BUY 2 BOTTLES – GET 1 FREE",
        second_product_shipping_id: "Free Shipping!",
        second_product_id: "0",
        second_product_name: "3 Month Neuro Tech IQ Pack",
        second_product_retail: "269",
        second_product_price: "99",
        second_product_save: "170",
        second_product_each: "33.66",
        third_product_label: "BUY 2 BOTTLES – GET 1 FREE",
        third_product_shipping_id: "Free Shipping!",
        third_product_id: "",
        third_product_name: "3 Month Neuro Tech IQ Pack",
        third_product_retail: "269",
        third_product_price: "99",
        third_product_save: "170",
        third_product_each: "33.66",
        membership_add_on: "1",
        funnel_url: "", lander_url: ""

    });
    const [landerPageUrl, setLanderPageUrl] = useState('')
    const [funnel_slug, setfunnel_slug] = useState('')
    function camelCase(str) {
        return str.split(' ').map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ');
    }
    const handelInput = (e) => {
        e.persist();
        var imageRURL = BASE_URL+"whc-custom/storage/app/public/"
        setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, [e.target.name]: e.target.value })
        //console.warn(e.target.name,e.target.value,CheckoutFunnelInstance.lander_page)
        var urlPrefix = BASE_URL;
        if (e.target.name == 'lander_page') {
            // console.warn(e.target.value)
            var cUrl = ''
            if (e.target.value != 0 && e.target.value != 'Lander Page Not Selected') {
                var valueLander = e.target.value;
                var slugArrSplit = valueLander.split(' ')
                var slug = slugArrSplit.join('-');
                var cUrl = urlPrefix + slug;


            }
            if (e.target.value == 0) {
                setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['lander_page']: "Lander Page Not Selected" })
                setLanderPageUrl("Lander Page Not Selected")

            }
            setLanderPageUrl(cUrl)

        }

        if (e.target.name == 'funnel_url') {
            var valueLander = e.target.value.trim().toLowerCase();
            var slugArrSplit = valueLander.split(' ')
            var slug = slugArrSplit.join('-');
            setfunnel_slug(slug)
        }

        if (e.target.name == 'template_id') {
            if (e.target.value == 1) {
                isDisabled = 'package-container';

            }
            else {
                isDisabled = 'package-container is-disabled';
            }
        }

        if (e.target.name == 'first_product_id') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var pimg1 = el.getAttribute('data-img1');
            var name1 = camelCase(el.getAttribute('data-name1'))
            var price1 = el.getAttribute('data-price1');
            if (e.target.value == 0) {
                pimg1 = el.getAttribute('data-img1');
            }

            setProductimg1(pimg1);
            setproductName1(name1);
            setproductPrice1(price1);
            // console.log("Set image 1", pimg1)
        }
        if (e.target.name == 'membership_add_on') {
            var c = e.target.checked;
            if (c) {
                setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['membership_add_on']: "1" })
            }
            else {
                setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['membership_add_on']: "0" })
            }
        }
        if (e.target.name == 'second_product_id') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var pimg2 = el.getAttribute('data-img2');
            var name2 = camelCase(el.getAttribute('data-name2'));
            var price2 = el.getAttribute('data-price2');
            if (e.target.value == 0) {
                pimg2 = el.getAttribute('data-img2');
            }
            setProductimg2(pimg2);
            setproductName2(name2);
            setproductPrice2(price2);
        }

        if (e.target.name == 'third_product_id') {
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            var pimg3 = el.getAttribute('data-img3');
            var name3 = camelCase(el.getAttribute('data-name3'));
            var price3 = el.getAttribute('data-price3');
            if (e.target.value == 0) {
                pimg3 = el.getAttribute('data-img3');
            }
            setProductimg3(pimg3);
            setproductName3(name3);
            setproductPrice3(price3);
        }

    }
    // console.log(productImg1)

    function fireSwal(msg) {
        Swal.fire({
            title: msg,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33'
        })
    }
    const submitCheckoutFunnel = (e) => {

        const errors = {};
        if (CheckoutFunnelInstance.funnel_name == '') {
            errors.funnel_name = "Must provide a checkout page name";
            setErros(errors)
            fireSwal(errors.funnel_name)
            return false
        }
        if (!funnel_slug) {
            errors.funnel_slug = "Must provide a checkout slug";
            setErros(errors)
            fireSwal(errors.funnel_slug)
            return false
        }

        // if (!CheckoutFunnelInstance.lander_page || CheckoutFunnelInstance.lander_page == 'Lander Page Not Selected') {
        //     console.warn(CheckoutFunnelInstance.lander_page)
        //     errors.lander_page = "Must Select a Lander Page";
        //     setErros(errors)
        //     fireSwal(errors.lander_page)
        //     return false
        // }

        if (CheckoutFunnelInstance.first_product_id == 0) {
            errors.first_product_id = "Must Select a 1st product";
            setErros(errors)
            fireSwal(errors.first_product_id)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_label) {
            errors.first_product_label = "Must provide a 1st product label";
            setErros(errors)
            fireSwal(errors.first_product_label)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_shipping_id) {
            errors.first_product_shipping_id = "Must provide a 1st product shipping";
            setErros(errors)
            fireSwal(errors.first_product_shipping_id)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_name) {
            errors.first_product_name = "Must provide a 1st product name";
            setErros(errors)
            fireSwal(errors.first_product_name)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_retail) {
            errors.first_product_retail = "Must provide a 1st product's retail price";
            setErros(errors)
            fireSwal(errors.first_product_retail)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_price) {
            errors.first_product_price = "Must provide a 1st product's price";
            setErros(errors)
            fireSwal(errors.first_product_price)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_save) {
            errors.first_product_save = "Must provide a 1st product save";
            setErros(errors)
            fireSwal(errors.first_product_save)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_each) {
            errors.first_product_each = "Must provide a 1st each product price";
            setErros(errors)
            fireSwal(errors.first_product_each)
            return false
        }


        if (CheckoutFunnelInstance.second_product_id == 0) {
            errors.second_product_id = "Must Select a 2nd product";
            setErros(errors)
            fireSwal(errors.second_product_id)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_label) {
            errors.second_product_label = "Must provide a 2nd product label";
            setErros(errors)
            fireSwal(errors.second_product_label)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_shipping_id) {
            errors.second_product_shipping_id = "Must provide a 2nd product shipping";
            setErros(errors)
            fireSwal(errors.second_product_shipping_id)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_name) {
            errors.second_product_name = "Must provide a 2nd product name";
            setErros(errors)
            fireSwal(errors.second_product_name)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_retail) {
            errors.second_product_retail = "Must provide a 2nd product's retail price";
            setErros(errors)
            fireSwal(errors.second_product_retail)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_price) {
            errors.second_product_price = "Must provide a 2nd product's price";
            setErros(errors)
            fireSwal(errors.second_product_price)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_save) {
            errors.second_product_save = "Must provide a 2nd product save";
            setErros(errors)
            fireSwal(errors.second_product_save)
            return false
        }

        if (!CheckoutFunnelInstance.second_product_each) {
            errors.second_product_each = "Must provide a 2nd each product price";
            setErros(errors)
            fireSwal(errors.second_product_each)
            return false
        }



        if (CheckoutFunnelInstance.third_product_id == 0) {
            errors.third_product_id = "Must Select a 3rd product";
            setErros(errors)
            fireSwal(errors.third_product_id)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_label) {
            errors.third_product_label = "Must provide a 3rd product label";
            setErros(errors)
            fireSwal(errors.third_product_label)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_shipping_id) {
            errors.third_product_shipping_id = "Must provide a 3rd product shipping";
            setErros(errors)
            fireSwal(errors.third_product_shipping_id)
            return false
        }

        if (!CheckoutFunnelInstance.first_product_name) {
            errors.third_product_name = "Must provide a 3rd product name";
            setErros(errors)
            fireSwal(errors.third_product_name)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_retail) {
            errors.third_product_retail = "Must provide a 3rd product's retail price";
            setErros(errors)
            fireSwal(errors.third_product_retail)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_price) {
            errors.third_product_price = "Must provide a 3rd product's price";
            setErros(errors)
            fireSwal(errors.third_product_price)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_save) {
            errors.third_product_save = "Must provide a 3rd product save";
            setErros(errors)
            fireSwal(errors.third_product_save)
            return false
        }

        if (!CheckoutFunnelInstance.third_product_each) {
            errors.third_product_each = "Must provide a 3rd each product price";
            setErros(errors)
            fireSwal(errors.third_product_each)
            return false
        }



        const formdata = new FormData();
        formdata.append('funnel_name', CheckoutFunnelInstance.funnel_name);
        formdata.append('lander_page', CheckoutFunnelInstance.lander_page);
        formdata.append('lander_page_url', landerPageUrl);
        formdata.append('template_id', show);
        formdata.append('funnel_slug', funnel_slug);

        formdata.append('first_product_id', CheckoutFunnelInstance.first_product_id);
        formdata.append('first_product_label', CheckoutFunnelInstance.first_product_label);
        formdata.append('first_product_shipping_id', CheckoutFunnelInstance.first_product_shipping_id);
        formdata.append('first_product_name', productName1);
        formdata.append('first_product_retail', CheckoutFunnelInstance.first_product_retail);
        formdata.append('first_product_price', productPrice1);
        formdata.append('first_product_save', CheckoutFunnelInstance.first_product_save);
        formdata.append('first_product_each', CheckoutFunnelInstance.first_product_each);

        formdata.append('second_product_id', CheckoutFunnelInstance.second_product_id);
        formdata.append('second_product_label', CheckoutFunnelInstance.second_product_label);
        formdata.append('second_product_shipping_id', CheckoutFunnelInstance.second_product_shipping_id);
        formdata.append('second_product_name', productName2);
        formdata.append('second_product_retail', CheckoutFunnelInstance.second_product_retail);
        formdata.append('second_product_price', productPrice2);
        formdata.append('second_product_save', CheckoutFunnelInstance.second_product_save);
        formdata.append('second_product_each', CheckoutFunnelInstance.second_product_each);

        formdata.append('third_product_id', CheckoutFunnelInstance.third_product_id);
        formdata.append('third_product_label', CheckoutFunnelInstance.third_product_label);
        formdata.append('third_product_shipping_id', CheckoutFunnelInstance.third_product_shipping_id);
        formdata.append('third_product_name', productName3);
        formdata.append('third_product_retail', CheckoutFunnelInstance.third_product_retail);
        formdata.append('third_product_price', productPrice3);
        formdata.append('third_product_save', CheckoutFunnelInstance.third_product_save);
        formdata.append('third_product_each', CheckoutFunnelInstance.third_product_each);

        formdata.append('membership_add_on', CheckoutFunnelInstance.membership_add_on);

        axios.post(`api/createCheckoutFunnel`, formdata).then(res => {
            if (res.data.status === true) {
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                navigate('/admin/checkoutfunnelslist');
            }
            else {
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                })
            }
        }).catch(err => {
            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });

    }



    return (
        <>

            {/* <CTE
                wrapperclassName="wrapper"
                textclassName="text"
                initialValue={"click"}
            /> */}
            <div className='checkFunnel-row row mb-4'>
                <div className='col-md-12 mx-auto'>
                    <div className='cardBg-1'>
                        <div className='card m-4'>
                            <div class="card-header custom_card_hdr">
                                <span class="stepTitle">Checkout Page Name </span>
                            </div>
                            <div className='card-body'>
                                <div className="row align-items-center">
                                    {/* <div className="page-name col-md-3 mb-2 mb-md-0">Checkout Page Name</div> */}
                                    <div className="headerCus  col-md-12">
                                        <input type="" name="funnel_name" className="form-control" defaultValue={""} onChange={handelInput}>
                                        </input>
                                    </div>
                                    <div className="page-name col-md-3 mb-2 mb-md-0 mt-4">URL/Slug: </div>

                                    <div className="headerCus  col-md-9 mt-4">
                                        <div className='row align-items-center' >
                                            <div className='col-md-7' style={{ textAlign: 'left' }}>
                                               {BASE_URL}{funnel_slug}
                                            </div>
                                            <div className='col-md-5'>
                                                <input type="" name="funnel_url" className="form-control" defaultValue={" "} onChange={handelInput}>

                                                </input>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card m-4 d-none'>
                            <div class="card-header custom_card_hdr d-none">
                                <span class="stepTitle">Lander Page</span>
                            </div>
                            <div className='card-body'>
                                <div className="row align-items-center">
                                    {/* <div className="page-name col-md-3 mb-2 mb-md-0">Lander Page</div> */}
                                    <div className="headerCus  col-md-9">
                                        <select type="" name="lander_page" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                            <option value="0" >Select Lander Page</option>
                                            <option value="lander page-1">Lander Page 1</option>
                                            <option value="lander page-2">Lander Page 2</option>
                                        </select>

                                    </div>

                                    {landerPageUrl ? <><div className="page-name col-md-3 mb-2 mb-md-0">Lander URL: </div>

                                        <div className="headerCus  col-md-9 mt-2">
                                            <div className='row align-items-center l-2' >
                                                {/* <div className='col-md-5'>
                                               BASE_URL+ 
                                            </div> */}

                                                {landerPageUrl}


                                            </div></div> </> : null}




                                </div>
                            </div>
                        </div>
                        <div className='card m-4'>
                            <div class="card-header custom_card_hdr">
                                <span class="stepTitle">Select Template</span>
                            </div>
                            <div className='card-body'>
                                <div className="row align-items-center">
                                    {/* <div className="page-name col-md-3 mb-2 mb-md-0">Select Template</div> */}
                                    <div className="headerCus  col-md-9">
                                        <select type="" name="template_id" className="form-control mb-4" defaultValue={"0"} onChange={tmeChange}>
                                            <option value="0">Chose Template</option>
                                            <option value="1">Template 1</option>
                                            <option value="2" >Template 2</option>
                                            <option value="3" >Template 3</option>
                                            <option value="4" >Template 4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {show === "1" ? <> <div className='checkoutMain'>     <div className='checkoutPageWrapper m-4'>
                <div className='checkout-container container'>
                    <div className='innerLogo'>
                        <img src="" />
                    </div>
                    <div className='orderWrapper'>
                        {/* Left Section  */}
                        <div className='orderWrapper-left'>
                            <div className='newCheckout-wrapper'>

                                <div className={isDisabled}>

                                    {/* Package 1  selected_pack */}
                                    <select type="" name="first_product_id" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                        <option value="0" data-img1={Product1} data-name1={"3 Month Neuro Tech IQ Pack"} data-price1={'99'} >Select First Product</option>

                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img1={products.image} data-name1={products.name} data-price1={products.price}>
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div className='package1 plan-selection mt-3'>
                                        <div className='payProd'>
                                            <div className='payProd_header'>
                                                <p className='payProd_title'>
                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"BUY 3  BOTTLES – GET 2 FREE"}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: value }) }}
                                                    />
                                                </p>

                                                <span className='shipping_msg'><CTE textClass="textEdit" initialValue={"Free Shipping!"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_shipping_id']: value }) }}
                                                /></span>
                                            </div>
                                            <div className='payProd_body'>
                                                <span className='radio'></span>
                                                <div className='product_figure'>
                                                    <img src={productImg1 ? productImg1 : ''} className="product_img img-fluid" />
                                                </div>
                                                <div className='product_cont'>
                                                    <p className='prod_name'>
                                                        {productName1}
                                                    </p>
                                                    <p className='prod_offer_price'>
                                                        <span>Retail Price: <span className='text_red1'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"269"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_retail']: value }) }}
                                                        /></span></span><br />
                                                        <span>Your Price: ${productPrice1} – <strong>Save</strong>: $<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"170"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_save']: value }) }}
                                                        /></span>
                                                    </p>
                                                    <p className='prod_actual_price'>
                                                        <span className='price'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"33.66"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_each']: value }) }}
                                                        /></span><em> each</em>
                                                    </p>
                                                    <p className='stock_status'>
                                                        In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Package 1 End  */}
                                    {/* Package 2  */}

                                    <select type="" name="second_product_id" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                        <option value="0" data-img2={Product2} data-name2={"3 Month Neuro Tech IQ Pack"} data-price2={'99'}>Select Second Product</option>
                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img2={products.image} data-name2={products.name} data-price2={products.price} >
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <div className='package2 plan-selection mt-3'>
                                        <div className='payProd'>
                                            <div className='payProd_header'>
                                                <p className='payProd_title'>

                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"BUY 2 BOTTLES – GET 1 FREE "}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: value }) }}
                                                    /></p>
                                                <span className='shipping_msg'> <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Free Shipping!"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_shipping_id']: value }) }}
                                                /></span>
                                            </div>
                                            <div className='payProd_body'>
                                                <span className='radio'></span>
                                                <div className='product_figure'>
                                                    <img src={productImg2 ? productImg2 : ''} className="product_img img-fluid" />
                                                </div>
                                                <div className='product_cont'>
                                                    <p className='prod_name'> {productName2}</p>
                                                    <p className='prod_offer_price'>
                                                        <span>Retail Price: <span className='text_red1'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"269"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_retail']: value }) }}
                                                        /></span></span><br />
                                                        <span>Your Price: ${productPrice2}– <strong>Save</strong>: $<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"170"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_save']: value }) }}
                                                        /></span>
                                                    </p>
                                                    <p className='prod_actual_price'>
                                                        <span className='price'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"33.66"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_each']: value }) }}
                                                        /></span><em> each</em>
                                                    </p>
                                                    <p className='stock_status'>
                                                        In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Package 2 End  */}
                                    {/* Package 3  */}

                                    <select type="" name="third_product_id" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                        <option value="0" data-img3={Product3} data-name3={"3 Month Neuro Tech IQ Pack"} data-price3={'99'}>Select Third Product</option>
                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img3={products.image} data-name3={products.name} data-price3={products.price} >
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <div className='package3 plan-selection mt-3'>
                                        <div className='payProd'>
                                            <div className='payProd_header'>
                                                <p className='payProd_title'>

                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"BUY 2 BOTTLES – GET 1 FREE "}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: value }) }}
                                                    /></p>
                                                <span className='shipping_msg'> <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Free Shipping!"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_shipping_id']: value }) }}
                                                /></span>
                                            </div>
                                            <div className='payProd_body'>
                                                <span className='radio'></span>
                                                <div className='product_figure'>
                                                    <img src={productImg3 ? productImg3 : ''} className="product_img img-fluid" />
                                                </div>
                                                <div className='product_cont'>
                                                    <p className='prod_name'> {productName3}</p>
                                                    <p className='prod_offer_price'>
                                                        <span>Retail Price: <span className='text_red1'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"269"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_retail']: value }) }}
                                                        /></span></span><br />
                                                        <span>Your Price: ${productPrice3} – <strong>Save</strong>: $<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"170"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_save']: value }) }}
                                                        /></span>
                                                    </p>
                                                    <p className='prod_actual_price'>
                                                        <span className='price'>$<CTE inputClass="wrapperTextdark" textClass="textEdit" initialValue={"33.66"}
                                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_each']: value }) }}
                                                        /></span><em> each</em>
                                                    </p>
                                                    <p className='stock_status'>
                                                        In Stock. Current Sell-Out Risk: <span className="text_red1">HIGH</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Package 3 End  */}
                                </div>

                                {/* Order Delivery  */}
                                <div className='order-delivery'>
                                    <img src={IconDelivery} className="icon_delivery img-fluid" />
                                    <h3>USPS First-Class 2-3 Days Delivery</h3>
                                    <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                                </div>
                                {/* Order Delivery end  */}


                                {/* Order counter Begin  */}
                                <div className="order-counter">
                                    <article className="clearfix">
                                        <div className="float_left">Shipping Type:</div>
                                        <div id="block2_shipping" className="float_right"><b>FREE</b> Priority Shipping</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="float_left">Shipping Price:</div>
                                        <div id="block2_price" className="float_right"><b>$0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article id="" className="clearfix ws_vip">
                                        <div className="float_left">Sapphire Membership:</div>
                                        <div id="block2_price" className="float_right"><b>$0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="float_left">Your Total:</div>
                                        <div id="block2_total" className="float_right">$0.00</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="last clearfix">
                                        <div id="block2_total2" className="float_left">Retail: $0.00</div>
                                        <div id="block2_save" className="float_right"><b>You Save: $0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                </div>
                                {/* Order counter End  */}

                                {/* Gurantee Div  */}
                                <div className="order-satisfaction">
                                    <img src={IconStisfaction} alt="" className="img-fluid gurantee_img" />
                                    <h3>Satisfaction Guarantee!</h3>
                                    <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back
                                        Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                                </div>
                                {/* Gurantee Div End */}

                                {/* security 1 begin  */}
                                <div className="secu-1">
                                    <div className="row">
                                        <div className="col-md-9 mx-auto text-center">
                                            <div className="secImg-1">
                                                <img src={PostalLogo} alt="" />
                                            </div>
                                            <div className="arrive-date">Your order is due to arrive on <span className="orange">September 13 2022</span> .
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Security 1 end  */}

                                {/* Security 2 Begin  */}
                                <div className="secu-2">
                                    <div className="row">
                                        <div className="col-lg-9 mx-auto text-center">
                                            <div className="row secu-row">
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge2} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge3} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2">
                                                        <img className="img-fluid" src={SecurityBadge4} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Security 2 End  */}
                            </div>
                        </div>
                        {/* Left section end  */}
                        {/* Right Section  */}
                        <div className='orderWrapper-right'>
                            <div className='formBox'>
                                <div className='formBox-hdr'>
                                    <img className="img-fluid" src={FormTop} alt="" />
                                </div>
                                <div className='formBox-body'>
                                    <div className='text-center payOption'>
                                        <p className="allCards mb-0"><strong>We Accept:</strong></p>
                                        <img src={PaymentCards} className="img-fluid" alt="" />
                                    </div>
                                    {/* Form Div  */}
                                    <div className='checkout-form-div'>
                                        <form className='row form_row'>
                                            <div className='col-md-12 form_col'>
                                                <input type="text" className='form-control' placeholder="Credit Card Number" disabled />
                                            </div>
                                            <div className='col-md-12 form_col'>
                                                <input type="password" className='form-control' placeholder="Security Code" disabled />
                                            </div>
                                            <div className='col-md-6 form_col'>
                                                <select className='form-control' disabled>
                                                    <option value="01">Month</option>
                                                    <option value="01">(01) Jan</option>
                                                    <option value="02">(02) Feb</option>
                                                    <option value="03">(03) Mar</option>
                                                    <option value="04">(04) Apr</option>
                                                    <option value="05">(05) May</option>
                                                    <option value="06">(06) Jun</option>
                                                    <option value="07">(07) Jul</option>
                                                    <option value="08">(08) Aug</option>
                                                    <option value="09">(09) Sep</option>
                                                    <option value="10">(10) Oct</option>
                                                    <option value="11">(11) Nov</option>
                                                    <option value="12">(12) Dec</option>
                                                </select>
                                            </div>
                                            <div className='col-md-6 form_col'>
                                                <select className='form-control' disabled>
                                                    <option value="2022">Year</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                </select>
                                            </div>
                                            <div className='col-md-12 form_col'>
                                                <button href='#' className='pay-btn text-center' disabled><img src={PaymentButton} alt="" className="img-fluid" /></button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* form Div End  */}

                                    {/* Trans Begin  */}
                                    <div className='trans text-center'>
                                        <img src={paymentMultiSecure} alt="" className="img-fluid w-100" />
                                    </div>
                                    {/* Trans End  */}

                                    {/* Safe Checkout Gurantee Begin  */}
                                    <div className="w_item w_item_1 section-guranteed">
                                        <div className="w_inner">
                                            <div className="w_thumb">
                                                <figure>
                                                    <img className="img-fluid" alt="" src={Guaranteed} />
                                                </figure>
                                            </div>
                                            <div className="w_desc">
                                                <p>GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Safe Checkout Gurantee End  */}

                                    {/* Yellow Box Begin  */}
                                    <div className='col-12 row'>
                                        <div className='col-8'>
                                            <p>Sapphire Membership</p>
                                        </div>
                                        <div className='col-4'>
                                            <label class="switch">
                                                <input type="checkbox" defaultChecked={true} name="membership_add_on" onChange={handelInput}></input>
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className={CheckoutFunnelInstance.membership_add_on == 1 ? 'yellowbox mt-4' : 'd-none'} style={{ disply: CheckoutFunnelInstance.membership_add_on == 1 ? 'show' : 'none' }}>
                                        <i>
                                            <img className="pulse" src={Arrow2} alt="" />
                                        </i>
                                        <div className="check_area">
                                            <input id="checkD" className="vip_shipping_desk" checked="checked" type="checkbox" />
                                            <label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label>
                                        </div>
                                    </div>
                                    {/* Yellow Box End  */}
                                    {/* Top Terms Begin  */}
                                    <div className={CheckoutFunnelInstance.membership_add_on == 1 ? 'top-terms open' : 'd-none'} style={{ disply: CheckoutFunnelInstance.membership_add_on == 0 ? 'show' : 'none' }}>
                                        <h1 className="top-termsHdng">Sapphire Membership Terms</h1>
                                        <div className="top-trends-desc">
                                            <p>BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire Membership takes that one step further.</p>
                                            <p>As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire Membership Today!:</p>
                                            <p>-FREE shipping on all orders!</p>
                                            <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                            <p>-No hassle, no questions asked replacement on any items.</p>
                                            <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                            <p>-Get it all FREE for first 30 days!</p>
                                            <p>Some Notes About Your Membership:</p>
                                            <p>Remember – while your first month of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $12.77/ month to retain membership after your first month. If you wish to cancel your Sapphire Membership at any time, simply contact <br />our support using the below information: Support Email: <a to="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a></p>
                                        </div>
                                    </div>
                                    {/* Top Terms End  */}
                                </div>
                            </div>
                        </div>
                        {/* Right section end  */}
                    </div>
                </div>
            </div>

                {/* Security List Begin  */}
                <div className="securityIcon-sec">
                    <div className="checkout-container container securityIcon-container">
                        <ul className="order-icons-list 00">
                            <li>
                                <img src={privacyVerified} alt="privacy verified" width="86" />
                            </li>
                            <li>
                                <img src={CertifiedBy} alt="certified" width="86" />
                            </li>
                            <li>
                                <img src={SecurityVerified} alt="security verified" width="86" />
                            </li>
                            <li>
                                <img src={BusinessVerified} alt="business verified" width="85" />
                            </li>
                            <li>
                                <img src={PaymentCards} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                            </li>
                            <li>
                                <img src={PostalLogo2} alt="united states" width="299" />
                            </li>
                            <li>
                                <img src={OnlineLock} alt="shop online" width="187" />
                            </li>
                        </ul>
                    </div>
                </div>
                {/* Security List Begin  */}

                {/* Footer  */}
                <Copyright/>
            </div>
            <div className='card-body'>
                <div className="text-center text-md-end">
                    <button className='btn btn-success w-25' onClick={submitCheckoutFunnel}>Save</button>
                </div>
            </div>
            {/* footer End  */} </> : show == '3' ? <>
            <div>
                <div className="checkoutpage-14">
                    <div className="checkout-14-header-inner">
                        <div className="site-secure"><img src={siteSecurity} alt="site security" width="112" /></div>
                    </div>
                    {/* Container Begin  */}
                    <div className="container">
                        <div className="inner-logo text-center"><h2>Skincare Package</h2></div>
                        <div className="order-banner clearfix checkout-14-order-banner">
                            {/* <!-- Left  --> */}

                            <div id="payLft" className="fntAbel payLeft">
                                <div id="" className="newCheckout-wrapper">
                                    <select type="" name="first_product_id" className="form-control mb-3" defaultValue={"0"} onChange={handelInput}>
                                        <option value="0" data-img1={pro1} data-name1={"Blue Forrest 4 Pack Skin Products"} data-price1={'80'} >Select First Product</option>

                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img1={products.image} data-name1={products.name} data-price1={products.price}>
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div id="product_1_wrapper" className="package1 plan-selection">
                                        <div id="payProd1" className="payProd activePack">
                                            <h3 className="payProdTitle">
                                                <span className="title16 fntQuantBd">
                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Skincare Package 1"}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: value }) }}
                                                    />
                                                </span>
                                            </h3>
                                            <div className="productDiv">
                                                <div className="productDiv-img">
                                                    <span className="radio"></span>
                                                    <div className="proImg">
                                                        <img src={productImg1 ? productImg1 : pro1} className="product_img img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                <div className="productDiv-info">
                                                    <p className="proName">{productName1}</p>
                                                    <div className="div proexInfo">
                                                        <p className="price"><strong className="fntQuantBd">${productPrice1}</strong></p>
                                                        <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <select type="" name="second_product_id" className="form-control mb-3" defaultValue={"0"} onChange={handelInput}>
                                        <option value="0" data-img2={Product2} data-name2={"3 Month Neuro Tech IQ Pack"} data-price2={'99'}>Select Second Product</option>
                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img2={products.image} data-name2={products.name} data-price2={products.price} >
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div id="product_2_wrapper" className="package2 plan-selection">
                                        <div id="payProd2" className="payProd">
                                            <h3 className="payProdTitle">
                                                <span className="title16 fntQuantBd">
                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Skincare Package 2"}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: value }) }}
                                                    />
                                                </span>
                                            </h3>
                                            <div className="productDiv">
                                                <div className="productDiv-img">
                                                    <span className="radio"></span>
                                                    <div className="proImg">
                                                        <img src={productImg2 ? productImg2 : pro2} className="img-fluid product_img" alt="" />
                                                    </div>
                                                </div>
                                                <div className="productDiv-info">
                                                    <p className="proName">{productName2}</p>
                                                    <div className="div proexInfo">
                                                        <p className="price"><strong className="fntQuantBd">${productPrice2}</strong></p>
                                                        <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <select type="" name="third_product_id" className="form-control mb-3" defaultValue={"0"} onChange={handelInput}>
                                        <option value="0" data-img3={Product3} data-name3={"3 Month Neuro Tech IQ Pack"} data-price3={'99'}>Select Third Product</option>
                                        {products.map(products => {
                                            return (
                                                <option value={products.id} key={products.id} data-img3={products.image} data-name3={products.name} data-price3={products.price} >
                                                    {products.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <div id="product_3_wrapper" className="package3 plan-selection">
                                        <div id="payProd3" className="payProd">
                                            <h3 className="payProdTitle">
                                                <span className="title16 fntQuantBd">
                                                    <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Skincare Package 3"}
                                                        endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: value }) }}
                                                    />
                                                </span>
                                            </h3>
                                            <div className="productDiv">
                                                <div className="productDiv-img">
                                                    <span className="radio"></span>
                                                    <div className="proImg">
                                                        <img src={productImg3 ? productImg3 : pro3} className="img-fluid product_img" alt="" />
                                                    </div>
                                                </div>
                                                <div className="productDiv-info">
                                                    <p className="proName">{productName3}</p>
                                                    <div className="div proexInfo">
                                                        <p className="price"><strong className="fntQuantBd">${productPrice3}</strong></p>
                                                        <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-delivery">
                                    <h3>USPS First-Class 2-3 Days Delivery</h3>
                                    <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                                </div>
                                <div className="order-counter">
                                    <article className="clearfix">
                                        <div className="left">Shipping Type:</div>
                                        <div id="block2_shipping" className="right"><b>FREE</b> Priority Shipping</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="left">Shipping Price:</div>
                                        <div id="block2_price" className="right"><b>$0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="left">Your Total:</div>
                                        <div id="block2_total" className="right">$80</div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                    <article className="last clearfix">
                                        <div id="block2_total2" className="left">Retail: $80.00</div>
                                        <div id="block2_save" className="right"><b>You Save: $0.00</b></div>
                                        <div className="clear">&nbsp;</div>
                                    </article>
                                </div>
                                <div className="order-satisfaction">
                                    <h3>Satisfaction Guarantee!</h3>
                                    <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                                </div>
                                <div className="secu-1">
                                    <div className="row">
                                        <div className="col-md-9 mx-auto text-center">
                                            <div className="secImg-1"><img src={postalLogo} alt="" /></div>
                                            <div className="arrive-date">Your order is due to arrive on <span className="orange">undefined 12 2022</span> .</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="secu-2">
                                    <div className="row">
                                        <div className="col-lg-9 mx-auto text-center">
                                            <div className="row secu-row">
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge1} alt="" /></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge2} alt="" /></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge3} alt="" /></div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="secImg-2"><img className="img-fluid" src={badge4} alt="" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Left End  */}
                            {/* <!-- Right  --> */}
                            <div id="" className="formBox center-block payRight">
                                <img className="w-100" src={paymentForm} />
                                <div className="checkout14-form-div">
                                    <p className="checkout-form-title"><strong className="allCards">We Accept:</strong> <img src={paymentMultiCards} alt="Accepted Cards" width="180px" /></p>
                                    <form action="">
                                        <div className="form-div-1">
                                            <div className="form-holder">
                                                <input className="form-control" type="text" placeholder="Credit Card Number" />
                                            </div>
                                            <div className="form-holder">
                                                <input className="form-control" type="password" placeholder="Security Code" />
                                            </div>
                                            <div className="form-holder mb-0 cc_form-holder">
                                                <select name="cc_exp_month" className="form-control exp_month">
                                                    <option value="01">(01) Jan</option>
                                                    <option value="02">(02) Feb</option>
                                                    <option value="03">(03) Mar</option>
                                                    <option value="04">(04) Apr</option>
                                                    <option value="05">(05) May</option>
                                                    <option value="06">(06) Jun</option>
                                                    <option value="07">(07) Jul</option>
                                                    <option value="08">(08) Aug</option>
                                                    <option value="09">(09) Sep</option>
                                                    <option value="10">(10) Oct</option>
                                                    <option value="11">(11) Nov</option>
                                                    <option value="12">(12) Dec</option>
                                                </select>
                                                <select name="cc_exp_year" className="form-control exp_year">
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                    <option value="2034">2034</option>
                                                    <option value="2035">2035</option>
                                                    <option value="2036">2036</option>
                                                    <option value="2037">2037</option>
                                                    <option value="2038">2038</option>
                                                    <option value="2039">2039</option>
                                                    <option value="2040">2040</option>
                                                    <option value="2041">2041</option>
                                                    <option value="2042">2042</option>
                                                </select>
                                            </div>
                                            {/* <div className="checkoutBtn-div">
                                        <Link className="btn checkoutBtn" to="#"></Link>
                                    </div> */}
                                        </div>
                                        <label className="checkboxLabel mb-3">
                                            <input type="checkbox" className="check" />
                                            Shipping is the same as billing
                                        </label>
                                        <div className="form-div-2">
                                            <div className="form-holder">
                                                <label className="formLabel">Address</label>
                                                <input className="form-control" type="text" placeholder="Address" />
                                            </div>
                                            <div className="form-holder">
                                                <label className="formLabel">City:</label>
                                                <input className="form-control" type="text" placeholder="City" />
                                            </div>
                                            <div className="form-holder">
                                                <label className="formLabel">State:</label>
                                                <select className="form-control">
                                                    <option value="01">Alabama</option>
                                                </select>
                                            </div>
                                            <div className="form-holder">
                                                <label className="formLabel">Zip Code:</label>
                                                <input className="form-control" type="text" placeholder="Billing Zip Code" />
                                            </div>
                                            <div className="form-holder">
                                                <label className="formLabel">Country:</label>
                                                <select className="form-control">
                                                    <option value="01">United States</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="checkoutBtn-div mb-4">
                                            <Link to="#" className="btn checkoutBtn"></Link>
                                        </div>
                                        {/* <div className="form-div-2">
                                    <div className="form-holder">
                                        <input className="form-control" type="text" placeholder="First Name" />
                                    </div>
                                    <div className="form-holder">
                                        <input className="form-control" type="text" placeholder="Last Name" />
                                    </div>
                                    <div className="form-holder">
                                        <input className="form-control" type="email" placeholder="Email Address" />
                                    </div>
                                    <div className="form-holder">
                                        <input className="form-control" type="tel" placeholder="Phone Number" />
                                    </div>
                                    <div className="form-holder">
                                        <input className="form-control" type="text" placeholder="Billing Address" />
                                    </div>
                                    <div className="form-holder">
                                        <input className="form-control" type="text" placeholder="Billing City" />
                                    </div>
                                    <div className="form-holder">
                                        <select className="form-control">
                                            <option value="01">Alabama</option>
                                        </select>
                                    </div>
                                    <div className="form-holder">
                                        <input className="form-control" type="text" placeholder="Billing Zip Code" />
                                    </div>
                                    <div className="form-holder">
                                        <select className="form-control">
                                            <option value="01">United States</option>
                                        </select>
                                    </div>
                                    <div className="checkoutBtn-div">
                                        <Link to="#" className="btn checkoutBtn"></Link>
                                    </div>
                                </div> */}
                                    </form>
                                </div>
                                {/* <!-- Trans  --> */}
                                <div className="trans"><img loading="lazy" src={paymentMulti} alt="Safe and secure" width="100%" height="194" /></div>
                                {/* <p><!-- safe Checkout  --></p> */}
                                <div className="w_item w_item_1 section-guranteed">
                                    <div className="w_inner">
                                        <div className="w_thumb">
                                            <picture> <img className="no-lazy" src={guaranteed} alt="" /> </picture>
                                        </div>
                                        <div className="w_desc">
                                            <p>
                                                GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Container End  */}
                    {/* Security Sec Begin  */}
                    <div class="securityIcon-sec">
                        <div class="container">
                            <ul class="order-icons-list">
                                <li><img src={securityIcon1} alt="privacy verified" width="86" /></li>
                                <li><img src={securityIcon2} alt="certified" width="86" /></li>
                                <li><img src={securityIcon3} alt="security verified" width="86" /></li>
                                <li><img src={securityIcon4} alt="business verified" width="85" /></li>
                                <li>
                                    <img loading="lazy" src={securityIcon5} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                                </li>
                                <li><img src={securityIcon6} alt="united states" width="299" /></li>
                                <li><img src={securityIcon7} alt="shop online" width="187" /></li>
                            </ul>
                        </div>
                    </div>
                    {/* Security Sec End  */}
                    <div className='card-body'>
                        <div className="text-center text-md-end">
                            <button className='btn btn-success w-25' onClick={submitCheckoutFunnel}>Save</button>
                        </div>
                    </div>
                </div>

                {/* Footer  */}
                <Copyright/>
            </div>  
            {/* Footer End         */}
        
        </>:''}

        {show === '4' && <>
            <div className="checkoutpage-14">
                <div className="checkout-14-header-inner">
                    <div className="site-secure"><img src={siteSecurity} alt="site security" width="112" /></div>
                </div>
                {/* Container Begin  */}
                <div className="container">
                    <div className="inner-logo text-center"><h2>Skincare Package</h2></div>
                    <div className="order-banner clearfix checkout-14-order-banner">
                        {/* <!-- Left  --> */}

                        <div id="payLft" className="fntAbel payLeft">
                            <div id="" className="newCheckout-wrapper">
                                <select type="" name="first_product_id" className="form-control mb-3" defaultValue={"0"} onChange={handelInput}>
                                    <option value="0" data-img1={pro1} data-name1={"Blue Forrest 4 Pack Skin Products"} data-price1={'80'} >Select First Product</option>

                                    {products.map(products => {
                                        return (
                                            <option value={products.id} key={products.id} data-img1={products.image} data-name1={products.name} data-price1={products.price}>
                                                {products.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div id="product_1_wrapper" className="package1 plan-selection">
                                    <div id="payProd1" className="payProd activePack">
                                        <h3 className="payProdTitle">
                                            <span className="title16 fntQuantBd">
                                            <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Skincare Package 1"}
                                                endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: value }) }}
                                            />
                                            </span>
                                        </h3>
                                        <div className="productDiv">                                        
                                            <div className="productDiv-img">
                                                <span className="radio"></span>
                                                <div className="proImg">
                                                    <img src={productImg1?productImg1:pro1} className="product_img img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="productDiv-info">
                                                <p className="proName">{productName1}</p>
                                                <div className="div proexInfo">
                                                    <p className="price"><strong className="fntQuantBd">${productPrice1}</strong></p>
                                                    <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <select type="" name="second_product_id" className="form-control mb-3" defaultValue={"0"} onChange={handelInput}>
                                    <option value="0" data-img2={Product2} data-name2={"3 Month Neuro Tech IQ Pack"} data-price2={'99'}>Select Second Product</option>
                                    {products.map(products => {
                                        return (
                                            <option value={products.id} key={products.id} data-img2={products.image} data-name2={products.name} data-price2={products.price} >
                                                {products.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div id="product_2_wrapper" className="package2 plan-selection">
                                    <div id="payProd2" className="payProd">
                                        <h3 className="payProdTitle">
                                            <span className="title16 fntQuantBd">
                                            <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Skincare Package 2"}
                                                endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: value }) }}
                                            />
                                            </span>
                                        </h3>
                                        <div className="productDiv">                                        
                                            <div className="productDiv-img">
                                                <span className="radio"></span>
                                                <div className="proImg">
                                                    <img src={productImg2?productImg2:pro2} className="img-fluid product_img" alt="" />
                                                </div>
                                            </div>
                                            <div className="productDiv-info">
                                                <p className="proName">{productName2}</p>
                                                <div className="div proexInfo">
                                                    <p className="price"><strong className="fntQuantBd">${productPrice2}</strong></p>
                                                    <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <select type="" name="third_product_id" className="form-control mb-3" defaultValue={"0"} onChange={handelInput}>
                                    <option value="0" data-img3={Product3} data-name3={"3 Month Neuro Tech IQ Pack"} data-price3={'99'}>Select Third Product</option>
                                    {products.map(products => {
                                        return (
                                            <option value={products.id} key={products.id} data-img3={products.image} data-name3={products.name} data-price3={products.price} >
                                                {products.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div id="product_3_wrapper" className="package3 plan-selection">
                                    <div id="payProd3" className="payProd">
                                        <h3 className="payProdTitle">
                                            <span className="title16 fntQuantBd">
                                            <CTE inputClass="wrapperText" textClass="textEdit" initialValue={"Skincare Package 3"}
                                                endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: value }) }}
                                            />
                                            </span>
                                        </h3>
                                        <div className="productDiv">                                        
                                            <div className="productDiv-img">
                                                <span className="radio"></span>
                                                <div className="proImg">
                                                    <img src={productImg3?productImg3:pro3} className="img-fluid product_img" alt="" />
                                                </div>
                                            </div>
                                            <div className="productDiv-info">
                                                <p className="proName">{productName3}</p>
                                                <div className="div proexInfo">
                                                    <p className="price"><strong className="fntQuantBd">${productPrice3}</strong></p>
                                                    <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-delivery">
                                <h3>USPS First-Class 2-3s Day Delivery</h3>
                                <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                            </div>
                            <div className="order-counter">
                                <article className="clearfix">
                                    <div className="left">Shipping Type:</div>
                                    <div id="block2_shipping" className="right"><b>FREE</b> Priority Shipping</div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                                <article className="clearfix">
                                    <div className="left">Shipping Price:</div>
                                    <div id="block2_price" className="right"><b>$0.00</b></div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                                <article className="clearfix">
                                    <div className="left">Your Total:</div>
                                    <div id="block2_total" className="right">$80</div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                                <article className="last clearfix">
                                    <div id="block2_total2" className="left">Retail: $80.00</div>
                                    <div id="block2_save" className="right"><b>You Save: $0.00</b></div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                            </div>
                            <div className="order-satisfaction">
                                <h3>Satisfaction Guarantee!</h3>
                                <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                            </div>
                            <div className="secu-1">
                                <div className="row">
                                    <div className="col-md-9 mx-auto text-center">
                                        <div className="secImg-1"><img src={postalLogo} alt="" /></div>
                                        <div className="arrive-date">Your order is due to arrive on <span className="orange">undefined 12 2022</span> .</div>
                                    </div>
                                </div>
                            </div>
                            <div className="secu-2">
                                <div className="row">
                                    <div className="col-lg-9 mx-auto text-center">
                                        <div className="row secu-row">
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge1} alt="" /></div>
                                            </div>
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge2} alt="" /></div>
                                            </div>
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge3} alt="" /></div>
                                            </div>
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge4} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Left End  */}
                        {/* <!-- Right  --> */}
                        <div id="" className="formBox center-block payRight">
                            <img className="w-100" src={paymentForm} />
                            <div className="checkout14-form-div">
                                <p className="checkout-form-title"><strong className="allCards">We Accept:</strong> <img src={paymentMultiCards} alt="Accepted Cards" width="180px" /></p>
                                <form action="">
                                    <div className="form-div-1">
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Credit Card Number" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="password" placeholder="Security Code" />
                                        </div>
                                        <div className="form-holder mb-0 cc_form-holder">
                                            <select name="cc_exp_month" className="form-control exp_month">
                                                <option value="01">(01) Jan</option>
                                                <option value="02">(02) Feb</option>
                                                <option value="03">(03) Mar</option>
                                                <option value="04">(04) Apr</option>
                                                <option value="05">(05) May</option>
                                                <option value="06">(06) Jun</option>
                                                <option value="07">(07) Jul</option>
                                                <option value="08">(08) Aug</option>
                                                <option value="09">(09) Sep</option>
                                                <option value="10">(10) Oct</option>
                                                <option value="11">(11) Nov</option>
                                                <option value="12">(12) Dec</option>
                                            </select>
                                            <select name="cc_exp_year" className="form-control exp_year">
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                <option value="2033">2033</option>
                                                <option value="2034">2034</option>
                                                <option value="2035">2035</option>
                                                <option value="2036">2036</option>
                                                <option value="2037">2037</option>
                                                <option value="2038">2038</option>
                                                <option value="2039">2039</option>
                                                <option value="2040">2040</option>
                                                <option value="2041">2041</option>
                                                <option value="2042">2042</option>
                                            </select>
                                        </div>
                                        {/* <div className="checkoutBtn-div">
                                            <Link className="btn checkoutBtn" to="#"></Link>
                                        </div> */}
                                    </div>
                                    <label className="checkboxLabel mb-3">
                                        <input type="checkbox" className="check" />
                                        Shipping is the same as billing
                                    </label>
                                    <div className="form-div-2">
                                        <div className="form-holder">
                                            <label className="formLabel">Address</label>
                                            <input className="form-control" type="text" placeholder="Address" />
                                        </div>
                                        <div className="form-holder">
                                            <label className="formLabel">City:</label>
                                            <input className="form-control" type="text" placeholder="City" />
                                        </div>  
                                        <div className="form-holder">
                                            <label className="formLabel">State:</label>
                                            <select className="form-control">
                                                <option value="01">Alabama</option>
                                            </select>
                                        </div>
                                        <div className="form-holder">
                                            <label className="formLabel">Zip Code:</label>
                                            <input className="form-control" type="text" placeholder="Billing Zip Code" />
                                        </div>
                                        <div className="form-holder">
                                            <label className="formLabel">Country:</label>
                                            <select className="form-control">
                                                <option value="01">United States</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="checkoutBtn-div mb-4">                                                
                                        <Link to="#" className="btn checkoutBtn"></Link>
                                    </div>
                                    {/* <div className="form-div-2">
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="First Name" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Last Name" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="email" placeholder="Email Address" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="tel" placeholder="Phone Number" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Billing Address" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Billing City" />
                                        </div>
                                        <div className="form-holder">
                                            <select className="form-control">
                                                <option value="01">Alabama</option>
                                            </select>
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Billing Zip Code" />
                                        </div>
                                        <div className="form-holder">
                                            <select className="form-control">
                                                <option value="01">United States</option>
                                            </select>
                                        </div>
                                        <div className="checkoutBtn-div">
                                            <Link to="#" className="btn checkoutBtn"></Link>
                                        </div>
                                    </div> */}
                                </form>
                            </div>
                            {/* <!-- Trans  --> */}
                            <div className="trans"><img loading="lazy" src={paymentMulti} alt="Safe and secure" width="100%" height="194" /></div>
                            {/* <p><!-- safe Checkout  --></p> */}
                            <div className="w_item w_item_1 section-guranteed">
                                <div className="w_inner">
                                    <div className="w_thumb">
                                        <picture> <img className="no-lazy" src={guaranteed} alt="" /> </picture>
                                    </div>
                                    <div className="w_desc">
                                        <p>
                                            GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Container End  */}
                {/* Security Sec Begin  */}
                <div class="securityIcon-sec">
                    <div class="container">
                        <ul class="order-icons-list">
                            <li><img src={securityIcon1} alt="privacy verified" width="86" /></li>
                            <li><img src={securityIcon2} alt="certified" width="86" /></li>
                            <li><img src={securityIcon3} alt="security verified" width="86" /></li>
                            <li><img src={securityIcon4} alt="business verified" width="85" /></li>
                            <li>
                                <img loading="lazy" src={securityIcon5} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                            </li>
                            <li><img src={securityIcon6} alt="united states" width="299" /></li>
                            <li><img src={securityIcon7} alt="shop online" width="187" /></li>
                        </ul>
                    </div>
                </div>
                 {/* Security Sec End  */}
            </div>

            {/* Footer  */}
            <Copyright/>  
            <div className='card-body'>
                    <div className="text-center text-md-end">
                        <button className='btn btn-success w-25' onClick={submitCheckoutFunnel}>Save</button>
                    </div>
                </div>  
            {/* Footer End         */}
        
        </>}
        
        {show === "2" && <>  <div className="container">
                <div className="clearFix">
                    <div className="order__left">
                        <div className="steps">
                            <ul className="steps__list clearFix">
                                <li className="steps__item">1. Shipping Info</li>
                                <li className="steps__item active">2. Finish Order</li>
                                <li className="steps__item">3. Summary</li>
                            </ul>
                            <div className="approved-text">
                                <strong><span className="emphasis">APPROVED!</span> Free Bottle Packages Confirmed</strong>
                            </div>
                            <p>Limited supply available as of <span className="full-date date-container2 text-danger">Friday, 1/7/2022 </span>. We currently have product <strong>in stock</strong> and ready to ship within 24 hours.</p>
                            <p className="status-pr pt-0">Sell Out Risk: <span>HIGH</span></p>
                        </div>
                        <div className="product-selection">
                            {/* Package 1  selected_pack */}
                            <select type="" name="first_product_id" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                <option value="0" data-img1={Product1} data-name1={"3 Month Neuro Tech IQ Pack"} data-price1={'99'} >Select First Product</option>

                                {products.map(products => {
                                    return (
                                        <option value={products.id} key={products.id} data-img1={products.image} data-name1={products.name} data-price1={products.price}>
                                            {products.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="product product1 sel-prod active" data-package="5" data-productid="71" data-retail="345" data-total="195.00" data-save="150.00">
                                <div className="package-item">
                                    <div className="package-item__header">
                                        <div className="title-block">
                                            <span className="title-block__main">    <CTE inputclassName="wrapperText" textclassName="textEdit" initialValue={"BUY 3 GET 2 FREE*"}
                                                endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_label']: value }) }}
                                            /></span><br />
                                            <span className="title-block__retail">
                                                Retail:<br />
                                                <span className="retail-price">$39.00</span>/bottle
                                            </span>
                                        </div>
                                        <div className="shipping-row"><CTE textclassName="textEdit" initialValue={"FREE SHIPPING"}
                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_shipping_id']: value }) }}
                                        /></div>
                                    </div>
                                    <div className="package-item__content">
                                        <div className="package-item__status"><span></span></div>
                                        <div className="package-images">
                                            <div className="package-images__item">
                                                <img className="prod-btl1" src={productImg1} alt="" /><br />
                                                {/* <img className="prod-btl2" src={checkoutProduct} alt="" /><br />
                                                <img className="prod-btl3" src={checkoutProduct} alt="" /> */}
                                            </div>
                                            {/* <img className="chk-plus" src={plusIcon} alt="" /> */}
                                            {/* <div className="package-images__item">
                                                <img className="prod-btl4" src={checkoutProduct} alt="" /><br />
                                                <img className="prod-btl5" src={checkoutProduct} alt="" />
                                            </div> */}
                                            <div className="package__save">
                                                <span className="package__save_title">SAVE</span><br />
                                                <span className="package__save_item save-price">$<CTE inputclassName="wrapperTextdark" textclassName="textEdit" initialValue={"150"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_save']: value }) }}
                                                /></span>
                                            </div>
                                        </div>
                                        <div className="package-info">
                                            <span className="check-package-info__title">
                                                For Those Who Need<br />
                                                to Lose 25+ Pounds!
                                            </span>
                                            <br />
                                            <span className="package-info__subTitle">same as</span><br />
                                            <div className="package-info__price">
                                                $<CTE inputclassName="wrapperTextdark" textclassName="textEdit" initialValue={"39.00"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['first_product_each']: value }) }}
                                                />
                                                <span className="perBottle">/bottle</span>
                                                <span className="package-info__btn">Selected!</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Package 2  */}

                            <select type="" name="second_product_id" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                <option value="0" data-img2={Product2} data-name2={"3 Month Neuro Tech IQ Pack"} data-price2={'99'}>Select Second Product</option>
                                {products.map(products => {
                                    return (
                                        <option value={products.id} key={products.id} data-img2={products.image} data-name2={products.name} data-price2={products.price} >
                                            {products.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="product product2 sel-prod" data-package="3" data-productid="72" data-retail="207.00" data-total="147.00" data-save="60.00">
                                <div className="package-item">
                                    <div className="package-item__header">
                                        <div className="title-block">
                                            <span className="title-block__main"> <CTE inputclassName="wrapperText" textclassName="textEdit" initialValue={"BUY 2 GET 1 FREE*"}
                                                endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_label']: value }) }}
                                            /></span><br />
                                            <span className="title-block__retail">
                                                Retail:<br />
                                                <span className="retail-price">$49.00</span>/bottle
                                            </span>
                                        </div>
                                        <div className="shipping-row"><CTE inputclassName="wrapperText" textclassName="textEdit" initialValue={"FREE SHIPPING"}
                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_shipping_id']: value }) }}
                                        /></div>
                                    </div>
                                    <div className="package-item__content">
                                        <div className="package-item__status"><span></span></div>
                                        <div className="package-images">
                                            <div className="package-images__item">
                                                <img className="prod-btl1" src={productImg2} alt="" /><br />
                                                {/* <img className="prod-btl3" src={checkoutProduct} alt="" /> */}
                                            </div>
                                            {/* <img className="chk-plus" src={plusIcon} alt="" /> */}
                                            {/* <div className="package-images__item"><img className="prod-btl5" src={checkoutProduct} alt="" /></div> */}
                                            <div className="package__save">
                                                <span className="package__save_title">SAVE</span><br />
                                                <span className="package__save_item save-price">$<CTE inputclassName="wrapperTextdark" textclassName="textEdit" initialValue={"74.95"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_save']: value }) }}
                                                /></span>
                                            </div>
                                        </div>
                                        <div className="package-info">
                                            <span className="check-package-info__title">
                                                For Those Who Need<br />
                                                to Lose 15+ Pounds!
                                            </span>
                                            <br />
                                            <span className="package-info__subTitle">same as</span><br />
                                            <div className="package-info__price">
                                                <p className="price d-inline-block">$<CTE inputclassName="wrapperTextdark" textclassName="textEdit" initialValue={"49.00"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['second_product_each']: value }) }}
                                                /></p>
                                                <span className="perBottle">/bottle</span>
                                                <span className="package-info__btn">Select Package</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <select type="" name="third_product_id" className="form-control" defaultValue={"0"} onChange={handelInput}>
                                <option value="0" data-img3={Product3} data-name3={"3 Month Neuro Tech IQ Pack"} data-price3={'99'}>Select Third Product</option>
                                {products.map(products => {
                                    return (
                                        <option value={products.id} key={products.id} data-img3={products.image} data-name3={products.name} data-price3={products.price} >
                                            {products.name}
                                        </option>
                                    );
                                })}
                            </select>
                            <div className="product product3 sel-prod" data-package="1" data-productid="74" data-retail="69.00" data-total="69.00" data-save="0.00">
                                <div className="package-item">
                                    <div className="package-item__header">
                                        <div className="title-block">
                                            <span className="title-block__main"> <CTE inputclassName="wrapperText" textclassName="textEdit" initialValue={"BUY 1 BOTTLE"}
                                                endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_label']: value }) }}
                                            /></span><br />
                                            <span className="title-block__retail">
                                                Retail:<br />
                                                <span className="retail-price">$69.00</span>/bottle
                                            </span>
                                        </div>
                                        <div className="shipping-row"><CTE inputclassName="wrapperText" textclassName="textEdit" initialValue={"FREE SHIPPING"}
                                            endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_shipping_id']: value }) }}
                                        /></div>
                                    </div>
                                    <div className="package-item__content">
                                        <div className="package-item__status"><span></span></div>
                                        <div className="package-images">
                                            <div className="package-images__item"><img className="prod-btl1" src={productImg3} alt="" /></div>
                                            <div className="package__save">
                                                <span className="package__save_title">SAVE</span><br />
                                                <span className="package__save_item save-price">$<CTE inputclassName="wrapperTextdark" textclassName="textEdit" initialValue={"0.00"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_save']: value }) }}
                                                /></span>
                                            </div>
                                        </div>
                                        <div className="package-info">
                                            <span className="check-package-info__title">
                                                For Those Who Need<br />
                                                to Lose 7+ Pounds!
                                            </span>
                                            <br />
                                            <span className="package-info__subTitle">&nbsp;</span><br />
                                            <div className="package-info__price">
                                                <p className="price d-inline-block">$<CTE inputclassName="wrapperTextdark" textclassName="textEdit" initialValue={"69.00"}
                                                    endEditing={(value) => { setCheckoutFunnelInstance({ ...CheckoutFunnelInstance, ['third_product_each']: value }) }}
                                                /></p>
                                                <span className="perBottle">/bottle</span>
                                                <span className="package-info__btn">Select Package</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p><span className="footnote">* at retail price</span></p>
                                <div className="order-counter">
                                    <article className="clearfix">
                                        <div className="left">Shipping Type:</div>
                                        <div id="block2_shipping" className="right text-right"><b>FREE</b> Priority Shipping</div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="left">Shipping Price:</div>
                                        <div id="block2_price" className="right text-right"><b>$0.00</b></div>
                                    </article>
                                    <article id="" className="clearfix ws_vip">
                                        <div className="left">Sapphire Membership:</div>
                                        <div id="block2_price" className="right text-right"><b>$0.00</b></div>
                                    </article>
                                    <article className="clearfix">
                                        <div className="left">Your Total:</div>
                                        <div id="block2_total" className="right text-right">$195.00</div>
                                    </article>
                                    <article className="last clearfix">
                                        <div id="block2_total2" className="left">Retail: $345.00</div>
                                        <div id="block2_save" className="right text-right"><b>You Save: $150.00</b></div>
                                    </article>
                                </div>
                            </div>
                            <p>
                                {/* <!-- Price Chart End --> */}
                                <br />
                                <img className="img-fluid secure-icons" src={orSecureicons} />
                            </p>
                            <div className="guarantee-block">
                                <div className="guarantee-top">30 days money back guarantee</div>
                                <div className="guarantee-content">
                                    <img className="guarantee-icon" src="images/acv-checkout/guarantee-ico-30days.png" alt="" />
                                    <div className="guarantee-text">
                                        <p>
                                            We are so confident in our products and services, that we back it with a 30 days money back guarantee. If for any reason you are not fully satisfied with our products, simply return the purchased
                                            products in the original container within 30 days of when you received your order. We will refund you 100% of the purchase price – with absolutely no hassle.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="line-block">
                                <div className="line">
                                    <div className="text-center">HURRY! CONFIRM YOUR ORDER NOW!</div>
                                </div>
                                <div className="arrow">&nbsp;</div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Order Right --> */}

                    <div className="order__right">
                        <div className="form__header">
                            <h2>FINAL STEP:</h2>
                            <h3>PAYMENT INFORMATION</h3>
                        </div>
                        <div className="form__in">
                            <span className="accept-text">We Accept:</span>
                            <ul className="form__cards">
                                <li><img src={visa} alt="" /></li>
                                <li><img src={mastercard} alt="" /></li>
                                <li><img src={discover} alt="" /></li>
                                <li><img src={amex} alt="" /></li>
                            </ul>
                            <div id="order_form" className="paymentform form">
                                <label className="payment_as_shipping_label d-none" htmlFor="payment_as_shipping"><input id="payment_as_shipping" className="chkbox bill-inp d-none" checked="checked" readOnly name="" type="checkbox" />Billing same as Shipping<br /></label>
                                <div className="billing-info d-none">
                                    <div className="billing-form">
                                        {/* <!-- Billing Address  --> */}
                                        <div className="billingaddr-inner  d-none">
                                            <p className="billing-title">Billing Info</p>
                                            <div className="form-holder">
                                                First Name:<br />
                                                <input type="text" id="first_name" name="first_name" value="test" className="form-control required" placeholder="First Name" />
                                            </div>
                                            <div className="form-holder">
                                                Last Name:<br />
                                                <input type="text" id="last_name" name="last_name" value="test" className="form-control required" placeholder="Last Name" />
                                            </div>
                                            <div className="form-holder">
                                                Email:<br />
                                                <input type="text" id="email" name="email" value="admin@admin.com" className="form-control required" placeholder="Email Address" />
                                            </div>
                                            <div className="form-holder">
                                                Password:<br />
                                                <input type="password" autoComplete="off" id="password" name="password" className="form-control required" placeholder="Password" />
                                            </div>
                                            <div className="form-holder">
                                                Phone:<br />
                                                <input type="text" id="phone" name="phone" value="1234567890" className="form-control required" placeholder="Phone Number" />
                                            </div>

                                            <div className="form-holder">
                                                <div className="phone-12 columns"><label>Country:</label></div>
                                                <select id="billing_country" name="billing_country" className="mm-selectList">
                                                    <option value="US" selected="">United States</option>
                                                </select>
                                            </div>
                                            <div className="form-holder">
                                                <div className="phone-12 columns"><label>State/Region:</label></div>
                                                <select id="billing_state_dd" size="1" className="mm-selectList billing_country_subdivisions" placeholder="Billing State">
                                                    <option value="">Please select your state</option>
                                                    <option value="AL" selected="selected">Alabama</option>
                                                    <option value="AK">Alaska</option>
                                                    <option value="AS">American Samoa</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="DC">District of Columbia</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>
                                                    <option value="GU">Guam</option>
                                                    <option value="HI">Hawaii</option>
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="MP">Northern Mariana Islands</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="PR">Puerto Rico</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UM">United States Minor Outlying Islands</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VI">Virgin Islands</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>
                                                    <option value="-1">Other…</option>
                                                </select>
                                            </div>

                                            <div className="form-holder">
                                                <div className="phone-12 columns"><label>Address:</label></div>
                                                <div id="billingAddress" className="form-holder">
                                                    <input type="text" id="billing_address" name="billing_address" value="test" className="form-control required" placeholder="Billing Address" />
                                                </div>
                                            </div>
                                            <div className="form-holder">
                                                <div className="phone-12 columns"><label>City:</label></div>
                                                <div className="form-holder"><input type="text" id="billing_city" name="billing_city" value="Test" className="form-control required" placeholder="Billing City" /></div>
                                            </div>
                                            <div className="form-holder">
                                                <div className="phone-12 columns"><label>Zip Code:</label></div>
                                                <div id="billingZipCode" className="form-holder">
                                                    <input type="number" id="billing_zip" name="billing_zip" value="11111" className="form-control required" placeholder="Billing Zip Code" />
                                                </div>
                                            </div>




                                        </div>
                                        {/* <!-- Shipping Address  --> */}
                                        <div className="shippingAddr-inner d-none">
                                            <h3>Shipping Address</h3>
                                            <p id="mm-shipping-method-block" className="mm-formField">
                                                <label>Shipping Method:</label><br />
                                                <select id="shipping_method" name="shipping_method" className="mm-selectList">
                                                    <option value="FLATRATE-1">9.95 Flat Rate Shipping ($9.95)</option>
                                                </select>
                                            </p>
                                            <p className="mm-formField">
                                                Shipping is the same as billing<br />
                                                <input type="checkbox" id="mm_checkbox_billing_equals_shipping" checked="" className="mm-radioButton" readOnly />
                                            </p>
                                            <div>
                                                <p className="mm-formField"><input type="text" id="shipping_address" name="shipping_address" value="test" className="mm-textField" placeholder="Shipping Address" /></p>
                                                <p className="mm-formField"><input type="text" id="shipping_city" name="shipping_city" value="Test" className="mm-textField" placeholder="Shipping City" /></p>
                                                <p className="mm-formField">
                                                    <select id="shipping_state_dd" size="1" className="mm-selectList shipping_country_subdivisions" placeholder="Shipping State">
                                                        <option value="">Please select your state</option>
                                                        <option value="AL" selected="selected">Alabama</option>
                                                        <option value="AK">Alaska</option>
                                                        <option value="AS">American Samoa</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="DC">District of Columbia</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NJ">New Jersey</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="MP">Northern Mariana Islands</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UM">United States Minor Outlying Islands</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VI">Virgin Islands</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>
                                                        <option value="-1">Other…</option>
                                                    </select>

                                                </p>
                                                <p className="mm-formField"><input type="text" id="shipping_zip" name="shipping_zip" value="11111" className="mm-textField" placeholder="Shipping Zip Code" /></p>
                                                <p className="mm-formField">
                                                    <select id="shipping_country" name="shipping_country" className="mm-selectList">
                                                        <option value="US" selected="">United States</option>
                                                    </select>
                                                </p>
                                            </div>
                                        </div>
                                        {/* <!-- Shipping Address End  --> */}
                                    </div>

                                </div>
                                <p><span className="billing-title">Payment Info</span></p>
                                <div className="margin-bottom-5">
                                    <div className="phone-12 columns"><label>Card Number:</label></div>
                                </div>
                                <div className="phone-12 columns form-holder">
                                    <input disabled type="text" maxLength="16" id="cc_number" className="form-control required masked" placeholder="Credit Card Number" />
                                    <div className="accept-icon"></div>
                                </div>
                                <div className="clearFix margin-bottom-5">
                                    <div className="phone-12 columns"><label>Card Expiry Date:</label></div>
                                </div>
                                <div className="clearFix expiryholder">
                                    <div className="phone-12 columns form-holder mb-0">
                                        <select disabled name="cc_exp_month" id="cc_exp_month" className="form-control mm-selectList" tabIndex="-1" aria-hidden="true">
                                            <option value="01">(01) Jan</option>
                                            <option value="02">(02) Feb</option>
                                            <option value="03">(03) Mar</option>
                                            <option value="04">(04) Apr</option>
                                            <option value="05">(05) May</option>
                                            <option value="06">(06) Jun</option>
                                            <option value="07">(07) Jul</option>
                                            <option value="08">(08) Aug</option>
                                            <option value="09">(09) Sep</option>
                                            <option value="10">(10) Oct</option>
                                            <option value="11">(11) Nov</option>
                                            <option value="12">(12) Dec</option>
                                        </select>
                                        <select disabled name="cc_exp_year" id="cc_exp_year" className="mm-selectList" tabIndex="-1" aria-hidden="true">
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2027">2027</option>
                                            <option value="2028">2028</option>
                                            <option value="2029">2029</option>
                                            <option value="2030">2030</option>
                                            <option value="2031">2031</option>
                                            <option value="2032">2032</option>
                                            <option value="2033">2033</option>
                                            <option value="2034">2034</option>
                                            <option value="2035">2035</option>
                                            <option value="2036">2036</option>
                                            <option value="2037">2037</option>
                                            <option value="2038">2038</option>
                                            <option value="2039">2039</option>
                                            <option value="2040">2040</option>
                                            <option value="2041">2041</option>
                                            <option value="2042">2042</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="clearFix margin-bottom-5">
                                    <div className="phone-12 columns"><label>CVV:</label></div>
                                </div>
                                <div className="clearFix">
                                    <div className="phone-6 columns form-holder">
                                        <input autoComplete="off" type="password" disabled maxLength="4" id="cc_cvv" name="cc_cvv" className="mm-textField" placeholder="Security Code" />
                                    </div>
                                    <div className="phone-12 columns">
                                        <span className="cvv-link"><a className="ccvwhatsthis form-link cvvbox" href="javascript:void(0)"> Where can I find my Security Code?</a><br /></span>
                                    </div>
                                </div>
                                <div className="cvv-image clear"><img src="images/acv-checkout/cvv-image.png" alt="" /></div>
                                <div className="form__footer mb-4">
                                    <div className="secure-icon"><span>Secure 256-bit SSL Encryption</span></div>
                                    <div className="btn-wrapper">
                                        <p><button disabled href="#" className="mm-button large green">RUSH MY ORDER</button></p>
                                    </div>
                                </div>
                                <div className='col-12 row'>
                                    <div className='col-8'>
                                        <p>Sapphire Membership</p>
                                    </div>
                                    <div className='col-4'>
                                        <label className="switch">
                                            <input type="checkbox" defaultChecked={true} name="membership_add_on" onChange={handelInput}></input>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>

                                {/* <!-- yellow Box  --> */}
                                <div className={CheckoutFunnelInstance.membership_add_on == 1 ? 'yellowbox mt-4' : 'd-none'} style={{ disply: CheckoutFunnelInstance.membership_add_on == 1 ? 'show' : 'none' }}>
                                    <i><img className="pulse" src="images/acv-checkout/brain/arrow2.png" alt="" /></i>
                                    <div className="check_area"><input id="checkD" className="vip_shipping_desk chkbox" checked="checked" readOnly type="checkbox" /><label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label></div>
                                </div>
                                {/* <!-- Top Term  --> */}
                                <div className={CheckoutFunnelInstance.membership_add_on == 1 ? 'top-terms open' : 'd-none'} style={{ disply: CheckoutFunnelInstance.membership_add_on == 0 ? 'show' : 'none' }}>
                                    <h1 className="top-termsHdng">Sapphire Membership Membership Terms</h1>
                                    <div className="top-trends-desc">
                                        <p>
                                            BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire
                                            Membership takes that one step further.
                                        </p>
                                        <p>
                                            As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire
                                            Membership Today!:
                                        </p>
                                        <p>-FREE shipping on all orders!</p>
                                        <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                        <p>-No hassle, no questions asked replacement on any items.</p>
                                        <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                        <p>-Get it all FREE for first 30 days!</p>
                                        <p>Some Notes About Your Membership:</p>
                                        <p>
                                            Remember – while your first month of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $17.00/ month to retain membership after your first month. If you wish to cancel
                                            your Sapphire Membership at any time, simply contact<br />
                                            our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className='card-body'>
                    <div className="text-center text-md-end">
                        <button className='btn btn-success w-25' onClick={submitCheckoutFunnel}>Save</button>
                    </div>
                </div>
                {/* footer End  */}  </>}

        </>

    )
}

export default Template