import React, { useState, useEffect } from "react";
import { Link, useNavigate }  from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import betterHealthImage from '../../assests/frontend/assets/images/better-health-overnight-cover_01-e1626439710315-232x300.jpg';
import useStore from '../../store';
import {BASE_URL} from "../Config";
import Sidebar2 from "../../layouts/frontend/Sidebar2";

function Dashboard2(){
    const navigate = useNavigate();
    let userDetails = useStore((state) => state.userDetails);
    const [Details, setDetails] = useState([]);
    let imageURL = BASE_URL+"whc-custom/storage/app/public/image/";
    const isLogin = useStore((state) => state.isLogin);

    let getCategoryList = () => {
        axios.get(`api/listcategory`).then(res => {
            if (res.data.status === true) {
                setDetails(res.data.data);
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        getCategoryList();
    },[]);

    return(
        <>
            {(isLogin === 1) ?
            <div className="frontDashboard">
                <div className='container-fluid'>
                    {/* Row Begins  */}
                    <div className="row newShopList_row">
                        <Sidebar2/>
                        {/* Left Col Begin  */}
                        <div className="col-md-8 col_right">
                            <div>
                                <div className="breadcumNav-div text-center">
                                    <Link to="/" className="breadcrumbs_nav_link">Home</Link> <span>&#062;</span> <Link to="/dashboard"  className="breadcrumbs_nav_link last_elt">MY ACCOUNT DASHBOARD</Link> 
                                </div>
                                <div className="titleDiv text-center">
                                    <h2 className="title">Hello {userDetails.name}, Where Would You Like To Go?</h2>
                                </div>
                                {/* Row : Begin  */}
                                <div className="row prodList-row">

                                    {/* Col : Begin  */}
                                    { Details.map((item) => (
                                        (item.name != "Protein Powder") && (item.name != "CBD") && (item.name != "Membership") && (item.name != "Bundle Packs")? 
                                        <div className="col-lg-3 col-md-6 col-12 prodList-col">
                                            <div className="product_display">
                                                <div className="product_img">
                                                    <img src={imageURL+item.image} className="img-fluid" alt="" />
                                                </div>
                                                <Link to={"/shop/"+item.name.toLowerCase().replace(" ", "-")} className="prod_btn">{item.name}</Link>
                                            </div>
                                        </div>
                                        : ""
                                    ))}
                                    <div className="col-lg-3 col-md-6 col-12 prodList-col">
                                        <div className="product_display">
                                            <div className="product_img">
                                                <img src={betterHealthImage} className="img-fluid" alt="" />
                                            </div>
                                            <Link to={"/bonus"} className="prod_btn">Better Health Overnight Newsletter</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* Row End  */}
                            </div>
                        </div>
                        {/* Left Col End  */}
                    </div>
                    {/* Row Ends  */}
                </div>
            </div>
            : navigate("/login")}
        </>
    )
}




export default Dashboard2