import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { Input } from 'reactstrap';
import NoImage from '../../assests/admin/assets/img/nImage.jpg';
import {BASE_URL} from '../Config';


function Brand() {
    CheckPermission()

    const [show, setShow] = useState(false);
    const [List, setList] = useState([]);
    const [pending, setPending] = useState(true);
    const [Details, setDetails] = useState([]);
    const [Id, setId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchData, setsearchData] = useState([]);
    const [existingImage, setExistingImage] = useState('');
    let imgURL = BASE_URL+"/whc-custom/storage/app/public/image/";
    const [checkboxValue, setcheckboxValue] = useState(false);

    const handleClose = () => {
        setShow(false)
        setsearchData('')
    }
    const handleShow = () => setShow(true);

    async function getList() {
        axios.get(`api/listBrand`).then(res => {
            setList(res.data.data);
            setSearch(res.data.data)
            setPending(false);
        })

    }
    const columns = [
        {
            name: "Brand Name",
            selector: row => row.brand_name
        },
        {
            name: "Visibility",
            cell: row => (
                <label className="switch">
                    <input type="checkbox" defaultChecked={row.visibility == 1 ? true : false} name="visible"></input>
                    <span className="slider round"></span>
                </label>
            )
        },
        {
            name: "Image",
            selector: row => (
                <>
                <img src={row.brand_image === null ? NoImage : BASE_URL + "whc-custom/storage/app/public/image/" + row.brand_image} width="200px" height="100px" />
                </>
                
            )
        },
        {
            name: "Created",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteData(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getList()
    }, []);


    function createModal() {
        setDetails([]);
        setId(false);
        handleShow();
    }

    const formSubmit = (formData) => {
        const formdata = new FormData();
        formdata.append('brand_name', formData.brand_name);
        formdata.append('image', formData.image);
        formdata.append('visible', formData.visible === true ? '1' : '0');
        formdata.append('position', formData.position);
        axios.post(`api/createBrand`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getList();
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    function deleteData(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Brand?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteBrand/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getList();
                })
            }
        })
    }

    function showSingleData(id) {
        setId(id);
        axios.get(`api/detailsBrand/${id}`).then(res => {
            setExistingImage(res.data.brand_image);
            let Image = new File([''], res.data.brand_image, { type: "image/jpg", size: 0 }, 'utf-8');
            setDetails({
                brand_name: res.data.brand_name,
                image: Image,
                visible: res.data.visibility,
                position: res.data.position
            });

            handleShow();
        })
    }

    const navigate = useNavigate();

    const formUpdate = (formData) => {
        const formdata = new FormData();
        formdata.append('brand_name', formData.brand_name);
        formdata.append('image', formData.image);
        formdata.append('visible', formData.visible === true ? '1' : '0');
        formdata.append('position', formData.position);
        axios.post(`api/updateBrand/${Id}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getList();
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
    };

    useEffect(() => {
        const result = List.filter(country => {
            return country.brand_name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    return (
        <div>
            <div >
                <div className='modal'>
                    <Modal show={show} onHide={handleClose} className='custom-modal'>
                        <Modal.Header closeButton>
                            <Modal.Title>Brand</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body' style={{minHeight:'auto',overflowY:'hidden'}}>
                                                <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        brand_name: Details.brand_name,
                                                        image: Details.brand_image,
                                                        visible: Details.visible === 1 ? true : false,
                                                        //position: Details.position
                                                    }}

                                                    validationSchema={Yup.object({
                                                        brand_name: Yup.string().required('Brand name is required'),
                                                        image: Yup.mixed()
                                                            .nullable()
                                                            .required('Image is required')
                                                            .test('File Size',
                                                                'Upload file size is too large', (value) => !value || (value && value.size <= 1024000 * 1024000))
                                                            .test('format',
                                                                'Invalid file type. Only *.jpg,*.png,*.jpeg files acceptable. ', (value) => !value || (value && ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(value.type))),
                                                        //position: Yup.string().required('Position is required')
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        {(Id) ? formUpdate(values) : formSubmit(values); }
                                                    }}
                                                >
                                                {(formProps) => (
                                                    <Form className='row col-md-12'>
                                                        <div className='form-group md-3'>
                                                            <label> Brand Name </label>
                                                            <Field type="text" name="brand_name" className="form-control"></Field>
                                                            <CustomErrorMsg name="brand_name" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label> Image </label>
                                                            <Input type="file" name="image" className="form-control" onChange={(event) => formProps.setFieldValue("image", event.target.files[0])}/>
                                                            {Details.image && <div className='form-group md-3'><img src={imgURL + existingImage} width="200px" height="100px"/></div>}
                                                            <CustomErrorMsg name="image" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label> Visible </label>
                                                            <label className="switch" style={{marginLeft:16}}>
                                                                <Field type="checkbox" name="visible" onClick={(event) => formProps.setFieldValue("visible", event.target.checked)}  onChange={handleCheckboxChange} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                        {/* <div className='form-group md-3'>
                                                            <label> Position </label>
                                                            <Field type="text" name="position" className="form-control"></Field>
                                                            <CustomErrorMsg name="position" />
                                                        </div> */}
                                                        <div className='form-group md-3 text-end'>
                                                            <button type="submit" className='btn submitBtn btn-primary'>Save!</button>
                                                        </div>
                                                    </Form>
                                                )} 
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={Id ? update : submit}>
                                {Id ? "Update" : "Save"}
                            </Button>
                        </Modal.Footer> */}
                    </Modal>
                </div>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Brand List</div>
                            <div className="buttun_div  col-md-9">  <Button variant="primary" onClick={createModal}>
                                Create Brand
                            </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Brand table is empty" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brand