import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from '../../layouts/frontend/Navbar';
import Copyright from "../Copyright";
import axios from 'axios';
import Swal from 'sweetalert2';
import {BASE_URL} from '../Config';

import bookCover from '../../assests/frontend/assets/images/book-cover-free2.png';
import bundle3 from '../../assests/frontend/assets/images/bundle3-Bimg.png';
import tick from '../../assests/frontend/assets/images/check-blue.png';

function ThankYouCrypto(){
    let order_id = localStorage.getItem('order_id');
    const [price, setPrice] = useState('');

    let getOrderList = () => {
        const formdata = new FormData();
        formdata.append('order_id', order_id);
        axios.post(`api/get_order_list`, formdata).then(res => {
            console.log(res.data.data);
            if (res.data.status === true) {
                localStorage.setItem("V1email",'');
                setPrice(res.data.data.total);
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.message,
                icon: 'warning'
            })
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getOrderList();
    }, []);
    
    return(
        <>
            <Navbar></Navbar>
                <div className="cryptoThankYou-page">
                    <div className="container">
                        <div className="tnx-innerWrap">
                            <div className="inner_row row">
                                <div className="colm col1">
                                    <div className="confirmWrap">
                                        <div className="confirmWrap-main">
                                            <span className="tickIcon"><img src={tick} alt="" /></span>
                                            <h2 className="orderTitle">Thank you for your purchase of The Crypto Trading Guide</h2>
                                        </div>
                                        <div className="confirmWrap-info">
                                            <h4>Order Details:</h4>
                                            <div className="table-responsive">
                                                <table className="table conf-info-table">
                                                    <tbody>
                                                        <tr>
                                                            <th>Order Id</th>
                                                            <td id="orderId">{order_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <td>Successful Crypto Trading Guide</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Quantity</th>
                                                            <td id="order_quantity">1</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Price</th>
                                                            <td id="order_price">{price}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="colm col2">
                                    <a href={BASE_URL+'The-Crypto-Trading-Guide.pdf'} className="linkDiv" target="_blank">
                                        <h3>
                                            Click Here: <br />
                                            The Crypto Trading Guide
                                        </h3>
                                        <img src={bookCover} className="LinkImg" />
                                    </a>
                                </div>

                                <div className="colm col3">
                                    <a href={BASE_URL+'Bonus_ebooks.zip'} className="linkDiv" target="_blank">
                                        <h3>
                                            Click Here:<br />
                                            Bonuses!
                                        </h3>
                                        <img src={bundle3} className="LinkImg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Copyright/>
        </>
    );
}

export default ThankYouCrypto;