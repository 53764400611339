import React from 'react'
import { Link } from 'react-router-dom'

function AccessDenied() {
    return (
        <div className='consec'>

            <h1>Wholesale Health Club</h1>


            <div className="Auth-form-container">

                <form className="Auth-form" >
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">You don't have permission!</h3>


                        You can access the dashbaord from <Link to="/admin/dashboard"> here</Link> or login from <Link to="/admin/login"> here</Link>
                    </div>
                </form>
            </div>


        </div>
    )
}

export default AccessDenied