import React, { useState, useEffect } from 'react'
import Template from '../funneltemplates/Template-1'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom"; // v6
import MasterTemplate1 from "../CheckoutTemplates/MasterTemplate1";
import MasterTemplate3 from "../CheckoutTemplates/MasterTemplate3";
import BrainTemplate from "../LanderTemplate/BrainTemplate";

import Upsell from "../UpsellTemplate/Upsell";
import GummiesUpsell1 from "../UpsellTemplate/GummiesUpsell1";
import GummiesUpsell2 from "../UpsellTemplate/GummiesUpsell2";
import FaceCreamUpsell1 from "../UpsellTemplate/FaceCreamUpsell1";

import ThankYou from "../ThankYouTemplates/ThankYou";
import NotFound from "../admin/NotFound";
import ACVTemplate from "../LanderTemplate/AcvgummiesTemplate";
import AcvCheckout from "../CheckoutTemplates/AcvCheckout";
import FaceCream from "../LanderTemplate/FaceCreamTemplate.js";
import FaceCreamCheckout from '../CheckoutTemplates/FacecreamCheckout';
import NeobodUpsell from '../UpsellTemplate/NeobodUpsell';
import AcvgummiesMobileTemplate from "../LanderTemplate/AcvgummiesMobileTemplate";
import AcvMobileCheckout from "../CheckoutTemplates/AcvMobileCheckout";
import ThankYouCrypto from "../frontend/ThankYouCrypto";
import Nootropic from '../LanderTemplate/NootropicTemplate';
import NootropicCheckout from '../CheckoutTemplates/NootropicCheckout';
import SkincareCheckout from '../CheckoutTemplates/SkincareCheckout';
import Skincare from '../LanderTemplate/SkincareTemplate';

function MasterCheckout() {
    const params = useParams()
    const navigate = useNavigate();
    const [Data, setCheckoutFunnelData] = useState({})
    const [slugData, setSlugdata] = useState('')
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function setFunnelFlowData(data){
        //console.log(data);
        //axios.get(`api/getFlowByLanderId/${landerID}`).then(res1 => {
            // setData(res.data.data[0]);
            // console.log(res1.data)
            localStorage.removeItem('funnel');
            localStorage.removeItem('combine_product');
            localStorage.removeItem('combine_product_id');
            localStorage.removeItem('prospectId');
            localStorage.removeItem('method');
            localStorage.removeItem('c_s');
            localStorage.removeItem('con_s');
            localStorage.removeItem('up1_s');
            localStorage.removeItem('up2_s');
            localStorage.removeItem('up3_s');
            localStorage.removeItem('up4_s');
            localStorage.removeItem('up5_s');
            localStorage.removeItem('order_id');
            localStorage.removeItem('membership_orderId');
            localStorage.removeItem('orderId');
            localStorage.removeItem('Upsell1_orderId');
            localStorage.removeItem('Upsell2_orderId');
            localStorage.removeItem('CCNumber');
            localStorage.removeItem('CCType');
            localStorage.removeItem('cvv');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('neobodOrderId');
            localStorage.removeItem('neobodOrderStatus');

            localStorage.removeItem('firstName');
            localStorage.removeItem('lastName');
            localStorage.removeItem('email');
            localStorage.removeItem('phone');
            localStorage.removeItem('shippingAddress1');
            localStorage.removeItem('shippingCity');
            localStorage.removeItem('shippingState');
            localStorage.removeItem('shippingZip');
            localStorage.removeItem('country');
            localStorage.removeItem('billingSameAsShipping');
            localStorage.removeItem('shipping_address');
            localStorage.removeItem('shipping_city');
            localStorage.removeItem('shipping_state');
            localStorage.removeItem('shipping_zip');
            localStorage.removeItem('shipping_country');

            localStorage.removeItem('affid');
            localStorage.removeItem('sid');
            localStorage.removeItem('s1');
            localStorage.removeItem('s2');
            localStorage.removeItem('s3');
            localStorage.removeItem('click_id');
            localStorage.removeItem('notes');
            
            localStorage.setItem('c_s', data.checkout.funnel_slug)
            localStorage.setItem('con_s', data.confirmation.confirmation_slug)

            if (data.upsell_1) {
                localStorage.setItem('up1_s', data.upsell_one.upsell_slug)
            }

            if (data.upsell_2) {
                localStorage.setItem('up2_s', data.upsell_two.upsell_slug)
            }

            if (data.upsell_3) {
                localStorage.setItem('up3_s', data.upsell_three.upsell_slug)
            }

            if (data.upsell_4) {
                localStorage.setItem('up4_s', data.upsell_four.upsell_slug)
            }

            if (data.upsell_5) {
                localStorage.setItem('up5_s', data.upsell_five.upsell_slug)
            }
        //})
    }

    async function getCheckoutFunnelBySlug() {

        const slug = params.slug

        if (slug != null) {
            await axios.get(`api/getSlugDataBySlug/${slug}`).then(res => {
                if (res.data.status) {
                    //console.log(res.data.data[0].template_name);
                    setSlugdata(res.data.data[0].template_name);
                    setCheckoutFunnelData(res.data.checkoutData.data);
                    if(Object.values(res.data.funnelFlow).length !== 0){
                        setFunnelFlowData(res.data.funnelFlow[0]);
                    }
            
                }
                else {
                    //console.log(res.data.status);
                    navigate('/notfound')
                }

            })
        }
    }
    useEffect(() => { getCheckoutFunnelBySlug(); }, []);

    let component = null;
    
    //Select Component based on Slug
    //console.log(slugData);
    if(slugData != '' )
    {
        
        switch(slugData) {
            
            //Checkout Templates 
            case 'MasterTemplate1':
            component = <MasterTemplate1 data={Data} />;
            break;

            case 'AcvCheckout':
            component = <AcvCheckout data={Data} /> 
            break;
            
            case 'FaceCreamCheckout':
            component = <FaceCreamCheckout data={Data}/>;
            break;

            case 'MasterTemplate3':
                
            component = <MasterTemplate3 data={Data} />;
            break;

            case 'AcvMobileCheckout':
            component = <AcvMobileCheckout data={Data} />;
            break;

            case 'NootropicCheckout':
            component = <NootropicCheckout data={Data} />;
            break;

            case 'SkincareCheckout':
            component = <SkincareCheckout data={Data} />;
            break;

            //Upsell Templates 
            case 'sleep-formula':
            component = <Upsell />;
            break;

            case 'total-cleanse-formula':
            component = <GummiesUpsell1 />;
            break;

            case 'double-offer':
            component = <GummiesUpsell2  />;
            break;

            case 'blue-forrest-goddess-eye-cream':
            component = <FaceCreamUpsell1 />;
            break;

            case 'neobod-upsell':
            component = <NeobodUpsell />;
            break;
            

            //Lander Templates 

            case 'ACV-Gummies':
            component = <ACVTemplate />
            break;

            case 'Facecream':
            component = <FaceCream />;
            break;

            case 'Brain':
            component = <BrainTemplate />;
            break;

            case 'Acvgummies-Mobile-Lander':
            component = <AcvgummiesMobileTemplate />;
            break;

            case 'Nootropic':
            component = <Nootropic />;
            break;

            case 'Skincare':
            component = <Skincare />;
            break;

            //Confirmation Templates 

            case 'thankyou':
            component = <ThankYou />;
            break;

            case 'crypto-thank-you':
            component = <ThankYouCrypto />;
            break;

          default:
            component = <NotFound />;
        }
        
    }
    
    return (
        <>
       {component}
        </>

    )
}

export default MasterCheckout