import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import CheckPermission from '../frontend/auth/CheckPermission';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";

function CrmInstance() {
    CheckPermission()

    const [crms, setCRMS] = useState([]);
    const [crmInstance, setCrmInstance] = useState({
        type: "Sticky.io",
        label: "",
        endpoint: "",
        u_name: "",
        pass: "",
        errorendpoint: ""

    });
    const [errors, setErros] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    // const formik = useFormik({
    //     initialValues: { crmInstance: initialState }
    // });
    const initialState = {
        type: "Sticky.io",
        label: "",
        endpoint: "",
        u_name: "",
        pass: "",

    };


    const [showAddModal, setshowAddModal] = useState(false);
    const [loading, setLoading] = useState(true);



    const handleAddClose = () => {
        setshowAddModal(false);
        setErros({});
        setCrmInstance({ ...initialState });
        setcrminstantID(false)
        setCRMDetails([]);
    }
    const handleAddShow = () => setshowAddModal(true);

    const handelInput = (e) => {
        e.persist();
        if (crminstantID === true) {
            setErros(false);
        }
        setIsSubmit(true);
        setCrmInstance({ ...crmInstance, [e.target.name]: e.target.value })
    }


    const handelSubmit = (e) => {
        e.preventDefault();
        setErros(validate(crmInstance));
        setIsSubmit(true);
        setcrminstantID(false);
        // console.log(errors)


    }


    const validate = (crmInstance) => {

        const errors = {};
        const regex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!crmInstance.label) {
            errors.label = "Must provide a label";

        }
        if (!crmInstance.u_name) {
            errors.u_name = "Must provide an API username!";

        }
        if (!crmInstance.pass) {
            errors.pass = "Must provide an API password!";

        }
        if (!crmInstance.endpoint) {
            errors.endpoint = "Must provide an API endpoint!";

        } else if (!regex.test(crmInstance.endpoint)) {
            errors.endpoint = "Please provide an valid API endpoint!";
        }
        return errors;
    }


    const [crminstantID, setcrminstantID] = useState(false);
    const [crnDetails, setCRMDetails] = useState([]);
    const editCRMInstance = (e, id) => {
        // console.log(id);
        setcrminstantID(id);
        axios.get(`api/detailscrm/${id}`).then(res => {
            setCRMDetails(res.data);
            console.log('single crm details' + res.data.provider_label)
            crmInstance.label = res.data.provider_label
            crmInstance.u_name = res.data.api_username
            crmInstance.endpoint = res.data.api_endpoint
            crmInstance.pass = res.data.api_password
            console.log('inistal state' + crmInstance.label)
            setErros({});
            handleAddShow();
        })
    }

    const handelUpdate = () => {
        setErros(validateUpdate(crmInstance));



    }

    const validateUpdate = (crmInstance) => {

        const errors = {};
        const regex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        if (!crmInstance.label) {
            errors.label = "Must provide a label";

        }
        if (!crmInstance.u_name) {
            errors.u_name = "Must provide an API username!";

        }
        if (!crmInstance.pass) {
            errors.pass = "Must provide an API password!";

        }
        if (!crmInstance.endpoint) {
            errors.endpoint = "Must provide an API endpoint!";

        } else if (!regex.test(crmInstance.endpoint)) {
            errors.endpoint = "Please provide an valid API endpoint!";
        }
        return errors;
    }

    async function getCRMList() {

        axios.get(`api/listcrm`).then(res => {


            setCRMS(res.data);

            setLoading(false);



        }).catch(err => {




        }
        )
    }

    const delectCRMInstance = (e, id) => {
        e.preventDefault();

        Swal.fire({
            title: 'Are you sure you want to delete this CRM API?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deletecrm/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    window.location.reload();
                })
            }
        })

    }

    const updateCRM = (data) => {

        // const formdata = new FormData();
        // formdata.append('provider_label', data.label);
        // formdata.append('api_username', data.u_name);
        // formdata.append('api_password', data.pass);
        // formdata.append('api_endpoint', data.endpoint);
        // formdata.append('billing_type', 'test');

        const dataA = {
            provider_label: data.label,
            api_username: data.u_name,
            api_password: data.pass,
            api_endpoint: data.endpoint,
            billing_type: "test",
        }

        axios.put(`api/updatecrm/${crminstantID}`, dataA).then(res => {
               
            Swal.fire({
                title: 'Success',
                html: res.data.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 1000
            })
            window.location.reload();
            // getUserList();
            
        }).catch(err => {
            console.log(err.response)
            //window.location.reload();
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });

    }

    useEffect(() => {
        if (!crminstantID) {
            if (Object.keys(errors).length === 0 && isSubmit) {
                const data = {
                    provider_label: crmInstance.label,
                    api_username: crmInstance.u_name,
                    api_password: crmInstance.pass,
                    api_endpoint: crmInstance.endpoint,
                    billing_type: "test",
                }
                axios.post(`api/createcrm`, data).then(res => {

                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    setCrmInstance({ ...initialState });
                    setshowAddModal(false);
                    window.location.reload();

                }).catch(err => {

                })
            }
        } else {
            if (Object.keys(errors).length === 0 && isSubmit) {
                const data = {
                    provider_label: crmInstance.label ? crmInstance.label : crnDetails.provider_label,
                    api_username: crmInstance.u_name ? crmInstance.u_name : crnDetails.api_username,
                    api_password: crmInstance.pass ? crmInstance.pass : crnDetails.api_password,
                    api_endpoint: crmInstance.endpoint ? crmInstance.endpoint : crnDetails.api_endpoint,
                    billing_type: "test",
                }
                axios.put(`api/updatecrm/${crminstantID}`, data).then(res => {

                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })


                }).then(() => {
                    setCRMDetails([])
                    handleAddClose()
                    window.location.reload();

                }).catch(err => {
                    Swal.fire({
                        title: 'Warning',
                        html: err.response.data.message,
                        icon: 'warning'
                    })

                })
            }

        }



    }, [errors])
    useEffect(() => {
        const timeout = setTimeout(() => {
            getCRMList();

        }, 1000);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <div className='container py-5'>

            <div className='row justify-content-center'>

                <div className='modal'>



                    <Modal show={showAddModal} onHide={handleAddClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>   {crminstantID ? "Update CRM API Endpoint " : "Add CRM API Endpoint "}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        type: crmInstance.type,
                                                        label: crmInstance.label,
                                                        endpoint: crmInstance.endpoint,
                                                        u_name: crmInstance.u_name,
                                                        pass: crmInstance.pass,
                                                    }}

                                                    validationSchema={Yup.object({
                                                        type: Yup.string().required('user name is required'),
                                                        label: Yup.string().required('Label is required'),
                                                        endpoint: Yup.string().required('API endpoint is required'),
                                                        u_name: Yup.string().required('User name is required'),
                                                        pass: Yup.string().required('Password is required'),
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        {(crminstantID) ? updateCRM(values) : handelSubmit(values); }
                                                    }}
                                                >
                                                <Form>
                                                    <div className='form-group md-3'>
                                                        <label> CRM Type</label>
                                                        <Field type="" name="type" className="form-control" disabled />
                                                        <CustomErrorMsg name="type" />
                                                    </div>

                                                    <div className='form-group md-3'>
                                                        <label> Label </label>
                                                        <Field type="" name="label" className="form-control" />
                                                        <CustomErrorMsg name="label" />
                                                    </div>
                                                    {errors.label && <p className="text-danger">Must provide an API label!</p>}
                                                    <div className='form-group md-3'>
                                                        <label> API Endpoint</label>
                                                        <Field type="" name="endpoint" className="form-control" />
                                                        <CustomErrorMsg name="endpoint" />
                                                    </div>
                                                    {errors.endpoint && <p className="text-danger">{errors.endpoint}</p>}
                                                    <div className='form-group md-3'>
                                                        <label>User Name</label>
                                                        <Field type="" name="u_name" className="form-control" />
                                                        <CustomErrorMsg name="u_name" />
                                                    </div>
                                                    {errors.u_name && <p className="text-danger">Must provide an API username!</p>}
                                                    <div className='form-group md-3'>
                                                        <label>Password</label>
                                                        <Field type="" name="pass" className="form-control" />
                                                        <CustomErrorMsg name="pass" />
                                                    </div>

                                                    <br />



                                                    <br />
                                                    <div className='form-group md-3'>
                                                        <Button type="submit" className='btn btn-large'>{crminstantID ? "Update!" : "Save!"}</Button>
                                                    </div>
                                                </Form>
                                                </Formik>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button> */}
                            {/* <Button variant="primary" onClick={crminstantID ? handelUpdate : handelSubmit}>
                                Save!
                            </Button> */}
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className='col-md-12 '>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">CRM APIs</div>
                            {/* <div className="buttun_div  col-md-9"><button type="button" className="btn btn-primary" onClick={handleAddShow}>Add Crm Instance</button></div> */}
                        </div>
                    </div>

                    <div className='card'>
                        <div className='card-body'>
                            <div>

                            </div>
                            <table id="example" className="table custom_admin_table" >
                                <thead>

                                    <tr>
                                        <th>Type</th>
                                        <th>Label</th>
                                        <th>API endpoint</th>
                                        <th>User Name</th>
                                        <th>Password</th>

                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && <tr >
                                        <td colSpan="5"> Loading..... </td>

                                    </tr>}
                                    {crms.map(crm => {
                                        return (
                                            <tr key={crm.id}>
                                                <td>Sticky.io</td>
                                                <td>{crm.provider_label}</td>
                                                <td>{crm.api_endpoint}</td>
                                                <td>{crm.api_username}</td>
                                                <td>*******</td>


                                                <td> <div className='actionButtons'><Button onClick={(e) => {
                                                    editCRMInstance(e, crm.id)
                                                }} ><FontAwesomeIcon icon={faGear} /> </Button>
                                                    {'  '}
                                                    <button className=' btn-danger btn' onClick={(e) => {
                                                        delectCRMInstance(e, crm.id)
                                                    }}><FontAwesomeIcon icon={faDeleteLeft} /> </button>
                                                </div></td>
                                            </tr>
                                        );
                                    })}


                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default CrmInstance