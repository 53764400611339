import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Modal } from 'react-bootstrap';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

function DomainLogs() {
    const [details, setDetails] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [pending, setPending] = useState(true);
    const [domainList, setDomainList] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [dbpayload, setDbpayload] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [domain, setDomain] = useState('');

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const documentDefinition = (jsonData) => {
        return {
            content: [
                jsonData
            ],
        };
    };
      
    const downloadPdf = (payLoad) => {
        const docDefinition = documentDefinition(payLoad);
        pdfMake.createPdf(docDefinition).open();
    };

    const handleClose = () => {
        setShow(false);
    }

    function getAllDomains(){
        axios.get(`api/get-all-domains`).then(res => {
            if(res.data.status === true){
                //let Items = Object.entries(res.data.data).map(([key, value]) => ({ key, value }));
                setDetails(res.data.data);
                //console.log(res.data.data[0].domain_name);
                let data = {'domain_name': res.data.data[0].domain_name};
                axios.post(`api/get-domain-logs-by-current-date`,data).then(res => {
                    //console.log(res.data);
                    setDomain(res.data.data ? res.data.data[0].domain_name : '');
                    if(res.data.status === true){
                        setDomainList(res.data.data);
                        setSearch(res.data.data);
                        setPending(false);
                    } else {
                        setDomainList([]);
                        setSearch([]);
                        setPending(false);
                    }
                })

                /*let getDomain = Items.find(domain => domain.key === Items[0].key)?.value;
                setDomainList(getDomain);
                setSearch(getDomain);
                setPending(false);*/
            }
        })
    }

    useEffect(() => {
        getAllDomains();
    }, []);

    let handleChange = (selectedDomain) => {
        setDomain(selectedDomain);
        let getValue = details.find(domain => domain.key === selectedDomain)?.value;
        setDomainList(getValue);
        setSearch(getValue);
        setPending(false);
    }

    const viewData = (payLoad) => {
        setDbpayload(payLoad);
        handleShow();
    }

    useEffect(() => {
        const result = domainList?.filter(domainName => {
            return domainName.domain_name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    const columns = [
        {
            name: 'Domain name',
            selector: row => row.domain_name,
        },
        {
            id : 'Log_date',
            name: 'Log date',
            selector: row => row.created_at.split("T")[0],
        },
        {
            name: 'Status',
            selector: row => row.response,
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { downloadPdf(row.payload) }} ><FontAwesomeIcon icon={faDownload} title="Download payload" /></button>
                    <button className='btn btn-danger' onClick={() => { viewData(row.payload) }} ><FontAwesomeIcon icon={faEye} title="View payload" /></button>
                </>
            )
        }
    ];

    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    /*const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });*/

    const today = new Date();
    
    const handleSelect = (ranges) => {
        //console.log(ranges);
        //setSelectionRange(ranges.selection);
        const startDate = moment(ranges.selection.startDate);
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const endDate = moment(ranges.selection.endDate);
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        let data = {'domain_name':domain, 'startDate':formattedStartDate, 'endDate':formattedEndDate}
        axios.post(`api/check-date-range`,data).then(res => {
            if(res.data.status === true){
                setDomainList(res.data.data);
                setSearch(res.data.data);
                setPending(false);
            } else {
                setDomainList([]);
                setSearch([]);
                setPending(false);
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 mb-3'>
                        <select className="form-control" name="shipping_state" onChange={(event)=>handleChange(event.target.value)}>
                            {details ? details.map((item) => (
                            <option key={item.domain_name} value={item.domain_name}>
                                {item.domain_name}
                            </option>
                            ))
                            :
                            <option value="">No available domains</option>
                            }
                        </select>
                    </div>
                    <div className='col-md-6 mb-3'>
                        {/* <DateRange
                            editableDateInputs={true}
                            showSelectionPreview={true}
                            ranges={[selectionRange]} 
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            maxDate={today} 
                        /> */}
                        <DateRangePicker
                            onChange={(item) => {
                                setState([item.selection]);
                                handleSelect(item);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                            maxDate={today}
                            direction="horizontal"
                        />;
                    </div>
                    <div className='col-md-12 pl-3'>
                        <div className="pageheader card mb-4">
                            <div className="card-body row align-items-center">
                                <div className="page-name col-md-3">Domain Logs</div>
                                <div className="buttun_div  col-md-9">
                                    {/* <Button variant="primary" onClick={createDomainModal}>
                                        Add Domain
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                        <h1 className="display-6"></h1>
                        <div className='card '>
                            <div className='card-body '>
                                <div className='datatable '>
                                    <DataTable
                                        noDataComponent="No Data Available" //or your component
                                        columns={columns}
                                        data={search}
                                        progressPending={pending}
                                        pagination
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={
                                            <input type="text" className={search && search.length > 0 ? 'form-control serachInput' : 'form-control serachInput d-none'} placeholder="Search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                        }
                                        subHeaderAlign="right"
                                        defaultSortFieldId="Log_date"
                                        defaultSortAsc={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Payload view</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div>
                                                {dbpayload}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default DomainLogs;