import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import Sidebar2 from '../../layouts/frontend/Sidebar2';

// Images 
import emailIcon from '../../assests/frontend/assets/images/email-white.png';
import callIcon from '../../assests/frontend/assets/images/call-white.png';
import useStore from '../../store'
import axios from 'axios';

function ContactUs() {
    const isLogin = useStore((state) => state.isLogin);
    const [emailSend, setEmailSend] = useState();
    const ref = useRef();
    const [loadershow, setloadershow] = useState(false);

    const scrollToCustom = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        scrollToCustom();
    }, []);

    return (
        <> {isLogin ? <><div className='contactPage'>
            <div className='container-fluid'>
                <div className="row newShopList_row">
                    <Sidebar2/>
                    <div className="col-md-8 col_right">
                        <div className='contactBtn-wrapper'>
                            <a href='tel:+480-573-0829' className='btn conBtn'><img src={callIcon} alt="" className='img-fluid' />Click to call</a>
                            <a href='mailto: support@wholesalehealthclub.com' className='btn conBtn'><img src={emailIcon} alt="" className='img-fluid' />Send Email</a>
                        </div>
                    </div>
                </div>
            </div>
            </div></> : <>
                <div className={loadershow ? 'loading' : 'd-none'}></div>                                
                <div className='contactPage'>
                    <div className='container-fluid'>
                        {/* Row Begins  */}
                        <div className="row newShopList_row">
                            <Sidebar2></Sidebar2>
                            {/* Left Col Begin  */}
                            <div className="col-md-8 col_right">
                                <div className='text-center cont-title-div'>
                                    <h2 className='contactTitle'>Customer Service Phone: <a href="tel:(480) 573-0829">(480) 573-0829</a></h2>
                                    <p>Customer Service Email: <a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></p>
                                </div>
                                <div className='contactBox-wrapper'>
                                    <div className='contactBox'>
                                        <h2 className='contactBox_title'>Contact Us</h2>
                                        <div className='contactForm'>
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    name: '',
                                                    phone: '',
                                                    email: '',
                                                    message: '',
                                                }}
                                                validationSchema={Yup.object({
                                                    name: Yup.string().required('Name is required'),
                                                    email: Yup.string().required('Email is required'),
                                                    phone: Yup.number().integer(),
                                                    message: Yup.string().required('Message is required')
                                                })}

                                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                                    //console.log(values)
                                                    setSubmitting(true)
                                                    setloadershow(true);
                                                    axios.post(`api/contact-us`, values).then(res => {
                                                        //console.log(res.data)
                                                        setEmailSend(res.data.message)
                                                        setTimeout(() => setEmailSend(), 5000);
                                                        resetForm();
                                                        setloadershow(false);
                                                    })

                                                }}
                                            >
                                                <Form ref={ref}>
                                                    <div className='formGrp'>
                                                        <label className='contact-Lable'>Name <sup>*</sup></label>
                                                        <Field type="text" className="form-control" placeholder="Name" name="name" />
                                                        <CustomErrorMsg name="name" />
                                                    </div>
                                                    <div className='formGrp'>
                                                        <label className='contact-Lable'>Email <sup>*</sup></label>
                                                        <Field type="email" className="form-control" placeholder="Email" name="email" />
                                                        <CustomErrorMsg name="email" />
                                                    </div>
                                                    <div className='formGrp'>
                                                        <label className='contact-Lable'>Phone (Optional)</label>
                                                        <Field type="tel" className="form-control" placeholder="Phone" name="phone" />
                                                        <CustomErrorMsg name="phone" />
                                                    </div>
                                                    <div className='formGrp'>
                                                        <label className='contact-Lable'>Message <sup>*</sup></label>
                                                        <Field as="textarea"
                                                            name="message"
                                                            id="message"
                                                            rows={5}
                                                            className="form-control"
                                                            placeholder="Enter your message here" />
                                                        <CustomErrorMsg name="message" />
                                                    </div>
                                                    <div className='formGrp'>
                                                        <input type="submit" className='btn contact-submit' value="SEND" />
                                                    </div>

                                                </Form>
                                            </Formik>
                                            {emailSend && <p className='email_Message'>{emailSend} </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Left Col End  */}
                        </div>
                        {/* Row Ends  */}
                    </div>
                </div>
            </>}
        </>
    )
}

export default ContactUs