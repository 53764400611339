import React from 'react'
import { Link, useLocation, useParams }  from 'react-router-dom';
import '../../assests/upsell/css/gummiesupsell.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Copyright from '../Copyright';
import AccessDeniedPage from "../AccessDeniedPage";
// Images 
import clockIcon from "../../assests/upsell/images/clock_icon.png"
import animatedOrderBtn from '../../assests/upsell/images/add-to-order-animated.gif'
import ProductOne from '../../assests/upsell/images/checkout-product-3.png'
import Productthree from '../../assests/upsell/images/checkout-product-2.png'
import Productfive from '../../assests/upsell/images/checkout-product-1.png'
import pic2 from '../../assests/upsell/images/pic2.png'
import textPic from '../../assests/upsell/images/text_pic.png'
import visaUpsell from '../../assests/upsell/images/visa-upsell.png'
import { useEffect,useState } from 'react';
import {BASE_URL} from '../Config';
import useStore from '../../store';


function GummiesUpsell2() {
    const [disable,setDisable] = useState(false);
    const [loadershow, setloadershow] = useState(false);
    const currentYear = new Date().getFullYear();
    const [upsellProductPrice,setUpsellProductPrice] = useState('');
    const [upsellImg,setUpsellImg] = useState('');
    const [ProdPrice,setProdPrice] = useState(0.00);
    const [second, setSecond] = React.useState(59);
    const [minute, setMInute] = React.useState(4);
    const [productName, setProductName] = useState("");
    const location = useLocation();
    let params = location.search;
    const isLogin = useStore((state) => state.isLogin);
    const afterLogin = useStore((state) => state.afterLogin);
    const params2 = useParams();

    React.useEffect(() => {
        second > 0 && setTimeout(() => setSecond(second - 1), 1000);
        second == 0 && minute != 0 && setTimeout(() => setMInute(minute - 1), 1000);
        second == 0 && setTimeout(() => setSecond(59), 1000);
        minute == 0 && second == 0 && setTimeout(() => setMInute(4), 1000);
      }, [second,params2.slug]);
    let userDetails = useStore((state) => state.userDetails);
    
    /*function getProductDetails(prodId){
        axios.get(`/api/productDetailsByID/${prodId}`).then(res => {
            console.log(res.data);
            // setloadershow(false)
            if(res.data.status === true){
                setUpsellImg(BASE_URL+"whc-custom/storage/app/public/"+res.data.data[0].image)
                setProdPrice(res.data.data[0].price)
            }else{
                setUpsellImg(BASE_URL+"whc-custom/storage/app/public/")
                setProdPrice(0.00)
            }
        })
    }*/

    function getUpsellProduct(){
        let product = localStorage.getItem('ProductId');
        // gummies product
        if(product == '80'){
            localStorage.setItem('upsellProductId','84');
        }
        if(product == '81' || product === '97' ){
            localStorage.setItem('upsellProductId','83');
        }
        if(product === '79'){
            localStorage.setItem('upsellProductId','85');
        }
        // skincare product
        if(product == '101' ||product == '153'){
            localStorage.setItem('upsellProductId','137');
        }
        if(product == '103' || product == '154'){
            localStorage.setItem('upsellProductId','138');
        }
        if(product == '104' || product == '106' || product == '155'){
            localStorage.setItem('upsellProductId','139');
        }
        // skincare2 product
        if(product == '153'){
            localStorage.setItem('upsellProductId','157');
        }
        if(product == '154'){
            localStorage.setItem('upsellProductId','158');
        }
        if(product == '155' || product == '156'){
            localStorage.setItem('upsellProductId','159');
        }
        //brain page
        if(product == '76'){
            localStorage.setItem('upsellProductId','44');
        }
        if(product == '78' || product === '107' ){
            localStorage.setItem('upsellProductId','42');
        }
        if(product === '77'){
            localStorage.setItem('upsellProductId','43');
        }
        //brain4 page
        if(product == '145'){
            localStorage.setItem('upsellProductId','149');
        }
        if(product == '147' || product === '148' ){
            localStorage.setItem('upsellProductId','151');
        }
        if(product === '146'){
            localStorage.setItem('upsellProductId','150');
        }
        //brain4p page
        if(product == '161'){
            localStorage.setItem('upsellProductId','165');
        }
        if(product == '163' || product === '164' ){
            localStorage.setItem('upsellProductId','167');
        }
        if(product === '162'){
            localStorage.setItem('upsellProductId','166');
        }
        //skincare2p page
        if(product == '169'){
            localStorage.setItem('upsellProductId','173');
        }
        if(product == '171' || product === '172' ){
            localStorage.setItem('upsellProductId','175');
        }
        if(product === '170'){
            localStorage.setItem('upsellProductId','174');
        }

        //nootropic2p page
        if(product == '178'){
            localStorage.setItem('upsellProductId','182');
        }
        if(product == '180' || product === '181' ){
            localStorage.setItem('upsellProductId','184');
        }
        if(product === '179'){
            localStorage.setItem('upsellProductId','183');
        }

        //skincare3p page
        if(product == '186'){
            localStorage.setItem('upsellProductId','190');
        }
        if(product == '188' || product === '189' ){
            localStorage.setItem('upsellProductId','192');
        }
        if(product === '187'){
            localStorage.setItem('upsellProductId','191');
        }
        
        axios.get(`/api/productDetailsByID/${product}`).then(res => {
            //console.log(res.data);
            //setloadershow(false)
            if(res.data.status === true){
                setProductName(res.data.data[0].name);
                setUpsellImg(BASE_URL+"whc-custom/storage/app/public/"+res.data.data[0].image);
                setProdPrice(res.data.data[0].price);
                //console.log(product);
            }else{
                setUpsellImg(BASE_URL+"whc-custom/storage/app/public/")
                setProdPrice(0.00);
                //setUpsellProduct(product);
            }
        })
        axios.get(`/api/productDetailsByID/${localStorage.getItem('upsellProductId')}`).then(response => {
            //console.log(response.data);
            setUpsellProductPrice(response.data.data[0].price);
        })
    }

    // create sapphire memebship for paypal user
    async function createMember(orderId,orderTotal,productId){
        localStorage.setItem('neobodPId',665);
        localStorage.setItem('neobodCid',668);
        const formdata = new FormData();
        formdata.append('firstName', localStorage.getItem('firstName'));
        formdata.append('lastName', localStorage.getItem('lastName'));
        formdata.append('billingAddress1', localStorage.getItem('shippingAddress1'));
        formdata.append('billingZip', localStorage.getItem('shippingZip'));
        formdata.append('billingState', localStorage.getItem('shippingState'));
        formdata.append('billingCity', localStorage.getItem('shippingCity'));
        formdata.append('phone', localStorage.getItem('phone'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('billingCountry', localStorage.getItem('country'));
        formdata.append('billingCountry', localStorage.getItem('country'));
        formdata.append('orderId', orderId);
        formdata.append('orderTotal', orderTotal);
        formdata.append('productId', productId);
        formdata.append('is_membership', localStorage.getItem('is_membership'));
        formdata.append('method', localStorage.getItem('method'));
        if(localStorage.getItem('combine_product_id') != '') {
            formdata.append('combine_product_id', localStorage.getItem('combine_product_id'));
        }
        setDisable(true);
        setloadershow(true);
        axios.post(`api/create-member-by-paypal`, formdata).then(res => {
            console.log(res.data.data);
            localStorage.setItem('userId',res.data.data.user_id);
            localStorage.setItem('userEmal',res.data.data.email);
            localStorage.setItem('membership_orderId', res.data.membership_orderId ? res.data.membership_orderId : false);
            let is_membership = 0;
            if(parseInt(res.data.data.is_membership) === 1){
                is_membership = 1;
            } else {
                is_membership = 0;
            }
            afterLogin(res.data.data.account_permission, res.data.data.user_type,res.data.data.user_id,res.data.data,is_membership);
            setDisable(false);
            setloadershow(false);
            /*axios.get(`api/check_user_membership/${res.data.data.email}}`).then(result => {
                let is_membership = 0;
                if(parseInt(result.data) === 1){
                    is_membership = 1;
                } else {
                    is_membership = 0;
                }
                afterLogin(res.data.data.account_permission, res.data.data.user_type,res.data.data.user_id,res.data.data,is_membership);
                setDisable(false);
                setloadershow(false);
            });*/
        });
    }


    useEffect(function(){
        getUpsellProduct();
        const urlParams = new URLSearchParams(window.location.search);
        let responseCode = urlParams.get('responseCode');
        let orderId = urlParams.get('orderId');
        let orderTotal = urlParams.get('orderTotal');
        let productId = urlParams.get('line_items[0][product_id]'); 
        if(responseCode == 100){
            localStorage.setItem('orderId',orderId);
            localStorage.setItem('method','paypal');
            if(isLogin != 1){
                //if(localStorage.getItem('is_membership') == 41){
                    const timer = setTimeout(() => {
                        createMember(orderId,orderTotal,productId);
                    }, 1000);
                    return () => clearTimeout(timer);
                //}
            }
        } else if(responseCode == 800) {
            setloadershow(true);
            localStorage.removeItem('prospectId');
            localStorage.setItem('method','paypal');
            //window.location.href = '/'+localStorage.getItem('c_s');
            axios.post(`api/get-pending-order-details`, {'order_id':orderId}).then(res => {
                //console.log(res.data);
                setloadershow(false);
                localStorage.setItem('affid',res.data.affid);
                localStorage.setItem('sid',res.data.sid);
                localStorage.setItem('s1',res.data.c1);
                localStorage.setItem('s2',res.data.c2);
                localStorage.setItem('s3',res.data.c3);
                localStorage.setItem('click_id',res.data.click_id);
                setloadershow(false);
                if (res.data.affid == 'null'  & res.data.sid == 'null' & res.data.c1 == 'null' & res.data.c2 == 'null' & res.data.c3 == 'null' & res.data.click_id == 'null') {
                    window.location.href = '/'+localStorage.getItem('c_s');
                } else if(res.data.affid != '' & res.data.sid != '' & res.data.c1 != '' & res.data.c2 != '' & res.data.c3 != '' & res.data.click_id != ''){
                   window.location.href = '/'+localStorage.getItem('c_s')+'?affid='+res.data.affid+'&sid='+res.data.sid+
                    '&s1='+res.data.c1+'&s2='+res.data.c2+'&s3='+res.data.c3+'&click_id='+res.data.click_id;
                } else {
                    setloadershow(false);
                    window.location.href = '/'+localStorage.getItem('c_s');
                }  
            });
        } else {
            localStorage.setItem('method','normal');
        }
        
    },[params2.slug])

    function upsellProcced(){
        setDisable(true);
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('first_name', localStorage.getItem('firstName'));
        formdata.append('last_name', localStorage.getItem('lastName'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('is_membership', 0);
        formdata.append('product_id', localStorage.getItem('upsellProductId'));
        formdata.append('is_upsell', 1);
        formdata.append('order_id', localStorage.getItem('orderId'));
        formdata.append('affid', localStorage.getItem('affid'));
        formdata.append('sid', localStorage.getItem('sid'));
        formdata.append('s1', localStorage.getItem('s1'));
        formdata.append('s2', localStorage.getItem('s2'));
        formdata.append('s3', localStorage.getItem('s3'));
        formdata.append('click_id', localStorage.getItem('click_id'));
        formdata.append('notes', localStorage.getItem('notes'));
        formdata.append('user_id',(userDetails) ? userDetails.user_id : localStorage.getItem('userId'));
        formdata.append('method',localStorage.getItem('method'));
        if(localStorage.getItem('funnel')){
            formdata.append('funnel',localStorage.getItem('funnel'));
        }
        //console.log(Object.fromEntries(formdata));
        if(localStorage.getItem('orderId')){
            axios.post(`api/place-upsell-order`, formdata).then(res => {
                if (res.data.status === true) {
                    //console.log(res.data.data);
                    if(localStorage.getItem('up2_s') === 'focus-offer' || localStorage.getItem('up2_s') === 'blue-forrest-goddess-eye-cream'){
                        localStorage.setItem('Upsell1_orderId', res.data.data.order_id);
                        window.location.href = '/'+localStorage.getItem('up2_s')+params;
                    } else {
                        localStorage.setItem('Upsell2_orderId', res.data.data.order_id);
                        if(localStorage.getItem('method') == 'paypal'){
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        } else {
                            if(localStorage.getItem('up3_s')){
                                window.location.href = '/'+localStorage.getItem('up3_s')+params;
                            }
                            else{
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            }
                        }
                    }   
                }
                else{
                    setDisable(false);
                    setloadershow(false);
                    Swal.fire({
                        title: res.data.data.decline_reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33'
                    })
                }
            })
        } else {
            Swal.fire({
                title: 'Order Decline',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/dashboard';
                }
            })
        }
    }

    function goToConfirmation(){
        setloadershow(true);
        if(localStorage.getItem('up2_s') === 'focus-offer' || localStorage.getItem('up2_s') === 'blue-forrest-goddess-eye-cream'){
            window.location.href = '/'+localStorage.getItem('up2_s')+params;
        } else {
            if(localStorage.getItem('up3_s')){
                window.location.href = '/'+localStorage.getItem('up3_s')+params;
            }
            else{
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            }
        }
    }

    return (
        <>
            {loadershow || isLogin === null ? (
                <div className="loader-new loading"></div>
            ) : (isLogin === 1) ? (
                <div className='gummiesUpsell2'>
                    {/* Sticky Top  */}
                    <div className="sticky-top doNotExitAlert">
                        <div className="container-fluid text-center">
                            <h2>ORDER NOT COMPLETE - DO NOT CLOSE THIS PAGE!</h2>
                        </div>
                    </div>
                    {/* Upsell Main  */}
                    <div className='upsell2-main container'>
                        <div className='upsell2-main-title text-center mt-3 mb-5'>
                            <h2><span className='yellowBg'>IMPORTANT INFORMATION ABOUT YOUR ORDER!</span></h2>
                        </div>
                        <div className='upsell2-cont1'>
                            <div className='row  align-items-center'>
                                <div className='col-sm-12 col-lg-5 text-center mb-4 mb-md-0'>
                                    {/* Cupon Begin  */}
                                    <div className="couponCountdown mt-4">
                                        <div className="text-center p-sm-2 p-md-4">
                                                <div><h2><span className='yellowBg'>SAVE 75%!</span></h2></div>
                                                <div className="font-weight-bold">This 75% OFF OFFER is presented to</div>
                                                <div className="smallText">select customers and is only valid for the next 5 minutes</div>
                                                <div id="timercontainer" className="timer-container mt-4">
                                                <div className="font-weight-bold">Time Sensitive</div>
                                                <div id="timerbox" className="row">
                                                    <div className="col-4 text-center">
                                                        <img src={clockIcon} className="img-fluid" />
                                                    </div>
                                                    <div className="col-5 pt-1">
                                                        <span id="minutes2" className="text-danger font-weight-bold timerText countDownMinute">{"0"+minute}</span>
                                                        <span className="text-danger font-weight-bold timerText">:</span>
                                                        <span id="seconds2" className="text-danger font-weight-bold timerText countDownSecond">{second<10?"0"+second:second}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3 font-weight-bold medText">
                                                Click the button below to <span className="font-weight-bold">Double Your Order</span> of {productName} for 75% OFF the retail price
                                            </div>
                                            <div className="text-center mt-3">
                                                <div className="form-horizontal">
                                                    <div className="form-group">
                                                        <span id="button2">
                                                            <input type="image" src={animatedOrderBtn} disabled={(disable)?'disabled':''} onClick={upsellProcced} className="img-fluid inputBtn" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>                                        
                                        </div>
                                    </div>
                                    {/* Cupon End  */}
                                </div>
                                <div className='col-sm-12 col-lg-7 text-center'>
                                    <div className="contentBox">
                                        <h1>Special Offer - Double Your Order!</h1>
                                        <p></p>
                                        <h3 className='mb-3'>Congratulations {userDetails.name.split(' ')[0]}</h3>
                                        {/* <h3 className='mb-3'>Congratulations Sumit</h3> */}
                                        <p>
                                            You have made a great choice that will surely improve your health and well-being!
                                        </p>
                                        <p>
                                            Many customers have voiced their concerns about getting {productName} in the future, since it's almost completely sold out after recently being featured on T.V.
                                        </p>
                                        <p>
                                            <span className="font-weight-bold">You have been selected to double your order for 75% Off the MSRP price! This is a one-time offer and only valid for the next 5 minutes.</span>
                                        </p>
                                        <p>
                                            Because of the high demand of {productName}, there is a strict limit to the number of special offers we can offer.
                                        </p>
                                        <p>
                                            It's recommended that you take {productName} every day just like a vitamin to ensure you see maximum results. This will also help you keep your body in peak performance.
                                            <span className="font-weight-bold">Secure your additional bottles right now to make sure you don't run out of your supply.</span> Take advantage of this offer to get double your order for 75% off the MSRP price.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='contentBox text-center mt-4'>
                            <p>The MSRP for this package is $ {parseFloat(ProdPrice).toFixed(2)} but when you take advantage of this special, one-time Double Your Order offer you'll
                            <br /><strong>Get more bottles for only $ {parseFloat(upsellProductPrice).toFixed(2)} - That's 75% off MSRP!</strong></p>
                            <p>Hurry and take advantage of this amazing discount - it will never be offered again!</p>
                            <h3>If You Dont Take Advantage Of This Now, You Will Hate Yourself Later</h3>
                            <p className="largeText mb-5">
                                This is only valid for the next <span id="minutes1" className="font-weight-bold countDownMinute yellowBg">{"0"+minute}</span> minutes and <span id="seconds1" className="font-weight-bold countDownSecond yellowBg">{second<10?"0"+second:second}</span> seconds.
                            </p>
                            <div className="mb-4 prodImg">
                                <img id="product_img" src={upsellImg} className="img-fluid" />
                            </div>
                            <div className="text-center mt-3">
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <span id="button1">
                                            <input type="image" src={animatedOrderBtn} disabled={(disable)?'disabled':''} onClick={upsellProcced} className="img-fluid inputBtn" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div action="" className="text-center bottom-nothanks">
                            <button onClick={goToConfirmation}  className="btn btn-sm upsell btn-danger p-3 mb-5"><span className="cross text-danger">X</span> No thanks! I don't want to double my order and save 75%.</button>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={loadershow ? 'loader-new loading' : 'd-none'}></div>
                    <AccessDeniedPage/>
                </>
            )}
            <Copyright />
        </>
    );
}
    
export default GummiesUpsell2