import React  from "react";
import Accordion from 'react-bootstrap/Accordion';
import { Link }  from 'react-router-dom';
import Sidebar2 from "../../layouts/frontend/Sidebar2";

function ReferralPage2(){
    

    return(
        <>
            <section className="referralPage">
                <div className='container-fluid'>
                    {/* Row Begins  */}
                    <div className="row newShopList_row">
                        <Sidebar2/>
                        {/* Left Col Begin  */}
                        <div className="col-md-8 col_right">
                            <div className="container">
                                {/* <div className="breadcumNav-div text-center">
                                    <Link to="/" className="breadcrumbs_nav_link">Home</Link> <span>&#062;</span> <Link to="/referral"  className="breadcrumbs_nav_link last_elt">REFERRAL</Link> 
                                </div> */}
                                {/* <div className="titleDiv text-center">
                                    <h2 className="title">Hello test, You are currently a Sapphire Membership Member</h2>
                                </div> */}
                                <div className="titleDiv text-center">
                                    <h2 className="title text-dark">Membership Perks</h2>
                                </div>
                                <div className="refAcc">
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Basic Membership Level Benefits:</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="referralTxt-lists">
                                                    <li>Access to member site</li>
                                                    <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                    <li>Purchase unlimited skincare products at $20 per item</li>
                                                    {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                    <li>The Basic Membership will be automatically charged $5.89 every month thereafter for a period of 30 days from the
                                                        date of purchase. You can cancel anytime by calling (480) 573-0829 or by emailing
                                                        support@wholesalehealthclub.com.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Silver Membership Level Benefits:</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="referralTxt-lists">
                                                    <li>Access to basic plus</li>
                                                    <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                    <li>Purchase unlimited skincare products at $20 per item</li>
                                                    {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                    <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                    <li>Bonus ebooks</li>
                                                    <li>The Silver Membership will be automatically charged $10.00 every month thereafter for a period of 30 days from the
                                                        date of purchase. You can cancel anytime by calling (480) 573-0829 or by emailing support@wholesalehealthclub.com.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Gold Membership Level Benefits:</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="referralTxt-lists">                                        
                                                    <li>Access to standard plus</li>
                                                    <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                    <li>Purchase unlimited skincare products at $20 per item</li>
                                                    {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                    <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                    <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                                                    <li>Bonus ebooks</li>
                                                    <li>The Gold Membership will be automatically charged $12.77 every month thereafter for a period of 30 days from <br /> date of purchase. You can cancel anytime by calling (480) 573-0829 or by emailing support@wholesalehealthclub.com.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Sapphire Membership Level Benefits:</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="referralTxt-lists">
                                                    <li>Access to premium plus</li>
                                                    <li>All Gold Membership Level Benefits.</li>
                                                    <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                    <li>Purchase unlimited skincare products at $20 per item</li>
                                                    {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                    <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                    <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                                                    <li>Bonus ebooks</li>
                                                    <li>The Sapphire Membership will be automatically charged $17.00 every month thereafter for a period of 30 days from <br/> date of purchase. You can cancel anytime by calling (480) 573-0829 or by emailing<br /> support@wholesalehealthclub.com.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header> Platinum Membership Level Benefits:</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className="referralTxt-lists">
                                                    <li>Access to standard plus</li>
                                                    <li>Purchase unlimited supplements at $15 per bottle.</li>
                                                    <li>Purchase unlimited skincare products at $20 per item</li>
                                                    {/* <li>Purchase unlimited powder products at $25 per item</li> */}
                                                    <li>Bonus offer: you will receive 1 free month of membership when 1 Successful Referral sign up using your referral code.</li>
                                                    <li>Bonus offer: for every 5 bottles purchased, get 1 free.</li>
                                                    <li>Bonus ebooks</li>
                                                    <li>The Platinum Membership will be automatically charged $21.00 every month thereafter for a period of 30 days from <br /> date of purchase. You can cancel anytime by calling (480) 573-0829 or by emailing<br /> support@wholesalehealthclub.com.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        {/* Left Col End  */}
                    </div>
                    {/* Row Ends  */}
                </div>




                
            </section>
        </>
    )
}


export default ReferralPage2