import React, { useEffect } from "react";
import { Route, Routes, Navigate, useLocation, Outlet } from "react-router-dom";

import MasterLayout from "./layouts/admin/MasterLayout";
import Profile from "./components/admin/Profile";
import Dashboard from "./components/admin/Dashboard";
import Home from "./components/frontend/Home";
import Login from "./components/frontend/auth/Login";
// import Customer from "./components/admin/Customer";
import PrivateRoutes from "./components/frontend/auth/PrivateRoutes";
import CRMI from "./components/admin/CrmInstance";

import axios from "axios";
import Orders from "./components/admin/Orders";
import AddProds from "./components/admin/AddProds";
import ListProducts from "./components/admin/Viewprods";
import Productmapping from "./components/admin/Productmapping";
import Adduser from "./components/admin/Adduser";
import NotFound from "./components/admin/NotFound";
import AccessDenied from "./components/admin/AccessDenied";
import Listusers from "./components/admin/Listusers";
import Category from "./components/admin/Category";
//import Test from "./components/admin/Test";
import AutoProductMap from "./components/admin/AutoProductMap";
import AddCheckoutFunnel from "./components/admin/AddCheckoutFunnel";
import CheckoutFunnelList from "./components/admin/CheckoutFunnelList";
import EditCheckoutFunnel from "./components/admin/EditCheckoutFunnel";
import BrainTemplate from "./components/LanderTemplate/BrainTemplate";
import AcvgummiesTemplate from "./components/LanderTemplate/AcvgummiesTemplate";
import AcvgummiesMobileTemplate from "./components/LanderTemplate/AcvgummiesMobileTemplate";
import AcvCheckout from "./components/CheckoutTemplates/AcvCheckout";
import AcvMobileCheckout from "./components/CheckoutTemplates/AcvMobileCheckout";
//import AcvCheckout from "./components/funneltemplates/AcvCheckout";
import Checkout14 from "./components/funneltemplates/Checkout-14";
import FaceCreamCheckout from "./components/CheckoutTemplates/FacecreamCheckout";
// import MasterTemplate1 from "./components/CheckoutTemplates/MasterTemplate1";
import MasterCheckout from "./components/admin/MasterCheckout";
import AssignementTemplate from "./components/admin/AssignementTemplate";
import AssignmentLander from "./components/admin/AssignmentLander";
import AssignmentUpsell from "./components/admin/AssignmentUpsell";
import ThankYou from "./components/ThankYouTemplates/ThankYou";
import MasterLander from "./components/admin/MasterLander";
import Confirmation from "./components/admin/Confirmation";
import Funnelflow from "./components/admin/FunnelFlow";
import FunnelFlowList from "./components/admin/FunnelFlowList";
import FunnelFlowEdit from "./components/admin/FunnelFlowEdit";
import MasterUpsell from "./components/admin/MasterUpsell";
import MasterConfirmation from "./components/admin/MasterConfirmation";
import Brand from "./components/admin/Brand";

import ProductReview from "./components/admin/ProductReview";

import MasterFrontLayout from "./layouts/frontend/MasterLayout";
import MasterFrontLayoutV2 from "./layouts/frontend/MasterLayoutV2";
import BrandMasterLayout from "./layouts/frontend/BrandMasterLayout";
import Shop from "./components/frontend/Shop";
import SingleProduct from "./components/frontend/SingleProduct";
import FrontLogin from "./components/frontend/FrontLogin";
import CheckoutPage from "./components/frontend/CheckoutPage";
import CheckoutPage2 from "./components/frontend/CheckoutPage2";
import Skincare from "./components/frontend/Skincare";
import Cart from "./components/frontend/Cart";
import ChekoutTwo from "./components/frontend/ChekoutTwo";
import Final from "./components/frontend/Confirmation";
import CreateMembership from "./components/frontend/CreateMembership";
import OrderStep from "./components/frontend/OrderStep";
import MembershipCheckout from "./components/frontend/MembershipCheckout";
import MyAccountInfo from "./components/frontend/MyAccountInfo";
import Landing from "./components/frontend/Landing";
import ResetPassword from './components/frontend/ResetPassword';
import ForgetPassword from './components/frontend/ForgetPassword';
import Discount from './components/frontend/Discount';
import DashboardFront from './components/frontend/Dashboard';
import Bonus from './components/admin/Bonus';
import BonusPage from './components/frontend/BonusPage';
import ReferralPage from './components/frontend/ReferralPage';
import BonusCategory from './components/admin/BonusCategory';
import FrontPage from './components/frontend/FrontPage';
import ContactPage from './components/frontend/ContactPage-logout';
import ContactPage2 from './components/frontend/ContactPage-login';
import GummiesUpsell1 from './components/UpsellTemplate/GummiesUpsell1';
import GummiesUpsell2 from './components/UpsellTemplate/GummiesUpsell2';
import FaceCreamUpsell1 from './components/UpsellTemplate/FaceCreamUpsell1';
import NeobodUpsell from './components/UpsellTemplate/NeobodUpsell.js';
import BonusSlug from './components/frontend/BonusSlug';
import FaceCream from './components/LanderTemplate/FaceCreamTemplate.js';
import TermAndCond from './components/frontend/TermAndCond.js';
import PrivacyPolicy from './components/frontend/PrivacyPolicy.js';
import Citations from './components/frontend/Citations.js';
import V1 from './components/frontend/V1.js';
import V2 from './components/frontend/V2.js';
import { BASE_URL } from './components/Config';
import { CartState } from './context/Context';
import useStore from './store';
import V2t from "./components/frontend/V2t";
import V1t from "./components/frontend/V1t";
import Transcript1 from "./components/frontend/Transcript1";
import Transcript2 from "./components/frontend/Transcript2";
import GummiesMobile from "./components/GummiesMobile";
import AccessDeniedPage from "./components/AccessDeniedPage";
import ThankYouCrypto from "./components/frontend/ThankYouCrypto";
import SapphireAddOn from "./components/admin/SapphireAddOn";
import UserComments from "./components/admin/UserComments";

// New look  imports
import FrontPage2 from './components/frontend/FrontPage2';
import DiscountPage2 from './components/frontend/DiscountPage2';
import DiscountPageV2 from './components/frontend/DiscountPageV2';
import ShopPage2 from "./components/frontend/ShopPage2";
import ShopGummyBrands2 from "./components/frontend/ShopGummyBrands2";
import ShopSkincareBrands2 from "./components/frontend/ShopSkincareBrands2";
import ShopSupplementBrands2 from "./components/frontend/ShopSupplementBrands2";
import ProductDetails2 from "./components/frontend/ProductDetails2";
import ShopSapphireNaturaProducts2 from "./components/frontend/ShopSapphireNaturaProducts2";
import DomainLogs from "./components/admin/DomainLogs";
import Login2 from "./components/frontend/Login2";
import Contact2 from "./components/frontend/Contact2";
import ContactUs from "./components/frontend/ContactUs";
import OrderStep2 from "./components/frontend/OrderStep2";
import Landing2 from "./components/frontend/Landing2";
import CheckoutPageBeta from "./components/frontend/CheckoutPageBeta";
import Final2 from "./components/frontend/Confirmation2";
import MyAccountInfo2 from "./components/frontend/MyAccountInfo2";
import Dashboard2 from "./components/frontend/Dashboard2";
import ReferralPage2 from "./components/frontend/ReferralPage2";
import BonusPage2 from "./components/frontend/BonusPage2";
import BonusSlug2 from "./components/frontend/BonusSlug2";
import PrivateRoutes2 from "./components/frontend/auth/PrivateRoutes2";
import Cart2 from "./components/frontend/Cart2";
import Api from "./components/frontend/Api";
import Faq from "./components/frontend/Faq";
import ProductByBrand from "./components/frontend/ProductByBrand";
import SingleProduct2 from "./components/frontend/SingleProduct2";
import ForgetPassword2 from "./components/frontend/ForgetPassword2";
import ResetPassword2 from "./components/frontend/ResetPassword2";
import V12 from "./components/frontend/V12";
import V22 from "./components/frontend/V22";
import V1t2 from "./components/frontend/v1t2";
import V2t2 from "./components/frontend/v2t2";
import ChekoutTwo2 from "./components/frontend/CheckoutTwo2";
import FaqCategory from "./components/admin/FaqCategory.js";
import FaqSubCategory from "./components/admin/FaqSubCategory.js";
import FaqQueries from "./components/admin/FaqQueries.js";
import Survey from "./components/admin/Survey.js";
import SurveyFeedback from "./components/admin/SurveyFeedback.js";

// New Look ends //

axios.defaults.baseURL =
  BASE_URL + "whc-custom/public/";
// axios.defaults.withCredentials = true;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");
axios.defaults.headers.get["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");
axios.defaults.headers.put["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");




var d = (localStorage.getItem("useData"));
let executedOnce = 0;

//  console.log(axios.defaults.baseURL);
function App() {
  const isLogin = useStore((state) => state.isLogin);
  const userId = useStore((state) => state.userId);
  const { state: { cart }, dispatch } = CartState();
  const location = useLocation();
  if (executedOnce === 0 && isLogin) {
    let params = window.location.href.split('/');
    if (params[3] === 'admin') {
      //console.log(params[3]);
    } else {
      axios.get(`api/cart/${userId}`).then(res => {
        if (res.data.data.length) {

          dispatch({
            type: "PREFILL_CART",
            payload: res.data.data,

          })

        }

      });
    }
    executedOnce++;
  }
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/acvgummies-mobile-landing" element={<AcvgummiesMobileTemplate />} />
        <Route path="/admin/accessdenied" element={<AccessDenied />} />
        <Route path="/:slug" element={<MasterCheckout />} />
        <Route path="/admin/" element={<Login replace to="/admin/login" />} />
        <Route path="/admin/" element={<MasterLayout />}>
          {/* <Route path="/admin/" element={<Navigate replace to="/admin/dashboard"/>}/> */}
          <Route element={<PrivateRoutes />}>
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/profile" element={<Profile />} />
            <Route path="/admin/crminstance" element={<CRMI />} />
            <Route path="/admin/orders" element={<Orders />} />
            <Route path="/admin/addprods" element={<AddProds />} />
            <Route path="/admin/viewprods" element={<ListProducts />} />
            <Route path="/admin/productmapping" element={<AutoProductMap />} />
            <Route path="/admin/users" element={<Adduser />} />
            <Route path="/admin/listusers" element={<Listusers />} />
            <Route path="/admin/category" element={<Category />} />
            <Route path="/admin/brand" element={<Brand />} />
            <Route path="/admin/product_review" element={<ProductReview />} />
            <Route path="/admin/checkoutfunnels" element={<AddCheckoutFunnel />} />
            <Route path="/admin/checkoutfunnelslist" element={<CheckoutFunnelList />} />
            <Route path="/admin/checkoutfunnelsedit" element={<EditCheckoutFunnel />} />
            <Route path="/admin/template" element={<AssignementTemplate />} />
            <Route path="/admin/lander" element={<AssignmentLander />} />
            <Route path="/admin/upsell" element={<AssignmentUpsell />} />
            <Route path="/admin/confirmation" element={<Confirmation />} />
            <Route path="/admin/funnelflow" element={<Funnelflow />} />
            <Route path="/admin/funnelflowlist" element={<FunnelFlowList />} />
            <Route path="/admin/funnelflowedit" element={<FunnelFlowEdit />} />
            <Route path="/admin/bonus-category" element={<BonusCategory />} />
            <Route path="/admin/bonus" element={<Bonus />} />
            <Route path="/admin/sapphire-add-on" element={<SapphireAddOn />} />
            <Route path="/admin/domain-logs" element={<DomainLogs />} />
            <Route path="/admin/add-on-queries" element={<UserComments />} />
            <Route path="/admin/faq-category" element={<FaqCategory />} />
            <Route path="/admin/faq-sub-category" element={<FaqSubCategory />} />
            <Route path="/admin/faq-queries" element={<FaqQueries />} />
            <Route path="/admin/survey-question" element={<Survey />} />
            <Route path="/admin/survey-feedback" element={<SurveyFeedback />} />
          </Route>
        </Route>
        {/* <Route path="/" element={<MasterFrontLayout />}>
          <Route path="/" element={<FrontPage />} />
          <Route path="/shop/:slug" element={<Shop />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/my-account-info" element={<MyAccountInfo />} />
            <Route path="/bonus" element={<BonusPage />} />
            <Route path="/bonus/:slug" element={<BonusSlug />} />
            <Route path="/referral" element={<ReferralPage />} />
          </Route>
          <Route path="/login" element={<FrontLogin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/dashboard" element={<DashboardFront />} />
          <Route path="/contact-us" element={<ContactPage2 />} />
        </Route>
        <Route path="/" element={<BrandMasterLayout />}>
          <Route path="/product/:slug" element={<SingleProduct />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout-2" element={<ChekoutTwo />} />
          <Route path="/confirmation" element={<Final />} />
          <Route path="/crypto-thank-you" element={<ThankYouCrypto />} />
        </Route>
        <Route path="/" >
          <Route path="/create-membership" element={<Landing />} />
          <Route path="/order-step-1" element={<OrderStep />} />
          <Route path="/membership-checkout" element={<CheckoutPage />} />
          <Route path="/membership-change" element={<CheckoutPage2 />} />
          <Route path="/discount" element={<Discount />} />
          <Route path="/term-and-condition" element={<TermAndCond />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/citations" element={<Citations />} />
          <Route path="/v1" element={<V1 />} />
          <Route path="/v2" element={<V2 />} />
          <Route path="/v1t" element={<V1t />} />
          <Route path="/v2t" element={<V2t />} />
          <Route path="/transcript-1" element={<Transcript1 />} />
          <Route path="/transcript-2" element={<Transcript2 />} />
          <Route path="/gummies-mobile" element={<GummiesMobile />} />
          <Route path="/access-denined" element={<AccessDeniedPage />} />
        </Route> */}

        {/* WHC New Look route  */}
        <Route path="/" element={<MasterFrontLayoutV2 />}>
          <Route path="/" element={<FrontPage2 />} />
          <Route path="/login" element={<Login2 />} />
          <Route path="/forget-password" element={<ForgetPassword2 />} />
          <Route path="/reset-password" element={<ResetPassword2 />} />
          <Route path="/discount" element={<DiscountPageV2 />} />
          <Route path="/shop/:slug" element={<ShopPage2 />} />
          <Route path="/create-membership" element={<Landing2 />} />
          <Route path="/order-step-1" element={<OrderStep2 />} />
          <Route path="/membership-checkout" element={<CheckoutPageBeta />} />
          <Route path="/confirmation" element={<Final2 />} />
          <Route path="/api" element={<Api />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/checkout-2" element={<ChekoutTwo2 />} />
          <Route path="/cart" element={<Cart2 />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/my-account-info" element={<MyAccountInfo2 />} />
            <Route path="/referral" element={<ReferralPage2 />} />
            <Route path="/bonus" element={<BonusPage2 />} />
            <Route path="/bonus/:slug" element={<BonusSlug2 />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard2 />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/cart" element={<Cart2 />} />
          {/* <Route path="/shop/:slug" element={<ProductByBrand />} /> */}
          <Route path="/product/:slug" element={<SingleProduct2 />} />
          <Route path="/v1" element={<V12 />} />
          <Route path="/v2" element={<V22 />} />
          <Route path="/v1t" element={<V1t2 />} />
          <Route path="/v2t" element={<V2t2 />} />
          <Route path="/term-and-condition" element={<TermAndCond />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/citations" element={<Citations />} />
        </Route>
        {/* WHC New Look route end */}
      </Routes>
    </div>
  );
}

export default App;