import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import useStore from '../../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavbar, useSidebar } from '../../context/Context';

function Sidebar2() {
    const isLogin = useStore((state) => state.isLogin);
    const [navIsActive, setNavIsActive] = useState(false);
    const navBtnClickHandle = event => {
        setNavIsActive(current => !current);
    };
    let URL = window.location.href;
    let URLparam = URL.substring(URL.lastIndexOf('/') + 1);
    let urlSlug = URL.substring(URL.lastIndexOf('/') + 0);
    const [category, setCategoryList] = useState([]);
    const params = useParams();
    const { sidebarState: { sidebarOpen }, sidebarDispatch } = useSidebar();
    const { navbarState: { activeTab }, navbarDispatch } = useNavbar();
    const [isMember, setIsMember] = useState(false);
    const isMembership = useStore((state) => state.isMember);
    const afterLogout = useStore((state) => state.afterLogout);

    async function getCategoryList() {
        axios.get(`api/frontEndListCategory`).then(res => {
            setCategoryList(res.data.data);
        })
    }

    useEffect(() => {
        getCategoryList();
        if (localStorage.getItem('is_member') == 1) {
            setIsMember(true);
        }
    }, [params.slug]);

    var toggleOnload = () => {
        if (activeTab) {
            navbarDispatch({ type: "CHANGE_TAB" })

        }
        if (sidebarOpen) {
            sidebarDispatch({ type: "TOGGLE_SIDEBAR" })

        }
    }

    const logoutSubmit = e => {
        e.preventDefault();
        sessionStorage.removeItem('yp');
        localStorage.removeItem('method');
        localStorage.removeItem('authToken');
        localStorage.removeItem('bearer');
        localStorage.removeItem('permission');
        localStorage.removeItem('userEmal');
        localStorage.removeItem('userId');
        localStorage.removeItem('useData');

        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('phone');
        localStorage.removeItem('email');
        localStorage.removeItem('shippingAddress1');
        localStorage.removeItem('shippingCity');
        localStorage.removeItem('shippingState');
        localStorage.removeItem('shippingZip');
        localStorage.removeItem('order_id');

        afterLogout()
        Swal.fire({
            title: 'Logout!',
            html: 'Logout Successful',
            icon: 'success'
        })
        window.location.href = '/login'
    }

    return (
        <>
            {/* Left Col Begin  */}
            <div className="col-md-4 col_left">
                <div className={navIsActive ? "nwSideNav show" : "nwSideNav"}>
                    <div className="nwSideNav_div">
                        <ul className="nwSideNav_lists">
                            {isLogin == 1 ?
                                <li className={urlSlug === ('/dashboard') ? "active" : null}>
                                    <Link to="/dashboard">Dashboard</Link>
                                </li>
                            :''}
                            {category.map(category => {
                                if (category.name.toLowerCase().replace(" ", "-") !== "bundle-packs" || isLogin) {
                                    return (
                                        <li key={category.id} className={URLparam === category.name.toLowerCase().replace(" ", "-") ? "active" : null}>
                                            <Link to={"/shop/" + category.name.toLowerCase().replace(" ", "-")} className={({ isActive }) => (isActive ? 'active' : 'inactive')} onClick ={toggleOnload }> {category.name}</Link>
                                        </li>
                                    );
                                }
                            })}
                            {isLogin ?
                                <li className={urlSlug === '/referral' ? "active" : null}><Link to="/referral">Referral</Link></li>
                            :''}
                            {isLogin && isMembership ?
                                <li className={urlSlug === '/bonus' ? "active" : null}><Link to="/bonus">Bonuses</Link></li>
                            :''}
                            {isLogin ?
                                <li className={urlSlug === '/my-account-info' ? "active" : null}><Link to="/my-account-info">My Account Info</Link></li>
                            :''}
                            <li className={urlSlug === '/contact-us' ? "active" : null}><Link to="/contact-us">Contact Us</Link></li>
                            {isLogin == 1 ? 
                                <li className={urlSlug === '/logout' ? "active" : null}><Link to="#" onClick={logoutSubmit}>Logout</Link></li> : 
                                <li className={urlSlug === '/login' ? "active" : null}><Link to="/login">Login</Link></li>
                            }
                            {isLogin == 0 ?
                                <li className={urlSlug === '/discount' ? "active" : null}><Link to="/discountv2">Discount</Link></li>
                            :''}
                            {isLogin == 1 ?
                                <li className={urlSlug === '/cart' ? "active" : null}><Link to="/cart">View Cart</Link></li>
                            :''}
                        </ul>
                    </div>
                </div>
            </div>
            {/* Left Col Ends  */}
        </>
    )
}

export default Sidebar2;