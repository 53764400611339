import React, { useState, useEffect} from "react";
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'reactstrap';
import {BASE_URL} from '../Config';

function FaqSubCategory(){
    const [show, setShow] = useState(false);
    const [id, setId] = useState("");
    const handleClose = () => { 
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }
    const [faqCategory,setFaqCategoryList] = useState([]);
    const [faqSubCategoryList,setFaqSubCategoryList] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [pending, setPending] = useState(true);
    const [details, setDetails] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [existingImage, setExistingImage] = useState('');
    let imgURL = BASE_URL+"/whc-custom/storage/app/public/image/";

    const getCategoryList = () => {
        axios.get(`api/listFaqCategory`).then(res => {
            setFaqCategoryList(res.data.data);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    const getFaqSubCategoryList = () => {
        axios.get(`api/listFaqSubCategory`).then(res => {
            setFaqSubCategoryList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function showSingleData(id) {
        //console.log(id);
        axios.get(`api/detailsFaqSubCategory/${id}`).then(res => {
            console.log(res.data.data);
            setExistingImage(res.data.data.sub_category_image);
            let Image = new File([''], res.data.data.sub_category_image, { type: "image/jpg", size: 0 }, 'utf-8');
            setDetails({
                faq_category_id: res.data.data.faq_category_id,
                sub_category_name: res.data.data.sub_category_name,
                sub_category_image: Image
            });
            setSelectedCategory(res.data.data.faq_category_id);
            setId(id);
            handleShow();
        })
    }

    function deleteFaqSubCategory(id) {
        console.log(id);
        Swal.fire({
            title: 'Are you sure you want to delete this faq sub category?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteFaqSubCategory/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getFaqSubCategoryList();
                })
            }
        })
    }

    useEffect(() => {
        getCategoryList();
        getFaqSubCategoryList();
    }, []);

    useEffect(() => {
        const result = faqSubCategoryList?.filter(faqSubCategoryName => {
            return faqSubCategoryName.sub_category_name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);
    }, [searchData]);

    const columns = [
        {
            name: "Category Name",
            selector: row => row.faq_category_name
        },
        {
            name: "Sub Category Name",
            selector: row => row.sub_category_name
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary'><FontAwesomeIcon icon={faGear} onClick={() => { showSingleData(row.id) }} title="Edit Sub Category" /></button>
                    <button className='btn btn-danger'><FontAwesomeIcon icon={faDeleteLeft} onClick={() => { deleteFaqSubCategory(row.id) }} title="Delect Bonus" /></button>
                </>
            )
        }
    ]

    const formSubmit = (data) => {
        let category_name = data.category_name;
        let sub_category_name = data.sub_category_name;
        let sub_category_image = data.image;
        const formdata = new FormData();
        formdata.append('category_name', category_name);
        formdata.append('sub_category_name', sub_category_name);
        formdata.append('sub_category_image', sub_category_image);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/createFaqSubCategory`, formdata).then(res => {
            //console.log(res);
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getFaqSubCategoryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    const formUpdate = (data) => {
        const formdata = new FormData();
        formdata.append('category_name', data.category_name);
        formdata.append('sub_category_name', data.sub_category_name);
        formdata.append('sub_category_image', data.image);
        formdata.append('sub_category_image_size', data.image.size);
        // console.log(Object.fromEntries(formdata));
        axios.post(`api/updateFaqSubCategory/${id}`, formdata, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getFaqSubCategoryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function createFaqSubCategoryModal() {
        setDetails([]);
        setId("");
        handleShow();
    }

    return (
        <div>
            <div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Formik
                            initialValues={{ 
                                category_name: details.faq_category_id,
                                sub_category_name: details.sub_category_name,
                                image: details.sub_category_image,
                            }}
                            validationSchema={Yup.object({
                                category_name: Yup.string().required('Category name is required'),
                                sub_category_name: Yup.string().required('Sub category name is required'),
                                image: Yup.mixed()
                                    .nullable()
                                    .required('Image is required')
                                    .test('File Size',
                                        'Upload file size is too large', (value) => !value || (value && value.size <= 1024000 * 1024000))
                                    .test('format',
                                        'Invalid file type. Only *.jpg,*.png,*.jpeg files acceptable. ', (value) => !value || (value && ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(value.type))),

                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true);
                                // alert(JSON.stringify(values, null, 2));
                                {(id) ? formUpdate(values) : formSubmit(values); }
                            }}
                        >
                        {(formProps) => (
                            <Form className='bonusForm'>
                                <Modal.Header closeButton>
                                    <Modal.Title>FAQ Sub Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='container'>
                                        <div className='row justify-content-center'>
                                            <div className='col-md-12'>
                                                <div className='card'>
                                                    <div className='card-body' style={{ minHeight: 'auto', overflowY: 'scroll' }}>
                                                        <div className='form-group md-3'>
                                                            <label> Select Category </label>
                                                            <Field 
                                                                as="select" 
                                                                name="category_name" 
                                                                className="form-control"
                                                            >
                                                                <option  value="">Select Category Type</option>
                                                                {faqCategory ? faqCategory.map(faqCategoryList => {
                                                                    return (
                                                                        <option value={faqCategoryList.id} key={faqCategoryList.id}>
                                                                            {faqCategoryList.faq_category_name}
                                                                        </option>
                                                                    );
                                                                }) : ''}
                                                            </Field>
                                                            <CustomErrorMsg name="category_name" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label>Sub Category Name </label>
                                                            <Field type="text" name="sub_category_name" className="form-control" />
                                                            <CustomErrorMsg name="sub_category_name" />
                                                        </div>
                                                        <div className='form-group md-3'>
                                                            <label> Image </label>
                                                            <Input type="file" name="image" className="form-control" onChange={(event) => formProps.setFieldValue("image", event.target.files[0])}/>
                                                            {details.sub_category_image && <div className='form-group md-3'><img src={imgURL + existingImage} width="200px" height="100px"/></div>}
                                                            <CustomErrorMsg name="image" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                        </Formik>
                    </Modal>
                </div>
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">FAQ Sub Category List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={createFaqSubCategoryModal}>
                                    Create FAQ Sub Category
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="No Data Available" //or your component
                                    columns={columns}
                                    data={search}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" className={search && search.length > 0 ? 'form-control serachInput' : 'form-control serachInput d-none'} placeholder="Search by sub category" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqSubCategory;