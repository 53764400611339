import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import uuid from 'react-uuid';
import ReactPlayer from 'react-player';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
// import exitIntent from 'exit-intent';
import { useExitIntent } from 'use-exit-intent';
import Copyright from '../Copyright';

// Images 
import roundCaret from '../../assests/frontend/assets/images/arrow.svg';
import contBtn from '../../assests/frontend/assets/images/click-to-cont-btn.jpg';

function V12() {

    const [show, setShow] = useState(false);
    let url = "https://sapphiremediallc.wistia.com/medias/hvni68pyu7";
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showDiv, setShowDiv] = useState(false);
    const navigate = useNavigate();
    const { registerHandler } = useExitIntent()

    setTimeout(() => {
        setShowDiv(true);
    }, 10 * 60 * 1000);

    const confirmSubmit = (data) => {
        localStorage.setItem("V1email", data.email);
        navigate("/create-membership");
    }
    registerHandler({
        id: 'myHandlerId',
        handler: () => {
            setShow(true);
        }
    })
    // exitIntent({
    //     threshold: 5,
    //     maxDisplays: 2,
    //     eventThrottle: 100,
    //     onExitIntent: () => {
    //         handleShow()
    //     }
    // })

    // useEffect(() => {
    //     removeExitIntent();
    // }, []);

    return (
        <>
            <div className='versionPage'>
                <div className='video-container'>
                    <ReactPlayer
                        className='react-player'
                        width='100%'
                        height='100%'
                        url={url}
                        config={{
                            wistia: {
                                playerId: { uuid }
                            },
                        }}
                    />
                </div>
                <div className='text-container'>
                    <div className='container'>
                        <div className='vText-wrapper'>
                            <h2 className='vTitle'>
                                Join over hundreds of people that are living a healthier lifestyle, and saving hundreds of dollars on essential products...
                            </h2>
                            {showDiv &&
                                <div className='form-container'>
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            email: ''
                                        }}
                                        validationSchema={Yup.object({
                                            email: Yup.string().required('Email is required'),
                                        })}

                                        onSubmit={(values, { setSubmitting }) => {
                                            setSubmitting(true);
                                            //alert(JSON.stringify(values, null, 2));
                                            confirmSubmit(values);
                                        }}
                                    >
                                        <Form>
                                            <Field className='form-control' type="email" placeholder="Enter Your Primary Email Address" name="email" />
                                            <CustomErrorMsg name="email" />
                                            <button type="submit" className="btn blue-grd" id="submitBtn">Next Step... <i><img src={roundCaret} alt="button Arrow Icon" /></i></button>
                                        </Form>
                                    </Formik>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal className='vModal' id="exitPopUp" size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2 className='modalLergeText'>WAIT !!!</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='boldTxt'>
                        You are about to exit the purchase page for Wholesale Health Club. BIG PHARMA will likely get this page SHUT DOWN. This may be a ONCE in a life time opportunity!
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button className='imgBtn' onClick={handleClose}><img src={contBtn} className="img-fluid" /></button>
                </Modal.Footer>
            </Modal>


        </>
    )
}



export default V12