import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft, faEye, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';
import { BASE_URL } from '../Config';
import NoImage from '../../assests/admin/assets/img/nImage.jpg';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { Input } from 'reactstrap';

const UserComments = () => {
    CheckPermission()
    const [CommentDetails, setCommentDetails] = useState({});
    const [getData, setData] = useState([])
    const [loadershow, setloadershow] = useState(false);
    const [show, setShow] = useState(false);
    const [adminResponse, setAdminResponse] = useState("");
    const [pending, setPending] = useState(true);
    const [checkboxValue, setcheckboxValue] = useState(false);
    const [commentId, setCommentId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchCategory, setSearchCategory] = useState([]);
    const [errormsg, setErrorMsg] = useState(null);
    let imgURL = BASE_URL + "/whc-custom/storage/app/public/image/";
    var response = "";
    const handleClose = () => {
        setShow(false)
        setSearch('')
    }
    const handleShow = () => setShow(true);

    async function getCommentsList() {
        axios.get(`api/listComments`).then(res => {
            // console.warn(res.data.data);
            setData(res.data.data);
            setSearchCategory(res.data.data)
            setPending(false);
            console.log(getData)
        })

    }
    const columns = [
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Email",
            selector: row => row.email
        },
        {
            name: "Attachment",
            selector: row => (<span>
                {row.img === null ? 'No Attachment' : <a href={BASE_URL + "whc-custom/storage/app/public/image/" + row.img} target="_blank"><FontAwesomeIcon icon={faPaperclip} /></a>}
            </span>
            ),
        },
        {
            name: "Comment",
            cell: row => (<span>{row.comments.length > 20 ? row.comments.substring(0, 20) + "..." : row.comments}</span>)
        },
        {
            id : 'Created_at',
            name: "Created At",
            cell: row => (
                <span>{row.created_at.split("T")[0]}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faEye} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteComment(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]
    useEffect(() => {
        getCommentsList()
    }, []);


    function createCommentModal() {
        setCommentDetails([]);
        setCommentId(false);
        handleShow();
    }

    const AdminResponseHandler = (e) => {
        if (e.target.value) {
            setErrorMsg(null)
            setAdminResponse(e.target.value)
        }
    }

    function deleteComment(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Comment?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/removeComment/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getCommentsList();
                })
            }
        })
    }

    function showSingleData(id) {
        //console.warn(id);
        setCommentId(id);
        setAdminResponse('');
        axios.get(`api/editComment/${id}`).then(res => {

            setCommentDetails({
                name: res.data.data.name,
                image: res.data.data.img,
                email: res.data.data.email,
                admin_reply: res.data.data.admin_reply ? res.data.data.admin_reply : "",
                id: res.data.data.id,
                comments: res.data.data.comments,
                phone: res.data.data.phone !== null ? res.data.data.phone : null
            });
            console.log(CommentDetails)
            handleShow();
        })
    }

    const navigate = useNavigate();

    const formUpdate = (values) => {
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('admin_reply', values.admin_reply);
        axios.post(`api/updateComment/${commentId}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                setloadershow(false);
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getCommentsList();
            }
        }).catch(err => {
            {
                response = Object.values(err.response.data.message).map((error, index) => {
                    return error[0];
                })
            }
            Swal.fire({
                title: 'Warning',
                html: response,
                icon: 'warning'
            })

        });
    }

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
    };

    useEffect(() => {
        const result = getData.filter(country => {
            return country.name.toLowerCase().match(search.toLowerCase());
        })
        setSearchCategory(result);
    }, [search]);

    return (

        <div>
            <div>
                <div className={loadershow ? 'loading' : 'd-none'}></div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add on query</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={loadershow ? 'loading' : 'd-none'}></div>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    {CommentDetails && <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body' style={{ minHeight: 'auto', overflowY: 'scroll' }}>
                                                <div className='form-group md-3'>
                                                    <label> Name : </label>
                                                    <span className='p-2'>{CommentDetails.name}</span>
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Email : </label>
                                                    <span className='p-2'>{CommentDetails.email}</span>
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Phone : </label>
                                                    <span className='p-2'>{CommentDetails.phone == null ? '' : CommentDetails.phone}</span>
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Comment :  </label>
                                                    <p className="p-2">{CommentDetails.comments}</p>
                                                </div>
                                                <div className='form-group md-3'>
                                                    <label> Attachment :  </label>
                                                    <span className="p-2">{CommentDetails.image === null ? 'No Attachment' : <a href={BASE_URL + "whc-custom/storage/app/public/image/" + CommentDetails.image} target="_blank"><FontAwesomeIcon icon={faPaperclip} /></a>}</span>
                                                </div>
                                                <hr />
                                                {CommentDetails.admin_reply && <div className='form-group md-3'>
                                                    <label> Admin Response :  </label>
                                                    <p className='p-2'>{CommentDetails.admin_reply}</p>

                                                </div>}
                                                {!CommentDetails.admin_reply && <div className='form-group md-3'>
                                                    <label> Admin Response :  </label>
                                                    <Formik
                                                        initialValues={{admin_reply : ''}}
                                                        validationSchema={Yup.object({
                                                            admin_reply: Yup.string()
                                                            .required('Message is required'),
                                                        })}
                                                        onSubmit={(values, { setSubmitting }) => {
                                                            setSubmitting(true);
                                                            // alert(JSON.stringify(values, null, 2));
                                                            formUpdate(values);
                                                        }}
                                                    >
                                                        <Form>
                                                            <Field as="textarea" name="admin_reply" className="form-control p-3" />
                                                            {/* {errormsg && <p className='mt-2 text-danger'>{errormsg}</p>} */}
                                                            <CustomErrorMsg name="admin_reply" />
                                                            <div className="mt-3">
                                                                <Button type="submit" className='btn submitBtn btn-primary'>Reply</Button>
                                                            </div>
                                                        </Form>
                                                    </Formik>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Add on query list</div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                                <DataTable
                                    noDataComponent="Add on query list is empty" //or your component
                                    columns={columns}
                                    data={searchCategory}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                    defaultSortFieldId="Created_at"
                                    defaultSortAsc={false}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserComments;