import React, {useState, useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Link, useLocation }  from 'react-router-dom';
import '../../assests/Lander/assets/css/acvgummies-mobile-landing.css';
import { Helmet } from 'react-helmet';
// Images 
import Logo2 from '../../assests/Lander/assets/images/logo-2.png';
import LogoJu from '../../assests/Lander/assets/images/logo-ju.png';
import securityIcons from '../../assests/Lander/assets/images/security-icons.png';
import product from '../../assests/Lander/assets/images/product.png';
import ketomagazines from '../../assests/Lander/assets/images/keto-magazines.png';
import step1 from '../../assests/Lander/assets/images/step-1.png';
import step2 from '../../assests/Lander/assets/images/step-2.png';
import step3 from '../../assests/Lander/assets/images/step-3.png';
import ketoScience from '../../assests/Lander/assets/images/keto-science.png';
import testimonial1 from '../../assests/Lander/assets/images/testimonial-1.png';
import testimonial2 from '../../assests/Lander/assets/images/testimonial-2.png';
import testimonial3 from '../../assests/Lander/assets/images/testimonial-3.png';
import gurantee from '../../assests/Lander/assets/images/GUARANTEE.png';
import ingredients from '../../assests/Lander/assets/images/INGREDIENTS.png';
import arrow1 from '../../assests/Lander/assets/images/arrow-1.png';
import logoF from '../../assests/Lander/assets/images/logo-f.png';
import socialIcons from '../../assests/Lander/assets/images/social-icons.jpg';
import websiteSecure from '../../assests/Lander/assets/images/website-secure.png';
import ca from '../../assests/Lander/assets/images/ca.png';
import iconLoading from '../../assests/Lander/assets/images/icon-loading.png';

function AcvgummiesMobileTemplate(){
    const currentYear = new Date().getFullYear();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    let params = location.search;
    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [minutes, seconds]);


    function getDateOneWeekFromNow() {
        // Create a new Date object for the current date
        const date = new Date();

        // Add 7 days to the date in milliseconds (1 week)
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Get the month, day, and year from the date
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();

        // Convert the month number to the month's name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];

        // Return the date in the format you specified
        return `${monthName} ${day}, ${year}`;
    }

    const dateOneWeekFromNow = getDateOneWeekFromNow();

    function handleClick(){
        setLoader(true);
        setTimeout(function () {
            window.location.href = '/acvgummies-mobile-checkout'+params;
        }, 3000);
        return false;
    }

    return (
        <>
        <div className="acvgummiesMobile">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {/* Loader  */}
            <div className={loader ? 'popup-loading-wrapper' : 'popup-loading-wrapper d-none'}>
                <div className="popup">
                    <figure className="product-image-acv">
                        <img alt="" src={product} className="img-fluid" />
                    </figure>
                    <p>Reserving Your Bottle Of</p>
                    <h2>Tru Body ACV Keto Gummies</h2>
                    <img src={iconLoading} alt="" className="loading-image" />
                </div>
            </div>
            {/* Loader End  */}
            {/* Header  */}
            <header className="header">
                <div className="header__warning">
                    <div className="container">
                        <span className="alert-color">Warning:</span> Due to extremely high media demand, there is limited supply of <span className="product-name">Trubody ACV Keto Gummies</span> in stock as of <span className="date-container"> {dateOneWeekFromNow}</span>
                        <span className="hurry"> HURRY! <span className="time" id="time">{minutes}:{seconds}</span></span>
                    </div>
                </div>
                <div className="headerMain">
                    <div className="container text-center">
                        <img alt="" className="header__logo" src={Logo2} />
                    </div>
                </div>
            </header>
            {/* Section Begin  */}
            <section className="first-section">
                <div className="container">
                    <div className="row">
                        <div className="first-section__side first-section__info">
                            <div className="first-section__side_in">
                                <img alt="" src={LogoJu} />
                                <h1>MELT FAT <span>FAST!</span></h1>
                                <h2>WITHOUT DIET OR EXERCISE</h2>
                                <h3>Powerful New Formula Triggers Fat-Burning Ketosis!</h3>
                                <ul>
                                    <li>Burn Fat for Energy not Carbs</li>
                                    <li>Release Fat Stores</li>
                                    <li>Increase Energy Naturally!</li>
                                    <li>Love the Way You Feel!</li>
                                </ul>
                                <img alt="" className="security-icons d-mobile" src={securityIcons} />
                                <div className="first-section__bottle gummies_img">
                                    <img alt="" src={product} />
                                    <ul className="garante-list row gummies_badge">
                                        <li className="garante-item_1"></li>
                                        <li className="garante-item_2"></li>
                                        <li className="garante-item_3"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Section End  */}
            {/* Why Is  */}
            <section className="why-is">
                <div className="container">
                    <div className="row">
                        <div className="why-is__side">
                            <img alt="" src={ketomagazines} className="keto-magazines" />
                            <img alt="" src={product} className="wy-btl gummies_btl" />
                        </div>
                        <div className="why-is__side">
                            <h2>
                                WHY IS Tru Body ACV Keto Gummies <br />
                                <span>SO POPULAR NOW?</span>
                            </h2>
                            <p>
                                A recent study published by the Diabetes, Obesity, and Metabolism Journal found that Tru Body ACV Keto Gummies supported burning fat for energy instead of carbohydrates greatly increasing weight loss and energy.
                                Furthermore, TV doctor Oz, recently named Tru Body ACV Keto Gummies the "Holy Grail" of weight loss for good reason - IT WORKS.
                            </p>
                            <p>
                                It is important to note that the Tru Body ACV Keto Gummies with 100% BHB (Beta-Hydroxybutyrate) used in the study was the real deal and Tru Body ACV Keto Gummies exceeds the studies product potency using proprietary methods.
                            </p>
                            <p>Bottom Line: It Works and it's Better for your Health!</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Why is End  */}
            {/* How use section  */}
            <section className="how-use">
                <div className="container">
                    <h2 className="how-use__titleMain">
                        <span>HOW TO USE Tru Body ACV Keto Gummies</span><br />
                        TO GET RESULTS
                    </h2>
                    <div className="stepSlider">
                        <Carousel>
                            {/* Carousel Item  */}
                            <Carousel.Item>
                                <div className="how-use__item">
                                    <img alt="" src={step1} />
                                    <span className="how-use__step">Step 1</span>
                                    <span className="how-use__title">INSTANT FAT BURN</span>
                                    <p>Tru Body ACV Keto Gummies works to release stored fat, by helping your body burn fat for energy instead of carbs. Advanced Ketones are behind this miracle product that helps you lose up to 5 lbs in the first week.</p>
                                </div>
                            </Carousel.Item>
                            {/* Carousel Item  */}
                            <Carousel.Item>
                                <div className="how-use__item">
                                    <img alt="" src={step2} />
                                    <span className="how-use__step">Step 2</span>
                                    <span className="how-use__title">ACCELERATED FAT BURN</span>
                                    <p>With your weight loss goals achieved, continue to take Tru Body ACV Keto Gummies for 3-5 months as to stabilize your appetite, as well as to maintain and transform your new, slim body.</p>
                                </div>
                            </Carousel.Item>
                            {/* Carousel Item  */}
                            <Carousel.Item>
                                <div className="how-use__item">
                                    <img alt="" src={step3} />
                                    <span className="how-use__step">Step 3</span>
                                    <span className="how-use__title">TRANSFORM YOUR BODY</span>
                                    <p>With your weight loss goals achieved, continue to take Tru Body ACV Keto Gummies for 3-5 months as to stabilize your appetite, as well as to maintain and transform your new, slim body.</p>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className="diagonal-line-container">
                        <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0"></polygon>
                        </svg>
                    </div>
                </div>
            </section>
            {/* How use section end  */}
            {/* Science of ketosis Section Begin  */}
            <section className="science green-gradient">
                <div className="container">
                    <div className="flip diagonal-line-container">
                        <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0"></polygon>
                        </svg>
                    </div>
                    <div className="row">
                        <div className="science__left">
                            <h2 className="science__title">THE SCIENCE OF</h2>
                            <h2 className="science__subTitle">KETOSIS<span>(Tru Body ACV Keto Gummies)</span></h2>
                            <p>
                                Ketosis is the state where your body is actually burning fat for energy instead of carbs. Ketosis is extremely hard to obtain on your own and takes weeks to accomplish. Tru Body ACV Keto Gummies actually helps your
                                body achieve ketosis fast and helps you burn fat for energy instead of carbs!
                            </p>
                            <ul>
                                <li>
                                    <span>No More Stored Fat:</span> Currently with the massive load of cabohydrates in our foods, our bodies are conditioned to burn carbs for energy instead of fat. Because it is an easier energy source for
                                    the body to use up.
                                </li>
                                <li>
                                    <span>Fat - The New Energy:</span> Ketosis is the state where your body is actually burning fat for energy instead of carbs. Ketosis is extremely hard to obtain on your own and takes weeks to accomplish.
                                    Tru Body ACV Keto Gummies actually helps your body achieve ketosis fast and helps you burn fat for energy instead of carbs!
                                </li>
                                <li>
                                    <span>More Health Benefits:</span> Tru Body ACV Keto Gummies BHB works almost instantly to help support ketosis in the body by Burning FAT for energy. Fat IS the body's ideal source of energy and when you are in
                                    ketosis you experience energy and mental clarity like never before and of course very rapid weight loss.
                                </li>
                            </ul>
                        </div>
                        <div className="science__right">
                            <div className="science__img">
                                <img alt="" src={ketoScience} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Science of ketosis Section End  */}
            {/* Review Section Begin  */}
            <section className="review">
                <div className="container">
                    <div className="diagonal-line-container">
                        <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0"></polygon>
                        </svg>
                    </div>
                    <h2><span>REAL</span> SUCCESS STORIES</h2>
                    <div className="reviewSlider">
                        <Carousel>
                            {/* Carousel Item  */}
                            <Carousel.Item>
                                <div className="slide__item">
                                    <div className="slide__item_left">
                                        <img alt="" src={testimonial1} />
                                    </div>
                                    <div className="slide__item_right">
                                        <div className="table-cell">
                                            <p>
                                                "After watching video after video of keto success stories, I felt hopeful that I could actually start losing weight without anything too extreme. When I found Tru Body ACV Keto Gummies online and thought I'd
                                                give it a shot. I started losing weight, I though maybe it was a fluke at first. After my first 10 pounds, I cried. If you need something to work like me, you won't be let down"
                                            </p>
                                            <span>- Ashley R</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            {/* Carousel Item  */}
                            <Carousel.Item>
                                <div className="slide__item">
                                    <div className="slide__item_left">
                                        <img alt="" src={testimonial2} />
                                    </div>
                                    <div className="slide__item_right">
                                        <div className="table-cell">
                                            <p>
                                                "I've been hearing about Tru Body ACV Keto Gummies for a while now. My sister took it and had some amazing success. I thought, if I could just lose a few pounds I would feel better about myself. I was shocked
                                                to say the least when I lost 20lbs in 30 days. Now I tell everyone "
                                            </p>
                                            <span>- Isabella N</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            {/* Carousel Item  */}
                            <Carousel.Item>
                                <div className="slide__item">
                                    <div className="slide__item_left">
                                        <img alt="" src={testimonial3} />
                                    </div>
                                    <div className="slide__item_right">
                                        <div className="table-cell">
                                            <p>
                                                "Tru Body ACV Keto Gummies is by far the best product I've used for ketosis. It works and it works well. I am the leanest I have ever been in my life. I had my body fat tested before and after and I went from
                                                a whopping 26% body fat down to 16% in 4 months. I owe a big thanks to Tru Body ACV Keto Gummies for this amazing experience."
                                            </p>
                                            <span>- Darin K</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </section>
            {/* Review Section End  */}
            {/* Last Block  */}
            <section className="last-block">
                <div className="container">
                    <div className="diagonal-line-container flip">
                        <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                            <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0"></polygon>
                        </svg>
                    </div>
                    <div className="last-block__block row">
                        <div className="last-block_left">
                            <h2>
                                THE MOST POWERFUL<br />
                                Tru Body ACV Keto Gummies
                            </h2>
                            <h3>
                                FULL SPECTRUM<br />
                                KETO BHB SALTS
                            </h3>
                            <ul className="row">
                                <li><img alt="" src={gurantee} /></li>
                                <li><img alt="" src={ingredients} /></li>
                                <li><img alt="" src={arrow1} /></li>
                            </ul>
                        </div>
                        <div className="last-block_middle">
                            <div className="last-block-bottle">
                                <img alt="" src={product} />
                            </div>
                            <Link to="#" onClick={()=>handleClick()} className="last-block__btn btn__send loading-btn">
                                <span>RUSH MY ORDER</span>
                            </Link>
                            <img alt="" className="logo-f" src={logoF} />
                        </div>
                        <div className="last-block_right">
                            <img alt="" src="/wp-content/themes/artemis-swp/assets/images/mobile-landing/wallpapers.png" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Last Block End   */}
            {/* Footer Begin  */}
            <footer className="footer proLand-footer">
                <div className="container">
                    <img alt="" className="website-secure" src={websiteSecure} />
                    <ul className="soc-list row">
                        <li><img alt="" src={socialIcons} /></li>
                        <li><img alt="" src={ca} /></li>
                    </ul>
                    <p>
                        Tru Body ACV Keto Gummies is committed to maintaining the highest quality products and the utmost integrity in business practices. All products sold on this website are certified by Good Manufacturing Practices (GMP), which
                        is the highest standard of testing in the supplement industry.
                    </p>
                    <p>
                        Notice: The products and information found on this site are not intended to replace professional medical advice or treatment. These statements have not been evaluated by the Food and Drug Administration. These
                        products are not intended to diagnose, treat, cure or prevent any disease. Individual results may vary.
                    </p>
                    <p>
                        Copyright
                        ©
                        {" "+currentYear+" "}
                        Tru Body ACV Keto Gummies. All Rights Reserved.
                    </p>

                    <ul className="terms-links">
                        <li>
                            <Link to="#" onClick={(event) => {
                                event.preventDefault();
                                window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Privacy Policy |&nbsp;</Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(event) => {
                                event.preventDefault();
                                window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Terms and Conditions |</Link>
                        </li>
                        <li><Link to="/contact-us">&nbsp;Contact Us</Link></li>
                    </ul>
                </div>
            </footer>
            {/* Footer End  */}
            <div className="fixed-btn">
                <Link to="#" onClick={()=>handleClick()} className="btn__send loading-btn">RUSH MY ORDER NOW </Link>
            </div>
        </div>
        </>
    )
}
export default AcvgummiesMobileTemplate