import React, { useState, useEffect } from 'react'
import '../../assests/admin/css/thankyou.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useStore from '../../store';
import cartIcon from '../../assests/frontend/assets/images/shopping-cart.png';
import money from '../../assests/frontend/assets/images/notes.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import { keyBy } from 'lodash';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Copyright from '../Copyright';
import { BASE_URL } from '../Config';

function Final() {
    const [show, setShow] = useState(false);
    const [loadershow, setloadershow] = useState(true);
    const isLogin = useStore((state) => state.isLogin);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let order_id = localStorage.getItem('order_id');
    const [orderData, setOrderData] = useState([]);
    const [total, setTotal] = useState('');
    let userDetails = useStore((state) => state.userDetails);
    const moment = extendMoment(Moment);
    const afterLogin = useStore((state) => state.afterLogin);
    const params2 = useParams();
    const navigate = useNavigate();
    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';

    let getOrderList = () => {
        const formdata = new FormData();
        formdata.append('order_id', order_id);
        axios.post(`api/get_order_list`, formdata).then(res => {
            //console.log(res.data.data);
            if (res.data.status === true) {
                localStorage.setItem("V1email",'');
                setOrderData(res.data.data.items);
                setTotal(res.data.data.total);
                setloadershow(false);
            }
        }).catch(err => {
            console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.message,
                icon: 'warning'
            })
        });
    }

    async function createMember(orderId,orderTotal,productId){
        const formdata = new FormData();
        formdata.append('firstName', localStorage.getItem('firstName'));
        formdata.append('lastName', localStorage.getItem('lastName'));
        formdata.append('billingAddress1', localStorage.getItem('shippingAddress1'));
        formdata.append('billingZip', localStorage.getItem('shippingZip'));
        formdata.append('billingState', localStorage.getItem('shippingState'));
        formdata.append('billingCity', localStorage.getItem('shippingCity'));
        formdata.append('phone', localStorage.getItem('phone'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('billingCountry', localStorage.getItem('country'));
        formdata.append('orderId', orderId);
        formdata.append('orderTotal', orderTotal);
        formdata.append('productId', productId);
        formdata.append('method', localStorage.getItem('method'));
        formdata.append('referral_code', localStorage.getItem('referral_code'));
        setloadershow(true);
        axios.post(`api/create-member-by-paypal`, formdata).then(res => {
            console.log(res.data.data);
            localStorage.setItem('userId',res.data.data.user_id);
            localStorage.setItem('userEmal',res.data.data.email);
            localStorage.setItem('membership_orderId', res.data.membership_orderId ? res.data.membership_orderId : false);
            afterLogin(res.data.data.account_permission, res.data.data.user_type,res.data.data.user_id,res.data.data,res.data.data.is_membership);
            setloadershow(false);
            /*axios.get(`api/check_user_membership/${res.data.data.email}}`).then(result => {
                console.log(result);
                let is_membership = 0;
                if(parseInt(result.data) === 1){
                    is_membership = 1;
                } else {
                    is_membership = 0;
                }
                afterLogin(res.data.data.account_permission, res.data.data.user_type,res.data.data.user_id,res.data.data,is_membership);
                setloadershow(false);
            });*/
        });
    }

    /*useEffect(() => {
        // Simulate loading for 3 seconds
        const timer = setTimeout(() => {
            setloadershow(false);
        }, 3000);
    
        return () => clearTimeout(timer); // Clean up the timer on component unmount
    }, []);*/
    
    useEffect(() => {
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(window.location.search);
        let responseCode = urlParams.get('responseCode');
        let orderId = urlParams.get('orderId');
        let orderTotal = urlParams.get('orderTotal');
        let productId = urlParams.get('line_items[0][product_id]');
        if(responseCode == 100 & orderId != ''){
            order_id = orderId;
            setTotal(orderTotal);
            getOrderList();
            localStorage.setItem('orderId',order_id);
            localStorage.setItem('method','paypal');
            if(isLogin != 1){
                //if(localStorage.getItem('is_membership') == 41){
                    const timer = setTimeout(() => {
                        createMember(orderId,orderTotal,productId);
                    }, 1000);
                    return () => clearTimeout(timer);
                //}
            }
        } else if (responseCode == 800) {
            setloadershow(true);
            axios.post(`api/get-pending-order-details`, {'order_id':orderId}).then(res => {
                //console.log(res.data);
                localStorage.setItem('affid',res.data.affid);
                localStorage.setItem('sid',res.data.sid);
                localStorage.setItem('s1',res.data.c1);
                localStorage.setItem('s2',res.data.c2);
                localStorage.setItem('s3',res.data.c3);
                localStorage.setItem('click_id',res.data.click_id);
                
                if (res.data.affid == 'null'  & res.data.sid == 'null' & res.data.c1 == 'null' & res.data.c2 == 'null' & res.data.c3 == 'null' & res.data.click_id == 'null') {
                    window.location.href = '/'+localStorage.getItem('c_s');
                } else if(res.data.affid != '' & res.data.sid != '' & res.data.c1 != '' & res.data.c2 != '' & res.data.c3 != '' & res.data.click_id != ''){
                    window.location.href = '/'+localStorage.getItem('c_s')+'?affid='+res.data.affid+'&sid='+res.data.sid+
                    '&s1='+res.data.c1+'&s2='+res.data.c2+'&s3='+res.data.c3+'&click_id='+res.data.click_id;
                } else {
                    window.location.href = '/'+localStorage.getItem('c_s');
                }  
            });
        } else {
            getOrderList();
        }
        
    }, []);

    return (
        <>
            {loadershow  ? <div className="loader-new loading"></div> : 
            <div className="thankYou-page">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12 col-lg-12 mx-auto  mt-5 thankYou-cont'>
                            <div className="thankyouTable-wrapper">
                                <div className="thankyouTable-hdr">
                                    <h4 className='title'><span className="cartIcon"> <img src={cartIcon} className="img-fluid" /></span> Order History (most recent orders) - {moment().format('M/D/Y')}</h4>
                                    {/* <Button variant="primary" onClick={handleShow}>
                                    View All
                                </Button> */}
                                </div>
                                <div className='table-responsive'>
                                    <Table striped hover>
                                        <thead>
                                            <tr>
                                                <th>ORDER #</th>
                                                {/* <th>ORDER DATE</th> */}
                                                <th>DESCRIPTION</th>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                                <th>AMOUNT</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData.map((item) => (
                                                <tr>
                                                    <td>{item.order_id}</td>
                                                    {/* <td>{moment(item.acquisition_date).format('M/D/Y')}</td> */}
                                                    <td>{item.name}</td>
                                                    <td className='qty'>{item.product_qty}</td>
                                                    <td>${item.unit_price}</td>
                                                    <td>${item.price}</td>
                                                    <td>{item.order_status}</td>
                                                </tr>
                                            ))}
                                        
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-12 mx-auto  mt-5 thankYou-cont'>
                            <h3>Thank you for your order, <Link to="/bonus">GET YOUR FREE BONUSES NOW!</Link></h3>
                        </div>
                        <div className='col-md-7 col-lg-5 mx-auto  mt-5 thankYou-cont'>
                            <div className="thankYou-card">
                                <p className='thankYouTopTxt'>If you have any questions concerning your order, feel free to contact us at <a
                                    href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a>.</p>
                            </div>

                            <div className="thankYou-card">
                                <p className='cardTitle'>Your login credentials are:</p>
                                <p>Username: {userDetails.email}<br></br>
                                    Password: ********
                                </p>
                            </div>

                            <div className="thankYou-card">
                                <p className='thankYou-card'>Order Total: ${total}</p>
                            </div>

                            <div className="thankYou-card">
                                <p className='cardTitle'>Here are your billing details:</p>
                                <p>Name: {userDetails.name}</p>
                                <p>Address: {userDetails.address}</p>
                                <p>City: {userDetails.city}</p>
                                <p>State: {userDetails.state}</p>
                                <p>Zip: {userDetails.zip}</p>
                                <p>Country: {userDetails.country}</p>
                            </div>

                        </div>

                        <Modal className='orderHistory' show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Complete Order History</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='orderHistory-table'>
                                    <div className='table-responsive'>
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                    <th>ORDER #</th>
                                                    <th>ORDER DATE</th>
                                                    <th>DESCRIPTION</th>
                                                    <th>AMOUNT</th>
                                                    <th>TYPE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>55058</td>
                                                    <td>Nov 7, 2022</td>
                                                    <td>Total Cleanse Formula</td>
                                                    <td>$ 58.95</td>
                                                    <td>Successful Payment</td>
                                                </tr>
                                                <tr>
                                                    <td>55059</td>
                                                    <td>Nov 7, 2022</td>
                                                    <td>Sapphire Membership</td>
                                                    <td>$ 21.00</td>
                                                    <td>Successful Payment</td>
                                                </tr>
                                                <tr>
                                                    <td>55060</td>
                                                    <td>Nov 7, 2022</td>
                                                    <td>Tru Body ACV Keto Gummies 2</td>
                                                    <td>$ 147.00</td>
                                                    <td>Successful Payment</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
            }
            {/* Footer Begin  */}
            <Copyright/>
            {/* Footer End  */}
        </>


    )
}

export default Final