import React, { useEffect, useState } from "react";
import { Link, useLocation }  from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import {BASE_URL,stateList,GetCardType} from '../Config';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import moment from "moment";
import useStore from '../../store';
import axios from "axios";
import Swal from "sweetalert2";

import '../../assests/funnel/assets/css/acv-mobile-checkout.css';

import wholesaleLogo from '../../assests/funnel/assets/images/wholesale-logo.png';
import godaddyimg from '../../assests/funnel/assets/images/godaddyimg2.png';
import secureLogo from '../../assests/funnel/assets/images/secureLogo.png';
import safe from '../../assests/funnel/assets/images/safe.png';
import secureicons from '../../assests/funnel/assets/images/or-secureicons.jpg';
import guaranteeIco from '../../assests/funnel/assets/images/guarantee-ico-30days.png';
import socialIcons from '../../assests/Lander/assets/images/social-icons.jpg';
import websiteSecure from '../../assests/Lander/assets/images/website-secure.png';
import ca from '../../assests/Lander/assets/images/ca.png';
import pack1 from '../../assests/Lander/assets/images/package-1.png';
import pack2 from '../../assests/Lander/assets/images/package-2.png';
import pack3 from '../../assests/Lander/assets/images/product-checkout.png';
import visa from '../../assests/Lander/assets/images/visa.png';
import mastercard from '../../assests/Lander/assets/images/mastercard.png';
import discover from '../../assests/Lander/assets/images/discover.png';
import amex from '../../assests/Lander/assets/images/amex.png';
import cvvImg from '../../assests/Lander/assets/images/cvv-image.png';


function AcvMobileCheckout(props){
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    let params = location.search;
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({});
    const [SMToggle, setSMToggle] = useState(false);
    const [SMAddonToggle, setSMAddonToggle] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState(1);
    const [price, setPrice] = useState(0)
    const [retail, setReatil] = useState(0)
    const [save, setSave] = useState(0)
    const [total, setTotal] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [pID, setPID] = useState(0)
    const [FPDiv, setFPDiv] = useState(true)
    const [SPDiv, setSFPDiv] = useState(false)
    const [TPDiv, setTPDiv] = useState(false)
    const [loadershow, setloadershow] = useState(false);
    const [activeClass, setActiveClass] = useState('');
    const [divDisplay, setDivDisplay] = useState('');
    const [second, setSecond] = React.useState(59);
    const [minute, setMInute] = React.useState(9);
    const afterLogin = useStore((state) => state.afterLogin);
    const [cardType, setCardType] = useState("");
    const [showDiv, setShowDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    // Modal 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    //State List ;
    const options = stateList;

    useEffect(() => { 
        setIntialData(); 
    }, []);

    React.useEffect(() => {
        second > 0 && setTimeout(() => setSecond(second - 1), 1000);
        second == 0 && minute != 0 && setTimeout(() => setMInute(minute - 1), 1000);
        second == 0 && setTimeout(() => setSecond(59), 1000);
        minute == 0 && second == 0 && setTimeout(() => setMInute(4), 1000);
    }, [second]);

    const [states, setStates] = useState([]);
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };
    

    function setIntialData() {
        //console.log(props);
        setActiveClass(1);
        setDivDisplay(1);
        setCheckoutFunnelData(props.data);
        setPrice(props.data[0].first_product_price);
        setReatil(props.data[0].first_product_retail);
        setSave(props.data[0].first_product_save);
        setShipping(0);
        setTotal(parseFloat((props.data[0].first_product_price)) + parseFloat(shipping));
        setPID((props.data[0].first_product_id))
        localStorage.setItem('ProductId',props.data[0].first_product_id);
    }

    function setOrderSummery(productPrice, productId, productRetailPrice, productPriceSave, productShippingId, ProductDiv) {
        if (ProductDiv == 1) {
            setSelectedProduct(1);
            setFPDiv(true);
            setSFPDiv(false);
            setTPDiv(false);
            setActiveClass(3);
            setDivDisplay(3);
            localStorage.setItem('ProductId',productId);
        }
        if (ProductDiv == 2) {
            setSelectedProduct(2);
            setFPDiv(false);
            setSFPDiv(true);
            setTPDiv(false);
            setActiveClass(3);
            setDivDisplay(3);
            localStorage.setItem('ProductId',productId);
        }
        if (ProductDiv == 3) {
            setSelectedProduct(3);
            setFPDiv(false);
            setSFPDiv(false);
            setTPDiv(true);
            localStorage.setItem('ProductId',productId);
            setActiveClass(3);
            setDivDisplay(3);
        }
    }

    function handleSPAddon(selectedProduct) {
        setSMAddonToggle(!SMAddonToggle);
        //console.log(SMAddonToggle);
        if (selectedProduct == 3) {
            if (!SMAddonToggle) {
                setShipping(0);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                localStorage.setItem('ProductId',checkoutFunnelData[0].third_product_id);
            } else {
                setShipping(9.95);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(9.90));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(9.90));
                localStorage.setItem('ProductId',97);
            }
        }
    }

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked); 
    };

    const Form1Schema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
        phone: Yup.string().required('Phone no is required')
            .matches(/^[0-9]+$/, "Please enter only number!")
            .min(10, 'Please enter a valid contact number!')
            .max(10, 'Please enter a valid contact number!')
    })

    const handleForm1Submit = (values,{ setSubmitting }) => {
        setSubmitting (true);
        localStorage.setItem('firstName', values.firstName);
        localStorage.setItem('lastName', values.lastName);
        localStorage.setItem('email', values.email);
        localStorage.setItem('phone', values.phone);

        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('campaign_id',9);
        formdata.append('tag1','diet-prospect');

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);
        
        setActiveClass(2);
        setDivDisplay(2);
        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                    }
                })
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    function confirmSubmit(data) {
        setloadershow(true);
        let expirationDate = data.cc_month+data.cc_year.slice(-2);
        localStorage.setItem('neobodPId',665);
        localStorage.setItem('neobodCid',668);
        localStorage.setItem('method','normal');
        const formdata = new FormData();
        formdata.append('product_id', localStorage.getItem('ProductId'));

        if (checkoutFunnelData[0].membership_add_on == 1) {
            if (SMAddonToggle) {
                formdata.append('is_membership', 41);
            }
            else {
                formdata.append('is_membership', 0);
            }
        }
        else {
            formdata.append('is_membership', 0);
        }
        formdata.append('first_name',localStorage.getItem('firstName'));
        formdata.append('last_name',localStorage.getItem('lastName'));
        formdata.append('email',localStorage.getItem('email'));
        formdata.append('phone',localStorage.getItem('phone'));
        formdata.append('address',localStorage.getItem('shippingAddress1'));
        formdata.append('city',localStorage.getItem('shippingCity'));
        formdata.append('state',localStorage.getItem('shippingState'));
        formdata.append('zip',localStorage.getItem('shippingZip'));
        formdata.append('country',localStorage.getItem('country'));
        formdata.append('creditCardNumber', data.ccnumber);
        formdata.append('expirationDate', expirationDate);
        formdata.append('cvv', data.cvv);
        formdata.append('creditCardType', data.card_type);
        formdata.append('affid', data.affid);
        formdata.append('sid', data.sid);
        formdata.append('s1', data.s1);
        formdata.append('s2', data.s2);
        formdata.append('s3', data.s3);
        formdata.append('click_id', data.click_id);
        formdata.append('notes', data.notes);

        formdata.append('billingSameAsShipping', localStorage.getItem('billingSameAsShipping'));
        formdata.append('shipping_address', localStorage.getItem('shipping_address'));
        formdata.append('shipping_city', localStorage.getItem('shipping_city'));
        formdata.append('shipping_state', localStorage.getItem('shipping_state'));
        formdata.append('shipping_zip', localStorage.getItem('shipping_zip'));
        formdata.append('shipping_country', localStorage.getItem('shipping_country'));

        formdata.append('prospectId', localStorage.getItem('prospectId'));
        formdata.append('method',localStorage.getItem('method'));

        localStorage.setItem('affid', data.affid);
        localStorage.setItem('sid', data.sid);
        localStorage.setItem('s1', data.s1);
        localStorage.setItem('s2', data.s2);
        localStorage.setItem('s3', data.s3);
        localStorage.setItem('click_id', data.click_id);
        localStorage.setItem('notes', data.notes);
        //console.log(Object.fromEntries(formdata));
        axios.post(`api/place-funnel-order-with-prospect`, formdata).then(res => {
            // console.log(res);
            
            if (res.data.status === true) {
                sessionStorage.setItem('yp', res.data.data.user_info.yp);
                localStorage.setItem('orderId', res.data.data.order_id);
                localStorage.setItem('membership_orderId', res.data.membership ? res.data.membership.order_id : false);
                localStorage.setItem('authToken', res.data.data.member_token);
                localStorage.setItem('permission', res.data.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.user_info.email);

                axios.get(`api/check_user_membership/${localStorage.getItem('email')}`).then(result => {
                    //console.log(result.data);
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.data.user_info.account_permission, res.data.data.user_info.user_type,res.data.data.user_info.user_id,res.data.data.user_info,is_membership);
                    if (localStorage.getItem('up1_s')) {
                        window.location.href = '/' + localStorage.getItem('up1_s')+params;
                    }else {
                         window.location.href = '/' + localStorage.getItem('con_s')+params;
                    }
                });
            }
            else {
                setloadershow(false);
                //console.log(res);
                Swal.fire({
                    title: res.data.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                })
            }
        })
                                                    
    }

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    return(
        <>
            {Object.values(checkoutFunnelData).length !== 0 ? <><div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="mobileCheckout acvMobileCheckout">
                <div className="m-wrapper">
                    <div className="container">
                        <div className="m-check-row">
                            {/* Left Begin  */}
                            <div className="m-check-left">
                                {/* Top Begin  */}
                                <div className="acvMob-top" id="top-1">
                                    <div className="acvMob-logo mb-4 text-center">
                                        <img src={wholesaleLogo} alt="Logo" />
                                    </div>
                                    <div className="fastDelivery">
                                        <img alt="" src={godaddyimg} className="img-fluid" />
                                    </div>
                                    <div className="breadcrumbs">
                                        <ul className="breadcrumbs__list">
                                            <li className={"breadcrumbs__item breadcrumbs__item_1 "+(activeClass === 1 ? 'breadcrumbs__item_active' : '')}>
                                                <span>Qualify Now</span>
                                            </li>
                                            <li className={"breadcrumbs__item breadcrumbs__item_2 "+(activeClass === 2 ? 'breadcrumbs__item_active' : '')}>
                                                <span>Select Package</span>
                                            </li>
                                            <li className={"breadcrumbs__item breadcrumbs__item_3 "+((activeClass === 3) || (activeClass === 4) ? 'breadcrumbs__item_active' : '')}>
                                                <span>Confirm Order</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className={divDisplay === 1 ? 'Claim text-center' : 'Claim text-center d-none'}>
                                        <b className="claimTitle">Claim Your KETO Package Today!</b>                                        
                                        <strong>
                                            At 10x the Fat Burn,<br />
                                            You Won’t Believe it’s All Natural!
                                        </strong>
                                    </p>
                                </div>
                                {/* Top End  */}

                                {/* Form Div Begin  */}
                                <div className={(divDisplay === 1 ? 'form-partial mob-check-form mb-4 px-3' : 'form-partial mob-check-form mb-4 px-3 d-none')} id="initialForm">
                                    <Formik 
                                        enableReinitialize = {true}
                                        initialValues={{
                                            firstName: '',
                                            lastName: '',
                                            email: '',
                                            phone: '',
                                        }}
                                        validationSchema={Form1Schema}
                                        onSubmit={handleForm1Submit}
                                    >
                                        {(formProps,isSubmitting ) => (
                                        <Form name="qualify_form1" className="kform form123" id="theForm">                        
                                            <div className="lead-form-wrapper">
                                                <div className="columns form-holder">
                                                    <label className="mb-1">First Name:</label>
                                                    <Field className="form-control required" data-placement="auto left" name="firstName" id="fields_fname" placeholder="First Name*" title="First Name*" type="text"/>
                                                    <CustomErrorMsg name="firstName" />
                                                </div>
                                                <div className="columns form-holder">
                                                    <label className="mb-1">Last Name:</label>
                                                    <Field className="form-control required" data-placement="auto left" name="lastName" id="fields_lname" placeholder="Last Name*" title="Last Name*" type="text"/>
                                                    <CustomErrorMsg name="lastName" />
                                                </div>
                                                <div className="columns">
                                                    <label className="mb-1">Email:</label>
                                                    <Field className="form-control required" data-placement="auto left" name="email" id="fields_email" placeholder="Email*" title="Email" type="email"/>
                                                    <CustomErrorMsg name="email" />
                                                </div>
                                                <div className="columns">
                                                    <label className="mb-1">Phone:</label>
                                                    <Field className="form-control required" data-placement="auto left" name="phone" id="fields_phone" placeholder="Phone Number*" title="Phone Number" type="tel" maxLength="10" />
                                                    <CustomErrorMsg name="phone" />
                                                </div>
                                                
                                                <button type="submit" className="partialsubmitbutton" disabled={isSubmitting}> Qualify For My Order » </button>
                                            </div>
                                        </Form>
                                        )}
                                    </Formik>                    
                                </div>
                                {/* Form Div End  */}

                                {/* Select Package Begin  */}
                                <div className={(divDisplay === 2) ? 'select-package-div' : 'select-package-div d-none'}>
                                    <p className="packageTitle text-center"><b>Approved</b></p>
                                    <div className="linebreak my-3"></div>

                                    {/* Pack Wrapper  */}
                                    <div className="packWrapper">
                                        {/* <!-- Pack 1  --> */}
                                        <div className={FPDiv ? 'package package1 package_active' : 'package package1'} onClick={() => { setOrderSummery(checkoutFunnelData[0].first_product_price, checkoutFunnelData[0].first_product_id, checkoutFunnelData[0].first_product_retail, checkoutFunnelData[0].first_product_save, checkoutFunnelData[0].first_product_shipping_id, 1)}}>
                                            <div className="package__left">
                                                <span className="package__name">Package 1</span>
                                                <span className="package__coll">3 BOTTLES</span>
                                                <span className="package__free">+ Get 2 Free!*</span>
                                                <p className="retail-price-mobile">Retail Price : <span className="text_red">$495</span><br/>
                                                Your Price : $195 - Save $300
                                                </p>
                                                <span className="package__price">
                                                    <p className="price d-inline-block">$39.00</p>
                                                    <span>/bottle</span>
                                                </span>
                                                <span className="package__stock">In Stock – Sell Out Risk: High</span>
                                                <span className="save-label">save over <span>50%</span></span>
                                            </div>
                                            <div className="package__right">
                                                <div className="bottle-block clearfix">
                                                    <div className="packItem">
                                                        <img src={pack1} className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                                <div className="retail-block">
                                                <span className="package__retail">Retail: <span className="retail-price">$65.49</span>/bottle</span>
                                                <span className="shipping-block"><span>Free shipping</span></span>
                                                </div>
                                            </div>
                                            <span className="best-choise">Best <br /> choice</span>
                                            <span className="package-btn btn_pulse">yes – choose this package <span>»</span></span>
                                        </div>
                                        {/* <!-- Pack 1 End --> */}
                                        {/* <!-- Line Break  --> */}
                                        <div className="linebreak my-2"></div>

                                        {/* <!-- Pack 2  --> */}
                                        <div className={SPDiv ? 'package package2 package_active' : 'package package2'} onClick={() => { setOrderSummery(checkoutFunnelData[0].second_product_price, checkoutFunnelData[0].second_product_id, checkoutFunnelData[0].second_product_retail, checkoutFunnelData[0].second_product_save, checkoutFunnelData[0].second_product_shipping_id, 2)}}>
                                            <div className="package__left">
                                                <span className="package__name">Package 2</span>
                                                <span className="package__coll">2 BOTTLES</span>
                                                <span className="package__free">+ Get 1 Free!*</span>
                                                <p className="retail-price-mobile">Retail Price : <span className="text_red">$327</span><br/>
                                                Your Price : $147 - Save $180
                                                </p>
                                                <span className="package__price">
                                                    <p className="price">$49.00</p>
                                                    <span>/bottle</span>
                                                </span>
                                                <span className="package__stock">In Stock – Sell Out Risk: High</span>
                                            </div>
                                            <div className="package__right">
                                                <div className="bottle-block clearfix">
                                                    <div className="packItem">
                                                        <img src={pack2} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="save-block">
                                                        <span className="save-block__title">Save</span>
                                                        <span className="save-block__price save-price">$74.95</span>
                                                    </div>
                                                </div>
                                                <div className="retail-block">
                                                <span className="package__retail">Retail: <span className="retail-price">$74.95</span>/bottle</span>
                                                <span className="shipping-block"><span>Free shipping</span></span>
                                                </div>
                                            </div>
                                            <span className="package-btn">yes – choose this package <span>»</span></span>
                                        </div>
                                        {/* Pack 2 End  */}

                                        {/* <!-- Line Break  --> */}
                                        <div className="linebreak my-2"></div>

                                        {/* <!-- Pack 3  --> */}
                                        <div className={TPDiv ? 'package package3 package_active' : 'package package3'} onClick={() => { setOrderSummery(checkoutFunnelData[0].third_product_price, checkoutFunnelData[0].third_product_id, checkoutFunnelData[0].third_product_retail, checkoutFunnelData[0].third_product_save, checkoutFunnelData[0].third_product_shipping_id, 3)}}>
                                            <div className="package__left">
                                                <span className="package__name">Package 3</span>
                                                <span className="package__coll">1 BOTTLE</span>
                                                <p className="retail-price-mobile">Retail Price : <span className="text_red">$69</span><br/>
                                                Your Price : $69 - Save $0.00
                                                </p>
                                                <span className="package__price">
                                                    <p className="price inline-block">$69.00</p>
                                                    <span>/bottle</span>
                                                </span>
                                                <span className="package__stock">In Stock – Sell Out Risk: High</span>
                                            </div>
                                            <div className="package__right">
                                                <div className="bottle-block clearfix">
                                                    <div className="packItem">
                                                        <img alt="" src={pack3} className="prod-btl1" />
                                                    </div>
                                                </div>
                                                <div className="retail-block">
                                                    <span className="package__retail ">Retail: <span className="retail-price">$69.00</span>/bottle</span>
                                                    <span className="shipping-block"><span>Free shipping</span></span>
                                                </div>
                                            </div>
                                            <span className="package-btn">yes – choose this package <span>»</span></span>
                                        </div>   
                                        {/* Pack 3 end  */}
                                    </div>
                                    {/* Pack Wrapper End  */}
                                
                                </div>       
                                {/* Select Package End  */}

                                
                                
                                    {/* Confirm Order */}
                                    <div className={(divDisplay === 3) ? 'confirm-order' : 'confirm-order d-none'}>
                                        <p className="claimTitle text-center mb-4"><b>Your package is reserved for the next: <span id="clockdiv">{minute}:{second<10?"0"+second:second}</span></b></p>
                                        <p className="packageTitle text-center text-dark"><b> Where do we send it?</b></p>
                                        <div className="form-partial mob-check-form">
                                            <div className="shipping-form-wrapper">
                                            <Formik
                                                enableReinitialize = {true}
                                                initialValues={{
                                                    firstName: localStorage.getItem('firstName'),
                                                    lastName: localStorage.getItem('lastName'),
                                                    email: localStorage.getItem('email'),
                                                    phone: localStorage.getItem('phone'),
                                                    shippingAddress1: '',
                                                    shippingZip: '',
                                                    shippingCity: '',
                                                    shippingState: '',
                                                    country:'',
                                                    shipping_address: '',
                                                    shipping_city: '',
                                                    shipping_state: '',
                                                    shipping_zip: '',
                                                    shipping_country: ''
                                                }}
                                                validationSchema={Yup.object({
                                                    firstName: Yup.string().required('First name is required'),
                                                    lastName: Yup.string().required('Last name is required'),
                                                    shippingAddress1: Yup.string().required('Address is required'),
                                                    shippingZip: Yup.string().required('Zip code is required'),
                                                    shippingCity: Yup.string().required('City is required'),
                                                    shippingState: Yup.string().required('State is required'),
                                                    email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                                    phone: Yup.string().required('Phone no is required')
                                                        .matches(/^[0-9]+$/, "Please enter only number!")
                                                        .min(10, 'Please enter a valid contact number!')
                                                        .max(10, 'Please enter a valid contact number!'),
                                                    country: Yup.string().required('Country is required'),
                                                    shipCheckbox:Yup.string(),
                                                    shipping_address: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping address is required")
                                                    }),
                                                    shipping_city: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping city is required")
                                                    }),
                                                    shipping_state: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping state is required")
                                                    }),
                                                    shipping_zip: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping zip is required")
                                                    }),
                                                    shipping_country: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping country is required")
                                                    })
                                                })}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setSubmitting(true);
                                                    // alert(JSON.stringify(values, null, 2));
                                                    localStorage.setItem('firstName', values.firstName);
                                                    localStorage.setItem('lastName', values.lastName);
                                                    localStorage.setItem('email', values.email);
                                                    localStorage.setItem('phone', values.phone);
                                                    localStorage.setItem("shippingAddress1",values.shippingAddress1);
                                                    localStorage.setItem("shippingCity",values.shippingCity);
                                                    localStorage.setItem("shippingState",values.shippingState);
                                                    localStorage.setItem("shippingZip",values.shippingZip);
                                                    localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');

                                                    if(values.shipping_address !==''){
                                                        localStorage.setItem("billingSameAsShipping",'NO');
                                                        localStorage.setItem("shipping_address",values.shipping_address);
                                                        localStorage.setItem("shipping_city",values.shipping_city);
                                                        localStorage.setItem("shipping_state",values.shipping_state);
                                                        localStorage.setItem("shipping_zip",values.shipping_zip);
                                                        if(values.shipping_country === '239'){
                                                            values.shipping_country = 'US';
                                                        } else {
                                                            values.shipping_country = 'CA';
                                                        }
                                                        localStorage.setItem("shipping_country",values.shipping_country);
                                                    }else {
                                                        localStorage.setItem("billingSameAsShipping",'YES');
                                                    }

                                                    setActiveClass(4);
                                                    setDivDisplay(4);
                                                    //console.log(values);
                                                }}
                                            >
                                            {(formProps) => (
                                            <Form className="">
                                                <div className="shipping-form-grp">
                                                    <div className="columns form-holder">
                                                        <Field type="text" className="mm-textField" placeholder="First Name" name="firstName"/>
                                                        <CustomErrorMsg name="firstName" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field type="text" className="mm-textField" placeholder="Last Name" name="lastName"/>
                                                        <CustomErrorMsg name="lastName" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field type="email" className="mm-textField" placeholder="Email Address" name="email"/>
                                                        <CustomErrorMsg name="email" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field type="tel" className="mm-textField" placeholder="Phone Number" maxLength={10} name="phone"/>
                                                        <CustomErrorMsg name="phone" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field type="text" className="mm-textField" placeholder="Billing Address" name="shippingAddress1"/>
                                                        <CustomErrorMsg name="shippingAddress1" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field type="text" name="shippingCity" className="mm-textField" placeholder="Billing City" />
                                                        <CustomErrorMsg name="shippingCity" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field
                                                            name="country"
                                                            as="select"
                                                            placeholder="Select an option"
                                                            className="mm-textField"
                                                            onChange={(event) => {
                                                                formProps.handleChange(event);
                                                                handleCountryChange(event); // Call the custom function here if needed
                                                            }}
                                                        >
                                                            <option selected="selected" value="" >Select country</option>
                                                            <option value="239" >United State</option>
                                                            <option value="41" >Canada</option>
                                                        </Field>
                                                        <CustomErrorMsg name="country" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field
                                                            name="shippingState"
                                                            as="select"
                                                            className="mm-textField"
                                                        >
                                                            <option value="" >Select an option</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.abbreviation}>
                                                                    {state.state}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <CustomErrorMsg name="shippingState" />
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <Field type="text" maxLength="6" name="shippingZip" className="mm-textField" placeholder="Billing Zip Code" />
                                                        <CustomErrorMsg name="shippingZip" />
                                                    </div>
                                                    <div>
                                                        <label className="checkboxLabel mb-3">
                                                            <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />Shipping is the same as billing
                                                        </label>
                                                        {showDiv &&
                                                        <div className="form-div-2">
                                                            <div className="form-holder">
                                                                <label className="formLabel">Address</label>
                                                                <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                                <CustomErrorMsg name="shipping_address" />
                                                            </div>
                                                            <div className="form-holder">
                                                                <label className="formLabel">City:</label>
                                                                <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                                <CustomErrorMsg name="shipping_city" />
                                                            </div>
                                                            <div className="form-holder">
                                                                <label className="formLabel">Country:</label>
                                                                <Field 
                                                                    as="select" 
                                                                    className="form-control" 
                                                                    name="shipping_country"
                                                                    onChange={(event) => {
                                                                        formProps.handleChange(event);
                                                                        handleCountryChange(event);
                                                                    }}
                                                                >
                                                                    <option selected="selected" value="" >Select country</option>
                                                                    <option value="239" >United State</option>
                                                                    <option value="41" >Canada</option>
                                                                </Field>
                                                                <CustomErrorMsg name="shipping_country" />
                                                            </div>  
                                                            <div className="form-holder">
                                                                <label className="formLabel">State:</label>
                                                                <Field 
                                                                    as="select" 
                                                                    className="form-control" 
                                                                    name="shipping_state"
                                                                >
                                                                <option value="" >Select an option</option>
                                                                {states.map((state) => (
                                                                    <option key={state.id} value={state.abbreviation}>
                                                                        {state.state}
                                                                    </option>
                                                                ))}
                                                                </Field>
                                                                <CustomErrorMsg name="shipping_state" />
                                                            </div>
                                                            <div className="form-holder">
                                                                <label className="formLabel">Zip Code:</label>
                                                                <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                                <CustomErrorMsg name="shipping_zip" />
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className="columns form-holder">
                                                        <button type="submit" className="partialsubmitbutton"> Proceed to Checkout »</button>
                                                    </div>
                                                </div>
                                            </Form>
                                            )}
                                            </Formik>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Confirm Order End */}
                                
                                <Formik
                                    enableReinitialize = {true}
                                    initialValues={{
                                        card_type: '',
                                        cc_month: 0,
                                        cc_year: '', 
                                        ccnumber: '', 
                                        cvv: '',
                                        affid: affid,
                                        sid: sid,
                                        s1: s1,
                                        s2: s2,
                                        s3: s3,
                                        click_id: click_id,
                                        notes: notes
                                    }}
                                    validationSchema={Yup.object({
                                        cc_year: Yup.string().required('Select valid year'),
                                        cc_month: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                            is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                            then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                        }),
                                        card_type: Yup.string(),
                                        ccnumber: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                        .required('Card number is required')
                                        .max(16, 'Credit card should not be grater than 16 digits')
                                        .min(15, 'Credit card should not be less than 15 digits'),
                                        cvv: Yup.string().matches(/^[0-9]{3,4}$/,
                                                'Security code must be a 3 or 4 digit number')
                                                .required('Security code is required')
                                    })}

                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        // alert(JSON.stringify(values, null, 2));
                                        values.card_type = GetCardType('"' + values.ccnumber + '"');
                                        confirmSubmit(values);
                                    }}
                                >
                                <Form>
                                    {/* Final Step – Payment Information  */}
                                    <div className={(divDisplay === 4) ? 'finalOrder' : 'finalOrder d-none'}>
                                        <p className="packageTitle text-center text-dark mb-1"><b> Final Step – Payment Information</b></p>
                                        <p className="smText text-center text-dark"><em>Your order will be processed on our secure servers</em></p>
                                        <div className="cards-list">
                                            <span>We Accept:</span>
                                            <img alt="" src={visa} />
                                            <img alt="" src={mastercard} />
                                            <img alt="" src={discover} />
                                            <img alt="" src={amex} />
                                        </div>
                                        <div className="checkout-form-wrapper mob-check-form mt-4">
                                            <div className="form-holder">
                                                <label>Card Number:</label>
                                                <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                                <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                                <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                                <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                                <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                                <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                                <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                                <Field type="hidden" className="form-control" name="notes" value={notes}/>
                                                <Field type="text" maxLength="16" className="mm-textField" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                <CustomErrorMsg name="ccnumber" />
                                            </div>
                                            <div className="form-holder mb_form_row">
                                                <div className="col_6">
                                                    <label>Select Month:</label>
                                                    <Field as="select" className="selectField" name="cc_month">
                                                        <option value="0">Select</option>
                                                        <option value="01">January</option>
                                                        <option value="02">February</option>
                                                        <option value="03">March</option>
                                                        <option value="04">April</option>
                                                        <option value="05">May</option>
                                                        <option value="06">June</option>
                                                        <option value="07">July</option>
                                                        <option value="08">August</option>
                                                        <option value="09">September</option>
                                                        <option value="10">October</option>
                                                        <option value="11">November</option>
                                                        <option value="12">December</option>
                                                    </Field>
                                                    <CustomErrorMsg name="cc_month" />
                                                </div>
                                                <div className="col_6">
                                                    <label>Select Year:</label>
                                                    <Field as="select" className="selectField" name="cc_year" >
                                                        <option value="">Select</option>
                                                        {years.map(year => (
                                                            <option key={year} value={year}>
                                                                {year}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <CustomErrorMsg name="cc_year" />
                                                </div>
                                            </div>
                                            <div className="form-holder mb_form_row">
                                                <div className="col_6">
                                                    <label>CVV Number:</label>
                                                    <Field type="text" maxLength="4" placeholder="CVV" name="cvv"/>
                                                    <CustomErrorMsg name="cvv" />
                                                </div>
                                                <div className="col_6">
                                                    <div className="cvv-link left">
                                                        <button onClick={handleShow}>
                                                            What is this?
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-holder">
                                                <button type="submit" className="partialsubmitbutton  btn_pulse"> Rush My Order Now » </button>
                                                <div className="frmCheckElemts mt-3 text-center">
                                                    <label className="spl-checkbox">
                                                        <input type="checkbox" name="emag_subscription" id="emag_subscription"  className="chkbox no-error" onChange={() => { handleSPAddon(selectedProduct) }} defaultChecked={SMAddonToggle}/>
                                                        Yes, I want FREE Shipping With Sapphire Membership!                            
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                {/* Final Step – Payment Information End   */}
                                </Formik>   
                                <div className="linebreak my-4"></div>

                                <div className="secureLogo">
                                    <div className="text-center">We care about your privacy.</div>
                                    <img src={secureLogo} className="img-fluid" alt="" />
                                </div>

                                <div className="linebreak my-4"></div>

                                <div className="secureLogo">
                                    <img src={safe} className="img-fluid" alt="" />
                                </div>

                                <div className="linebreak my-4"></div>

                                <div className="secureicons">
                                    <img src={secureicons} className="img-fluid" alt="" />
                                </div>


                                {/* Guranteee Block Begin  */}
                                <div className="guarantee-block">
                                    <div className="guarantee-top">30 days money back guarantee</div>
                                    <div className="guarantee-content">
                                        <img className="guarantee-icon" src={guaranteeIco} alt="" />
                                        <div className="guarantee-text">
                                            <p>We are so confident in our products and services, that we back it with a 30 days money back guarantee. If for any reason you are not fully satisfied with our products, simply return the purchased products in the original container within 30 days of when you received your order. We will refund you 100% of the purchase price – with absolutely no hassle.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* Gurantrr Block End  */}

                                {/* <!-- Top Term  --> */}
                                <div className="form__in">
                                    <div className={checkoutFunnelData[0].membership_add_on == 1 ? SMToggle ? 'p-2 top-terms open' : 'top-terms' : 'd-none'}>
                                    <h1 className="top-termsHdng" onClick={() => { setSMToggle(!SMToggle) }}>Sapphire Membership Membership Terms</h1>
                                    <div className="top-trends-desc">
                                        <p>
                                            BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire
                                            Membership takes that one step further.
                                        </p>
                                        <p>
                                            As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire
                                            Membership Today!:
                                        </p>
                                        <p>-FREE shipping on all orders!</p>
                                        <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                        <p>-No hassle, no questions asked replacement on any items.</p>
                                        <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                        <p>-Get it all FREE for the first 14 days!</p>
                                        <p>Some Notes About Your Membership:</p>
                                        <p>
                                            Remember – while your 2 weeks of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $17.00/ month to retain membership after your first 2 weeks. If you wish to cancel
                                            your Sapphire Membership at any time, simply contact
                                            our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a>
                                        </p>
                                    </div>
                                    </div>
                                </div>


                            </div>
                            {/* Left End  */}
                        </div>
                    </div>
                </div>
                

                {/* CVV Modal  */}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="cvvImg">
                            <img src={cvvImg} className="w-100" alt=""  />
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Footer Begin  */}
                <footer className="footer proLand-footer">
                    <div className="container">
                        <img alt="" className="website-secure" src={websiteSecure} />
                        <ul className="soc-list row">
                            <li><img alt="" src={socialIcons} /></li>
                            <li><img alt="" src={ca} /></li>
                        </ul>

                        <p>
                            Tru Body ACV Keto Gummies is committed to maintaining the highest quality products and the utmost integrity in business practices. All products sold on this website are certified by Good Manufacturing Practices (GMP), which
                            is the highest standard of testing in the supplement industry.
                        </p>
                        <p>
                            Notice: The products and information found on this site are not intended to replace professional medical advice or treatment. These statements have not been evaluated by the Food and Drug Administration. These
                            products are not intended to diagnose, treat, cure or prevent any disease. Individual results may vary.
                        </p>
                        <p>
                            Copyright
                            ©
                            {" "+currentYear+" "}
                            Tru Body ACV Keto Gummies. All Rights Reserved.
                        </p>

                        <ul className="terms-links">
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Privacy Policy |&nbsp;</Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Terms and Conditions |</Link>
                            </li>
                            <li><Link to="/contact-us">&nbsp;Contact Us</Link></li>
                        </ul>
                    </div>
                </footer>
                {/* Footer End  */}


            </div>
            </> : null}
            
        </>
    );
}

export default AcvMobileCheckout;
