import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import star from '../../assests/frontend/assets/images/star.png';
import star2 from '../../assests/frontend/assets/images/two-star.png';
import star3 from '../../assests/frontend/assets/images/three-star.png';
import star4 from '../../assests/frontend/assets/images/four-star.png';
import star5 from '../../assests/frontend/assets/images/five-star.png';
import starYellow from '../../assests/frontend/assets/images/star-yellow.png';
import avatar from '../../assests/frontend/assets/images/user-avatar.png';

import { CartState } from '../../context/Context';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import useStore from '../../store';
import {BASE_URL} from '../Config';
import Copyright from '../Copyright';

function SingleProduct() {
    const params = useParams()
    const { state: { cart }, dispatch } = CartState();
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [sURL, setSurl] = useState(BASE_URL+'whc-custom/storage/app/public/')
    const [productData, setproductData] = useState(false);
    const [productReview, setproductReview] = useState(false);
    const navigate = useNavigate();
    const isLogin = useStore((state) => state.isLogin);
    const isMember = useStore((state) => state.isMember);
    const ref = useRef(null);
    const [is_membership, setIsMembership] = useState('');

    const scrollToCustom = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [productReviewInstance, setProductReviewInstance] = useState({
        name: "",
        email: "",
        user_id: "",
        stars: "",
        review: "",
        saveNameEmail: ""
    });

    const handelInput = (e) => {
        e.persist();
        setProductReviewInstance({ ...productReviewInstance, [e.target.name]: e.target.value })
        if (e.target.name == 'saveNameEmail') {
            var c = e.target.checked;
            if (c) {
                setProductReviewInstance({ ...productReviewInstance, ['saveNameEmail']: "1" })
            }
            else {
                setProductReviewInstance({ ...productReviewInstance, ['saveNameEmail']: "0" })
            }
        }
    }

    const [rtingValidation, SetRtingValidation] = useState(false);
    const [emailValidation, SetEmailValidation] = useState(false);
    const [nameValidation, SetNameValidation] = useState(false);
    const submitProductReview = (e) => {
        e.preventDefault();
        const queryParamsA = new URLSearchParams(window.location.search);
        const P_ID = params.slug;
        SetNameValidation(false);
        SetEmailValidation(false);
        SetRtingValidation(false);

        if (!localStorage.getItem('userId')) {
            if (productReviewInstance.name == '' && !localStorage.getItem('reviewName')) {
                SetNameValidation(true);
                return false;
            }

            if (productReviewInstance.email == '' && !localStorage.getItem('reviewEmail')) {
                SetEmailValidation(true);
                return false;
            }
        }
        if (productReviewInstance.stars == '') {
            SetRtingValidation(true);
            return false;
        }
        const formdata = new FormData();
        formdata.append('review', productReviewInstance.review);
        formdata.append('rating', productReviewInstance.stars);
        formdata.append('product_id', P_ID);
        if (localStorage.getItem('userId')) {
            formdata.append('user_id', localStorage.getItem('userId'));
        }
        else {


            if (localStorage.getItem('reviewName')) {
                formdata.append('name', localStorage.getItem('reviewName'));
                formdata.append('email', localStorage.getItem('reviewEmail'));
            }
            else {
                formdata.append('name', productReviewInstance.name);
                formdata.append('email', productReviewInstance.email);
            }
        }

        axios.post(`api/saveReview`, formdata).then(res => {
            if (res.data.status === true) {
                getProductData();
                if (productReviewInstance.saveNameEmail == 1) {
                    localStorage.setItem('reviewEmail', productReviewInstance.email);
                    localStorage.setItem('reviewName', productReviewInstance.name);
                }
                else {
                    localStorage.removeItem('reviewEmail');
                    localStorage.removeItem('reviewName');
                }
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
            }
        }).catch(err => {
            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });


    }
    const [averageRating, setAverageRating] = useState(0);
    async function getProductData() {
        if(localStorage.getItem('userEmal')){
            axios.get(`api/check_user_membership/${localStorage.getItem('userEmal')}`).then(result => {
                if(parseInt(result.data) === 1){
                    setIsMembership(1);
                } else {
                    setIsMembership(0);
                }
            });
        }

        const queryParams = new URLSearchParams(window.location.search);
        const ID = params.slug
        axios.get(`api/productDetailsByID/${ID}`).then(res => {
            //console.log(res.data.data);
            setproductData(res.data.data);
            axios.get(`api/listProductReviews/${ID}`).then(res => {
                //console.warn(res.data.data);
                setproductReview(res.data.data);
                if (res.data.data.length > 0) {
                    var totalRating = 0;
                    res.data.data.map(function (data, i) {
                        totalRating = totalRating + data.rating;
                    })
                    var averagerating = totalRating / res.data.data.length;
                    console.log(averageRating);
                    setAverageRating(averagerating);
                }

            })
        }).catch(err => {
            console.log(err.response)
        }
        )
    }


    function addToCartAccessControl(message,isLoggedIn){
        Swal.fire({
            title: message,
            showCancelButton: true,
            showConfirmButton: isLoggedIn,
            confirmButtonText: 'Login',
            cancelButtonText: "Register",
            cancelButtonColor: '#18aebf',
            showCloseButton: true,

        }).then((result) => {
            if (result.isConfirmed) {
                navigate("/login");
            }
            if (result.dismiss === 'cancel') {
                navigate("/create-membership");
            }
        })
    }

    // useEffect(()=>{
    //     console.log(cart);
    // },[cart]);

    useEffect(() => {
        getProductData();
        scrollToCustom();
    }, [params]);
    // console.log('Cart data')
    // console.log(productData.id)

    // cart.map((e) => {
    //     console.log(e.id)
    // })


    return (
        <>
            <div className='singleProduct' ref={ref}>
                <div className='container'>
                    <div className='pageTitle-div text-center'>
                        {/* <h2 className='pageTitle'></h2> */}
                        <div className='breadcrumbs_nav'>
                            {/* <a className='breadcrumbs_nav' href="/">HOME</a>  <a href="/shop/supplements" className=''>Shop</a> <a href={productData != false ? "/shop/" + productData[0].category_name.toLowerCase().replace(" ", "-") : null} className='lastElem'>{productData != false ? productData[0].category_name : null}</a> */}
                        </div>
                    </div>
                    {/* Product Wrapper  */}
                    <div className='singleProduct-wrap mb-3 mb-lg-5'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='productSlider'>
                                    <div className='productSlider-main'>
                                        <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                                            <div>
                                                <div className='main-image'>
                                                    <img src={productData != false ? sURL + productData[0].image : null} className="img-fluid" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className='main-image'>
                                                    <img src={productData != false ? sURL + productData[0].label_image : null} className="img-fluid" />
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className='productSlider-nav'>
                                        <Slider
                                            asNavFor={nav1}
                                            ref={(slider2) => setNav2(slider2)}
                                            slidesToShow={2}
                                            swipeToSlide={true}
                                            focusOnSelect={true}
                                            infinite={true}
                                            variableWidth = {true}
                                        >
                                            <div>
                                                <div className='nav-image'>
                                                    <img src={productData != false ? sURL + productData[0].image : null} className="img-fluid" />
                                                </div>
                                            </div>

                                            {productData && productData[0].label_image === null ? <></> :
                                                <div>
                                                    <div className='nav-image'>
                                                        <img src={productData != false ? sURL + productData[0].label_image : null} className="img-fluid" />
                                                    </div>
                                                </div>
                                            }
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='singleProduct-info-cont'>
                                    <span >{productData != false ? productData[0].brand_name : null}</span>
                                    <h1 className="product_title entry-title">{productData != false ? productData[0].name : null}</h1>
                                    <div className='ratingDiv'>
                                        <span className='ratingStars'>
                                            {averageRating == 0 ? <span>No reviews yet.</span> : ''}
                                            {averageRating && averageRating > 0 && (averageRating < 1 || averageRating == 1) ? <img className='star' src={star} /> : ''}
                                            {averageRating && averageRating > 1 && (averageRating < 2 || averageRating == 2) ? <img className='star' src={star2} /> : ''}
                                            {averageRating && averageRating > 2 && (averageRating < 3 || averageRating == 3) ? <img className='star' src={star3} /> : ''}
                                            {averageRating && averageRating > 3 && (averageRating < 4 || averageRating == 4) ? <img className='star' src={star4} /> : ''}
                                            {averageRating && averageRating > 4 && (averageRating < 5 || averageRating == 5) ? <img className='star' src={star5} /> : ''}
                                        </span>
                                        <Link to='#'> ({productReview ? productReview.length : ''} customer reviews) </Link>
                                    </div>

                                    <div className='quatitySel'>
                                        {productData && cart.map((prod) => {
                                            if (prod.id === productData[0].id) {
                                                return (
                                                    <input className='form-control' type="number" id="quantity" name="quantity" min="1" max="100" value={prod.qty} onChange={e => dispatch({
                                                        type: "CHANGE_QTY",
                                                        payload: {
                                                            id: prod.id,
                                                            qty: e.target.value
                                                        },
                                                    })} />

                                                )
                                            }

                                        })}
                                    </div>

                                    {productData && cart.map((prod) => {
                                        if (prod.id === productData[0].id) {
                                            return (
                                                <button onClick={() => {
                                                    dispatch({
                                                        type: "CHANGE_QTY",
                                                        payload: {
                                                            id: productData[0].id,
                                                            qty: Number(prod.qty) + 1
                                                        },
                                                    })

                                                }} className='btn blackBtn'> Add To Cart </button>
                                            )
                                        }

                                    })}

                                    {productData && cart.some(pro => pro.id === productData[0].id) ? null : (isLogin===1) ?<button onClick={() => {

                                        if (isLogin == 0) {
                                            addToCartAccessControl('Login OR, Create an Account',true);
                                        }
                                        else {
                                            if(isMember === 0){
                                                addToCartAccessControl('Please purchase membership to get this item',false);
                                            } else {
                                                if(is_membership === 0){
                                                    addToCartAccessControl('Please purchase membership to get this item',false);
                                                } else {
                                                    dispatch({
                                                        type: 'ADD_TO_CART',
                                                        payload: productData[0]
                                                    })
                                                }
                                            }
                                        }
                                    }} className='btn blackBtn test'>Add To Cart</button>:''}
                                    <div className="product-details">
                                        <p>{productData != false ? productData[0].description : null}.</p>
                                    </div>
                                    <Accordion className="ing-acc accordion">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Ingredients</Accordion.Header>
                                            <Accordion.Body>
                                                <p>{productData != false ? productData[0].ingredients : null}</p>
                                                <p>{productData != false ? productData[0].disclaimer : null}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    {/* Ingridient  */}
                                    <div className="product_meta">
                                        <span className="posted_in">Category: <a href={productData != false ? "/shop/" + productData[0].category_name.toLowerCase().replace(" ", "-") : null} rel='tag'>{productData != false ? productData[0].category_name : null}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Product Wrapper End  */}

                    {/* Review Begin  */}
                    <div className='reviewWrapper'>
                        <a className='reviewCount'>REVIEWS ({productReview ? productReview.length : ''})</a>

                        <h2 className='contTitle'>Reviews for {productData != false ? productData[0].name : null}</h2>

                        <div className='reviewRow'>
                            {/* Review Col  */}
                            {productReview.length == 0 ? <span className='mb-3'>No reviews yet.</span> : ''}
                            {productReview ? productReview.map(data => {

                                return (
                                    <div className='reviewCol'>
                                        <div className='review-fig'>
                                            <img src={avatar} className="img-fluid" />
                                        </div>
                                        <div className='review-cont'>
                                            <div className='review-info-1'>
                                                <strong className="review__author">{data.user_name} </strong>
                                                <span className="review__dash">–</span> <time className="published-date" dateTime="2021-09-22T13:22:18+00:00">{data.created_at.split("T")[0]} </time>
                                            </div>
                                            <p className='ratingStars'>
                                                {data.rating == 1 ? <img className='star' src={star} /> : ''}
                                                {data.rating == 2 ? <img className='star' src={star2} /> : ''}
                                                {data.rating == 3 ? <img className='star' src={star3} /> : ''}
                                                {data.rating == 4 ? <img className='star' src={star4} /> : ''}
                                                {data.rating == 5 ? <img className='star' src={star5} /> : ''}

                                            </p>
                                            <div className="description">
                                                <p>{data.review}</p>
                                            </div>
                                        </div>
                                    </div>

                                );
                            }) : <span>No reviews yet</span>}

                        </div>

                    </div>
                    {/* Review End  */}


                    {/* Review Form Begin  */}
                    <div className='review_form mb-4'>
                        <p><strong>Add a review</strong></p>
                        <>
                            <div className='row'>
                                <div className={localStorage.getItem('userId') ? 'd-none' : 'col-md-6 mb-4'}>
                                    <label>Name *</label>
                                    <input className='form-control' name='name' defaultValue={localStorage.getItem('reviewName') ? localStorage.getItem('reviewName') : ''} onChange={handelInput} />
                                    <br></br>
                                    <span className={nameValidation ? 'text-danger' : 'd-none'}><i>Please enter name</i></span>
                                </div>
                                <div className={localStorage.getItem('userId') ? 'd-none' : 'col-md-6 mb-4'}>
                                    <label>Email *</label>
                                    <input className='form-control' defaultValue={localStorage.getItem('reviewEmail') ? localStorage.getItem('reviewEmail') : ''} name='email' onChange={handelInput} />
                                    <br></br>
                                    <span className={emailValidation ? 'text-danger' : 'd-none'}><i>Please enter email</i></span>
                                </div>
                                <div className={localStorage.getItem('userId') ? 'd-none' : 'col-md-6 mb-4'}>
                                    <p className="comment-form-cookies-consent">
                                        <input id="wp-comment-cookies-consent" onChange={handelInput} name="saveNameEmail" type="checkbox" defaultChecked={localStorage.getItem('reviewName') ? true : false} />
                                        <label htmlFor="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                    </p>
                                </div>
                                <div className='col-md-12 mb-4'>
                                    <h6><strong>Your Rating</strong></h6>
                                    <div className="custom-rating">
                                        <label>
                                            <input type="radio" name="stars" onChange={handelInput} value="1" />
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" onChange={handelInput} value="2" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" onChange={handelInput} value="3" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" onChange={handelInput} value="4" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>
                                        <label>
                                            <input type="radio" name="stars" onChange={handelInput} value="5" />
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                            <span className="icon">★</span>
                                        </label>

                                    </div>
                                    <br></br>
                                    <span className={rtingValidation ? 'text-danger' : 'd-none'}><i>Please select a rating</i></span>
                                </div>
                                <div className='col-md-12 mb-4'>
                                    <textarea className='form-control' name='review' onChange={handelInput} placeholder='Message'></textarea>
                                    <span><i></i></span>
                                </div>
                                <div className='col-md-12 mb-4'>
                                    <button className='btn blackBtn' onClick={submitProductReview}>Submit</button>
                                </div>
                            </div>
                        </>
                    </div>
                    {/* Review Form Begin  */}

                    {/* Related Begin  */}
                    <div className='relatedProducts-wrapper'>
                        <h2 className="contTitle">Related products</h2>
                        {/* Product Container 1  */}
                        <div className='proCont'>
                            {/* Product Row Begin  */}
                            <div className='shopProduct-row row'>
                                {/* Col Begin  */}
                                {productData ? productData[1].map(item => {
                                    return (<div className='shopProduct-col col-md-3' key={item.id}>
                                        <div className='shopProduct text-center'>
                                            <Link to={'/product/' + item.id}>
                                                <figure className='shopProduct-fig'>
                                                    <img src={sURL + item.image} className='img-fluid' />
                                                </figure>
                                            </Link>
                                            <div className='shopProduct-cont'>
                                                <Link to={'/product/' + item.id} className='productName'>{item.name}</Link>
                                                <p className='pro-price d-none'>$15</p>
                                                <a href='#' className='btn cartBtn d-none'>Add To Cart</a>
                                            </div>
                                        </div>
                                    </div>);
                                }) : null}
                            </div>
                            {/* Product Row Begin  */}
                        </div>
                    </div>
                    {/* Related end  */}








                </div>
            </div>

            <Copyright/>
        </>
    )
}

export default SingleProduct