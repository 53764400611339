
import React, { useRef, useState, useFormikContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, useField, useFormik } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import Button from 'react-bootstrap/Button';
import { BASE_URL } from '../Config';
import axios from 'axios'
import Swal from 'sweetalert2'
import { Input } from 'reactstrap';

function Api() {
    let DocLink = BASE_URL + "/whc-custom/storage/app/public/doc/Sapphire Membership Addon Api.pdf";
    const formik = useFormik({});
    const fileInputRef = useRef(null);

    const fileDownloadHandler = (filepath) => {
        console.log(filepath);
        const link = document.createElement('a');
        link.href = filepath;
        link.target = '_blank';
        link.download = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const formSubmit = (data) => {

        const formData = new FormData();
        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('phone', data.phone !== null ? data.phone : null)
        formData.append('comments', data.message)
        formData.append('image', data.image !== null ? data.image : null)

        axios.post(`api/addComment`, formData).then(res => {
            document.getElementsByName("img")[0].value = '';
            if (res.data.status == true) {
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                //window.location.reload();
            } else {
                Swal.fire({
                    title: 'Error',
                    html: res.data.message,
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1000
                })
            }

        }).catch((error) => {
            Swal.fire({
                title: 'Error',
                html: error.data.message,
                icon: 'warning',
                showConfirmButton: false,
                timer: 1000
            })
        })
    }

    return (
        <>

            <div className='documentApi'>
                <div className='container'>
                    <div className='documantation-wrapper'>
                        <h1>General API Documentation</h1>
                        <div className='page-content'>
                            <Button variant="default" onClick={(e) => fileDownloadHandler(DocLink)} className='btn'>Download</Button>
                        </div>
                    </div>
                </div>

            </div>


            <div className='contactApi'>
                <div className='container'>

                    <div className='contactBox-wrapper'>
                        <div className='contactBox'>
                            <h2 className='contactBox_title'>API Questions</h2>
                            <div className='contactForm'>

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        name: '',
                                        phone: '',
                                        email: '',
                                        message: '',
                                        img: null
                                    }}
                                    validationSchema={Yup.object({
                                        name: Yup.string().required('Name is required'),
                                        email: Yup.string().required('Email is required'),
                                        phone: Yup.number().integer(),
                                        message: Yup.string().required('Message is required')
                                    })}

                                    onSubmit={(values, { setSubmitting, resetForm }) => {
                                        setSubmitting(true);
                                        console.log(values);
                                        formSubmit(values);
                                        resetForm({
                                            values: {
                                                name: '',
                                                phone: '',
                                                email: '',
                                                message: '',
                                                img: null,
                                            },
                                        });
                                    }}

                                >
                                    {(formProps) => (

                                        <Form>
                                            <div className='formGrp'>
                                                <label className='contact-Lable'>Name <sup>*</sup></label>
                                                {/* <input type="text" className='form-control' placeholder='' /> */}
                                                <Field type="text" className="form-control" placeholder="Name" name="name" />
                                                <CustomErrorMsg name="name" />
                                            </div>
                                            <div className='formGrp'>
                                                <label className='contact-Lable'>Email <sup>*</sup></label>
                                                <Field type="email" className="form-control" placeholder="Email" name="email" />
                                                <CustomErrorMsg name="email" />
                                            </div>
                                            <div className='formGrp'>
                                                <label className='contact-Lable'>Phone (Optional)</label>
                                                <Field type="tel" className="form-control" placeholder="Phone" name="phone" />
                                                <CustomErrorMsg name="phone" />
                                            </div>
                                            <div className='formGrp'>
                                                <label for="img" className='upload-image contact-Lable'>Select image: (Only jpg, jpeg, png format support)</label>
                                                {/* <Input type="file" ref={fileInputRef} name="img" placeholder="image" className="form-control" onChange={(event) => formProps.setFieldValue("img", event.target.files[0])} /> */}
                                                <Field type="file" className="form-control" placeholder="image" onChange={(event) => formProps.setFieldValue("image", event.target.files[0])} name="img" accept="image/*" />
                                            </div>
                                            <div className='formGrp'>
                                                <label className='contact-Lable'>Queries <sup>*</sup></label>
                                                <Field as="textarea"
                                                    name="message"
                                                    id="message"
                                                    rows={5}
                                                    className="form-control"
                                                    placeholder="Enter your questions here" />
                                                <CustomErrorMsg name="message" />
                                            </div>
                                            <div className='formGrp'>
                                                <input type="submit" className='btn contact-submit' value="SEND" />
                                            </div>

                                        </Form>
                                    )}
                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}



export default Api