import axios from 'axios';
import React, { useEffect } from 'react'

function Dashboard() {

    useEffect(() => {
        axios.defaults.headers.post['Authorization'] = 'Bearer ' + localStorage.getItem('authToken');
        axios.defaults.headers.get['Authorization'] = 'Bearer ' + localStorage.getItem('authToken');
        axios.defaults.headers.put['Authorization'] = 'Bearer ' + localStorage.getItem('authToken');
        // window.location.reload();


    })
    return (
        <div > <h3 className='row justify-content-center mt-4 mx-auto'>Welcome</h3></div>
    )
}

export default Dashboard