import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { Input } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import {BASE_URL} from '../Config';

function BonusCategory() {
    const [show, setShow] = useState(false);
    const [bonusCategoryList, setBonusCategoryList] = useState([]);
    const [searchData, setsearchData] = useState("");
    const [search, setSearch] = useState([]);
    const [existingFeatureImage, setExistingFeatureImage] = useState('');
    const [pending, setPending] = useState(true);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);
    const [details, setDetails] = useState({
        name: "",
        image : ""
    });
    const [id, setId] = useState("");
    
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";

    function getBonusCategoryList () {
        setPending(true);
        axios.get(`api/bonus_category_list`).then(res => {
            setBonusCategoryList(res.data.data);
            setSearch(res.data.data);
            setPending(false);
        }).catch(err => {
            //console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    function showSingleData(id) {
        //console.log(id);
        axios.get(`api/bonus_category_details/${id}`).then(res => {
            setExistingFeatureImage(res.data.data.image);  
            let featureImage= new File([''],res.data.data.image,{type:"image/jpg",size:0}, 'utf-8');
            setDetails({
                name: res.data.data.name,
                image : featureImage
            });
            setId(id);
            handleShow();
        })
    }

    function deleteCategory(id) {
        //console.log(id);
        Swal.fire({
            title: 'Are you sure you want to delete this Bonus Content?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showCloseButton: true
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/delete_bonus_category/${id}`).then(res => {
                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    getBonusCategoryList();
                })
            }
        })
    }

    const columns = [
        {
            name: "Name",
            selector: row => row.name
        },
        {
            name: "Image",
            cell: row => (
                <img src={imgURL+row.image} width="100" height="50" />
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} title="Edit Category" /></button>
                    <button className='btn btn-danger' onClick={() => { deleteCategory(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} title="Delect Category" /></button>
                </>
            )
        }
    ]

    /*const option = {
        options: [{name: 'Sapphire', id: 1},{name: 'Platinum', id: 2},{name: 'Gold', id: 3},{name: 'Silver', id: 4},{name: 'Basic', id: 5}]
    };*/

    /*const selectedValue = {
        selectedValues: []
    };*/

    function createBonusCategoryModal() {
        setId("");
        handleShow();
    }

    useEffect(() => {
        getBonusCategoryList();
    },[]);

    useEffect(() => {
        const result = bonusCategoryList?.filter(categoryName => {
            return categoryName.name.toLowerCase().match(searchData.toLowerCase());
        })
        setSearch(result);  
    },[searchData]);

    let bonusCategorySubmit= (data)=>{
        
        let image_size = data.image.size;
        let image = data.image;
        let name = data.name;
        
        const formdata = new FormData();
        formdata.append('name', name);
        formdata.append('image', image);
        formdata.append('image_size', image_size);
        if(id != ""){
            formdata.append('id', id);
        }
        axios.post(`api/add_bonus_category`,formdata).then(res => {
            //console.log(res);
            if(res.data.status === true){
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                getBonusCategoryList();
            }
        }).catch(err => {
            //console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        })
    }

    return (
        <div>
            <div>
                <div className='modal'>
                    <Modal show={show} onHide={handleClose}>
                        <Formik
                            //initialValues={{ name: '', image: '', doc:'', membership:[] }}
                            initialValues={details}
                            validationSchema={Yup.object({
                                name: Yup.string().required('Category Name Is Required'),
                                //membership: Yup.array().required('It Is Required').min(1,"At least one membership need to be selected"),
                                image: Yup.mixed()
                                .nullable()
                                .required('A file is required')
                                .test('File Size',
                                'Upload file size is too large', (value) => !value || (value && value.size <= 1024 * 1024))
                                .test('format',
                                'Invalid file type. Only *.jpg,*.png,*.jpeg files acceptable. ', (value) => !value || (value && ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type))),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting (true);
                                // alert(JSON.stringify(values, null, 2));
                                bonusCategorySubmit(values);
                            }}
                        >    
                            {(formProps) =>(
                                <Form className='bonusForm'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Bonus Category</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='container'>
                                            <div className='row justify-content-center'>
                                                <div className='col-md-12'>
                                                    <div className='card'>
                                                        <div className='card-body' style={{minHeight:'auto',overflowY:'scroll'}}>
                                                            <div className='form-group md-3'>
                                                                <label> Category Name </label>
                                                                <Field type="text" name="name" className="form-control"/>
                                                                <CustomErrorMsg name="name" />
                                                            </div>
                                                            <div className='form-group md-3'>
                                                                <label> Featured Image </label>
                                                                <Input type="file" name="image" className="form-control" onChange={(event)=>formProps.setFieldValue("image",event.target.files[0])}/>
                                                                {details.image && <div className='form-group md-3'><img src={imgURL+existingFeatureImage} width="200px" height="100px"/></div>}
                                                                <CustomErrorMsg name="image" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type='submit'>
                                            Save
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
              
                </div>
                                          
            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Bonus Category List</div>
                            <div className="buttun_div  col-md-9"> 
                                <Button variant="primary" onClick={createBonusCategoryModal}>
                                    Create Bonus Category
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card '>
                        <div className='card-body '>
                            <div className='datatable '>
                            <DataTable
                                noDataComponent="No Data Available" //or your component
                                columns={columns}
                                data={search}
                                progressPending={pending}
                                pagination
                                fixedHeader
                                subHeader
                                subHeaderComponent={
                                    <input type="text" className={search && search.length>0?'form-control serachInput':'form-control serachInput d-none'} placeholder="Search" value={searchData} onChange={(e) => { setsearchData(e.target.value) }} />
                                }
                                subHeaderAlign="right"
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BonusCategory