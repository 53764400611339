import React, { useEffect, useRef, useState } from "react";
import '../../assests/funnel/assets/css/acv-checkout.css';
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../../components/frontend/CustomErrorMsg";
import moment from "moment";
import Loader from '../Loader';
import { Link, useParams }  from 'react-router-dom';

// Images 
import blackFridayBanner from '../../assests/funnel/assets/images/BlackFriday-Banner.png';
import checkoutProduct from '../../assests/funnel/assets/images/product-checkout.png';
import plusIcon from '../../assests/funnel/assets/images/plus-icon.png';
import cvvImage from '../../assests/funnel/assets/images/cvv-image.png';
import moneyBack from '../../assests/funnel/assets/images/guarantee-ico-30days.png';
import visa from '../../assests/funnel/assets/images/visa.png';
import mastercard from '../../assests/funnel/assets/images/mastercard.png';
import discover from '../../assests/funnel/assets/images/discover.png';
import amex from '../../assests/funnel/assets/images/amex.png';
import orSecureicons from '../../assests/funnel/assets/images/or-secureicons.jpg';
import securedBy from '../../assests/funnel/assets/images/secured-by.png';
import symantec from '../../assests/funnel/assets/images/symantec.png';
import satisfactionSeal from '../../assests/funnel/assets/images/satisfaction-seal.png';
import customerServiceSeal from '../../assests/funnel/assets/images/customer-service-seal.png';
import cvv2Location from '../../assests/funnel/assets/images/cvv2-location.jpg';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Modal, Spinner } from 'react-bootstrap';
import useStore from '../../store';
import {BASE_URL,stateList,GetCardType} from '../Config';
import Copyright from "../Copyright";



function AcvCheckout(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [checkoutFunnelData, setCheckoutFunnelData] = useState({})
    const [SMToggle, setSMToggle] = useState(false)
    const [SMAddonToggle, setSMAddonToggle] = useState(true)
    const [selectedProduct, setSelectedProduct] = useState(1)

    const [price, setPrice] = useState(0)
    const [retail, setReatil] = useState(0)
    const [save, setSave] = useState(0)
    const [total, setTotal] = useState(0)
    const [shipping, setShipping] = useState(0)
    const [pID, setPID] = useState(0)
    const [FPDiv, setFPDiv] = useState(true)
    const [SPDiv, setSFPDiv] = useState(false)
    const [TPDiv, setTPDiv] = useState(false)
    const [loadershow, setloadershow] = useState(false)
    const [firstselected, setfirstSelectedset] = useState("Selected!")
    const [secondselected, setsecondSelectedset] = useState("Select Package")
    const [thirdselected, setthirdSelectedset] = useState("Select Package")
    const [shippingDiv, setShippingDiv] = useState(false);
    const [cardType, setCardType] = useState("");
    const [cc_number, setcc_number] = useState("");
    const params2 = useParams();
    
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    let params = location.search;

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);

    const [inputValue, setInputValue] = useState('');
    const [showDiv, setShowDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    const [second, setSecond] = React.useState(59);
    const [minute, setMInute] = React.useState(4);
    const afterLogin = useStore((state) => state.afterLogin);
    React.useEffect(() => {
        second > 0 && setTimeout(() => setSecond(second - 1), 1000);
        second == 0 && minute != 0 && setTimeout(() => setMInute(minute - 1), 1000);
        second == 0 && setTimeout(() => setSecond(59), 1000);
        minute == 0 && second == 0 && setTimeout(() => setMInute(4), 1000);
      }, [second]);

    //State List ;
    const options = stateList;
    const [states, setStates] = useState([]);
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };
    
    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
        
    };

    function setIntialData() {
        //console.log(props);
        setCheckoutFunnelData(props.data);
        setPrice(props.data[0].first_product_price);
        setReatil(props.data[0].first_product_retail);
        setSave(props.data[0].first_product_save);
        setShipping(0);
        setTotal(parseFloat((props.data[0].first_product_price)) + parseFloat(shipping));
        setPID((props.data[0].first_product_id))
        localStorage.setItem('ProductId',props.data[0].first_product_id);
    }

    useEffect(() => { setIntialData() }, []);

    useEffect(()=>{
        handleCountryChange2('239');
    },[params2.slug]);

    function setOrderSummery(productPrice, productId, productRetailPrice, productPriceSave, productShippingId, ProductDiv) {
        if (ProductDiv == 1) {
            setSelectedProduct(1);
            setFPDiv(true);
            setSFPDiv(false);
            setTPDiv(false);
            setfirstSelectedset("Selected!");
            setsecondSelectedset("Select Package");
            setthirdSelectedset("Select Package");
            setShippingDiv(false);
            localStorage.setItem('ProductId',productId);

            setPrice(productPrice);
            setReatil(productRetailPrice);
            setSave(productPriceSave);
            setShipping(0);
            setTotal(parseFloat(productPrice)+parseFloat(shipping));
            setPID(productId);
        }
        if (ProductDiv == 2) {
            setSelectedProduct(2);
            setFPDiv(false);
            setSFPDiv(true);
            setTPDiv(false);
            setfirstSelectedset("Select Package");
            setsecondSelectedset("Selected!");
            setthirdSelectedset("Select Package");
            setShippingDiv(false);
            localStorage.setItem('ProductId',productId);

            setPrice(productPrice);
            setReatil(productRetailPrice);
            setSave(productPriceSave);
            setShipping(0);
            setTotal(parseFloat(productPrice)+parseFloat(shipping));
            setPID(productId);
        }
        if (ProductDiv == 3) {
            setSelectedProduct(3);
            setFPDiv(false);
            setSFPDiv(false);
            setTPDiv(true);
            setfirstSelectedset("Select Package");
            setsecondSelectedset("Select Package");
            setthirdSelectedset("Selected!");

            if(!SMAddonToggle){
                setPrice(productPrice);
                setSave(productPriceSave);
                setShipping(9.95);
                setTotal(parseFloat(productPrice)+parseFloat(9.95));
                setReatil(parseFloat(productPrice)+parseFloat(9.95));
                setPID(productId);
                setShippingDiv(true);
                localStorage.setItem('ProductId',97);
                
            } else {
                setPrice(productPrice);
                setSave(productPriceSave);
                setShipping(0);
                setTotal(parseFloat(productPrice)+parseFloat(0.00));
                setReatil(parseFloat(productPrice)+parseFloat(0.00));
                setPID(productId);
                setShippingDiv(false);
                localStorage.setItem('ProductId',productId);
                
            }
        }
    }

    function getDateOneWeekFromNow() {
        // Create a new Date object for the current date
        const date = new Date();

        // Add 7 days to the date in milliseconds (1 week)
        //date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Get the month, day, and year from the date
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();

        // Convert the month number to the month's name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];

        // Return the date in the format you specified
        return `${monthName} ${day}, ${year}`;
    }

    const dateOneWeekFromNow = getDateOneWeekFromNow();

    function handleSPAddon(selectedProduct) {
        setSMAddonToggle(!SMAddonToggle);
        //console.log(SMAddonToggle);
        if (selectedProduct == 3) {
            if (!SMAddonToggle) {
                setShipping(0);
                setShippingDiv(false);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(0.00));
                localStorage.setItem('ProductId',checkoutFunnelData[0].third_product_id);
            } else {
                setShipping(9.95);
                setShippingDiv(true);
                setTotal(parseFloat(props.data[0].third_product_price)+parseFloat(9.90));
                setReatil(parseFloat(props.data[0].third_product_price)+parseFloat(9.90));
                localStorage.setItem('ProductId',97);
            }
        }
    }

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setcc_number(CCNumber);
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }


    const confirmSubmit = (data) => {
        //console.log(data);
        setloadershow(true);
        let expirationDate = data.cc_month+data.cc_year.slice(-2);

        localStorage.setItem('neobodPId',665);
        localStorage.setItem('neobodCid',668);
        localStorage.setItem('method','normal');
        const formdata = new FormData();
        formdata.append('product_id', localStorage.getItem('ProductId'));

        if (checkoutFunnelData[0].membership_add_on == 1) {
            if (SMAddonToggle) {
                formdata.append('is_membership', 41);
            }
            else {
                formdata.append('is_membership', 0);
            }
        }
        else {
            formdata.append('is_membership', 0);
        }

        formdata.append('first_name', localStorage.getItem('firstName'));
        formdata.append('last_name', localStorage.getItem('lastName'));
        formdata.append('address', localStorage.getItem('shippingAddress1'));
        formdata.append('zip', localStorage.getItem('shippingZip'));
        formdata.append('state', localStorage.getItem('shippingState'));
        formdata.append('city', localStorage.getItem('shippingCity'));
        formdata.append('phone', localStorage.getItem('phone'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('country',  localStorage.getItem('country'));

        formdata.append('creditCardNumber', data.ccnumber);
        formdata.append('expirationDate', expirationDate);
        formdata.append('cvv', data.cvv);
        formdata.append('creditCardType', data.card_type);
        formdata.append('affid', data.affid);
        formdata.append('sid', data.sid);
        formdata.append('s1', data.s1);
        formdata.append('s2', data.s2);
        formdata.append('s3', data.s3);
        formdata.append('click_id', data.click_id);
        formdata.append('notes', data.notes);

        formdata.append('billingSameAsShipping', data.billingSameAsShipping);
        formdata.append('shipping_address', data.shipping_address);
        formdata.append('shipping_city', data.shipping_city);
        formdata.append('shipping_state', data.shipping_state);
        formdata.append('shipping_zip', data.shipping_zip);
        formdata.append('shipping_country', data.shipping_country);

        formdata.append('prospectId', localStorage.getItem('prospectId'));
        formdata.append('method',localStorage.getItem('method'));

        localStorage.setItem('total', total);
        localStorage.setItem('affid', data.affid);
        localStorage.setItem('sid', data.sid);
        localStorage.setItem('s1', data.s1);
        localStorage.setItem('s2', data.s2);
        localStorage.setItem('s3', data.s3);
        localStorage.setItem('click_id', data.click_id);
        localStorage.setItem('notes', data.notes);

        //console.log(Object.fromEntries(formdata));
        axios.post(`api/place-funnel-order-with-prospect`, formdata).then(res => {
            // console.log(res);
            
            if (res.data.status === true) {
                sessionStorage.setItem('yp', res.data.data.user_info.yp);
                localStorage.setItem('orderId', res.data.data.order_id);
                localStorage.setItem('membership_orderId', res.data.membership ? res.data.membership.order_id : false);
                localStorage.setItem('authToken', res.data.data.member_token);
                localStorage.setItem('permission', res.data.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.user_info.email);

                axios.get(`api/check_user_membership/${localStorage.getItem('email')}`).then(result => {
                    //console.log(result.data);
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.data.user_info.account_permission, res.data.data.user_info.user_type,res.data.data.user_info.user_id,res.data.data.user_info,is_membership);
                    if (localStorage.getItem('up1_s')) {
                        window.location.href = '/' + localStorage.getItem('up1_s')+params;
                    }else {
                         window.location.href = '/' + localStorage.getItem('con_s')+params;
                    }
                });
            }
            else {
                setloadershow(false);
                //console.log(res);
                Swal.fire({
                    title: res.data.message,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33'
                })
            }
        })
    }


    // Security code 

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const formRef = useRef(null);
    const handleRefClick = () => {

        isMobile && formRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    
    return (
        <>
            {Object.values(checkoutFunnelData).length !== 0 ? <><div className={loadershow ? 'loading' : 'd-none'}></div><div className={loadershow ? 'd-none' : 'card funnel_card'}>
            <div className="acv-checkout">
                <div className="wrapper">
                    {/* Hedaer Begin  */}
                    <header className="order__header sticky_enabled">
                        <div className="order__header_top">
                            <div className="container"><span className="views-coll">12 others </span> are viewing this offer right now <span id="time" className="timer">{"0"+minute}:{second<10?"0"+second:second}</span></div>
                        </div>
                    </header>
                    {/* Hedaer End  */}
                    {/* BlackFriday Banner  */}
                    {/* <div className="balckFriday-banner">
                        <img src={blackFridayBanner} className="img-fluid w-100" alt="" />
                    </div> */}
                    {/* BlackFriday Banner End */}
                    {/* Order Begin  */}
                    <div className="order">
                        <div className="container">
                            <div className="clearFix">
                                <div className="order__left">
                                    <div className="steps">
                                        <ul className="steps__list clearFix">
                                            <li className="steps__item">1. Shipping Info</li>
                                            <li className="steps__item active">2. Finish Order</li>
                                            <li className="steps__item">3. Summary</li>
                                        </ul>
                                        <div className="approved-text">
                                            <strong><span className="emphasis">APPROVED!</span> Free Bottle Packages Confirmed</strong>
                                        </div>
                                        <p>Limited supply available as of <span className="full-date date-container2 text-danger">{dateOneWeekFromNow} </span>. We currently have product <strong>in stock</strong> and ready to ship within 24 hours.</p>
                                        <p className="status-pr pt-0">Sell Out Risk: <span>HIGH</span></p>
                                    </div>
                                    <div className="product-selection">
                                        <div className={FPDiv ? 'product product1 sel-prod active' : 'product product1 sel-prod '} onClick={() => { setOrderSummery(checkoutFunnelData[0].first_product_price, checkoutFunnelData[0].first_product_id, checkoutFunnelData[0].first_product_retail, checkoutFunnelData[0].first_product_save, checkoutFunnelData[0].first_product_shipping_id, 1)
                                          handleRefClick()


                                        }}>
                                            <div className="package-item">
                                                <div className="package-item__header">
                                                    <div className="title-block">
                                                        <span className="title-block__main">{checkoutFunnelData[0].first_product_label}</span><br />
                                                        <span className="title-block__retail">
                                                            Retail:<br />
                                                            <span className="retail-price">${checkoutFunnelData[0].first_product_each}</span>/bottle
                                                        </span>
                                                    </div>
                                                    <div className="shipping-row"><span className="ship-type">{checkoutFunnelData[0].first_product_shipping_id}</span></div>
                                                </div>
                                                <div className="package-item__content">
                                                    <div className="package-item__status"><span></span></div>
                                                    <div className="package-images">
                                                        <div className="package-images__item">
                                                            <img className="prod-btl1" src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].first_product_img} alt="" /><br />


                                                        </div>

                                                        {/* <div className="package__save">
                                                            <span className="package__save_title">SAVE</span><br />
                                                            <span className="package__save_item save-price">${checkoutFunnelData[0].first_product_save}</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="package-info">
                                                        <span className="check-package-info__title">
                                                            For Those Who Need<br />
                                                            to Lose 25+ Pounds!
                                                        </span>
                                                        <br />
                                                        <p><strong>Retail Price : <span className="text_red">$495</span></strong><br/>
                                                        <strong>Your Price : $195 - Save $300</strong>
                                                        </p>
                                                        <div className="package-info__price">
                                                            <p className="price d-inline-block">${checkoutFunnelData[0].first_product_each}</p>
                                                            <span className="perBottle">/bottle</span>
                                                            <span className="package-info__btn">{firstselected}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={SPDiv ? "product product2 sel-prod active" : "product product2 sel-prod"} onClick={() => { setOrderSummery(checkoutFunnelData[0].second_product_price, checkoutFunnelData[0].second_product_id, checkoutFunnelData[0].second_product_retail, checkoutFunnelData[0].second_product_save, checkoutFunnelData[0].second_product_shipping_id, 2);  handleRefClick()

}}>
                                            <div className="package-item">
                                                <div className="package-item__header">
                                                    <div className="title-block">
                                                        <span className="title-block__main">BUY 2 GET 1 FREE*</span><br />
                                                        <span className="title-block__retail">
                                                            Retail:<br />
                                                            <span className="retail-price">${checkoutFunnelData[0].second_product_retail}</span>/bottle
                                                        </span>
                                                    </div>
                                                    <div className="shipping-row"><span className="ship-type">FREE SHIPPING</span></div>
                                                </div>
                                                <div className="package-item__content">
                                                    <div className="package-item__status"><span></span></div>
                                                    <div className="package-images">
                                                        <div className="package-images__item">
                                                            <img className="prod-btl1" src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].second_product_img} alt="" /><br />

                                                        </div>

                                                        {/* <div className="package__save">
                                                            <span className="package__save_title">SAVE</span><br />
                                                            <span className="package__save_item save-price">${checkoutFunnelData[0].second_product_save}</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="package-info">
                                                        <span className="check-package-info__title">
                                                            For Those Who Need<br />
                                                            to Lose 15+ Pounds!
                                                        </span>
                                                        <br />
                                                        <p><strong>Retail Price : <span className="text_red">$327</span></strong><br/>
                                                        <strong>Your Price : $147 - Save $180</strong>
                                                        </p>
                                                        <div className="package-info__price">
                                                            <p className="price d-inline-block">${checkoutFunnelData[0].second_product_each}</p>
                                                            <span className="perBottle">/bottle</span>
                                                            <span className="package-info__btn">{secondselected}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={TPDiv ? 'product product3 sel-prod active' : 'product product3 sel-prod'} onClick={() => { setOrderSummery(checkoutFunnelData[0].third_product_price, checkoutFunnelData[0].third_product_id, checkoutFunnelData[0].third_product_retail, checkoutFunnelData[0].third_product_save, checkoutFunnelData[0].third_product_shipping_id, 3);  handleRefClick()

}}>
                                            <div className="package-item">
                                                <div className="package-item__header">
                                                    <div className="title-block">
                                                        <span className="title-block__main">{checkoutFunnelData[0].third_product_label}</span><br />
                                                        <span className="title-block__retail">
                                                            Retail:<br />
                                                            <span className="retail-price">${checkoutFunnelData[0].third_product_retail}</span>/bottle
                                                        </span>
                                                    </div>
                                                    <div className="shipping-row"><span className="ship-type" id="freeShipping">{shippingDiv === false ? 'FREE' : '$9.95'} SHIPPING</span></div>
                                                </div>
                                                <div className="package-item__content">
                                                    <div className="package-item__status"><span></span></div>
                                                    <div className="package-images">
                                                        <div className="package-images__item"><img className="prod-btl1" src={BASE_URL+"whc-custom/storage/app/public/" + checkoutFunnelData[1].third_product_img} alt="" /></div>
                                                        {/* <div className="package__save">
                                                            <span className="package__save_title">SAVE</span><br />
                                                            <span className="package__save_item save-price">${checkoutFunnelData[0].third_product_save}</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="package-info">
                                                        <span className="check-package-info__title">
                                                            For Those Who Need<br />
                                                            to Lose 7+ Pounds!
                                                        </span>
                                                        <br />
                                                        <span className="package-info__subTitle">&nbsp;</span><br />
                                                        <p><strong>Retail Price : <span className="text_red">$69.00</span></strong><br/>
                                                        <strong>Your Price : $69.00 - Save $0.00</strong>
                                                        </p>
                                                        <div className="package-info__price">
                                                            <p className="price d-inline-block">${checkoutFunnelData[0].third_product_each}</p>
                                                            <span className="perBottle">/bottle</span>
                                                            <span className="package-info__btn">{thirdselected}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p><span className="footnote">* at retail price</span></p>
                                            <div className="order-counter">
                                                <article className="clearfix">
                                                    <div className="left">Shipping Type:</div>
                                                    <div id="block2_shipping" className="right text-right"><b>{shipping == 0 ? <b>FREE</b> : null} </b> Priority Shipping</div>
                                                </article>
                                                <article className="clearfix">
                                                    <div className="left">Shipping Price:</div>
                                                    <div id="block2_price" className="right text-right"><b>${shipping.toFixed(2)}</b></div>
                                                </article>
                                                <article id="" className="clearfix ws_vip">
                                                    <div className="left">Sapphire Membership:</div>
                                                    <div id="block2_price" className="right text-right"><b>$0.00</b></div>
                                                </article>
                                                <article className="clearfix">
                                                    <div className="left">Your Total:</div>
                                                    <div id="block2_total" className="right text-right">${total.toFixed(2)}</div>
                                                </article>
                                                <article className="last clearfix">
                                                    <div id="block2_total2" className="left">Retail: ${parseFloat(retail).toFixed(2)}</div>
                                                    <div id="block2_save" className="right text-right"><b>You Save: ${parseFloat(save).toFixed(2)}</b></div>
                                                </article>
                                            </div>
                                        </div>
                                        <p>
                                            {/* <!-- Price Chart End --> */}
                                            <br />
                                            <img className="img-fluid secure-icons" src={orSecureicons} />
                                        </p>
                                        <div className="guarantee-block">
                                            <div className="guarantee-top">30 days money back guarantee</div>
                                            <div className="guarantee-content">
                                                <img className="guarantee-icon" src={moneyBack} alt="" />
                                                <div className="guarantee-text">
                                                    <p>
                                                        We are so confident in our products and services, that we back it with a 30 days money back guarantee. If for any reason you are not fully satisfied with our products, simply return the purchased
                                                        products in the original container within 30 days of when you received your order. We will refund you 100% of the purchase price – with absolutely no hassle.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line-block">
                                            <div className="line">
                                                <div className="text-center">HURRY! CONFIRM YOUR ORDER NOW!</div>
                                            </div>
                                            <div className="arrow">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Order Right --> */}
                                <Formik
                                    enableReinitialize = {true}
                                    initialValues={{
                                        card_type: '',
                                        cc_month: 0,
                                        cc_year: '', 
                                        ccnumber: '', 
                                        cvv: '',
                                        shipping_address: '',
                                        shipping_city: '',
                                        shipping_state: '',
                                        shipping_zip: '',
                                        shipping_country: '239',
                                        affid: affid,
                                        sid: sid,
                                        s1: s1,
                                        s2: s2,
                                        s3: s3,
                                        click_id: click_id,
                                        notes: notes ,
                                        shipCheckbox:true
                                    }}
                                    validationSchema={Yup.object({
                                        cc_year: Yup.string().required('Select valid year'),
                                        cc_month: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                            is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                            then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                        }),
                                        card_type: Yup.string(),
                                        ccnumber: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                        .required('Card number is required')
                                        .max(16, 'Credit card should not be grater than 16 digits')
                                        .min(15, 'Credit card should not be less than 15 digits'),
                                        cvv: Yup.string().matches(/^[0-9]{3,4}$/,
                                             'Security code must be a 3 or 4 digit number')
                                             .required('Security code is required'),
                                        shipCheckbox:Yup.string(),
                                        shipping_address: Yup.string().when("shipCheckbox", {
                                            is: (val)=> (val==='false') ? true:false,
                                            then: Yup.string().required("Shipping address is required")
                                        }),
                                        shipping_city: Yup.string().when("shipCheckbox", {
                                            is: (val)=> (val==='false') ? true:false,
                                            then: Yup.string().required("Shipping city is required")
                                        }),
                                        shipping_state: Yup.string().when("shipCheckbox", {
                                            is: (val)=> (val==='false') ? true:false,
                                            then: Yup.string().required("Shipping state is required")
                                        }),
                                        shipping_zip: Yup.string().when("shipCheckbox", {
                                            is: (val)=> (val==='false') ? true:false,
                                            then: Yup.string().required("Shipping zip is required")
                                        }),
                                        shipping_country: Yup.string().when("shipCheckbox", {
                                            is: (val)=> (val==='false') ? true:false,
                                            then: Yup.string().required("Shipping country is required")
                                        })
                                    })}

                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting (true);
                                        values.card_type = GetCardType('"' + values.ccnumber + '"');
                                        //alert(JSON.stringify(values, null, 2));
                                        if(values.shipping_address !==''){
                                            values.billingSameAsShipping = "NO";
                                        }else {
                                            values.billingSameAsShipping = "YES";
                                        }
                                        if(values.shipping_country === '239'){
                                            values.shipping_country = 'US';
                                        } else {
                                            values.shipping_country = 'CA';
                                        }
                                        
                                        confirmSubmit(values);
                                    }}
                                >
                                    {(formProps) => (
                                <Form>
                                    <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                    <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                    <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                    <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                    <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                    <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                    <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                    <Field type="hidden" className="form-control" name="notes" value={notes}/>
                                    <div className="order__right">
                                        <div className="form__header">
                                            <h2>FINAL STEP:</h2>
                                            <h3>PAYMENT INFORMATION</h3>
                                        </div>
                                        <div className="form__in">
                                            <span className="accept-text">We Accept:</span>
                                            <ul className="form__cards">
                                                <li><img src={visa} alt="" /></li>
                                                <li><img src={mastercard} alt="" /></li>
                                                <li><img src={discover} alt="" /></li>
                                                <li><img src={amex} alt="" /></li>
                                            </ul>
                                            <div id="order_form" className="paymentform form"  ref={formRef}>
                                                <p><span className="billing-title">Payment Info</span></p>
                                                <div className="margin-bottom-5">
                                                    <div className="phone-12 columns"><label>Card Number:</label></div>
                                                </div>
                                                <div className="phone-12 columns form-holder">
                                                    <Field type="text" maxLength="16" className="form-control" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                    <CustomErrorMsg name="ccnumber" />
                                                </div>
                                                {/* <div className="clearFix margin-bottom-5">
                                                    <div className="phone-12 columns"><label>Exp. month:</label></div>
                                                </div> */}
                                                <div className="clearFix expiryholder">
                                                    <div className="phone-12 columns form-holder mb-0">
                                                        
                                                        <div className="cc-month">
                                                            <div className="phone-12 columns"><label>Exp. month:</label></div>
                                                            <Field as="select" className="form-control mm-selectList" name="cc_month">
                                                                <option value="0">Select</option>
                                                                <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </Field>
                                                            <CustomErrorMsg name="cc_month" />
                                                        </div>
                                                        <div className="cc-year">
                                                            <div className="phone-12 columns"><label>Exp. year:</label></div>
                                                            <Field as="select" className="form-control" name="cc_year" >
                                                                <option value="">Select</option>
                                                                {years.map(year => (
                                                                    <option key={year} value={year}>
                                                                        {year}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            <CustomErrorMsg name="cc_year" />
                                                        </div>
                                                    </div>
                                                    {/* {errors.year && <p className="text-danger">{errors.year}</p>} */}
                                                </div>
                                                <div className="clearFix margin-bottom-5">
                                                    <div className="phone-12 columns"><label>CVV:</label></div>
                                                </div>
                                                <div className="clearFix">
                                                    <div className="phone-6 col-md-12 columns form-holder">
                                                        {/* <input autoComplete="off" type="password" maxLength="4" onChange={handelInput} id="cc_cvv" name="cvv" className="mm-textField" placeholder="Security Code" />
                                                        {errors.cvv && <p className="text-danger">{errors.cvv}</p>} */}
                                                        <Field type="text" maxLength="4" className="mm-textField" placeholder="CVV" name="cvv"/>
                                                        <CustomErrorMsg name="cvv" />
                                                    </div>
                                                    <div className="phone-12 columns">
                                                        <span className="cvv-link"><a className="ccvwhatsthis form-link cvvbox" onClick={handleShow}> Where can I find my Security Code?</a><br /></span>
                                                    </div>
                                                </div>
                                                <div className="cvv-image clear"><img src={cvvImage} alt="" /></div>
                                                <div>
                                                    <label className="acvCheck checkboxLabel mb-3">
                                                        <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />Shipping is the same as billing
                                                    </label>
                                                    {showDiv &&
                                                    <div className="form-div-2">
                                                        <div className="form-holder">
                                                            <label className="formLabel">Address</label>
                                                            <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                            <CustomErrorMsg name="shipping_address" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">City:</label>
                                                            <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                            <CustomErrorMsg name="shipping_city" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">Country:</label>
                                                            <Field 
                                                                as="select" 
                                                                className="form-control" 
                                                                name="shipping_country"
                                                                onChange={(event) => {
                                                                    formProps.handleChange(event);
                                                                    handleCountryChange(event);
                                                                }}
                                                            >
                                                                <option value="239" >United States</option>
                                                                <option value="41" >Canada</option>
                                                            </Field>
                                                            <CustomErrorMsg name="shipping_country" />
                                                        </div>  
                                                        <div className="form-holder">
                                                            <label className="formLabel">State:</label>
                                                            <Field 
                                                                as="select" 
                                                                className="form-control" 
                                                                name="shipping_state"
                                                            >
                                                            <option value="" >Select an option</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.abbreviation}>
                                                                    {state.state}
                                                                </option>
                                                            ))}
                                                            </Field>
                                                            <CustomErrorMsg name="shipping_state" />
                                                        </div>
                                                        <div className="form-holder">
                                                            <label className="formLabel">Zip Code:</label>
                                                            <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                            <CustomErrorMsg name="shipping_zip" />
                                                        </div>
                                                        
                                                    </div>
                                                    }
                                                </div>
                                                <div className="form__footer mb-4">
                                                    <div className="secure-icon"><span>Secure 256-bit SSL Encryption</span></div>
                                                    <div className="btn-wrapper">
                                                        <button type="submit" className="mm-button large green">RUSH MY ORDER</button>
                                                    </div>
                                                </div>

                                                {/* <!-- yellow Box  --> */}
                                                <div className="check_area acv_check-area yellowbox">

                                                    <input id="checkD" className="vip_shipping_desk" onChange={() => { handleSPAddon(selectedProduct) }} defaultChecked={SMAddonToggle} type="checkbox" />
                                                    <label htmlFor="checkD">YES, I want FREE Shipping With Sapphire Membership!</label>
                                                </div>
                                                {/* <!-- Top Term  --> */}
                                                <div className={checkoutFunnelData[0].membership_add_on == 1 ? SMToggle ? 'top-terms open' : 'top-terms' : 'd-none'}>
                                                    <h1 className="top-termsHdng" onClick={() => { setSMToggle(!SMToggle) }}>Sapphire Membership Terms</h1>
                                                    <div className="top-trends-desc">
                                                        <p>
                                                            BIG SAVINGS are ahead! Sapphire Membership comes loaded with benefits. At Wholesalehealthclub we pride ourselves on offering customers the best deals on the products they love. Your Sapphire
                                                            Membership takes that one step further.
                                                        </p>
                                                        <p>
                                                            As a Sapphire Membership member we’ll send you a unique discount code each month to your inbox. Be sure to use this code at checkout to take advantage of the following benefits with your Sapphire
                                                            Membership Today!:
                                                        </p>
                                                        <p>-FREE shipping on all orders!</p>
                                                        <p>-Up to 80% off all of the great products you’ll find on wholesalehealthclub.com</p>
                                                        <p>-No hassle, no questions asked replacement on any items.</p>
                                                        <p>-Priority Processing – Have your orders placed in front of the line!</p>
                                                        <p>-Get it all FREE for the first 14 days!</p>
                                                        <p>Some Notes About Your Membership:</p>
                                                        <p>
                                                        Remember – while your 2 weeks of Wholesalehealthclub Sapphire Membership is free of charge, you will be billed $17.00/ month to retain membership after your first 2 weeks. If you wish to cancel
                                                            your Sapphire Membership at any time, simply contact
                                                            our support using the below information: Support Email: <a href="mailto:support@wholesalehealthclub.com"> support@wholesalehealthclub.com </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                    )}
                            </Formik>
                            </div>
                        </div>
                    </div>
                    {/* Order End  */}

                    {/* Footer  */}
                    <div className="checkout-footer">
                        <div className="container">
                            <p className="footer_pj">Our Promises and Assurances to You</p>
                            <ul className="footer-part clearFix">
                                <li>
                                    <div className="footer-part__img"><img src={securedBy} alt="" /></div>
                                    <p>Highest Security Levels for Online Transactions 256 bit Encryption.</p>
                                </li>
                                <li>
                                    <div className="footer-part__img"><img src={symantec} alt="" /></div>
                                    <p>Your Privacy Protected Rest Assured.</p>
                                </li>
                                <li>
                                    <div className="footer-part__img"><img src={satisfactionSeal} alt="" /></div>
                                    <p>100% Consumer Satisfaction Guaranteed.</p>
                                </li>
                                <li>
                                    <div className="footer-part__img"><img src={customerServiceSeal} alt="" /></div>
                                    <p>Award Winning Customer Service.</p>
                                </li>
                            </ul>
                            <div className="text-center mb-3">
                                We are committed to maintaining the highest quality products and the utmost integrity in business practices. All products sold on this website are certified by Good Manufacturing Practices (GMP), which is the highest
                                standard of testing in the supplement industry.
                            </div>
                            <div className="text-center mb-3">
                                Notice: The products and information found on this site are not intended to replace professional medical advice or treatment. These statements have not been evaluated by the Food and Drug Administration. These products
                                are not intended to diagnose, treat, cure or prevent any disease. Individual results may vary.
                            </div>
                            {/* <div className="text-center">©<span className="copyright-text">{currentYear}</span> Wholesale Health Club. All Rights Reserved.</div>
                            <ul className="terms-links">
                                <li>
                                    <Link className="privacybtn" to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Terms &amp; Conditions |&nbsp;</Link>
                                </li>
                                <li>
                                    <Link className="termsbtn" to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Privacy Policy |&nbsp;</Link>
                                </li>
                                <li>
                                    <Link to='/contact-us'>Contact Us</Link>
                                </li>
                            </ul> */}
                            
                        </div>
                    </div>
                    <Copyright/>
                    {/* Footer End  */}

                </div>
            </div>
            <Modal show={false} className="text-center">
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Spinner animation="grow" />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal></div></> : null}


             
            {/* Security Code Modal                       */}
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="securityCod-ref text-center">
                        <img src={cvv2Location} className="img-fluid" />            
                    </div>
                </Modal.Body>
                
            </Modal>
        </>
    )
}

export default AcvCheckout;