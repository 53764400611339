import { Navigate, Outlet } from 'react-router-dom'
import { useNavigate } from "react-router-dom"; // v6
import useStore from '../../../store';
import axios from 'axios';
const PrivateRoutes = () => {
    const navigate = useNavigate();
    const isLogin = useStore((state) => state.isLogin);
    // const isMember = useStore((state) => state.userDetails.isMember);
    const userType = useStore((state) => state.userType);
    // let email = useStore((state) => state.userDetails.email);
    var slugArr = window.location.href.split('/');
    //console.log(slugArr);
    var slug = slugArr[3]
    let auth = false;
    var path = '/admin';
    
    if(slug ==='my-account-info' || slug==="bonus" || slug ==='referral' || slug === 'shop'){
        path = '/login'
        if(userType === 3){
            auth = true
        }
        
    }
    
    if (isLogin == 1 ) {
        if(userType == 1 || userType == 2){
            auth = true
        }
    }
    
    
    return (
        auth ? <Outlet /> :<Navigate to={path} />
    )
}

export default PrivateRoutes;