import React, { useState, useEffect } from 'react'
import cartProduct1 from '../../assests/frontend/assets/images/trubody-daily-multivitamin-1-1-300x574.png';
import secure from '../../assests/frontend/assets/images/secure.jpg';
import { CartState } from '../../context/Context';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import {extendWith, omit} from 'lodash';
import Loader from '../Loader';
import {BASE_URL,stateList,GetCardType} from '../Config';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import moment from "moment";
import useStore from '../../store';
import PaypalImage from '../../assests/frontend/assets/images/paypal-01.png';
import { encode } from 'base-64';
import paypal from '../../assests/frontend/assets/images/paypal.png';
import paypalBtn from '../../assests/frontend/assets/images/paypal-btn.png';
import AccessDeniedPage from '../AccessDeniedPage';

export default function ChekoutTwo2() {
    const location = useLocation();
    const { state: { cart }, dispatch } = CartState();
    const [total, setTotal] = useState();
    const [discount, setDiscount] = useState();
    const [totalQuantity, setTotalQuantity] = useState();
    const [errors, setErrors] = useState({});
    const [isChecked, setIsChecked] = useState(true);
    const navigate = useNavigate();
    const [showDiv, setShowDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    const [selectedPayment, setSelectedPayment] = useState('paypal');
    const isLogin = useStore((state) => state.isLogin);
    const isMember = useStore((state) => state.isMember);
    const [is_membership, setIsMembership] = useState('');
    let userEmail = useStore((state) => state.userDetails.email);
    const handlePaymentSelection = (payment) => {
        setSelectedPayment(payment);
    };

    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';
    const params2 = useParams();
   
    let userDetails = useStore((state) => state.userDetails);
    let firstName = '';
    let lasttName = '';
    if(userDetails==''){
        //console.log('not logged in');
    } else {
        let fullname = userDetails.name.split(' ');
        firstName = fullname[0];
        lasttName = fullname[1];
    }
    /*if(userDetails.length > 0){
        console.log(userDetails);
        let fullname = userDetails.name.split(' ');
        firstName = fullname[0];
        lasttName = fullname[1];
    }*/
    const [cardType, setCardType] = useState("");
    const [cc_number, setcc_number] = useState("");
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState('');

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);

    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');

    const [isPSubmit, setIsPSubmit] = useState(false);
    const [loaded, setLoaded] = useState(false);

    //State List ;
    //const options=stateList;

    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(()=>{
        handleCountryChange2('239');
        axios.get(`api/check_user_membership/${userEmail}`).then(result => {
            //console.log(result.data);
            if(parseInt(result.data) === 1){
                setIsMembership(1);
            } else {
                setIsMembership(0);
            }
        });
    },[params2.slug]);

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
        
    };

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            //console.log(GetCardType(CCNumber));
            setcc_number(CCNumber);
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    const updateCart = () => {
        let totalQuantity = 0;
        let discountQuantity = 0;
        cart.map(function (item){
            totalQuantity += parseInt(item.qty);
        });
        setTotalQuantity(totalQuantity);
        let sortedCart = cart.sort((a, b)=>{return a.price - b.price});
        let DiscountQty = Math.floor(totalQuantity/5);
        let totalDiscount = calculateDiscount(DiscountQty,sortedCart);
        setDiscount(totalDiscount);
    }

    let calculateDiscount = (DiscountQty,sortedCart)=>{

		let totalDiscount = 0;
		for(let cartItem of sortedCart){
			

			if(DiscountQty>0)
			{
				
				if (cartItem.qty>= DiscountQty)
				{
					
					totalDiscount += (parseInt(cartItem.price)*DiscountQty);
					DiscountQty=DiscountQty - parseInt(cartItem.qty);

				}
				if(cartItem.qty < DiscountQty)
				{
					
					totalDiscount+=(parseInt(cartItem.price) * parseInt(cartItem.qty));
					DiscountQty=DiscountQty - parseInt(cartItem.qty);
				}
			

			} else {
                break;
            }
			
		};

		return totalDiscount; 
	}

    const Discounts = () => (
        <tr className="cart-subtotal">
            <th>Discount</th>
            <td className='Subtotal text-end' data-title="Subtotal"><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{parseFloat(discount).toFixed(2)}</bdi></span></td>
        </tr>
    )

    useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.removeItem('c_s');
        setTotal(cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0));
        updateCart();
    }, [cart]);

    const confirmSubmit = (data) => {
        if(totalQuantity > 0){
            let expirationDate = data.cc_month+data.cc_year.slice(-2);
            const formdata = new FormData();
            formdata.append('first_name', data.firstName);
            formdata.append('last_name', data.lastName);
            formdata.append('company_name', data.company_name);
            formdata.append('country', data.shippingCountry);
            formdata.append('address', data.shippingAddress1);
            formdata.append('billing_address_2', data.shippingAddress2);
            formdata.append('city', data.shippingCity);
            formdata.append('state', data.shippingState);
            formdata.append('zip', data.shippingZip);
            formdata.append('phone', data.phone);
            formdata.append('email', data.email);
            formdata.append('name_on_card', data.name_on_card);
            formdata.append('creditCardNumber', data.ccnumber);
            formdata.append('creditCardType', data.card_type);
            formdata.append('expirationDate', expirationDate);
            formdata.append('cvv', data.cvv);
            formdata.append('is_membership', 41);
            formdata.append('cartData', JSON.stringify(cart));
            formdata.append('discount', discount);
            formdata.append('total_quantity', totalQuantity);

            formdata.append('billingSameAsShipping', data.billingSameAsShipping);
            formdata.append('shipping_address', data.shipping_address);
            formdata.append('shipping_city', data.shipping_city);
            formdata.append('shipping_state', data.shipping_state);
            formdata.append('shipping_zip', data.shipping_zip);
            formdata.append('shipping_country', data.shipping_country);

            //console.log(Object.fromEntries(formdata));
            setLoaded(true);
            axios.post(`api/place_shop_order`, formdata).then(res => {
                //console.log(res.data.response_code);
                if (res.data.response_code === '100') {
                    localStorage.setItem('order_id', res.data.order_id);
                    dispatch({
                        type: "EMPTY_CART",
                    });
                    setLoaded(false);
                    navigate("/confirmation");
                }
                else {
                    setLoaded(false);
                    Swal.fire({
                        title: 'Warning',
                        html: res.data.message,
                        icon: 'warning'
                    });

                }
            }).catch(err => {
                setLoaded(false);
                //console.log(err.response);
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })
            });
        } else {
            Swal.fire({
                title: 'Warning',
                html: 'Please select one product',
                icon: 'warning'
            })
        }
        
    }

    //PayPalSubmit Handler
    function PayPalHandler(values){
        if(totalQuantity > 0){
            let cartArray = cart.map(item => ({
                billing_model_id: item.billing_model_id,
                product_id: item.product_id,
                offer_id: item.offer_id,
                qty: item.qty,
                price: item.price,
                crm_product_id: item.crm_product_id
            }));
            let firstName = values.firstName;
            let lastName = values.lastName;
            let companyName = values.company_name;
            let address = values.shippingAddress1;
            let zip = values.shippingZip;
            let state = values.shippingState;
            let city = values.shippingCity;
            let phone = values.phone;
            let email = values.email;
            let country = values.shippingCountry;
            let refcode = values.refCode;
            let alt_pay_url = localURL+'confirmation';
            let prospectId = '';
            let order_type = 'shop_order';
            let productwise_Discount = discount;
            let total_quantity = totalQuantity;
            
    
            localStorage.setItem('firstName', values.first_name);
            localStorage.setItem('lastName', values.last_name);
            localStorage.setItem('shippingAddress1', values.address);
            localStorage.setItem('shippingZip', values.zip);
            localStorage.setItem('shippingState', values.state);
            localStorage.setItem('shippingCity', values.city);
            localStorage.setItem('phone', values.phone);
            localStorage.setItem('email', values.email);
            localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
            localStorage.setItem('method','paypal');
            localStorage.setItem('c_s','checkout-2');
    
            window.location.href = BASE_URL.replace("/live", "")+
                'whc-custom/public/api/paypal?order_type='+
                order_type+'&refcode='+
                refcode+'&firstName='+
                firstName+'&lastName='+
                lastName+'&address='+
                address+'&zip='+
                zip+'&state='+
                state+'&city='+
                city+'&phone='+
                phone+'&email='+
                email+'&country='+
                country+'&alt_pay_url='+
                alt_pay_url+'&prospectId='+
                prospectId+'&cartData='+
                JSON.stringify(cartArray)+'&discount='+
                productwise_Discount+'&total_quantity='+
                total_quantity;
        } else {
            Swal.fire({
                title: 'Warning',
                html: 'Please select one product',
                icon: 'warning'
            })
        }
        

    }

    return (
        <>
            
            {loaded ? <Loader /> : null}
            {isLogin === 1 && isMember === 1 ? 
                is_membership === 1 ? 
                    <section className='cartPage'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 mx-auto'>
                                    <div className='row'>
                                        <div className='col-md-6 mb-4 mb-md-0'>
                                            <div className="cart_totals checkoutTwo_table">
                                                <h3>Order summary</h3>
                                                <div className="table-responsive">
                                                    <table cellSpacing={0} className="shop_table">
                                                        <tbody>
                                                            {cart.length > 0 ? (
                                                                <>
                                                                    {cart.map(function (item) {
                                                                        return (
                                                                            <tr className="cart_item">
                                                                                <td className="product-name">
                                                                                    <div className='cart_pro_img'>
                                                                                        <img src={BASE_URL+'whc-custom/storage/app/public/' + item.image} className="img-fluid" />
                                                                                    </div>
                                                                                    <div className='cart_item_details'>
                                                                                        <div className='quantity'>
                                                                                            <input type="number" min={1} className='form-control' value={item.qty} onChange={e => {
                                                                                                dispatch({
                                                                                                    type: "CHANGE_QTY",
                                                                                                    payload: {
                                                                                                        id: item.id,
                                                                                                        qty: e.target.value
                                                                                                    },
                                                                                                })


                                                                                            }} />
                                                                                        </div>
                                                                                        <div className='item_name'>
                                                                                            <a href="#" className='remove' onClick={() => {
                                                                                                dispatch({
                                                                                                    type: "REMOVE_FROM_CART",
                                                                                                    payload: item
                                                                                                })
                                                                                            }}>X</a>
                                                                                            <span className='name'>{item.name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className='product-total'>
                                                                                    <a className='remove_item' onClick={() => {
                                                                                        dispatch({
                                                                                            type: "REMOVE_FROM_CART",
                                                                                            payload: item
                                                                                        })
                                                                                    }}>REMOVE</a>
                                                                                    <span className="amount"><bdi><span className="woocommerce-Price-currencySymbol"></span>{'$' + parseFloat(item.price * item.qty).toFixed(2)}</bdi></span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </>
                                                            ) : <tr className="cart_item">
                                                                <td className="product-name">
                                                                    Cart is empty!
                                                                </td>

                                                            </tr>}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr className="cart-subtotal">
                                                                <th>Subtotal</th>
                                                                <td className='Subtotal text-end' data-title="Subtotal"><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{parseFloat(total).toFixed(2)}</bdi></span></td>
                                                            </tr>
                                                            { discount ? <Discounts /> : null }
                                                            <tr className="order-total">
                                                                <th>Total</th>
                                                                <td className='text-end' data-title="Total"><strong><span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">$</span>{ discount ? (parseFloat(total).toFixed(2) - discount) : parseFloat(total).toFixed(2)}</bdi></span></strong> </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            {/*  field begin     */}
                                            <Formik 
                                                enableReinitialize = {true}
                                                initialValues={{
                                                    firstName: firstName,
                                                    lastName: lasttName,
                                                    shippingAddress1: userDetails.address,
                                                    shippingZip: userDetails.zip,
                                                    shippingCity: userDetails.city,
                                                    shippingCountry: userDetails.country === 'US' ? '239' : '41',
                                                    shippingState: userDetails.state,
                                                    email: userDetails.email,
                                                    phone: userDetails.phone,
                                                    card_type: '',
                                                    name_on_card: '',
                                                    cc_month: 0,
                                                    cc_year: '', 
                                                    ccnumber: '', 
                                                    cvv: '',
                                                    shipping_address: '',
                                                    shipping_city: '',
                                                    shipping_state: '',
                                                    shipping_zip: '',
                                                    shipping_country: '239',
                                                    affid: affid,
                                                    sid: sid,
                                                    s1: s1,
                                                    s2: s2,
                                                    s3: s3,
                                                    click_id: click_id,
                                                    notes: notes ,
                                                    shipCheckbox:true,
                                                    acceptTerms: false
                                                }}
                                                validationSchema={Yup.object({
                                                    firstName: Yup.string().required('First name is required'),
                                                    lastName: Yup.string().required('Last name is required'),
                                                    shippingAddress1: Yup.string().required('Email is required'),
                                                    shippingZip: Yup.string().required('Zip is required'),
                                                        //.matches(/^[0-9]+$/, "Please enter only number!")
                                                        //.min(5,'Zip code should not exceed 5 digits'),
                                                    shippingCity: Yup.string().required('City is required'),
                                                    shippingState: Yup.string().required('State is required'),
                                                    shippingCountry: Yup.string().required('Country is required'),
                                                    email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                                    phone: Yup.string().required('Phone no is required')
                                                        .matches(/^[0-9]+$/, "Please enter only number!")
                                                        .min(10, 'Please enter a valid contact number!')
                                                        .max(10, 'Please enter a valid contact number!'),
                                                    /*name_on_card:Yup.string().required('Name on card is required!').matches(/^[a-zA-Z ]+$/, 'Name should only contain alphabetical characters.'),
                                                    cc_year: Yup.string().required('Select valid year'),
                                                    cc_month: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                        is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                        then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                                    }),*/
                                                    name_on_card:Yup.string().when("submitButton", {
                                                        is: "paypal",
                                                        then: Yup.string().notRequired(),
                                                        otherwise: Yup.string().required('Name on card is required!').matches(/^[a-zA-Z ]+$/, 'Name should only contain alphabetical characters.'),
                                                    }),
                                                    cc_year: Yup.string().when("submitButton", {
                                                        is: "paypal",
                                                        then: Yup.string().notRequired(),
                                                        otherwise: Yup.string().required("Select valid year"),
                                                    }),
                                                    cc_month: Yup.number().when("submitButton", {
                                                        is: "paypal",
                                                        then: Yup.number().notRequired(),
                                                        otherwise: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                                            is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                                            then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                                        }),
                                                    }),
                                                    card_type: Yup.string(),
                                                    ccnumber: Yup.string().when("submitButton", {
                                                        is: "paypal",
                                                        then: Yup.string().notRequired(),
                                                        otherwise: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                                        .required('Card number is required')
                                                        .max(16, 'Credit card should not be grater than 16 digits')
                                                        .min(15, 'Credit card should not be less than 15 digits'),
                                                    }),
                                                    cvv: Yup.string().when("submitButton", {
                                                        is: "paypal",
                                                        then: Yup.string().notRequired(),
                                                        otherwise: Yup.string().matches(/^[0-9]{3,4}$/,
                                                            'Security code must be a 3 or 4 digit number')
                                                            .required('Security code is required'),
                                                    }),
                                                    /*ccnumber: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                                    .required('Card number is required')
                                                    .max(16, 'Credit card should not be grater than 16 digits')
                                                    .min(15, 'Credit card should not be less than 15 digits'),
                                                    cvv: Yup.string().matches(/^[0-9]{3,4}$/,
                                                        'Security code must be a 3 or 4 digit number')
                                                        .required('Security code is required'),*/
                                                    shipCheckbox:Yup.string(),
                                                    shipping_address: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping address is required")
                                                    }),
                                                    shipping_city: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping city is required")
                                                    }),
                                                    shipping_state: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping state is required")
                                                    }),
                                                    shipping_zip: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping zip is required")
                                                    }),
                                                    shipping_country: Yup.string().when("shipCheckbox", {
                                                        is: (val)=> (val==='false') ? true:false,
                                                        then: Yup.string().required("Shipping country is required")
                                                    }),
                                                    acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions")
                                                })}
                                                onSubmit={(values) => {
                                                    //console.log(values)

                                                    values.card_type = GetCardType('"' + values.ccnumber + '"');
                                                    if(values.shipping_address !==''){
                                                        values.billingSameAsShipping = "NO";
                                                    }else {
                                                        values.billingSameAsShipping = "YES";
                                                    }
                                                    if(values.shippingCountry === '239'){
                                                        values.shippingCountry = 'US';
                                                    } else {
                                                        values.shippingCountry = 'CA';
                                                    }
                                                    if(values.shipping_country === '239'){
                                                        values.shipping_country = 'US';
                                                    } else {
                                                        values.shipping_country = 'CA';
                                                    }
                                                    if(values.submitButton === 'paypal'){
                                                        PayPalHandler(values);
                                                    } else {
                                                        confirmSubmit(values);
                                                    }
                                                   
                                                }}
                                            >
                                                {(formProps) => (
                                                <Form>
                                                    <div className='checkoutFormDiv paymentMethod checkoutTwo'>
                                                        <h2 className="formTitle">Payment Method</h2>
                                                        <div className={`payPal radioSec ${selectedPayment === 'paypal' ? 'expanded' : ''}`}>
                                                            <Field
                                                                type="radio"
                                                                name="contactPreference"
                                                                value="paypal"
                                                                className="rb-paypal"
                                                                id="rb-paypal"
                                                                onClick={(event) => handlePaymentSelection(event.target.value)}
                                                                checked={selectedPayment === 'paypal'}
                                                            />
                                                            <label className="label label-title" htmlFor="rb-paypal"><img src={paypal} className="paypallogo img-fluid" /></label>
                                                        </div>
                                                        <div className={`creditCard radioSec ${selectedPayment === 'creditCard' ? 'expanded' : ''}`}>
                                                            <Field
                                                                type="radio"
                                                                name="contactPreference"
                                                                value="creditCard"
                                                                className="rb-credit"
                                                                id="rb-credit"
                                                                onClick={() => handlePaymentSelection('creditCard')}
                                                                checked={selectedPayment === 'creditCard'}
                                                            />
                                                            <label className="label label-title" htmlFor="rb-credit">Credit Card or Debit</label>
                                                            <div className="label creditdiv" htmlFor="creditdiv">
                                                                {/* inner credit card section */}
                                                                <Field type="hidden" className="form-control" id="card_type" name="card_type" value={cardType}/>
                                                                <Field type="hidden" className="form-control" name="affid" value={affid}/>
                                                                <Field type="hidden" className="form-control" name="sid" value={sid}/>
                                                                <Field type="hidden" className="form-control" name="s1" value={s1}/>
                                                                <Field type="hidden" className="form-control" name="s2" value={s2}/>
                                                                <Field type="hidden" className="form-control" name="s3" value={s3}/>
                                                                <Field type="hidden" className="form-control" name="click_id" value={click_id}/>
                                                                <Field type="hidden" className="form-control" name="notes" value={notes}/>
                                                                <div className='billing-fields mb-4 mb-md-5'>
                                                                    <h3 className='form_title'>Billing details</h3>
                                                                    <div className='field-wrapper'>
                                                                        <div className='form-row'>
                                                                            <Field type="text" name="firstName" className="form-control validate[required]" placeholder="First Name*" />
                                                                            <CustomErrorMsg name="firstName" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" name="lastName" className="form-control validate[required]" placeholder="Last Name*" />
                                                                            <CustomErrorMsg name="lastName" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" className="form-control input-text" name="company_name" id="company_name" placeholder="Company name (optional)"/>
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field 
                                                                                as="select" 
                                                                                className="form-control" 
                                                                                name="shippingCountry"
                                                                                onChange={(event) => {
                                                                                    formProps.handleChange(event);
                                                                                    handleCountryChange(event);
                                                                                }}
                                                                            >
                                                                                <option value="239">United States</option>
                                                                                <option value="41">Canada</option>
                                                                            </Field>
                                                                            <CustomErrorMsg name="shippingCountry" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field
                                                                                name="shippingState"
                                                                                as="select"
                                                                                placeholder="Select an option"
                                                                                className="form-control input-text"
                                                                                
                                                                            >
                                                                            <option value="" >Select an option</option>
                                                                            {/* {options.map((option) => (
                                                                                <option key={option.abbreviation} value={option.abbreviation}>
                                                                                    {option.name}
                                                                                </option>
                                                                            ))} */}
                                                                            {states.map((state) => (
                                                                                <option 
                                                                                    key={state.id} 
                                                                                    value={state.abbreviation}
                                                                                    selected={state.abbreviation === userDetails.state}>
                                                                                    {state.state}
                                                                                </option>
                                                                            ))}
                                                                            </Field>
                                                                            <CustomErrorMsg name="shippingState" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" name="shippingAddress1" className="form-control validate[required]" placeholder="Address" />
                                                                            <CustomErrorMsg name="shippingAddress1" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" className="form-control input-text" name="shippingAddress2" placeholder="Apartment, suite, unit, etc. (optional)"/>
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" name="shippingCity" className="form-control validate[required]" placeholder="City" />
                                                                            <CustomErrorMsg name="shippingCity" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" maxLength="6" name="shippingZip" className="form-control validate[required]" placeholder="Zip" />
                                                                            <CustomErrorMsg name="shippingZip" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="text" name="phone" className="form-control validate[required]" placeholder="Phone" maxLength={10} />
                                                                            <CustomErrorMsg name="phone" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <Field type="email" name="email" className="form-control validate[required]" placeholder="Email" />
                                                                            <CustomErrorMsg name="email" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            <div>
                                                            <label className="checkboxLabel mb-3">
                                                                <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />Shipping is the same as billing
                                                            </label>
                                                            {showDiv &&
                                                            <div className="form-div-2">
                                                                <div className="form-holder">
                                                                    <label className="formLabel">Address</label>
                                                                    <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address"/>
                                                                    <CustomErrorMsg name="shipping_address" />
                                                                </div>
                                                                <div className="form-holder">
                                                                    <label className="formLabel">City:</label>
                                                                    <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city"/>
                                                                    <CustomErrorMsg name="shipping_city" />
                                                                </div>  
                                                                <div className="form-holder">
                                                                    <label className="formLabel">State:</label>
                                                                    <Field as="select" className="form-control" name="shipping_state">
                                                                        {/* <option value="">Select State</option>
                                                                        {options.map((option) => (
                                                                            <option key={option.abbreviation} value={option.abbreviation}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))} */}
                                                                        <option value="" >Select state</option>
                                                                        {states.map((state) => (
                                                                        <option 
                                                                            key={state.id} 
                                                                            value={state.abbreviation}>
                                                                            {state.state}
                                                                        </option>
                                                                    ))}
                                                                    </Field>
                                                                    <CustomErrorMsg name="shipping_state" />
                                                                </div>
                                                                <div className="form-holder">
                                                                    <label className="formLabel">Zip Code:</label>
                                                                    <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip"/>
                                                                    <CustomErrorMsg name="shipping_zip" />
                                                                </div>
                                                                <div className="form-holder">
                                                                    <label className="formLabel">Country:</label>
                                                                    <Field 
                                                                        as="select" 
                                                                        className="form-control" 
                                                                        name="shipping_country"
                                                                        onChange={(event) => {
                                                                            formProps.handleChange(event);
                                                                            handleCountryChange(event);
                                                                        }}
                                                                    >
                                                                        <option value="239">United States</option>
                                                                        <option value="41" >Canada</option>
                                                                    </Field>
                                                                    <CustomErrorMsg name="shipping_country" />
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                        {/* additional field begin     */}
                                                        <div className='additional-fields'>
                                                            <h3 className='form_title'>ADDITIONAL INFORMATION</h3>
                                                            <div className='field-wrapper'>
                                                                <div className='form-row'>
                                                                    <label htmlFor="order_comments" className="form-label">Order notes&nbsp;<span className="optional">(optional)</span></label>
                                                                    <Field component="textarea" id="order_comments" className="input-text form-control" name="order_comments" placeholder="Notes about your order, e.g. special notes for delivery." rows={0} cols={5}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* additional field End     */}
                                                        {/* Payment Field  */}
                                                        <div className='payment-field'>
                                                            <h3 className='form_title'>PAYMENT INFORMATION</h3>
                                                            <div className='checkout-payment'>
                                                                <div className='payment_methods'>
                                                                    <div className='field-wrapper'>
                                                                        <div className='form-row'>
                                                                            <label htmlFor="name_on_card" className="form-label">Name on card  <span className="required">*</span> </label>
                                                                            <Field type="text" className="form-control input-text" name="name_on_card" id="wc-unify-name-on-card" placeholder="Name on card"/>
                                                                            <CustomErrorMsg name="name_on_card" />
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <label htmlFor="cc_number" className="form-label">Card number  <span className="required">*</span> </label>
                                                                            <Field type="text" maxLength="16" className="form-control" placeholder="Card Number" name="ccnumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                                            <CustomErrorMsg name="ccnumber" />
                                                                        </div>
                                                                        <div className='form-row cc_form-holder'>
                                                                            <div className="cc-month">
                                                                                <Field as="select" className="form-control mm-selectList" name="cc_month"  >
                                                                                    <option value="0">Select</option>
                                                                                    <option value="01">January</option>
                                                                                    <option value="02">February</option>
                                                                                    <option value="03">March</option>
                                                                                    <option value="04">April</option>
                                                                                    <option value="05">May</option>
                                                                                    <option value="06">June</option>
                                                                                    <option value="07">July</option>
                                                                                    <option value="08">August</option>
                                                                                    <option value="09">September</option>
                                                                                    <option value="10">October</option>
                                                                                    <option value="11">November</option>
                                                                                    <option value="12">December</option>
                                                                                </Field>
                                                                                <CustomErrorMsg name="cc_month" />
                                                                            </div>
                                                                            <div className="cc-year">
                                                                                <Field as="select" className="form-control" name="cc_year" >
                                                                                    <option value="">Select</option>
                                                                                    {years.map(year => (
                                                                                        <option key={year} value={year}>
                                                                                            {year}
                                                                                        </option>
                                                                                    ))}
                                                                                </Field>
                                                                                <CustomErrorMsg name="cc_year" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-row'>
                                                                            <label htmlFor="order_comments" className="form-label">Card Code  <span className="required">*</span> </label>
                                                                            <Field type="text" maxLength="4" className="mm-textField form-control" placeholder="CVV" name="cvv"/>
                                                                            <CustomErrorMsg name="cvv" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Payment Field End  */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Place order begin  */}
                                                    <div className="form-row place-order">
                                                        <div className="terms-and-conditions-wrapper">
                                                            <div className="privacy-policy-text"></div>
                                                            <p className="form-row validate-required">
                                                                <label className="form_label_checkbox checkboxLabel termsCheckbox">
                                                                    <Field name="acceptTerms" type="checkbox" id="termsCheck" />
                                                                    <span className="terms-and-conditions-checkbox-text">I agree with Wholesale Health Club’s&nbsp; 
                                                                    <Link to="#" onClick={(event) => {
                                                                        event.preventDefault();
                                                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                                        }}>Terms of Service
                                                                    </Link> and&nbsp;
                                                                    <Link to="#" onClick={(event) => {
                                                                        event.preventDefault();
                                                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                                    }}>Privacy Policy
                                                                    </Link>.</span>&nbsp;<span className="required">*</span>
                                                                </label>
                                                                <CustomErrorMsg name="acceptTerms" />
                                                            </p>
                                                        </div>
                                                        {/* <div className="">
                                                            <button type="submit" className='btn p-0' name="submitButton" onClick={(event) => formProps.setFieldValue("submitButton", 'button1')}>
                                                                <img src={PaypalImage} className="img-fluid"/>
                                                            </button>
                                                        </div> */}
                                                        <button 
                                                            type="submit" 
                                                            className="btn cartBtn2 cartBtn2-solidBlack w-100" 
                                                            id="place_order" 
                                                            value="Confirm Order" 
                                                            data-value="Confirm Order" 
                                                            name="submitButton" 
                                                            onClick={(event) => formProps.setFieldValue("submitButton", selectedPayment)}>Confirm Order
                                                        </button>
                                                        <span className="bill_des_custom">Billing Descriptor: wholesalehealthclub.com</span>
                                                        <div className="trust-icons">
                                                            <img src={secure} className="img-fluid" />
                                                        </div>
                                                    </div>
                                                    {/* Place Oreder end  */}
                                                </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                : 
                    <>
                        <AccessDeniedPage />
                    </>
                
            :
                <>
                    <AccessDeniedPage />
                </>
            }
        </>
    )
}
