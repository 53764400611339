import React from 'react'
import { Link } from "react-router-dom";

// Images V2
import fbLogo from '../../assests/new/frontend/images/facebook-logo-white.png';
import linkedLogo from '../../assests/new/frontend/images/linkedin-logo-white.png';
import insta from '../../assests/new/frontend/images/instagram-logo-white.png';
import twitter from '../../assests/new/frontend/images/twitter-logo-white.png';
import homeIcon from '../../assests/new/frontend/images/home-icon.png';

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <>
            {/* Footer Begin  */}
            <footer className="nwFootter">
                {/* Social  */}
                <div className="nwFootter_social">
                    <div className="container-fluid">
                        <ul className="f_social_links">
                            <li><Link to=""><img src={fbLogo} className="img-fluid" alt="" /></Link></li>
                            <li><Link to=""><img src={linkedLogo} className="img-fluid" alt="" /></Link></li>
                            <li><Link to=""><img src={insta} className="img-fluid" alt="" /></Link></li>
                            <li><Link to=""><img src={twitter} className="img-fluid" alt="" /></Link></li>
                        </ul>
                    </div>
                </div>
            </footer>
            <div className="nwFootter_mid">
                <div className="container-fluid"></div>
            </div>
            {/* Footer Begin  */}
            <footer className="nwFootter">
                {/* Link copy rights   */}
                <div className="nwFootter_content text-center">
                    <div className="container-fluid">
                        <p>These statements have not been evaluated by the Food & Drug</p>
                        <p>Administration. This product is not intended to diagnose, treat, cure or prevent any disease.</p>
                        <br></br>
                        <ul className="f_quick_links">
                            <li>
                                <Link to='#' onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Terms And Conditions | </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Privacy Policy | </Link>
                            </li>
                            <li>
                                <Link to="#" onClick={(event) => {
                                    event.preventDefault();
                                    window.open('/citations', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                }}>Citations | </Link>
                            </li>
                            <li>
                                <Link to='/contact-us'> Contact Us </Link>
                            </li>
                        </ul>
                        <p>Copyright © {currentYear} | Wholesale Health Club | PO BOX 90129 Lakeland FL 33804 | Billing <br /> descriptor: <a href='https://wholesalehealthclub.com/'>wholesalehealthclub.com</a> | Customer Service&nbsp;
                        <span>Phone: <a href="tel:(480)573-0829">(480)573-0829</a></span> <span> | Customer Service Email: <br /><a href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></span></p>
                    </div>
                    
                </div>
            </footer>
            {/* Footer Begin  */}
        </>
    )
}

export default Footer