import React, { useState, useEffect } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom"; // v6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faDeleteLeft } from '@fortawesome/free-solid-svg-icons'
import CheckPermission from '../frontend/auth/CheckPermission';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../frontend/CustomErrorMsg";

function AutoProductMapFormik() {
    CheckPermission()
    const [productMappInstance, setproductMappInstance] = useState({
        name: "0",
        crmpid: "0",
        camid: "0",
        billingmodid: "0",
        offerid: '0',
        shipid: "0",
        name_name: "0",
        crmpid_name: "0",
        camid_name: "0",
        billingmodid_name: "0",
        offerid_name: '0',
        shipid_name: "0",
        price: "0"
    });

    const [show, setShow] = useState(false);
    const [mappedproductList, setMappedProductLists] = useState([]);
    const [pending, setPending] = useState(true);

    const [errors, setErros] = useState({});
    const [isPSubmit, setIsPSubmit] = useState(false);
    const [mappedProductDetails, setMappedProductDetails] = useState([]);
    const [mappedProductId, setMappedProductId] = useState(false);
    const [search, setSearch] = useState("");
    const [searchProducts, setSearchProducts] = useState([]);

    const [loadershow, setloaderShow] = useState(false);
    const [commonOffers, setCommonOffers] = useState([]);

    const [offerList, setOfferList] = useState([]);
    const [offerListShow, setOfferListShow] = useState("block");

    const [billingModelList, setbillingModelList] = useState([]);
    const [billingModelListShow, setbillingModelListShow] = useState("block");
    const [crmProductList, setcrmProductList] = useState([]);
    const [crmProductListShow, setcrmProductListShow] = useState("block");
    const [submitButtonShow, setsubmitButtonShow] = useState("block");
    const [productName, setProductName] = useState("")
    const [camidName, setcamidName] = useState("")
    const [offerName, setofferName] = useState("")
    const [billingModeleName, setbillingModeleName] = useState("")
    const [CRMproductName, setCRMProductName] = useState("")
    const [shippingName, setshippingName] = useState("")
    const [productPrice, setProductPrice] = useState("");
    const [products, setproducts] = useState([]);
    const [shippingMethodList, setshippingMethodList] = useState([]);
    // total 10 data listing with offers (*API/getCampaignAllDataList);
    const [CampaignAllDataList, setCampaignAllDataList] = useState([]);
    // total 30 data listing without offers (*API/getCampaignList);
    const [compaigns, setCompaigns] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);


    useEffect(()=>{
        // console.log('Campaign',CampaignAllDataList[0]);
        setCommonOffers(CampaignAllDataList[0]?.offers);
    },[CampaignAllDataList]);
    
    useEffect(() => { getCampaignAllDataList() }, []);

    useEffect(() => { getshippingMethodListList() }, []);

    useEffect(() => { getMappedProductList() }, []);
    
    // setting offer options according selected campaigns
    useEffect(() => {
        const handleOffers = (selectedOption) =>{
            const data = CampaignAllDataList?.find(({ c_id }) => c_id == selectedOption);
            if(data){
                setOfferList(data?.offers);
            }else{
                setOfferList([]);
            }
        };
        handleOffers(selectedCampaign);
    },[selectedCampaign]); 

    useEffect(() => {
        const result = mappedproductList.filter(country => {
            return country.name.toLowerCase().match(search.toLowerCase());
        })
        setSearchProducts(result);
    }, [search]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            getProductList();

        }, 1000);
        return () => clearTimeout(timeout);
    }, []);


    // all functions
    async function getCampaignAllDataList(formdata) {
        axios.get(`api/getCampaignAllDataList`, formdata).then(res => {
            setCampaignAllDataList(res.data.data.data);
        })
    };
    
    async function getMappedProductList() {

        if (compaigns.length == 0) {
            //setloaderShow(true)
            await axios.get(`api/getCampaignList`).then(res => {
                // console.log(' all campaigns: ',res.data.data)
                setCompaigns(res.data.data);
                //console.warn(res.data.data);
                axios.get(`api/listProductMapping`).then(res => {
                    // console.warn(res.data.data);
                    setMappedProductLists(res.data);
                    setSearchProducts(res.data)
                    setPending(false);
                })
                //setloaderShow(false)
            })
        }
        else {
            await axios.get(`api/listProductMapping`).then(res => {
                // console.warn(res.data.data);
                setMappedProductLists(res.data);
                setSearchProducts(res.data)
                setPending(false);
            })
        }

    };

    async function getshippingMethodListList() {
        await axios.get(`api/getShippingMethodList`).then(res => {
            setshippingMethodList(res.data.data);
            //console.warn(res.data.data);
        })
    }

    function getProductList() {
        axios.get(`api/listnotmappedproduct`).then(res => {
            setproducts(res.data.data);
        })
    };

    const handleClose = () => {
        setShow(false)
        setSearch('')
    };
    const handleShow = () => setShow(true);

    const handelInput = (e) => {
        e.persist();
        if (mappedProductId == false) {
            // setErros(validate(productMappInstance));
        }
        setIsPSubmit(true);
        setproductMappInstance({ ...productMappInstance, [e.target.name]: e.target.value })

        if (e.target.name == "name") {
            setProductName(e.target.selectedOptions[0].text)
            const index = e.target.selectedIndex;
            const el = e.target.childNodes[index]
            const option_price = el.getAttribute('data-price');
            setProductPrice(option_price);
        }

        if (e.target.name == "crmpid") {
            setCRMProductName(e.target.selectedOptions[0].text)
        }
        if (e.target.name == "shipid") {
            setshippingName(e.target.selectedOptions[0].text)
        }

        if (e.target.name == "camid") {
            setcamidName(e.target.selectedOptions[0].text)
            setloaderShow(true)
            const formdata = new FormData();
            formdata.append('campaign_id', e.target.value);
            setproductMappInstance({ ...productMappInstance, ['camid']: e.target.value });
            setSelectedCampaign(e.target.value);
            setloaderShow(false)
        }

        if (e.target.name == "offerid") {
            // setShow(false)
            setofferName(e.target.selectedOptions[0].text)
            setloaderShow(true)
            const formdata = new FormData();
            formdata.append('campaign_id', productMappInstance.camid);
            formdata.append('offer_id', e.target.value);
            // getBillingModelList(formdata)
            //var offerL = []

            offerList.map(function (OffersAll, i) {

                if (OffersAll['id'] == e.target.value) {
                    setbillingModelList(OffersAll.billing_models);

                }
            })
            setloaderShow(false)
        }

        if (e.target.name == "billingmodid") {
            setproductMappInstance({ ...productMappInstance, ['billingmodid']: e.target.value })
            // setShow(false)
            setbillingModeleName(e.target.selectedOptions[0].text)
            setloaderShow(true)
            const formdata = new FormData();
            formdata.append('campaign_id', productMappInstance.camid);
            formdata.append('offer_id', productMappInstance.offerid);
            formdata.append('billing_model_id', e.target.value);
            // getCRMProductList(formdata)
            offerList.map(function (OffersAll, i) {
                if (OffersAll['id'] == productMappInstance.offerid) {
                    setcrmProductList(OffersAll.products);
                }
            })
            setloaderShow(false)
        }

    };

    function createProductModal() {
        setProductPrice("");
        setOfferListShow("show")
        setbillingModelListShow("show")
        setcrmProductListShow("show")
        setsubmitButtonShow("show");
        setMappedProductDetails([]);
        setproductMappInstance({
            name: "0",
            crmpid: "0",
            camid: "0",
            billingmodid: "0",
            offerid: '0',
            shipid: "0",
            price: "0"
        });
        setMappedProductId(false);
        setErros({});
        handleShow();
    };

    const submitProductMapping = (data) => {
        handleClose();
        const formdata = new FormData();
        formdata.append('product_id', data.name);
        formdata.append('campaign_id', data.camid);
        formdata.append('offer_id', data.offerid);
        formdata.append('billing_model_id', data.billingmodid);
        formdata.append('shipping_id', data.shipid);
        formdata.append('price', productPrice);
        formdata.append('crm_product_id', data.crmpid);

        formdata.append('product_name', productName);
        formdata.append('campaign_name', camidName);
        formdata.append('offer_name', offerName);
        formdata.append('billing_model_name', billingModeleName);
        formdata.append('shipping_name', shippingName);
        formdata.append('crm_product_name', CRMproductName);


        axios.post(`api/createProductMapping`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                // window.location.reload();
                getProductList()
                getMappedProductList()
            }
        }).catch(err => {
            console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    };

    function deleteMappedProduct(id) {
        Swal.fire({
            title: 'Are you sure you want to delete this Mapped Product?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`api/deleteProductMapping/${id}`).then(res => {

                    Swal.fire({
                        title: 'Deleted',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    // window.location.reload();
                    getProductList()
                    getMappedProductList()
                })
            }
        })
    };

    async function showSingleData(id) {
        //console.warn(id);
        setProductPrice("");
        setMappedProductId(id);
        setOfferListShow("show")
        setbillingModelListShow("show")
        setcrmProductListShow("show")
        setsubmitButtonShow("show");
        setloaderShow(true)
        setproductMappInstance({
            name: "0",
            crmpid: "0",
            camid: "0",
            billingmodid: "0",
            offerid: '0',
            shipid: "0",
            price: "0"
        });
        setProductName("");
        setcamidName("");
        setofferName("");
        setbillingModeleName("");
        setCRMProductName("");
        setshippingName("");
        await axios.get(`api/detailsProductMapping/${id}`).then(res1 => {

            CampaignAllDataList.map(function (CampaignAllData, i) {
                if (CampaignAllData['c_id'] == res1.data.campaign_id) {
                    setOfferList(CampaignAllData.offers);
                    // console.warn(offerList);

                    CampaignAllData.offers.map(function (OffersAll, i) {
                        //console.warn(offerList);
                        if (OffersAll['id'] == res1.data.offer_id) {
                            setbillingModelList(OffersAll.billing_models);
                            CampaignAllData.offers.map(function (OffersAll, i) {
                                if (OffersAll['id'] == res1.data.offer_id) {
                                    setcrmProductList(OffersAll.products);
                                }
                            })
                        }
                    })
                }
            })



            setMappedProductDetails(res1.data);
            setErros({});
            setloaderShow(false)
            setShow(true)
        })
    };

    const updateProductMapping = (data) => {
        handleClose();
        const formdata = new FormData();
        formdata.append('product_id', data.name != "0" ? data.name : mappedProductDetails.product_id);
        formdata.append('campaign_id', data.camid != "0" ? data.camid : mappedProductDetails.campaign_id);
        formdata.append('offer_id', data.offerid != "0" ? data.offerid : mappedProductDetails.offer_id);
        formdata.append('billing_model_id', data.billingmodid != "0" ? data.billingmodid : mappedProductDetails.billing_model_id);
        formdata.append('shipping_id', data.shipid != "0" ? data.shipid : mappedProductDetails.shipping_id);
        formdata.append('price', productPrice ? productPrice : mappedProductDetails.price);
        formdata.append('crm_product_id', data.crmpid != "0" ? data.crmpid : mappedProductDetails.crm_product_id);

        formdata.append('product_name', productName ? productName : mappedProductDetails.product_name);
        formdata.append('campaign_name', camidName ? camidName : mappedProductDetails.campaign_name);
        formdata.append('offer_name', offerName ? offerName : mappedProductDetails.offer_name);
        formdata.append('billing_model_name', billingModeleName ? billingModeleName : mappedProductDetails.billing_model_name);
        formdata.append('shipping_name', shippingName ? shippingName : mappedProductDetails.shipping_name);
        formdata.append('crm_product_name', CRMproductName ? CRMproductName : mappedProductDetails.crm_product_name);
        console.warn(formdata);
        axios.post(`api/updateProductMapping/${mappedProductId}`, formdata).then(res => {
            if (res.data.status === true) {
                handleClose();
                Swal.fire({
                    title: 'Success',
                    html: res.data.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1000
                })
                // window.location.reload();
                getProductList()
                getMappedProductList()
            }
        }).catch(err => {
            console.log(err.response)
            window.location.reload();
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })

        });
    };

    const loaderHandleClose = () => setloaderShow(false);
    const loaderHandleShow = () => setloaderShow(true);
    const validate = (productMappInstance) => {
        const errors = {};
        // console.warn(productMappInstance.name);
        if (productMappInstance.name == "0") {
            errors.name = "Must select a Product name";
        }

        if (!productMappInstance.crmpid) {
            errors.crmpid = "Must Provide a CRM Product ID!";
        }

        if (!productMappInstance.camid) {
            errors.camid = "Must provide a Campaign ID!";
        }

        if (!productMappInstance.billingmodid) {
            errors.billingmodid = "Must provide a Billing Model ID";
        }
        if (!productMappInstance.offerid) {
            errors.offerid = "Must provide a Offer ID";
        }

        if (!productMappInstance.shipid) {
            errors.shipid = "Must provide a Shipping ID";
        }
        // if (!productMappInstance.price) {
        //     errors.shipid = "Must provide Price";
        // }
        return errors;
    };

    const columns = [
        {
            name: "Product Name",
            selector: row => row.name
        },
        {
            name: "CRM Product Name",
            selector: row => row.crm_product_name
        },
        {
            name: "CRM Product Price",
            cell: row => (
                <span>${row.price}</span>
            )
        },
        {
            name: "Campaign",
            cell: row => (
                <span>{row.campaign_name}</span>
            )
        },
        {
            name: "Offer",
            cell: row => (
                <span>{row.offer_name}</span>
            )
        },
        {
            name: "Billing Model",
            cell: row => (
                <span>{row.billing_model_name}</span>
            )
        },
        {
            name: "Shipping",
            cell: row => (
                <span>{row.shipping_name}</span>
            )
        },
        {
            name: "Action",
            cell: row => (
                <>
                    <button style={{ marginRight: 1 + 'em' }} className='btn btn-primary' onClick={() => { showSingleData(row.id) }} ><FontAwesomeIcon icon={faGear} /></button>
                    <button className='btn btn-danger' onClick={() => { deleteMappedProduct(row.id) }} ><FontAwesomeIcon icon={faDeleteLeft} /></button>
                </>
            )
        }
    ]

    return (
        <div>
            <div >
                <>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Map Products with CRM</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <div className='card'>
                                            <div className='card-body'>
                                            <Formik
                                                    enableReinitialize = {true}
                                                    initialValues={{
                                                        name: mappedProductDetails.product_id,
                                                        crmpid: mappedProductDetails.crm_product_id,
                                                        camid: mappedProductDetails.campaign_id,
                                                        billingmodid: mappedProductDetails.billing_model_id,
                                                        offerid: mappedProductDetails.offer_id,
                                                        shipid: mappedProductDetails.shipping_id,
                                                        price: mappedProductDetails.price,
                                                    }}

                                                    validationSchema={Yup.object({
                                                        name: Yup.string().required('Please select a product'),
                                                        crmpid: Yup.string().required('Please select a CRM product'),
                                                        camid: Yup.string().required('Please select a CRM compaign'),
                                                        billingmodid: Yup.string().required('Please select a CRM billing model'),
                                                        offerid: offerList.length && Yup.string().required('Please select a CRM offer'),
                                                        shipid: Yup.string().required('Please select a CRM shiping'),
                                                        // price: Yup.string('').required('Price is required'),
                                                    })}

                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setSubmitting(true);
                                                        //alert(JSON.stringify(values, null, 2));
                                                        {(mappedProductId) ? updateProductMapping(values) : submitProductMapping(values); }
                                                    }}
                                                >
                                                <Form onChange={handelInput}>
                                                    <div className='form-group md-3'>
                                                        <label> Name </label>
                                                        <Field as="select" type="" name="name" className="form-control">
                                                            <option value="" data-price=" ">Select Product</option>
                                                            {mappedProductDetails.product_id ? <option value={mappedProductDetails.product_id} key={mappedProductDetails.id} data-price={mappedProductDetails.price} >{mappedProductDetails.product_name}</option> : ""}
                                                            {products.map(products => {
                                                                return (
                                                                    <option value={products.id} key={products.id} data-price={products.price} >
                                                                        ({products.id}){products.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <CustomErrorMsg name="name" />
                                                    </div>


                                                    <div className='form-group md-3'>
                                                        <label> Price </label>
                                                        <Field type="number" disabled name="price" className="form-control" value={productPrice ? productPrice : mappedProductDetails.price} />
                                                        <CustomErrorMsg name="price" />
                                                    </div>


                                                    <div className='form-group md-3'>
                                                        <label>Campaigns</label>
                                                        <Field as="select" name="camid" className="form-control">
                                                            <option value="">Select Campaign</option>
                                                            {compaigns.map(compaigns => {
                                                                return (
                                                                    <option value={compaigns.id} key={compaigns.id}>
                                                                        ({compaigns.id}){compaigns.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <CustomErrorMsg name="camid" />
                                                    </div>


                                                    <div className='form-group md-3' style={{ display: offerListShow }}>
                                                        <label> Offers</label>
                                                        <Field as="select" name="offerid" className="form-control">
                                                            <option value="">Select Offer</option>
                                                            {offerList?.map(offerList => {
                                                                return (
                                                                    <option value={offerList.id} key={offerList.id}>
                                                                        ({offerList.id}){offerList.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <CustomErrorMsg name="offerid" />
                                                    </div>


                                                    <div className='form-group md-3' style={{ display: billingModelListShow }}>
                                                        <label> Billing Model</label>
                                                        <Field as="select" name="billingmodid" className="form-control">
                                                            <option value="">Select Billing Model</option>
                                                            {billingModelList.map(billingModelList => {
                                                                return (
                                                                    <option value={billingModelList.id} key={billingModelList.id}>
                                                                        ({billingModelList.id}){billingModelList.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <CustomErrorMsg name="billingmodid" />
                                                    </div>


                                                    <div className='form-group md-3' style={{ display: crmProductListShow }}>
                                                        <label>CRM Products</label>
                                                        <Field as="select" name="crmpid" className="form-control">
                                                            <option value="">Select CRM Products</option>
                                                            {crmProductList.map(crmProductList => {
                                                                return (
                                                                    <option value={crmProductList.id} key={billingModelList.id}>
                                                                        ({crmProductList.id}){crmProductList.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <CustomErrorMsg name="crmpid" />
                                                    </div>


                                                    <div className='form-group md-3'>
                                                        <label> Shipping Method</label>
                                                        <Field as="select" name="shipid" className="form-control">
                                                            <option value="">Select Shipping Method</option>
                                                            {shippingMethodList.map(shippingMethodList => {
                                                                return (
                                                                    <option value={shippingMethodList.id} key={shippingMethodList.id}>
                                                                        ({shippingMethodList.id}){shippingMethodList.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        <CustomErrorMsg name="shipid" />
                                                    </div>


                                                    <br />
                                                    <div className='form-group md-3 '>
                                                        <Button type="submit">{mappedProductId ? "Update!" : "Save!"}</Button>
                                                    </div>
                                                </Form>
                                                </Formik>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {/* <Button style={{ display: submitButtonShow }} variant="primary" onClick={mappedProductId ? updateProductMapping : submitProductMapping}>
                                {mappedProductId ? "Update" : "Save"}
                            </Button> */}
                        </Modal.Footer>
                    </Modal>

                    <Modal show={loadershow} className="text-center">
                        <Modal.Header>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Spinner animation="grow" />
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </>

            </div>
            <div className='container'>
                <div className='col-md-12 pl-3'>
                    <div className="pageheader card mb-4">
                        <div className="card-body row align-items-center">
                            <div className="page-name col-md-3">Mapped Product List</div>
                            <div className="buttun_div  col-md-9">
                                <Button variant="primary" onClick={createProductModal}>
                                     Create Product Mapping
                                </Button>
                            </div>
                        </div>
                    </div>
                    <h1 className="display-6"></h1>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='datatable'>
                                <DataTable
                                    noDataComponent="Mapped Product table is empty" //or your component
                                    columns={columns}
                                    data={searchProducts}
                                    progressPending={pending}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                        <input type="text" placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    }
                                    subHeaderAlign="right"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutoProductMapFormik;