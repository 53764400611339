import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import {BASE_URL} from '../Config';
import useStore from '../../store';
import AccessDeniedPage from "../AccessDeniedPage";

import betterHealthImage from '../../assests/frontend/assets/images/betterHealthImage.jpg';
import brainPuzzle from '../../assests/frontend/assets/images/NoPicturePuzzle_Puzzles-300x188.webp';
import celebrityDiteBook from '../../assests/frontend/assets/images/celebrityDietCookBook.jpg';
import balancedDietCocept from '../../assests/frontend/assets/images/balanced-diet-concept-300x225.jpg';
import exerciseNewsletters from '../../assests/frontend/assets/images/workout-equipment-for-professional-bodybuilder-300x200.jpg';
import lifeStyleNewsletters from '../../assests/frontend/assets/images/happy-mature-people-couple-exercising-300x200.jpg';
import lookYounger from '../../assests/frontend/assets/images/lookYoungerFeelYoungerBook.jpg';
import clubCover from '../../assests/frontend/assets/images/10_wholesale_club_cover_final.png';
import Sidebar2 from "../../layouts/frontend/Sidebar2";



function BonusSlug2() {
    const [bonusList, setBonusList] = useState([]);
    let URL = window.location.href;
    let URLparam = URL.substring(URL.lastIndexOf('/') + 1)
    let imgURL = BASE_URL+"whc-custom/storage/app/public/";
    const isLogin = useStore((state) => state.isLogin);
    const isMember = useStore((state) => state.isMember);
    const navigate = useNavigate();

     function findBonusListById() {
        axios.get(`api/bonus_list_frontend/${URLparam}`).then(res => {
            console.log(res.data.data);
            setBonusList(res.data.data);
        }).catch(err => {
            //console.log(err.response)
            Swal.fire({
                title: 'Warning',
                html: err,
                icon: 'warning'
            })
        });
    }
  
    useEffect(() => {
        findBonusListById();
    }, []);

    return (
        <>
            {((isLogin === 1) && (isMember === 1)) ?
                <section className="bonusPage">
                    
                        <div className='container-fluid'>
                            {/* Row Begins  */}
                            <div className="row newShopList_row">
                                <Sidebar2/>
                                {/* Left Col Begin  */}
                                <div className="col-md-8 col_right">
                                    {/* Row : Begin  */}
                                    <div className="row prodList-row bonusProdLists">
                                        {/* Col : Begin  */}
                                        {bonusList?bonusList.map((item) => (
                                            <div className="col-lg-4 col-md-4 prodList-col">
                                                <div className="product_display">
                                                    <div className="product_img">
                                                        <img src={imgURL+item.image} className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="content-block">
                                                        <div className="title-block-bonus text-center">
                                                            <h6 className="text-center">{item.name}</h6>
                                                        </div>
                                                        <a href={imgURL+item.doc} target='_blank' rel='noopener noreferrer' className="prod_btn">Download PDF</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )):''}
                                        {/* Col End  */}
                                    </div>
                                    {/* Row End  */}
                                </div>
                                {/* Left Col End  */}
                            </div>
                            {/* Row Ends  */}
                        </div>
                    
                </section>
            : ((isLogin === 1) && (isMember === 0)) ? <AccessDeniedPage/> : ''}
        </>
    )
}


export default BonusSlug2