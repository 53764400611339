import React, { useEffect, useRef, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import '../../assests/Lander/assets/css/acvgummies-landing.css';
import { Link, useNavigate } from 'react-router-dom';
import {stateList} from '../Config';
import Popup from "../frontend/Popup";
import { Helmet } from 'react-helmet';

// Images 
import Logo2 from '../../assests/Lander/assets/images/logo-2.png';
import LogoJu from '../../assests/Lander/assets/images/logo-ju.png';
import arrowCurve from '../../assests/Lander/assets/images/arrow.png';
import product from '../../assests/Lander/assets/images/product.png';
import ketomagazines from '../../assests/Lander/assets/images/keto-magazines.png';
import step1 from '../../assests/Lander/assets/images/step-1.png';
import step2 from '../../assests/Lander/assets/images/step-2.png';
import step3 from '../../assests/Lander/assets/images/step-3.png';
import ketoScience from '../../assests/Lander/assets/images/keto-science.png';
import testimonial1 from '../../assests/Lander/assets/images/testimonial-1.png';
import testimonial2 from '../../assests/Lander/assets/images/testimonial-2.png';
import testimonial3 from '../../assests/Lander/assets/images/testimonial-3.png';
import gurantee from '../../assests/Lander/assets/images/GUARANTEE.png';
import ingredients from '../../assests/Lander/assets/images/INGREDIENTS.png';
import madeInUsa from '../../assests/Lander/assets/images/made-in-usa.png';
import arrow1 from '../../assests/Lander/assets/images/arrow-1.png';
import wallpapers from '../../assests/Lander/assets/images/wallpapers.png';
import socialIcons from '../../assests/Lander/assets/images/social-icons.jpg';
import websiteSecure from '../../assests/Lander/assets/images/website-secure.png';
import ca from '../../assests/Lander/assets/images/ca.png';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation, useParams } from 'react-router-dom';
import CustomErrorMsg from "../frontend/CustomErrorMsg";
import { Button } from "bootstrap";
import axios from 'axios';
import Swal from 'sweetalert2';
import AcvgummiesMobileTemplate from "./AcvgummiesMobileTemplate";

function AcvgummiesTemplate() {
    const navigate = useNavigate();
    const ref = useRef(null);
    const [loadershow, setloadershow] = useState(false);
    const location = useLocation();
    const currentYear = new Date().getFullYear();
    let params = location.search;
    const scrollToCustom = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const options = stateList;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const [minutes, setMinutes] = useState(9);
    const [seconds, setSeconds] = useState(59);
    const [states, setStates] = useState([]);
    const params2 = useParams();
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [minutes, seconds]);

    useEffect(()=>{
        handleCountryChange2('239');
    },[params2.slug]);

    function getDateOneWeekFromNow() {
        // Create a new Date object for the current date
        const date = new Date();

        // Add 7 days to the date in milliseconds (1 week)
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        // Get the month, day, and year from the date
        const month = date.getMonth();
        const day = date.getDate();
        const year = date.getFullYear();

        // Convert the month number to the month's name
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];

        // Return the date in the format you specified
        return `${monthName} ${day}, ${year}`;
    }

    const dateOneWeekFromNow = getDateOneWeekFromNow();

    function confirmSubmit(values) {
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('firstName',values.firstName);
        formdata.append('lastName',values.lastName);
        formdata.append('email',values.email);
        formdata.append('phone',values.phone);
        formdata.append('shippingAddress',values.shippingAddress1);
        formdata.append('shippingCity',values.shippingCity);
        formdata.append('shippingState',values.shippingState);
        formdata.append('shippingZip',values.shippingZip);
        formdata.append('shippingCountry',values.country === '239' ? 'US' : 'CA');
        formdata.append('campaign_id',9);
        formdata.append('tag1','diet-prospect');

        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        formdata.append('affid', affid);
        formdata.append('sid', sid);
        formdata.append('s1', s1);
        formdata.append('s2', s2);
        formdata.append('s3', s3);
        formdata.append('click_id', click_id);
        formdata.append('notes', notes);

        //console.log(Object.fromEntries(formdata));
        
        axios.post(`api/maropost_tag_add`, formdata).then(res => {
            if (res.data.status === true) {
                axios.post(`api/create-prospect`, formdata).then(result => {
                    if(result.data.status === true) {
                        localStorage.setItem('prospectId',result.data.prospectId);
                        window.location.href = '/' + localStorage.getItem('c_s')+params;
                    }
                })
            }
        }).catch(err => {
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    return (
        <>
            {(isMobile) ? <AcvgummiesMobileTemplate/> : <>
                <div className={loadershow ? 'loading' : 'd-none'}></div>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <main>
                    <div className="diet-page">
                        <div className="diet-header">
                            <div className="diet-header__warning">
                                <div className="container">
                                    <span className="alert-color">Warning:</span> Due to extremely high media demand, there is limited supply of
                                    <span className="product-name"><span className="prod-name">&nbsp;Trubody ACV Keto Gummies</span></span> in stock as of <span className="date-container"> {dateOneWeekFromNow}</span> <span className="hurry">HURRY! <span id="time">{minutes}:{seconds}</span>
                                    </span>
                                </div>
                            </div>
                            <div className="container">
                                <img alt="" className="diet-header__logo" src={Logo2} width="176" />
                                <div className="diet-header__info">
                                    <div className="diet-header__info_side">
                                        <span>Get My Free Bottle!</span>
                                        <p><span>Voted #1</span> <span className="prod-name">Tru Body ACV Keto Gummies</span> Product in the USA</p>
                                    </div>
                                    <a className="diet-header__btn heartbeat" href="javascript:void(0)" onClick={scrollToCustom}><span className="rushbtn text-white">RUSH MY ORDER</span></a>
                                </div>
                            </div>
                        </div>
                        {/* Section 1  */}
                        <section className="first-section">
                            <div className="container">
                                <div className="row">
                                    <div className="first-section__side first-section__info">
                                        <div className="first-section__side_in">
                                            <img alt="" src={LogoJu} />
                                            <h1>MELT FAT <span>FAST!</span></h1>
                                            <h2>WITHOUT DIET OR EXERCISE</h2>
                                            <h3>Powerful New Formula Triggers
                                                Fat-Burning Ketosis!</h3>
                                            <ul className="workLists">
                                                <li>Burn Fat for Energy not Carbs</li>
                                                <li>Release Fat Stores</li>
                                                <li>Increase Energy Naturally!</li>
                                                <li>Love the Way You Feel!</li>
                                            </ul>
                                            <img alt="" className="d-mobile arrow-img" src={arrowCurve} />
                                            <img alt="" className="security-icons d-mobile" src="images/security-icons.png" />
                                            <div className="first-section__bottle">
                                                <img alt="" src={product} />
                                                <ul className="garante-list">
                                                    <li className="garante-item_1"></li>
                                                    <li className="garante-item_2"></li>
                                                    <li className="garante-item_3"></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <Formik initialValues={{
                                        firstName: '',
                                        lastName: '',
                                        shippingAddress1: '',
                                        shippingZip: '',
                                        shippingCity: '',
                                        country:'239',
                                        shippingState: '',
                                        email: '',
                                        phone: ''
                                    }}
                                        validationSchema={Yup.object({
                                            firstName: Yup.string().required('First name is required'),
                                            lastName: Yup.string().required('Last name is required'),
                                            shippingAddress1: Yup.string().required('Address is required'),
                                            shippingZip: Yup.string().required('Zip code is required'),
                                            shippingCity: Yup.string().required('City is required'),
                                            shippingState: Yup.string().required('State is required'),
                                            email: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                            phone: Yup.string().required('Phone no is required')
                                                .matches(/^[0-9]+$/, "Please enter only number!")
                                                .min(10, 'Please enter a valid contact number!')
                                                .max(10, 'Please enter a valid contact number!'),
                                            country: Yup.string().required('Country is required')
                                        })}
                                        onSubmit={(values) => {
                                            localStorage.setItem('firstName', values.firstName);
                                            localStorage.setItem('lastName', values.lastName);
                                            localStorage.setItem('shippingAddress1', values.shippingAddress1);
                                            localStorage.setItem('shippingZip', values.shippingZip);
                                            localStorage.setItem('shippingState', values.shippingState);
                                            localStorage.setItem('shippingCity', values.shippingCity);
                                            localStorage.setItem('phone', values.phone);
                                            localStorage.setItem('email', values.email);
                                            localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
                                            confirmSubmit(values);
                                        }}
                                    >
                                        {(formProps) => (
                                        <div className="first-section__side" ref={ref}>
                                            <h2 className="form-title"><span>STEP 1</span> - TELL US WHERE TO SEND YOUR BOTTLE</h2>
                                            <Form className="kform form123" >
                                                <div className="row">
                                                    <div className="phone-6 columns form-holder">
                                                        <label>First Name:</label>
                                                        <Field type="text" name="firstName" className="form-control validate[required]" placeholder="First Name*" />
                                                        <CustomErrorMsg name="firstName" />
                                                    </div>
                                                    <div className="phone-6 columns form-holder">
                                                        <label>Last Name:</label>
                                                        <Field type="text" name="lastName" className="form-control validate[required]" placeholder="Last Name*" />
                                                        <CustomErrorMsg name="lastName" />
                                                    </div>
                                                </div>
                                                <div className="phone-12 columns form-holder">
                                                    <label>Address:</label>
                                                    <Field type="text" name="shippingAddress1" className="form-control validate[required]" placeholder="Address" />
                                                    <CustomErrorMsg name="shippingAddress1" />
                                                </div>
                                                <div className="phone-12 columns form-holder">
                                                    <label>Zip Code:</label>
                                                    <Field type="text" maxLength="6" name="shippingZip" className="form-control validate[required]" placeholder="Zip" />
                                                    <CustomErrorMsg name="shippingZip" />
                                                </div>
                                                <div className="phone-12 columns form-holder">
                                                    <label>City:</label>
                                                    <Field type="text" name="shippingCity" className="form-control validate[required]" placeholder="City" />
                                                    <CustomErrorMsg name="shippingCity" />
                                                </div>
                                                <div className="phone-12 columns form-holder">
                                                    <label>Country:</label>
                                                    <Field
                                                        name="country"
                                                        as="select"
                                                        placeholder="Select an option"
                                                        className="form-control validate[required]"
                                                        onChange={(event) => {
                                                            formProps.handleChange(event);
                                                            handleCountryChange(event);
                                                        }}
                                                    >
                                                        <option value="239" >United States</option>
                                                        <option value="41" >Canada</option>
                                                    </Field>
                                                    <CustomErrorMsg name="country" />
                                                </div>
                                                <div className="phone-12 columns form-holder">
                                                    <label>State:</label>
                                                    <Field
                                                        name="shippingState"
                                                        as="select"
                                                        placeholder="Select an option"
                                                        className="form-control validate[required]"
                                                    >
                                                        {states.map((state) => (
                                                            <option key={state.id} value={state.abbreviation}>
                                                                {state.state}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <CustomErrorMsg name="shippingState" />
                                                </div>
                                                <div className="row">
                                                    <div className="phone-6 columns form-holder mb-0">
                                                        <label>Email:</label>
                                                        {/* <input className="form-control validate[required,custom[email]]" data-placement="auto left" name="email" id="uemail" placeholder="Email*" title="Email" type="email" value="" /> */}
                                                        <Field type="email" name="email" className="form-control validate[required]" placeholder="Email" />
                                                        <CustomErrorMsg name="email" />
                                                    </div>
                                                    <div className="phone-6 columns form-holder mb-0">
                                                        <label>Phone:</label>
                                                        <Field type="tel" name="phone" className="form-control validate[required]" placeholder="Phone" maxLength={10} />
                                                        <CustomErrorMsg name="phone" />
                                                    </div>
                                                </div>
                                                <button className="button-submit loading-btn" id="RushOrder" type="submit">RUSH MY ORDER</button>
                                            </Form>
                                        </div>
                                        )}
                                    </Formik>
                                    {/* <!-- Form Section End --> */}
                                </div>
                            </div>
                        </section>
                        {/* Section 1 end  */}
                        {/* Why Section Begin  */}
                        <section className="why-is">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 why-is__side">
                                        <img alt="" src={ketomagazines} className="keto-magazines" />
                                        <img alt="" src={product} className="wy-btl" />
                                    </div>
                                    <div className="col-md-6 why-is__side">
                                        <h2>WHY IS <br /><span className="prod-name">Tru Body ACV Keto Gummies</span> <br /><span>SO POPULAR NOW?</span></h2>
                                        <p>A recent study published by the Diabetes, Obesity, and Metabolism Journal found that <span className="prod-name">Tru Body ACV Keto Gummies</span> supported burning fat for energy instead of carbohydrates greatly increasing weight loss and energy.</p>
                                        <p>Furthermore, TV doctor, recently named <span className="prod-name">Tru Body ACV Keto Gummies</span> the "Holy Grail" of weight loss for good reason - IT WORKS.</p>
                                        <p>It is important to note that the <span className="prod-name">Tru Body ACV Keto Gummies</span> used in the study was the real deal and <span className="prod-name">Tru Body ACV Keto Gummies</span> exceeds the studies product potency using proprietary methods.</p>
                                        <p>Bottom Line: It Works and it's Better for your Health!</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Why Section End  */}
                        {/* How to use section : Begin  */}
                        <section className="how-use">
                            <div className="container">
                                <h2 className="how-use__titleMain">
                                    <span>HOW TO USE <span className="prod-name">Tru Body ACV Keto Gummies</span></span> <br />
                                    TO GET RESULTS
                                </h2>
                                <div className="how-use-desktop d-none d-md-block">
                                    <div className="row">
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="how-use__item">
                                                <img alt="" src={step1} />
                                                <span className="how-use__step">Step 1</span>
                                                <span className="how-use__title">INSTANT FAT BURN</span>
                                                <p><span className="prod-name">Tru Body ACV Keto Gummies</span> works to release stored fat, by helping your body burn fat for energy instead of carbs. Advanced Ketones are behind this miracle product that helps you lose up to 5 lbs in the first week.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="how-use__item">
                                                <img alt="" src={step2} />
                                                <span className="how-use__step">Step 2</span>
                                                <span className="how-use__title">ACCELERATED FAT BURN</span>
                                                <p>During the first month of use, <span className="prod-name">Tru Body ACV Keto Gummies</span> produces accelerated Fat Burn, which results in expected weight loss of up to 20 lbs. You will notice a drastic change in a very short period of time!</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="how-use__item">
                                                <img alt="" src={step3} />
                                                <span className="how-use__step">Step 3</span>
                                                <span className="how-use__title">TRANSFORM YOUR BODY</span>
                                                <p>With your weight loss goals achieved, continue to take <span className="prod-name">Tru Body ACV Keto Gummies</span> for 3-5 months as to stabilize your appetite, as well as to maintain and transform your new, slim body.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- For Mobile view  --> */}
                                <div className="how-use-mob d-md-none">
                                    <div className="howUse-slider">
                                        <Carousel>
                                            <Carousel.Item>
                                                <div className="how-use__item">
                                                    <img alt="" src={step1} />
                                                    <span className="how-use__step">Step 1</span>
                                                    <span className="how-use__title">INSTANT FAT BURN</span>
                                                    <p><span className="prod-name">Tru Body ACV Keto Gummies</span> works to release stored fat, by helping your body burn fat for energy instead of carbs. Advanced Ketones are behind this miracle product that helps you lose up to 5 lbs in the first week.</p>
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div className="how-use__item">
                                                    <img alt="" src={step2} />
                                                    <span className="how-use__step">Step 2</span>
                                                    <span className="how-use__title">ACCELERATED FAT BURN</span>
                                                    <p>During the first month of use, <span className="prod-name">Tru Body ACV Keto Gummies</span> produces accelerated Fat Burn, which results in expected weight loss of up to 20 lbs. You will notice a drastic change in a very short period of time!</p>
                                                </div>
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <div className="how-use__item">
                                                    <img alt="" src={step3} />
                                                    <span className="how-use__step">Step 3</span>
                                                    <span className="how-use__title">TRANSFORM YOUR BODY</span>
                                                    <p>With your weight loss goals achieved, continue to take <span className="prod-name">Tru Body ACV Keto Gummies</span> for 3-5 months as to stabilize your appetite, as well as to maintain and transform your new, slim body.</p>
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                {/* <!-- For Mobile view End  --> */}
                                <div className="diagonal-line-container">
                                    <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                                        <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0"></polygon>
                                    </svg>
                                </div>
                            </div>
                        </section>
                        {/* How to use section : End  */}
                        {/* <!-- Science : Begin  --> */}
                        <section className="science green-gradient">
                            <div className="container">
                                <div className="flip diagonal-line-container">
                                    <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                                        <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0">
                                        </polygon>
                                    </svg>
                                </div>
                                <div className="row">
                                    <div className="col-md-7 science__left">
                                        <h2 className="science__title">THE SCIENCE OF</h2>
                                        <h2 className="science__subTitle"> KETOSIS<span>(<span className="prod-name">Tru Body ACV Keto Gummies</span>)</span></h2>
                                        <p>Ketosis is the state where your body is actually burning fat for energy instead of carbs. Ketosis is extremely hard to obtain on your own and takes weeks to accomplish. <span className="prod-name">Tru Body ACV Keto Gummies</span> actually helps your body achieve ketosis fast and helps you burn fat for energy instead of carbs!</p>
                                        <ul>
                                            <li><span>No More Stored Fat:</span> Currently with the massive load of cabohydrates in our foods, our bodies are conditioned to burn carbs for energy instead of fat. Because it is an easier energy source for the body to use up.</li>
                                            <li><span>Fat - The New Energy:</span> Ketosis is the state where your body is actually burning fat for energy instead of carbs. Ketosis is extremely hard to obtain on your own and takes weeks to accomplish. <span className="prod-name">Tru Body ACV Keto Gummies</span> actually helps your body achieve ketosis fast and helps you burn fat for energy instead of carbs!</li>
                                            <li><span>More Health Benefits:</span> <span className="prod-name">Tru Body ACV Keto Gummies</span> works almost instantly to help support ketosis in the body by Burning FAT for energy. Fat IS the body's ideal source of energy and when you are in ketosis you experience energy and mental clarity like never before and of course very rapid weight loss.</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-5 science__right">
                                        <div className="science__img">
                                            <img alt="" src={ketoScience} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- Science : End  --> */}
                        {/* Review Slider : Begin   */}
                        <section className="review">
                            <div className="container">
                                <div className="diagonal-line-container">
                                    <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                                        <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0">
                                        </polygon>
                                    </svg>
                                </div>
                                <h2><span>REAL</span> SUCCESS STORIES</h2>
                                {/* <!-- slider  --> */}
                                <div className="single-item">
                                    <Carousel>
                                        <Carousel.Item>
                                            <div className="slide__item row">
                                                <div className="slide__item_left col-md-5">
                                                    <img alt="" src={testimonial1} />
                                                </div>
                                                <div className="slide__item_right col-md-7">
                                                    <div className="table-cell">
                                                        <p>"After watching video after video of keto success stories, I felt hopeful that I could actually start losing weight without anything too extreme. When I found <span className="prod-name">Tru Body ACV Keto Gummies</span> online and thought I'd give it a shot. I started losing weight, I though maybe it was a fluke at first. After my first 10 pounds, I cried. If you need something to work like me, you won't be let down"</p>
                                                        <span>- Ashley R</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="slide__item row">
                                                <div className="slide__item_left col-md-5">
                                                    <img alt="" src={testimonial2} />
                                                </div>
                                                <div className="slide__item_right col-md-7">
                                                    <div className="table-cell">
                                                        <p>"I've been hearing about <span className="prod-name">Tru Body ACV Keto Gummies</span> for a while now. My sister took
                                                            it and had some amazing success. I thought, if I could just lose
                                                            a few pounds I would feel better about myself. I was shocked to
                                                            say the least when I lost 20lbs in 30 days. Now I tell everyone
                                                            :)"</p>
                                                        <span>- Isabella N</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="slide__item row">
                                                <div className="slide__item_left col-md-5">
                                                    <img alt="" src={testimonial3} />
                                                </div>
                                                <div className="slide__item_right col-md-7">
                                                    <div className="table-cell">
                                                        <p>"<span className="prod-name">Tru Body ACV Keto Gummies</span> is by far the best product I've used for ketosis. It
                                                            works and it works well. I am the leanest I have ever been in my
                                                            life. I had my body fat tested before and after and I went from
                                                            a whopping 26% body fat down to 16% in 4 months. I owe a big
                                                            thanks to <span className="prod-name">Tru Body ACV Keto Gummies</span> for this amazing
                                                            experience."</p>
                                                        <span>- Darin K</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </div>
                        </section>
                        {/* <!-- Review Slider End  --> */}
                        {/* Last Block : Begin */}
                        <section className="last-block">
                            <div className="container">
                                <div className="diagonal-line-container flip">
                                    <svg className="diagonal-line" preserveAspectRatio="none" version="1.1" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
                                        <polygon fill="#f3f3f3" points="200,500 0,500 300,0 500,0"></polygon>
                                    </svg>
                                </div>
                                <div className="last-block__block row">
                                    <div className="last-block_left">
                                        <h2>
                                            THE MOST POWERFUL <br />
                                            <span className="prod-name">Tru Body ACV Keto Gummies</span>
                                        </h2>
                                        <ul className="row">
                                            <li><img alt="" src={gurantee} /></li>
                                            <li><img alt="" src={ingredients} /></li>
                                            <li><img alt="" src={madeInUsa} /></li>
                                            <li><img alt="" src={arrow1} /></li>
                                        </ul>
                                    </div>
                                    <div className="last-block_middle">
                                        <div className="last-block-bottle">
                                            <img alt="" src={product} />
                                        </div>
                                        <a className="last-block__btn" href="javascript:void(0)" onClick={scrollToCustom}>
                                            <span className="rushbtn">RUSH MY ORDER</span>
                                        </a>
                                        <img alt="" className="logo-f" src="images/logo-f.png" />
                                    </div>
                                    <div className="last-block_right">
                                        <img alt="" src={wallpapers} />
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* Last Block : End  */}
                        {/* <!-- Footer  --> */}
                        <footer className="proLand-footer">
                            <div className="container">
                                <img alt="" className="website-secure" src={websiteSecure} />
                                <ul className="soc-list row">
                                    <li><img alt="" src={socialIcons} /></li>
                                    <li><img alt="" src={ca} /></li>
                                </ul>
                                <p>
                                    <span className="prod-name">Tru Body ACV Keto Gummies</span> is committed to maintaining the highest quality products and the utmost integrity in business practices. All products sold on this website are certified by Good Manufacturing Practices (GMP), which is the highest
                                    standard of testing in the supplement industry.
                                </p>
                                <p>
                                    Notice: The products and information found on this site are not intended to replace professional medical advice or treatment. These statements have not been evaluated by the Food and Drug Administration. These products are not
                                    intended to diagnose, treat, cure or prevent any disease. Individual results may vary.
                                </p>
                                <p>
                                    © {currentYear}
                                    <span className="prod-name"> Wholesale Health Club</span>. All Rights Reserved.
                                </p>
                                <ul className="terms-links">
                                    <li>
                                        <Link className="cm-ajax-popup open-popup-link" to="#" onClick={(event) => {
                                            event.preventDefault();
                                            window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                        }}>Privacy Policy |&nbsp;</Link>
                                    </li>
                                    <li>
                                        <Link className="cm-ajax-popup open-popup-link" to="javascript:void(0);" onClick={(event) => {
                                            event.preventDefault();
                                            window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                        }}>Terms And Conditions |&nbsp;</Link>
                                    </li>
                                    <li>
                                        <Link to='/contact-us'>Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </footer>
                        <div className="fixed-btn d-md-none" onClick={scrollToCustom}>
                            <a href="#OrderForm" className="btn__send loading-btn">RUSH MY ORDER NOW </a>
                        </div>
                    </div>
                    <Popup/>
                </main></>
            }
        </>
    )
}
export default AcvgummiesTemplate