import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faBagShopping, faUserAlt, faBox, faGear, faDatabase, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
function Listusers() {
    return (
        <div className='container py-5'>

            <div className='row justify-content-center'>
                <h1 class="display-6">All users</h1>

                <div className='col-md-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <table id="example" className="table table-striped" >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Test</td>

                                        <td>Test@test.com</td>
                                        <td>Test</td>

                                        <td><Button><FontAwesomeIcon icon={faGear} /> </Button></td>
                                    </tr>


                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    )
}

export default Listusers