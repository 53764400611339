import React from "react";
import { Link } from 'react-router-dom';
import '../../assests/funnel/assets/css/checkout14.css';

// Images 
import siteSecurity from '../../assests/funnel/assets/images/checkout14/site-security-img.png';
import pro1 from '../../assests/funnel/assets/images/checkout14/blueforrestbeauty-productlineup.png';
import pro2 from '../../assests/funnel/assets/images/checkout14/tcl.png';
import pro3 from '../../assests/funnel/assets/images/checkout14/blueforrestbeauty-productlineup.png';
import postalLogo from '../../assests/funnel/assets/images/checkout14/postal-logos.png';
import badge1 from '../../assests/funnel/assets/images/checkout14/security-badge-1.png';
import badge2 from '../../assests/funnel/assets/images/checkout14/security-badge-2.png';
import badge3 from '../../assests/funnel/assets/images/checkout14/security-badge-3.png';
import badge4 from '../../assests/funnel/assets/images/checkout14/security-badge-4.png';
import paymentMulti from '../../assests/funnel/assets/images/checkout14/payment_multi_secure.jpg';
import guaranteed from '../../assests/funnel/assets/images/checkout14/guaranteed.png';
import paymentMultiCards from '../../assests/funnel/assets/images/checkout14/payment_multi_cards.png';
import paymentForm from '../../assests/funnel/assets/images/checkout14/payment_form_top_bg.png';
import securityIcon1 from '../../assests/funnel/assets/images/checkout14/privacy-verified.jpg';
import securityIcon2 from '../../assests/funnel/assets/images/checkout14/certified-by.jpg';
import securityIcon3 from '../../assests/funnel/assets/images/checkout14/security-verified.jpg';
import securityIcon4 from '../../assests/funnel/assets/images/checkout14/business-verified.jpg';
import securityIcon5 from '../../assests/funnel/assets/images/checkout14/visa-mc-discover-new.png';
import securityIcon6 from '../../assests/funnel/assets/images/checkout14/postal-logos_ZKYhlZD.png';
import securityIcon7 from '../../assests/funnel/assets/images/checkout14/shop-online-lock.png';
import Copyright from "../Copyright";

function Checkout14() {
    return (
        <>
            <div className="checkoutpage-14 lkjkj">
                <div className="checkout-14-header-inner">
                    <div className="site-secure"><img src={siteSecurity} alt="site security" width="112" /></div>
                </div>
                {/* Container Begin  */}
                <div className="container">
                    <div className="inner-logo text-center"><h2>Skincare Package</h2></div>
                    <div className="order-banner clearfix checkout-14-order-banner">
                        {/* <!-- Left  --> */}
                        <div id="payLft" className="fntAbel payLeft">
                            <div id="" className="newCheckout-wrapper">
                                <div id="product_1_wrapper" className="package1 plan-selection">
                                    <div id="payProd1" className="payProd activePack">
                                        <h3 className="payProdTitle">
                                            <span className="title16 fntQuantBd">
                                                Skincare Package 1
                                            </span>
                                        </h3>
                                        <div className="productDiv">
                                            <div className="productDiv-img">
                                                <span className="radio"></span>
                                                <div className="proImg">
                                                    <img src={pro1} className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="productDiv-info">
                                                <p className="proName">Blue Forrest 4 Pack Skin Products</p>
                                                <div className="div proexInfo">
                                                    <p className="price"><strong className="fntQuantBd">$80.00</strong></p>
                                                    <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="product_2_wrapper" className="package2 plan-selection">
                                    <div id="payProd2" className="payProd">
                                        <h3 className="payProdTitle">
                                            <span className="title16 fntQuantBd">
                                                Skincare Package 2
                                            </span>
                                        </h3>
                                        <div className="productDiv">
                                            <div className="productDiv-img">
                                                <span className="radio"></span>
                                                <div className="proImg">
                                                    <img src={pro2} className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="productDiv-info">
                                                <p className="proName">Complete Life 4 Pack Skin Products</p>
                                                <div className="div proexInfo">
                                                    <p className="price"><strong className="fntQuantBd">$80.00</strong></p>
                                                    <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="product_3_wrapper" className="package3 plan-selection">
                                    <div id="payProd3" className="payProd">
                                        <h3 className="payProdTitle">
                                            <span className="title16 fntQuantBd">
                                                Skincare Package 3
                                            </span>
                                        </h3>
                                        <div className="productDiv">
                                            <div className="productDiv-img">
                                                <span className="radio"></span>
                                                <div className="proImg">
                                                    <img src={pro3} className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="productDiv-info">
                                                <p className="proName">CSapphire Natural 3 Pack Skin Products</p>
                                                <div className="div proexInfo">
                                                    <p className="price"><strong className="fntQuantBd">$60.00</strong></p>
                                                    <p className="instock-info">In Stock. Current Sell-Out Risk: <span className="red">HIGH</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-delivery">
                                <h3>USPS First-Class 2-3 Days Delivery</h3>
                                <p>Parcels are shipped with insurance. Guaranteed Delivery.</p>
                            </div>
                            <div className="order-counter">
                                <article className="clearfix">
                                    <div className="left">Shipping Type:</div>
                                    <div id="block2_shipping" className="right"><b>FREE</b> Priority Shipping</div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                                <article className="clearfix">
                                    <div className="left">Shipping Price:</div>
                                    <div id="block2_price" className="right"><b>$0.00</b></div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                                <article className="clearfix">
                                    <div className="left">Your Total:</div>
                                    <div id="block2_total" className="right">$80</div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                                <article className="last clearfix">
                                    <div id="block2_total2" className="left">Retail: $80.00</div>
                                    <div id="block2_save" className="right"><b>You Save: $0.00</b></div>
                                    <div className="clear">&nbsp;</div>
                                </article>
                            </div>
                            <div className="order-satisfaction">
                                <h3>Satisfaction Guarantee!</h3>
                                <p>We’re so confident that Neuro Tech IQ will work for you that we are offering a <b>30-Days Money Back Guarantee!</b> So, feel confident that you will get results, or your money back!</p>
                            </div>
                            <div className="secu-1">
                                <div className="row">
                                    <div className="col-md-9 mx-auto text-center">
                                        <div className="secImg-1"><img src={postalLogo} alt="" /></div>
                                        <div className="arrive-date">Your order is due to arrive on <span className="orange">undefined 12 2022</span> .</div>
                                    </div>
                                </div>
                            </div>
                            <div className="secu-2">
                                <div className="row">
                                    <div className="col-lg-9 mx-auto text-center">
                                        <div className="row secu-row">
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge1} alt="" /></div>
                                            </div>
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge2} alt="" /></div>
                                            </div>
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge3} alt="" /></div>
                                            </div>
                                            <div className="col-3">
                                                <div className="secImg-2"><img className="img-fluid" src={badge4} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Left End  */}
                        {/* <!-- Right  --> */}
                        <div id="" className="formBox center-block payRight">
                            <img className="w-100" src={paymentForm} />
                            <div className="checkout14-form-div">
                                <p className="checkout-form-title"><strong className="allCards">We Accept:</strong> <img src={paymentMultiCards} alt="Accepted Cards" width="180px" /></p>
                                <form action="">
                                    <div className="form-div-1">
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Credit Card Number" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="password" placeholder="Security Code" />
                                        </div>
                                        <div className="form-holder mb-0 cc_form-holder">
                                            <label>Select Month</label>
                                            <select name="cc_exp_month" className="form-control exp_month">
                                                <option value="01">(01) Jan</option>
                                                <option value="02">(02) Feb</option>
                                                <option value="03">(03) Mar</option>
                                                <option value="04">(04) Apr</option>
                                                <option value="05">(05) May</option>
                                                <option value="06">(06) Jun</option>
                                                <option value="07">(07) Jul</option>
                                                <option value="08">(08) Aug</option>
                                                <option value="09">(09) Sep</option>
                                                <option value="10">(10) Oct</option>
                                                <option value="11">(11) Nov</option>
                                                <option value="12">(12) Dec</option>
                                            </select>
                                            <select name="cc_exp_year" className="form-control exp_year">
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                <option value="2033">2033</option>
                                                <option value="2034">2034</option>
                                                <option value="2035">2035</option>
                                                <option value="2036">2036</option>
                                                <option value="2037">2037</option>
                                                <option value="2038">2038</option>
                                                <option value="2039">2039</option>
                                                <option value="2040">2040</option>
                                                <option value="2041">2041</option>
                                                <option value="2042">2042</option>
                                            </select>
                                        </div>
                                        <div className="checkoutBtn-div">
                                            <Link className="btn checkoutBtn" to="#"></Link>
                                        </div>
                                    </div>
                                    <div className="form-div-2">
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="First Name" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Last Name" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="email" placeholder="Email Address" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="tel" placeholder="Phone Number" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Billing Address" />
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Billing City" />
                                        </div>
                                        <div className="form-holder">
                                            <select className="form-control">
                                                <option value="01">Alabama</option>
                                            </select>
                                        </div>
                                        <div className="form-holder">
                                            <input className="form-control" type="text" placeholder="Billing Zip Code" />
                                        </div>
                                        <div className="form-holder">
                                            <select className="form-control">
                                                <option value="01">United States</option>
                                            </select>
                                        </div>
                                        <div className="checkoutBtn-div">
                                            <Link to="#" className="btn checkoutBtn"></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* <!-- Trans  --> */}
                            <div className="trans"><img loading="lazy" src={paymentMulti} alt="Safe and secure" width="100%" height="194" /></div>
                            {/* <p><!-- safe Checkout  --></p> */}
                            <div className="w_item w_item_1 section-guranteed">
                                <div className="w_inner">
                                    <div className="w_thumb">
                                        <picture> <img className="no-lazy" src={guaranteed} alt="" /> </picture>
                                    </div>
                                    <div className="w_desc">
                                        <p>
                                            GUARANTEED <strong><span>SAFE</span></strong> CHECKOUT
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Container End  */}
                {/* Security Sec Begin  */}
                <div class="securityIcon-sec">
                    <div class="container">
                        <ul class="order-icons-list 00">
                            <li><img src={securityIcon1} alt="privacy verified" width="86" /></li>
                            <li><img src={securityIcon2} alt="certified" width="86" /></li>
                            <li><img src={securityIcon3} alt="security verified" width="86" /></li>
                            <li><img src={securityIcon4} alt="business verified" width="85" /></li>
                            <li>
                                <img loading="lazy" src={securityIcon5} alt="Visa, Mastercard, Discover accepted" width="160" height="36" />
                            </li>
                            <li><img src={securityIcon6} alt="united states" width="299" /></li>
                            <li><img src={securityIcon7} alt="shop online" width="187" /></li>
                        </ul>
                    </div>
                </div>
                {/* Security Sec End  */}
            </div>

            {/* Footer  */}
            <Copyright/>
            {/* Footer End         */}

        </>
    )
}

export default Checkout14;