import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import useStore from '../../store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import Copyright from '../Copyright';

function FrontLogin() {
    const navigate = useNavigate();

    const isLogin = useStore((state) => state.isLogin);
    const userType = useStore((state) => state.userType);
    const afterLogin = useStore((state) => state.afterLogin);
    const [loadershow, setloadershow] = useState(false);
    const inputReference = useRef(null);
    useEffect(() => {
        // inputReference.current.focus();
        if (isLogin == 1) {
            if (userType == 2 || userType == 1) {
                navigate("/admin/dashboard");
            }
            else {
                navigate("/");
            }

        }
    }, []);

    const loginSubmit = (data) => {
        
        setloadershow(true);
        // axios.post(`api/loginuser`, data).then(res => {
        axios.post(`api/login_sticky_user`, data).then(res => {
            // console.log(res.data.response_code);
            if (res.data.response_code === '100') {
                localStorage.setItem('authToken', res.data.member_token);
                localStorage.setItem('permission', res.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.data.email);

                axios.get(`api/check_user_membership/${data.email}`).then(result => {
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.user_info.account_permission, res.data.user_info.user_type, res.data.user_info.user_id, res.data.user_info, is_membership);
                    setloadershow(false);
                    Swal.fire({
                        title: 'Success',
                        html: "You Have Successfully Logged In",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    if (res.data.user_info.user_type == 1 || res.data.user_info.user_type == 2) {
                        navigate("/admin/dashboard")
                    }
                    else {
                        navigate("/dashboard")
                    }
                });
            }
            else {
                if (res.data.response_code === '4003') {
                    Swal.fire({
                        title: 'Please Check the below response!',
                        html: "We don't have any account with this email or please check your email and reset the password.",
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        // timer: 4000
                    });
                    setloadershow(false);

                }
                if (res.data.response_code === 'NO_SUB') {
                    Swal.fire({
                        title: 'Please Check the below response!',
                        html: "You don't have any Membership",
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        // timer: 4000
                    });
                    setloadershow(false);
                }
                else {
                    Swal.fire({
                        title: 'Please Check the below response!',
                        html: res.data.response_message,
                        icon: 'error',
                        showConfirmButton: true,
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonText: "Reset Password"

                        // timer: 4000
                    }).then(res => {
                        if (res.isConfirmed) {
                            navigate("/forget-password");
                        }
                    })
                    setloadershow(false);
                }

            }
        }).catch(err => {
            // console.log(err)

            Swal.fire({
                title: 'error',
                html: err.response.data.message,
                icon: 'error',
                showConfirmButton: false,
                closeOnConfirm: false,
                showCancelButton: true,
                closeOnCancel: true
                // timer: 4000
            })
        }
        )

    }
    return (
        <Formik
            initialValues={{ password: '', email: '' }}
            validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Email is required'),
                password: Yup.string()
                    .required('Password is required')
                    .min(8, 'Password must be at least 8 characters')
                    .max(40, 'Password must not exceed 40 characters'),

            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                // alert(JSON.stringify(values, null, 2));
                loginSubmit(values);

            }}
        >
            <>
                <div className={loadershow ? 'loading' : 'd-none'}></div>
                <div className='frontLogin'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-9 col-lg-7 mx-auto'>
                                <p className='text-center'><strong>Starting today, a healthy lifestyle doesn’t have to cost a fortune! Shop our curated selection of the highest quality, hand-selected supplements, skincare, and powders at the best price imaginable.</strong></p>
                                <p>This is your one stop shop for healthy living! Sign up today for a membership with Wholesale Health Club and reap amazing products with savings you won’t find anywhere else.</p>

                                {/* Form Begin  */}
                                <div className='loginForm  mt-5 mb-4'>
                                    <h2 class="contTitle rtWidth text-center">Enter your username and password below</h2>
                                    <Form className='row'>
                                        <div className='col-md-12 mb-4'>
                                            <div className='from_grp'>
                                                <label className='label'>Username</label>
                                                <Field className='form-control' type='email' name='email' placeholder="Enter email" />
                                                <CustomErrorMsg name="email" />
                                            </div>

                                        </div>
                                        <div className='col-md-12 mb-4'>
                                            <div className='from_grp'>
                                                <label className='label'>Password</label>
                                                <Field className='form-control' name='password' type="password" placeholder="Enter password" />
                                                <CustomErrorMsg name="password" />
                                            </div>

                                        </div>
                                        <div className='col-md-12 mb-4'>
                                            <div className='from_grp'>
                                                <button type="submit" className="btn btn-yellow rtWidth" >Login</button>
                                            </div>
                                        </div>
                                        <div className='col-md-12 mb-2'>
                                            <div className='from_grp '>
                                                <label className='label d-none d-md-block'></label>
                                                <div className='remDiv text-center w-100'>
                                                    <label for="rememberme" class="mm-remember-me">
                                                        <Field name="rememberme" id="rememberme" type="checkbox" checked="checked" value="forever" />
                                                        Remember me</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-4">
                                            <div class="from_grp ">
                                                <label class="label d-none d-md-block"></label>
                                                <div class=" text-center w-100">OR</div>
                                            </div>
                                        </div>
                                        <div className='col-md-12 mb-4'>
                                            <div className='from_grp'>
                                                <Link to='/create-membership' className="btn btn-yellow rtWidth"><strong>Create Account</strong></Link>
                                                {/* <a href='#' className="btn btn-yellow rtWidth"><strong>Create Account</strong></a> */}
                                            </div>
                                        </div>
                                        <div className='col-md-12 mb-4'>
                                            <div className='from_grp'>
                                                <label className='label d-none d-md-block'></label>
                                                <div className='forgotPass text-center w-100'>
                                                    <Link to="/forget-password">Forgot Password ?</Link>
                                                    {/* <Link to="/reset-password">/Reset </Link>Password? */}
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                {/* Form End  */}

                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer Begin  */}
                <Copyright/>
                {/* Footer End  */}
            </>
        </Formik>
    )
}

export default FrontLogin