import React, { useState, useEffect, useRef } from "react";
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "./CustomErrorMsg";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import useStore from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader';
import Copyright from '../Copyright';
import {BASE_URL,stateList,GetCardType} from '../Config';
import { Input } from 'reactstrap';

import pill from '../../assests/frontend/assets/images/pill.png';
import lotion from '../../assests/frontend/assets/images/Lotion.png';
import gummies from '../../assests/frontend/assets/images/gummies-icon.png';
import cclogos from '../../assests/frontend/assets/images/cclogos.png';
import mcafee from '../../assests/frontend/assets/images/mcafee.png';
import norton from '../../assests/frontend/assets/images/norton.png';
import moneyBack from '../../assests/frontend/assets/images/30-day-money-back.png';
import productImg from '../../assests/frontend/assets/images/SapphireNatural-NeuroTechIQ-5.png';
import productImg2 from '../../assests/frontend/assets/images/TruBodySupplements-GoACVKETO.png';
import productImg3 from '../../assests/frontend/assets/images/crypto-img.png';
import plus from '../../assests/frontend/assets/images/plus.png';
import moment from "moment";
import PaypalImage from '../../assests/frontend/assets/images/paypal-01.png';
import paypal from '../../assests/frontend/assets/images/paypal.png';
import paypalBtn from '../../assests/frontend/assets/images/paypal-btn.png';


function DiscountPageV2() {
    const [showDiv, setShowDiv] = useState(false);
    const [productId, setProductId] = useState("");
    const [orderType, setOrderType] = useState("");
    const afterLogin = useStore((state) => state.afterLogin);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const location = useLocation();
    const ref = useRef(null);
    const ref2 = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [activeClass, setActiveClass] = useState('');
    const [billingAsShipping, setbillingAsShipping] = useState('YES');
    const [productPrice, setProductPrice] = useState('0.00');
    const [offDiv, setOffDiv] = useState(false);
    const [checkboxValue, setcheckboxValue] = useState(true);
    const [cardType, setCardType] = useState("");
    const [cc_number, setcc_number] = useState("");
    const [isMember, setIsMember] = useState(0);
    const [selectedPayment, setSelectedPayment] = useState('paypal');


    const handlePaymentSelection = (value) => {
        setSelectedPayment(value);
        scrollToCustom();
    };
    
    const scrollToCustom = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    let refCode = new URLSearchParams(location.search).get('ref');
    let affid = new URLSearchParams(location.search).get('affid');
    let sid = new URLSearchParams(location.search).get('sid');
    let s1 = new URLSearchParams(location.search).get('s1');
    let s2 = new URLSearchParams(location.search).get('s2');
    let s3 = new URLSearchParams(location.search).get('s3');
    let click_id = new URLSearchParams(location.search).get('click_id');
    let notes = new URLSearchParams(location.search).get('notes');
    let localURL = window.location.origin.includes('localhost') ? 'http://localhost:3000/' : window.location.origin+'/';
    

    const handleCheckboxChange = (event) => {
        setcheckboxValue(event.target.checked);
        setShowDiv(!event.target.checked);
    };

    //State List ;
    const options = stateList;
    const [states, setStates] = useState([]);
    const params2 = useParams();
    
    const handleCountryChange = (event) => {
        const country_id = event.target.value;
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    const handleCountryChange2 = (country_id) => {
        axios.get(`api/get-state/${country_id}`).then(res => {
            if(res.data.status === true) {
                setStates(res.data.data);
            }
        })
    };

    useEffect(()=>{
        handleCountryChange2('239');
    },[params2.slug]);

    function setCreditCardType(CCNumber){
        if(CCNumber.length>=15){
            setcc_number(CCNumber);
            setCardType(GetCardType(CCNumber));
            document.getElementById('card_type').value = GetCardType(CCNumber);
        }
    }

    let updateProductID = (pId, order_type, proPrice) => {
        setActiveClass(pId);
        scrollToCustom();
        setProductId(pId);
        setOrderType(order_type);
        setProductPrice(proPrice);
        if (order_type === 'bundleProduct') {
            setOffDiv(true);
        }
    }

    const updateBundlePId = (pId, order_type, proPrice) => {
        // setActiveClass(pId);
        updateProductID(pId, order_type, proPrice);
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear + index);


    useEffect(() => {
        setActiveClass(60);
        setProductId(60);
        setOrderType('membership');
        setProductPrice('5.89');
        localStorage.removeItem('c_s');
    }, []);

    //PayPalSubmit Handler
    function PayPalHandler(values){
        let product_id = values.product_id;
        let firstName = values.first_name;
        let lastName = values.last_name;
        let address = values.address;
        let zip = values.zip;
        let state = values.state;
        let city = values.city;
        let phone = values.phone;
        let email = values.email;
        let country = values.country;
        let prospectId = '';
        let refcode = values.refCode;
        let alt_pay_url = localURL+'confirmation';
        let combine_product = 'null';
        let funnel = "discount";

        /* affiliate params*/
        let affid = new URLSearchParams(location.search).get('affid');
        let sid = new URLSearchParams(location.search).get('sid');
        let s1 = new URLSearchParams(location.search).get('s1');
        let s2 = new URLSearchParams(location.search).get('s2');
        let s3 = new URLSearchParams(location.search).get('s3');
        let click_id = new URLSearchParams(location.search).get('click_id');
        let notes = new URLSearchParams(location.search).get('notes');

        localStorage.setItem('firstName', values.first_name);
        localStorage.setItem('lastName', values.last_name);
        localStorage.setItem('shippingAddress1', values.address);
        localStorage.setItem('shippingZip', values.zip);
        localStorage.setItem('shippingState', values.state);
        localStorage.setItem('shippingCity', values.city);
        localStorage.setItem('phone', values.phone);
        localStorage.setItem('email', values.email);
        localStorage.setItem('country', (values.country === '239') ? 'US' : 'CA');
        localStorage.setItem('method','paypal');
        localStorage.setItem('c_s','discount');
        localStorage.setItem('referral_code',refcode);
        localStorage.setItem('funnel',funnel);

        localStorage.setItem('affid',affid);
        localStorage.setItem('sid',sid);
        localStorage.setItem('s1',s1);
        localStorage.setItem('s2',s2);
        localStorage.setItem('s3',s3);
        localStorage.setItem('click_id',click_id);
            
        window.location.href=BASE_URL+'whc-custom/public/api/paypal?product_id='+product_id+
        '&refcode='+refcode+
        '&firstName='+firstName+
        '&lastName='+lastName+
        '&address='+address+
        '&zip='+zip+
        '&state='+state+
        '&city='+city+
        '&phone='+phone+
        '&email='+email+
        '&country='+country+
        '&prospectId='+prospectId+
        '&alt_pay_url='+alt_pay_url+
        '&combine_product='+combine_product+
        '&funnel='+funnel+
        '&affid='+affid+
        '&sid='+sid+
        '&s1='+s1+
        '&s2='+s2+
        '&s3='+s3+
        '&click_id='+click_id+
        '&notes='+notes;

    }

    const updateSubmit = (formdata) => {
        //console.log(formdata);
        setLoaded(true);
        
        axios.post(`api/place_shop_order`, formdata).then(res => {
            console.log(res.data);
            if (res.data.response_code === '100') {
                localStorage.setItem('authToken', res.data.member_token);
                localStorage.setItem('permission', res.data.user_info.account_permission);
                localStorage.setItem('userId', res.data.user_info.user_id);
                localStorage.setItem('userEmal', res.data.user_info.email);
                localStorage.setItem('order_id', res.data.order_id);
                
                axios.get(`api/check_user_membership/${formdata.email}`).then(result => {
                    console.log(result.data);
                    let is_membership = 0;
                    if(parseInt(result.data) === 1){
                        is_membership = 1;
                    } else {
                        is_membership = 0;
                    }
                    afterLogin(res.data.user_info.account_permission, res.data.user_info.user_type, res.data.user_info.user_id, res.data.user_info, is_membership);
                    setLoaded(false);
                    if(productId === 144){
                        navigate("/crypto-thank-you");
                    } else {
                        navigate("/confirmation");
                    }
                });
                
            } else {
                setLoaded(false);
                Swal.fire({
                    title: 'Warning',
                    html: res.data.message,
                    icon: 'warning'
                })

            }
        }).catch(err => {
            console.log(err.response);
            Swal.fire({
                title: 'Warning',
                html: err.response.data.message,
                icon: 'warning'
            })
        });
    }

    return (
        <>
            {loaded ? <Loader /> : null}
            <div className="landingCheckoutPage landingPage">
                <section className="landingMain">
                    <div className="container-fluid">
                        <div className="titleDiv text-center">
                            <h2 className="checkTitleMain">Wholesale Health Club<br /> Subscriptions</h2>
                        </div>
                        <div className="prieCard-cont">
                            {/* Row  */}
                            <div className="priceCardRow row">
                                {/* Col Begin  */}
                                <div className="priceCardCol col-lg-3 col-md-6">
                                    <div className={"prieCard mx-auto " + (activeClass === 60 ? 'activePriceCard' : '')}>
                                        <div className="priecCard-hdr">
                                            <h3 className="priceTitle">BASIC</h3>
                                        </div>
                                        <div className="priceInfoCir">
                                            <p className="priceInfo-title">$5 <sup>.89</sup></p>
                                            <p className="priceDur">per month</p>
                                        </div>
                                        <div className="prieCard-signUp text-center">
                                            <button className="btn btn_com" onClick={() => { updateProductID(60, 'membership', '5.89') }}>Sign Up</button>
                                        </div>
                                        <div className="prieCard-body">
                                            <h3 className="priceCard-title">Access To Member Site</h3>
                                            <ul className="priceLists">
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={pill} /></span>
                                                        <span className="text">Supplements</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={lotion} /></span>
                                                        <span className="text">Skin Care </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={gummies} /></span>
                                                        <span className="text">Gummies </span>
                                                    </div>
                                                </li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div className="prieCard-footer">
                                            <div className="buyBtn-div text-center">
                                                <button className="btn btn_com" onClick={() => { updateProductID(60, 'membership', '5.89') }}>Buy Now</button>
                                            </div>
                                            <div className="visaCard text-center">
                                                <img src={cclogos} className="img-fluid" />
                                            </div>
                                            <div className="antiVirus text-center">
                                                <img src={norton} className="img-fluid" />
                                                <img src={mcafee} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Col End  */}
                                {/* Col Begin  */}
                                <div className="priceCardCol col-lg-3 col-md-6">
                                    <div className={"prieCard mx-auto " + (activeClass === 59 ? 'activePriceCard' : '')}>
                                        <div className="priecCard-hdr">
                                            <h3 className="priceTitle">SILVER</h3>
                                        </div>
                                        <div className="priceInfoCir">
                                            <p className="priceInfo-title">$10 <sup>.00</sup></p>
                                            <p className="priceDur">per month</p>
                                        </div>
                                        <div className="prieCard-signUp text-center">
                                            <button className="btn btn_com" onClick={() => { updateProductID(59, 'membership', '10.00') }}>Sign Up</button>
                                        </div>
                                        <div className="prieCard-body">
                                            <h3 className="priceCard-title">Basic PLUS</h3>
                                            <ul className="priceLists">
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={pill} /></span>
                                                        <span className="text">Supplements</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={lotion} /></span>
                                                        <span className="text">Skin Care </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={gummies} /></span>
                                                        <span className="text">Gummies </span>
                                                    </div>
                                                </li>
                                                <li>1 Referral =<br /> 1 Month FREE</li>
                                                <li>BONUS eBooks</li>
                                                <li></li>
                                            </ul>
                                        </div>
                                        <div className="prieCard-footer">
                                            <div className="buyBtn-div text-center">
                                                <button className="btn btn_com" onClick={() => { updateProductID(59, 'membership', '10.00') }}>Buy Now</button>
                                            </div>
                                            <div className="visaCard text-center">
                                                <img src={cclogos} className="img-fluid" />
                                            </div>
                                            <div className="antiVirus text-center">
                                                <img src={norton} className="img-fluid" />
                                                <img src={mcafee} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Col End  */}
                                {/* Col Begin  */}
                                <div className="priceCardCol col-lg-3 col-md-6">
                                    <div className={"prieCard mx-auto " + (activeClass === 58 ? 'activePriceCard' : '')}>
                                        <div className="priecCard-hdr">
                                            <h3 className="priceTitle">GOLD</h3>
                                        </div>
                                        <div className="priceInfoCir">
                                            <p className="priceInfo-title">$12 <sup>.77</sup></p>
                                            <p className="priceDur">per month</p>
                                        </div>
                                        <div className="prieCard-signUp text-center">
                                            <button className="btn btn_com" onClick={() => { updateProductID(58, 'membership', '12.77') }}>Sign Up</button>
                                        </div>
                                        <div className="prieCard-body">
                                            <h3 className="priceCard-title">Standard PLUS </h3>
                                            <ul className="priceLists">
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={pill} /></span>
                                                        <span className="text">Supplements</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={lotion} /></span>
                                                        <span className="text">Skin Care </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={gummies} /></span>
                                                        <span className="text">Gummies </span>
                                                    </div>
                                                </li>
                                                <li>1 Referral =<br /> 1 Month FREE</li>
                                                <li>Buy 5 Bottles or More<br /> Get 1 Bottle FREE</li>
                                                <li>BONUS eBooks</li>
                                            </ul>
                                        </div>
                                        <div className="prieCard-footer">
                                            <div className="buyBtn-div text-center">
                                                <button className="btn btn_com" onClick={() => { updateProductID(58, 'membership', '12.77') }}>Buy Now</button>
                                            </div>
                                            <div className="visaCard text-center">
                                                <img src={cclogos} className="img-fluid" />
                                            </div>
                                            <div className="antiVirus text-center">
                                                <img src={norton} className="img-fluid" />
                                                <img src={mcafee} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Col End  */}
                                {/* Col Begin  */}
                                <div className="priceCardCol col-lg-3 col-md-6">
                                    <div className={"prieCard mx-auto " + (activeClass === 41 ? 'activePriceCard' : '')}>
                                        <div className="priecCard-hdr">
                                            <h3 className="priceTitle">SAPPHIRE</h3>
                                        </div>
                                        <div className="priceInfoCir">
                                            <p className="priceInfo-title">$17 <sup>.00</sup></p>
                                            <p className="priceDur">per month</p>
                                        </div>
                                        <div className="prieCard-signUp text-center">
                                            <button className="btn btn_com" onClick={() => { updateProductID(41, 'membership', '17.00') }}>Sign Up</button>
                                        </div>
                                        <div className="prieCard-body">
                                            <h3 className="priceCard-title">Premium PLUS </h3>
                                            <ul className="priceLists">
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={pill} /></span>
                                                        <span className="text">Supplements</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={lotion} /></span>
                                                        <span className="text">Skin Care </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="iconText">
                                                        <span className="icon"><img src={gummies} /></span>
                                                        <span className="text">Gummies </span>
                                                    </div>
                                                </li>
                                                <li>1 Referral =<br /> 1 Month FREE</li>
                                                <li>Buy 5 Bottles or More<br /> Get 1 Bottle FREE</li>
                                                <li>BONUS eBooks</li>
                                            </ul>
                                        </div>
                                        <div className="prieCard-footer">
                                            <div className="buyBtn-div text-center">
                                                <button className="btn btn_com" onClick={() => { updateProductID(41, 'membership', '17.00') }}>Buy Now</button>
                                            </div>
                                            <div className="visaCard text-center">
                                                <img src={cclogos} className="img-fluid" />
                                            </div>
                                            <div className="antiVirus text-center">
                                                <img src={norton} className="img-fluid" />
                                                <img src={mcafee} className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Col End  */}
                            </div>
                            {/* Row End  */}
                        </div>
                    </div>
                </section>

                <section className="packageSec-2">
                    <div className="container-fluid">
                        <div className="titleDiv text-center">
                            <h2 className="checkTitleMain">Wholesale Health Club<br /> Product Packages</h2>
                        </div>
                        {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(76, 'bundleProduct', '0.00') }}>
                            <h3>Buy 3 Bottles, Get 2 FREE</h3>
                            <div className={"produtPackage " + (activeClass === 76 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-md-6 col-lg-5">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">5 Month Neuro Tech IQ Pack</p>
                                                <p className="priceInfo">Same As: <span className="price">$29.60<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$449</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$149</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$300</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6 col-lg-6">
                                        <div className="produtDiv">
                                            <div className="pack3">
                                                <img src={productImg} className="productImg prod-1 img-fluid" />
                                                <img src={productImg} className="productImg prod-2 img-fluid" />
                                                <img src={productImg} className="productImg prod-3 img-fluid" />
                                            </div>
                                            <div className="plus">
                                                <img src={plus} className="plus img-fluid" />
                                            </div>
                                            <div className="pack2">
                                                <img src={productImg} className="productImg prod-2 img-fluid" />
                                                <img src={productImg} className="productImg prod-3 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(76, 'bundleProduct', '0.00') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                        {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(77, 'bundleProduct', '0.00') }}>
                            <h3>Buy 2 Bottles, Get 1 FREE</h3>
                            <div className={"produtPackage " + (activeClass === 77 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-md-6 col-lg-5">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">3 Month Neuro Tech IQ Pack</p>
                                                <p className="priceInfo"><span className="price">$33.66<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$269</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$99</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$170</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6">
                                        <div className="produtDiv">
                                            <div className="pack2">
                                                <img src={productImg} className="productImg prod-1 img-fluid" />
                                                <img src={productImg} className="productImg prod-2 img-fluid" />
                                            </div>
                                            <div className="plus">
                                                <img src={plus} className="plus img-fluid" />
                                            </div>
                                            <div className="pack1">
                                                <img src={productImg} className="productImg prod-2 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(77, 'bundleProduct', '0.00') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                        {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(107, 'bundleProduct', '0.00') }}>
                            <h3>Buy 1 Neuro Tech IQ Bottle</h3>
                            <div className={"produtPackage " + (activeClass === 78 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-md-6 col-lg-5">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">1 Month Neuro Tech IQ Pack</p>
                                                <p className="priceInfo"><span className="price">$69.00<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$78.95</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$69</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$0</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6">
                                        <div className="produtDiv">
                                            <div className="pack1">
                                                <img src={productImg} className="productImg prod-2 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(107, 'bundleProduct', '0.00') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                        {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(79, 'bundleProduct', '0.00') }}>
                            <h3>Buy 3 Gummy Bottles, Get 2 FREE</h3>
                            <div className={"produtPackage " + (activeClass === 79 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-lg-5 col-md-6">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">5 Month Trubody Keto Gummies Pack</p>
                                                <p className="priceInfo"><span className="price">$39.00<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$327.45</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$195</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$132.45</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6">
                                        <div className="produtDiv">
                                            <div className="pack3">
                                                <img src={productImg2} className="productImg prod-1 img-fluid" />
                                                <img src={productImg2} className="productImg prod-2 img-fluid" />
                                                <img src={productImg2} className="productImg prod-3 img-fluid" />
                                            </div>
                                            <div className="plus">
                                                <img src={plus} className="plus img-fluid" />
                                            </div>
                                            <div className="pack2">
                                                <img src={productImg2} className="productImg prod-2 img-fluid" />
                                                <img src={productImg2} className="productImg prod-3 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(79, 'bundleProduct', '0.00') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                        {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(80, 'bundleProduct', '0.00') }}>
                            <h3>Buy 2 Gummy Bottles, Get 1 FREE</h3>
                            <div className={"produtPackage " + (activeClass === 80 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-md-6 col-lg-5">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">3 Month Trubody Keto Gummies Pack</p>
                                                <p className="priceInfo"><span className="price">$33.66<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$229.95</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$147</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$74.95</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6">
                                        <div className="produtDiv">
                                            <div className="pack2">
                                                <img src={productImg2} className="productImg prod-1 img-fluid" />
                                                <img src={productImg2} className="productImg prod-2 img-fluid" />
                                            </div>
                                            <div className="plus">
                                                <img src={plus} className="plus img-fluid" />
                                            </div>
                                            <div className="pack1">
                                                <img src={productImg2} className="productImg prod-2 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(80, 'bundleProduct', '0.00') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                        {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(81, 'bundleProduct', '0.00') }}>
                            <h3>Buy 1 Gummy Bottle</h3>
                            <div className={"produtPackage " + (activeClass === 81 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-md-6 col-lg-5">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">1 Month Trubody Keto Gummies Pack</p>
                                                <p className="priceInfo"><span className="price">$69.00<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$78.95</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$69</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$0</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6">
                                        <div className="produtDiv">
                                            <div className="pack1">
                                                <img src={productImg2} className="productImg prod-2 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(81, 'bundleProduct', '0.00') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                         {/* Begin  */}
                        <div className="produtPackageWrapper" onClick={() => { updateBundlePId(144, 'bundleProduct', '78.95') }}>
                            <h3 className="packTitleBorder">Crypto Trading Guide Bundle</h3>
                            <div className={"produtPackage " + (activeClass === 144 ? 'activePackage' : '')}>
                                <div className="produtPackage-row row">
                                    <div className="produtPackage-col col-md-6 col-lg-5">
                                        <div className="packageInfoBlock">
                                            <div className="produtPackage-1">
                                                <p className="packTitle">Crypto Trading Guide + Bonuses</p>
                                                <p className="priceInfo"><span className="price">$78.95<sup>each</sup></span></p>
                                            </div>
                                            <div className="produtPackage-2">
                                                <ul className="priceInfoList">
                                                    <li>
                                                        <p>Retail Price:</p>
                                                        <p className="price">$78.95</p>
                                                    </li>
                                                    <li>
                                                        <p>Your Price:</p>
                                                        <p className="price">$78.95</p>
                                                    </li>
                                                    <li>
                                                        <p>SAVE:</p>
                                                        <p className="price">$0</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="produtPackage-3">
                                                <p className="text-center">In Stock. Current Sell-Out Rick: <span className="text_red">HIGH</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="produtPackage-col col-md-6">
                                        <div className="produtDiv">
                                            <div className="pack1">
                                                <img src={productImg3} className="productImg prod-2 img-fluid" />
                                            </div>
                                        </div>
                                        <div className="buyBtn">
                                            <button className="btn btn_com" onClick={() => { updateProductID(144, 'bundleProduct', '78.95') }}>Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End  */}
                    </div>
                </section>

                <section className="checkoutForm-section">
                    <div className="container">
                        <div className="discountForm form-custom-2">
                            <Formik
                                enableReinitialize = {true}
                                initialValues={{
                                    first_name: '',
                                    last_name: '',
                                    phone: '',
                                    email: '',
                                    address: '',
                                    city: '',
                                    state: '',
                                    zip: '',
                                    country: '239',
                                    creditCardType: '',
                                    cc_month: 0,
                                    cc_year: '',
                                    creditCardNumber: '',
                                    cvv: '',
                                    acceptTerms: false,
                                    shipping_address: '',
                                    shipping_city: '',
                                    shipping_state: '',
                                    shipping_zip: '',
                                    shipping_country: '239',
                                    refCode: refCode,
                                    affid: affid,
                                    sid: sid,
                                    s1: s1,
                                    s2: s2,
                                    s3: s3,
                                    click_id: click_id,
                                    notes: notes
                                }}
                                validationSchema={Yup.object({
                                    first_name: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('First name is required'),
                                    }),
                                    last_name: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('Last name is required'),
                                    }),
                                    email: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().email("Email is required").max(255).required("Please enter a valid email"),
                                    }),
                                    phone: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('Phone no is required')
                                            .matches(/^[0-9]+$/, "Please enter only number!")
                                            .min(10, 'Please enter a valid contact number!')
                                            .max(10, 'Please enter a valid contact number!'),
                                    }),
                                    state: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('Please select state'),
                                    }),
                                    cc_year: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required("Select valid year"),
                                    }),
                                    cc_month: Yup.number().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.number().notRequired(),
                                        otherwise: Yup.number().moreThan(0,'Select valid month').required('Select month').when('cc_year', {
                                            is: (cc_year) => (parseInt(moment().year()) === parseInt(cc_year)) ? true : false,
                                            then: Yup.number().moreThan(parseInt(moment().format('MM'))-1, "Select valid month")
                                        }),
                                    }),
                                    address: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('Address is required'),
                                    }),
                                    city: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('City is required'),
                                    }),
                                    zip: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().required('Zip code is required')
                                            //.matches(/^[0-9]+$/, "Only numbers are allowed!")
                                            //.min(5,'Zip code should not exceed 5 digits'),
                                    }),
                                    acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
                                    creditCardNumber: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().matches(/^\d+$/, 'Only numbers are allowed')
                                        .required('Card number is required')
                                        .max(16, 'Credit card should not be grater than 16 digits')
                                        .min(15, 'Credit card should not be less than 15 digits'),
                                    }),
                                    cvv: Yup.string().when("submitButton", {
                                        is: "paypal",
                                        then: Yup.string().notRequired(),
                                        otherwise: Yup.string().matches(/^[0-9]{3,4}$/,
                                            'Security code must be a 3 or 4 digit number')
                                            .required('Security code is required'),
                                    }),
                                    shipCheckbox:Yup.string(),
                                    shipping_address: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Shipping address is required")
                                    }),
                                    shipping_city: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Shipping city is required")
                                    }),
                                    shipping_state: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Shipping state is required")
                                    }),
                                    shipping_zip: Yup.string().when("shipCheckbox", {
                                        is: (val)=> (val==='false') ? true:false,
                                        then: Yup.string().required("Shipping zip is required")
                                    }),
                                    // shipping_country: Yup.string().when("shipCheckbox", {
                                    //     is: (val)=> (val==='false') ? true:false,
                                    //     then: Yup.string().required("Shipping country is required")
                                    // })
                                })}

                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(true);
                                    //alert(JSON.stringify(values, null, 2));
                                    values.product_id = productId;
                                    values.order_type = orderType;
                                    values.creditCardType = GetCardType('"' + values.creditCardNumber + '"');
                                    if (values.shipping_address !== '') {
                                        values.billingSameAsShipping = "NO";
                                    } else {
                                        values.billingSameAsShipping = "YES";
                                    }
                                    if(values.country === '239') {
                                        values.country = 'US'
                                    } else {
                                        values.country = 'CA'
                                    }
                                    if(values.shipping_country === '239') {
                                        values.shipping_country = 'US'
                                    } else {
                                        values.shipping_country = 'CA'
                                    }
                                    //console.log(values);
                                    if(values.submitButton === 'paypal'){
                                        PayPalHandler(values);
                                    } else {
                                        updateSubmit(values);
                                    }   
                                }}
                            >
                                {(formProps) => (

                                    
                                <Form>
                                    <div className="row" ref={ref}>
                                        <div className="col-md-12 formDiv-col">
                                            <div className="formDiv">
                                                <h2 className="formTitle">Billing Details</h2>
                                                <div className="formRow row paymentMethod">
                                                    <div className="formDiv">
                                                        <h2 className="formTitle">Payment Method</h2>
                                                        <div className={`payPal radioSec ${selectedPayment === 'paypal' ? 'expanded' : ''}`}>
                                                            <Field
                                                                type="radio"
                                                                name="contactPreference"
                                                                value="paypal"
                                                                className="rb-paypal"
                                                                id="rb-paypal"
                                                                onClick={(event) => handlePaymentSelection(event.target.value)}
                                                                checked={selectedPayment === 'paypal'}
                                                            />
                                                            <label className="label label-title" htmlFor="rb-paypal"><img src={paypal} className="paypallogo img-fluid" /></label>
                                                        </div>
                                                        <div className={`creditCard radioSec ${selectedPayment === 'creditCard' ? 'expanded' : ''}`}>
                                                            <Field
                                                                type="radio"
                                                                name="contactPreference"
                                                                value="creditCard"
                                                                className="rb-credit"
                                                                id="rb-credit"
                                                                onClick={(event) => handlePaymentSelection(event.target.value)}
                                                                checked={selectedPayment === 'creditCard'}
                                                            />
                                                            <label className="label label-title" htmlFor="rb-credit">Credit Card or Debit</label>
                                                            <div className="label creditdiv" htmlFor="creditdiv">
                                                                <div className="formRow row">
                                                                    <div className="col-md-12 formDiv-col">
                                                                        <div className="formDiv">
                                                                            <h2 className="formTitle">Account Information</h2>
                                                                            <div className="formRow row">
                                                                                <div className="formCol col-md-6">
                                                                                    <Field type="hidden" className="form-control" id="card_type" name="creditCardType" value={cardType}/>
                                                                                    <Field type="hidden" className="form-control" name="affid" value={affid} />
                                                                                    <Field type="hidden" className="form-control" name="sid" value={sid} />
                                                                                    <Field type="hidden" className="form-control" name="s1" value={s1} />
                                                                                    <Field type="hidden" className="form-control" name="s2" value={s2} />
                                                                                    <Field type="hidden" className="form-control" name="s2" value={s3} />
                                                                                    <Field type="hidden" className="form-control" name="s2" value={click_id} />
                                                                                    <Field type="hidden" className="form-control" name="s2" value={notes} />
                                                                                    <Field type="text" className="form-control" placeholder="First Name" name="first_name" />
                                                                                    <CustomErrorMsg name="first_name" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field type="text" className="form-control" placeholder="Last Name" name="last_name" />
                                                                                    <CustomErrorMsg name="last_name" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field type="email" className="form-control" placeholder="Email Address" name="email" />
                                                                                    <CustomErrorMsg name="email" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field type="tel" maxLength="10" className="form-control" placeholder="Phone Number" name="phone" />
                                                                                    <CustomErrorMsg name="phone" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 formDiv-col">
                                                                        <div className="formDiv">
                                                                            <h2 className="formTitle">Billing Address</h2>
                                                                            <div className="formRow row">
                                                                                <div className="formCol col-md-12">
                                                                                    <Field type="text" className="form-control" placeholder="Billing Address" name="address" />
                                                                                    <CustomErrorMsg name="address" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field type="text" className="form-control" placeholder="Billing City" name="city" />
                                                                                    <CustomErrorMsg name="city" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field 
                                                                                        as="select" 
                                                                                        className="form-control" 
                                                                                        name="country"
                                                                                        onChange={(event) => {
                                                                                            formProps.handleChange(event);
                                                                                            handleCountryChange(event); // Call the custom function here if needed
                                                                                        }}
                                                                                    >
                                                                                        <option value="239" selected="selected">United States</option>
                                                                                        <option value="41" >Canada</option>
                                                                                    </Field>
                                                                                    <CustomErrorMsg name="country" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field type="text" maxLength="6" className="form-control" placeholder="Billing Zip Code" name="zip" />
                                                                                    <CustomErrorMsg name="zip" />
                                                                                </div>
                                                                                <div className="formCol col-md-6">
                                                                                    <Field as="select" className="form-control" name="state">
                                                                                        <option value="" >Select state</option>
                                                                                        {states.map((state) => (
                                                                                            <option key={state.id} value={state.abbreviation}>
                                                                                                {state.state}
                                                                                            </option>
                                                                                        ))}
                                                                                    </Field>
                                                                                    <CustomErrorMsg name="state" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 formDiv-col">
                                                                        <div className="formDiv">
                                                                            <h2 className="formTitle">Shipping Address</h2>
                                                                            <label className="shippingChec" htmlFor='shippingCheckbox'>
                                                                                <Field type="checkbox" id="shippingCheckbox" name="shipCheckbox" checked={checkboxValue===true ? 'checked' :""} onClick={(event) => formProps.setFieldValue("shipCheckbox", event.target.checked)}  onChange={handleCheckboxChange} />Shipping is the same as billing
                                                                            </label>
                                                                            {showDiv &&
                                                                                <div className="formRow row">
                                                                                    <div className="formCol col-md-12">
                                                                                        <Field type="text" className="form-control" placeholder="Shipping Address" name="shipping_address" />
                                                                                        <CustomErrorMsg name="shipping_address" />
                                                                                    </div>
                                                                                    <div className="formCol col-md-6">
                                                                                        <Field type="text" className="form-control" placeholder="Shipping City" name="shipping_city" />
                                                                                        <CustomErrorMsg name="shipping_city" />
                                                                                    </div>
                                                                                    <div className="formCol col-md-6">
                                                                                        <Field 
                                                                                            as="select" 
                                                                                            className="form-control" 
                                                                                            name="shipping_country"
                                                                                            onChange={(event) => {
                                                                                                formProps.handleChange(event);
                                                                                                handleCountryChange(event); // Call the custom function here if needed
                                                                                            }}
                                                                                        >
                                                                                            <option value="239" selected="selected">United States</option>
                                                                                            <option value="41" >Canada</option>
                                                                                        </Field>
                                                                                        <CustomErrorMsg name="shipping_country" />
                                                                                    </div>
                                                                                    <div className="formCol col-md-6">
                                                                                        <Field type="text" maxLength="6" className="form-control" placeholder="Shipping Zip Code" name="shipping_zip" />
                                                                                        <CustomErrorMsg name="shipping_zip" />
                                                                                    </div>
                                                                                    <div className="formCol col-md-6">
                                                                                        <Field as="select" className="form-control" name="shipping_state">
                                                                                            <option value="" >Select state</option>
                                                                                            {states.map((state) => (
                                                                                                <option key={state.id} value={state.abbreviation}>
                                                                                                    {state.state}
                                                                                                </option>
                                                                                            ))}
                                                                                        </Field>
                                                                                        <CustomErrorMsg name="shipping_state" />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <span className="label-subtitle mb-3">Visa, Mastercard, American Express, Discover</span>
                                                                    <div className="formCol col-12">
                                                                        <Field type="text" maxLength={16} className="form-control" placeholder="Card Number" name="creditCardNumber" onKeyUp={(event)=>setCreditCardType(event.target.value)}/>
                                                                        <CustomErrorMsg name="creditCardNumber" />
                                                                    </div>
                                                                    <div className="formCol col-12">
                                                                        <Field type="text" maxLength={4} className="form-control" placeholder="CVV" name="cvv" />
                                                                        <CustomErrorMsg name="cvv" />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="row cardTime-row">
                                                                            <div className="formCol col-6">
                                                                                <Field as="select" className="form-control" name="cc_month"  >
                                                                                    <option value="0">Select</option>
                                                                                    <option value="01">January</option>
                                                                                    <option value="02">February</option>
                                                                                    <option value="03">March</option>
                                                                                    <option value="04">April</option>
                                                                                    <option value="05">May</option>
                                                                                    <option value="06">June</option>
                                                                                    <option value="07">July</option>
                                                                                    <option value="08">August</option>
                                                                                    <option value="09">September</option>
                                                                                    <option value="10">October</option>
                                                                                    <option value="11">November</option>
                                                                                    <option value="12">December</option>
                                                                                </Field>
                                                                                <CustomErrorMsg name="cc_month" />
                                                                            </div>
                                                                            <div className="formCol col-6">
                                                                                <Field as="select" className="form-control" name="cc_year" >
                                                                                    <option value="">Select</option>
                                                                                    {years.map(year => (
                                                                                        <option key={year} value={year}>
                                                                                            {year}
                                                                                        </option>
                                                                                    ))}
                                                                                </Field>
                                                                                <CustomErrorMsg name="cc_year" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 formDiv-col">
                                            <div className="formDiv">
                                                <h2 className="formTitle">Referral Code</h2>
                                                <div className="formRow row">
                                                    <div className="formCol col-md-6">
                                                        <Field type="text" className="form-control" name="refCode" value={refCode} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 formDiv-col">
                                            <label className="termsCheck-label" htmlFor='termsCheck'>
                                                <Field name="acceptTerms" type="checkbox" id="termsCheck" />
                                                {offDiv ?
                                                    <div>By checking this box you confirm that you acknowledge the terms presented in the  <Link to='#' onClick={(event) => {
                                                        event.preventDefault();
                                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                    }}>Terms And Conditions</Link> and <Link to="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                    }}>Privacy Policy</Link></div>
                                                    : <div>By checking this box you confirm that you acknowledge the terms presented in the <Link to="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                    }}>Terms of Service</Link> and <Link to="#" onClick={(event) => {
                                                        event.preventDefault();
                                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                                    }}>Privacy Policy</Link>.Today’s charge is ${productPrice}. By clicking ‘Submit Order’ you agree that you are signing up for the membership (starting today). Your card will be automatically charged ${productPrice} every month thereafter for a period of 30 days from date of purchase. You can cancel anytime by calling (480) 573-0829 or by emailing support@wholesalehealthclub.com.</div>}
                                            </label>
                                            <CustomErrorMsg name="acceptTerms" />
                                        </div>
                                        <div className="col-md-12 formDiv-col" style={{ marginTop: '10px' }}>
                                            <button 
                                                type="submit" 
                                                className="btn submitBtn" 
                                                name="submitButton" 
                                                onClick={(event) => formProps.setFieldValue("submitButton", selectedPayment)}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                                )}
                            </Formik>
                            <ul className="privacyLists">
                                <li>
                                    <Link to="#" onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to='#' onClick={(event) => {
                                        event.preventDefault();
                                        window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                    }}>Terms And Conditions</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="buyNow-section">
                    <div className="container">
                        <div className="footerBuyNow">
                            <div className="visaCard text-center">
                                <img src={cclogos} className="img-fluid" />
                            </div>
                            <div className="antiVirus text-center">
                                <img src={norton} className="img-fluid" />
                                <img src={mcafee} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="guranteeSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-11 mx-auto">
                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <img src={moneyBack} className="img-fluid" />
                                    </div>
                                    <div className="col-md-9">
                                        <p className="guranteeText">100% 30 DAYS MONEY BACK GUARANTEE <br />
                                            Wholesale Health Club comes with a 30 Days, 100% Money Back Guarantee. That means if you change your mind about this decision at any point in the next 30 days all you need to do is email us, and we'll refund your purchase.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}


export default DiscountPageV2