import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div className='consec'>

            <h1>Wholesale Health Club</h1>


            <div className="Auth-form-container">

                <form className="Auth-form" >
                    <div className="Auth-form-content">
                        <h3 className="Auth-form-title">404 Page does not exist!</h3>


                        You can access your dashboard from <Link to="/dashboard"> here</Link> or login from <Link to="/login"> here</Link>
                    </div>
                </form>
            </div>


        </div>
    )
}

export default NotFound