import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import CustomErrorMsg from './CustomErrorMsg';
import { useNavigate } from "react-router-dom";

function ForgetPassword2() {
    let navigate = useNavigate(); 
    let  forgetSubmit=(data)=>{

        axios.post(`api/login_forgot_password`, data).then(res => {
            // 4013
        console.log(res.data);
        if (res.data.response_code === '100') {
            Swal.fire({
                    title: 'Success',
                    html: "A Temporary Password Has Been Sent To Your Registered Email ID. Please Check Your Email To Reset Password.",
                    icon: 'success',
                    showConfirmButton: true,
                    // timer: 100
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/reset-password");
                    }
                });
            
        }
        else{
            Swal.fire({
                title: 'Please see the below response',
                html: res.data.response_message,
                icon: 'error',
                showConfirmButton: false,
                showCancelButton: true,
                // timer: 4000
            });
        }
    }).catch(err => {
        console.log(err)
     
        Swal.fire({
            title: 'error',
            html: err.response.data.message,
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: true,
        })
    });
        

    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Formik
            initialValues={{  email: '' }}
                validationSchema={Yup.object({
                email: Yup.string().email('Invalid email address').required('Email is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting (true);
                // alert(JSON.stringify(values, null, 2));
                forgetSubmit(values);
            }}
        >
            {/* Main Begin  */}
            <main className="nw_main">
                <section className="nwSignin_section">
                    <div className="container">
                        <div className="nwSignin_wrapper">
                            <div className="nwSignin_inner text-center">
                                <p>Put Your Email ID To Get Temp. Password.</p>
                                <Form className="nwLogin_form">
                                    <div className="form-group">
                                        <Field className='form-control' type='text' name='email' placeholder="Enter Email Id" />
                                        <CustomErrorMsg name="email" />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn nwBtn-succes w-100">Reset Password</button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {/* Main End  */}
        </Formik>
    )
}
export default ForgetPassword2;