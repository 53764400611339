import React from 'react'
import {ErrorMessage} from 'formik';

const CustomErrorMsg = (props) => {
  return (
    <span className='errMsg'>
        <ErrorMessage name={props.name} />
    </span>
  )
}

export default CustomErrorMsg
