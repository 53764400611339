export const cartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_CART":
            return {
                ...state,
                cart: [...state.cart, { ...action.payload, qty: 1 }]
            };
        case "REMOVE_FROM_CART":
            return {
                ...state,
                cart: state.cart.filter(c => c.id !== action.payload.id)
            };
        case "CHANGE_QTY":
            return {
                ...state,
                cart: state.cart.filter((c) => c.id === action.payload.id ? (c.qty = action.payload.qty) : c.qty)

            };
        case "EMPTY_CART":
            return {
                ...state,
                cart: []

            };
        case "PREFILL_CART":

                return {
                    ...state,
                    cart: action.payload
    
                };

        default:
            return state;
    }
}

export const sidebarReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_SIDEBAR':
            return { ...state, sidebarOpen: !state.sidebarOpen }
        default:
            return state;
    }
}

export const navbarReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_TAB':
            return { ...state, activeTab: !state.activeTab }
        default:
            return state;
    }
}