import React, { useState, useEffect } from "react";
import axios from 'axios';
import Swal from 'sweetalert2'
import { Link, useNavigate, useLocation }  from 'react-router-dom';
import '../../assests/upsell/css/facecreamupsell.css';
import useStore from '../../store';
import AccessDeniedPage from "../AccessDeniedPage";

// Images 
import upsellBanner from '../../assests/upsell/images/facecream-upsell-banner.jpg'
import eyerenew from '../../assests/upsell/images/eyerenew_instalift_2new.png'
import scissor from '../../assests/upsell/images/Scissor-Stencil.png'
import lock2 from '../../assests/upsell/images/lock2.jpg'
import upOffBtn from '../../assests/upsell/images/up-off-btn.png'
import secure2 from '../../assests/upsell/images/secure2.jpg'
import eyecream2 from "../../assests/upsell/images/BlueForrest-Goddess-Eye-Cream-PumpBottle-2.png"
import Copyright from "../Copyright";
import close2 from "../../assests/upsell/images/close2.jpg";

function FaceCreamUpsell1() {
    const [loadershow, setloadershow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    let params = location.search;
    const currentYear = new Date().getFullYear();
    let userDetails = useStore((state) => state.userDetails);
    const isLogin = useStore((state) => state.isLogin);
    
    function upsellProcced(){
        setloadershow(true);
        const formdata = new FormData();
        formdata.append('first_name', localStorage.getItem('firstName'));
        formdata.append('last_name', localStorage.getItem('lastName'));
        formdata.append('email', localStorage.getItem('email'));
        formdata.append('is_membership', 0);
        if(localStorage.getItem('c_s') === 'cream2-checkout'){
            formdata.append('product_id', 160);
        } else if(localStorage.getItem('c_s') === 'cream2p-checkout') {
            formdata.append('product_id', 176);
        } else if(localStorage.getItem('c_s') === 'cream3p-checkout') {
            formdata.append('product_id', 193);
        } else {
            formdata.append('product_id', 140);
        }
        //formdata.append('product_id', 140);
        formdata.append('is_upsell', 1);
        formdata.append('order_id', localStorage.getItem('orderId'));
        formdata.append('affid', localStorage.getItem('affid'));
        formdata.append('sid', localStorage.getItem('sid'));
        formdata.append('s1', localStorage.getItem('s1'));
        formdata.append('s2', localStorage.getItem('s2'));
        formdata.append('s3', localStorage.getItem('s3'));
        formdata.append('click_id', localStorage.getItem('click_id'));
        formdata.append('notes', localStorage.getItem('notes'));
        formdata.append('user_id',userDetails.user_id);
        formdata.append('method',localStorage.getItem('method'));
        if(localStorage.getItem('funnel')){
            formdata.append('funnel',localStorage.getItem('funnel'));
        }
        if(localStorage.getItem('orderId')){
            axios.post(`api/place-upsell-order`, formdata).then(res => {
                if (res.data.status === true) {
                    if(localStorage.getItem('up2_s') === 'blue-forrest-goddess-eye-cream'){
                        localStorage.setItem('Upsell2_orderId', res.data.data.order_id);
                        if(localStorage.getItem('method') == 'paypal'){
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        } else {
                            if(localStorage.getItem('up3_s')){
                                window.location.href = '/'+localStorage.getItem('up3_s')+params;
                            } else {
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            }
                            
                        }
                        
                    } else {
                        localStorage.setItem('Upsell1_orderId', res.data.data.order_id);
                        if(localStorage.getItem('method') == 'paypal'){
                            window.location.href = '/'+localStorage.getItem('con_s')+params;
                        } else {
                            if(localStorage.getItem('up2_s')){
                                window.location.href = '/'+localStorage.getItem('up2_s')+params;
                            }
                            else{
                                window.location.href = '/'+localStorage.getItem('con_s')+params;
                            }
                        }
                    }
                    
                }
                else{
                    setloadershow(false);
                    Swal.fire({
                        title: res.data.data.decline_reason,
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33'
                    })
                }
            })
        } else {
            Swal.fire({
                title: 'Order Decline',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    window.location.href = '/dashboard';
                }
            })
        }
    }

    function goToConfirmation(){
        setloadershow(true);
        if(localStorage.getItem('up2_s') === 'blue-forrest-goddess-eye-cream'){
            if(localStorage.getItem('method') == 'paypal'){
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            } else {
                if(localStorage.getItem('up3_s')){
                    window.location.href = '/'+localStorage.getItem('up3_s')+params;
                } else {
                    window.location.href = '/'+localStorage.getItem('con_s')+params;
                }
            }
            
        } else {
            if(localStorage.getItem('up2_s')){
                window.location.href = '/'+localStorage.getItem('up2_s')+params;
            }
            else{
                window.location.href = '/'+localStorage.getItem('con_s')+params;
            }
        }
    }

    return (
        <>
            { isLogin === 1 ?
            <>
            <div className={loadershow ? 'loading' : 'd-none'}></div>
            <div className="facecreamUpsell">
                <div className="section-container">
                    {/* Banner  */}
                    <div className="upsell-section-1">
                        <div className="upsell-banner text-center">
                            <img src={upsellBanner} className="img-fluid" />
                        </div>
                    </div>
                    {/* Banner End  */}
                    {/* section 2 Begin  */}
                    <div className="sections section-2">
                        <div className="content">
                            <div className="topHeader">
                                <div className="upsell-header">WAIT! YOUR ORDER IS NOT COMPLETE</div>
                                <div className="sub-header">
                                    Customers that purchased Blue Forrest Nectar Of The Gods <br />
                                    have also purchased Blue Forrest Goddess Eye Cream to renew and instalift
                                </div>
                            </div>
                            <div className="form-wrap">
                                <form name="is-upsell" className="is-upsell">
                                    <div className="coupon">
                                        <div className="ib bottle-wrap">
                                            <img src={ localStorage.getItem('up2_s') === 'blue-forrest-goddess-eye-cream' ? eyecream2 : eyerenew} className="bottle" />
                                        </div>
                                        <div className="bottle-info ib">
                                            <p className="line-2 mb-0">AMPLIFY YOUR RESULTS</p>
                                            <p className="line-3">by upgrading to Age Defy, <span>Blue Forrest Goddess Eye Cream</span></p>
                                            <p className="line-4">Add your bottles</p>
                                            <p className="line-5">$29.47 <span>/ per item</span></p>
                                        </div>
                                    </div>
                                    <div className="scissors-wrap"><img src={scissor} className="scissors" /></div>
                                    <div className="upsellBtnDiv">
                                        <div id="security-tag" className="after gray">
                                            <p><img src={lock2} id="up-lock-two" alt="" /> Secure 256 Bit Encrypted Connection.</p>
                                        </div>
                                        <div className="btnDiv-inner text-center">
                                            <Link to="#" id="facecreamUpsellOrderButton" onClick={upsellProcced}>
                                                <img src={upOffBtn} />
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="footer-wrap">
                                <div className="footer-wrap-logo">
                                    <img src={secure2} className="secure" />
                                </div>
                                <div className="text-center">
                                    <h1 className="privacy">
                                        <Link to="#" className="nty-link" onClick={goToConfirmation}>
                                            <img src={close2} /> No Thanks, I decline this offer
                                        </Link>
                                    </h1>
                                </div>
                                <p className="footerTxt"> 
                                    We take great pride in the quality of our products and are confident that Blue Forrest Goddess Eye Cream Renew and Instalift are the most effective and powerful ingredients on the market today. If for any reason you do not
                                    find these products right for you please contact us immediately. By placing your 2 unit bundle order of Beauty Wrinkle Renew and Instalift, you will be enrolled in a new recurring membership, shipped monthly by default.
                                    Bundles cost $56 (USD) per item for a 1-2 item bundle, $48 (USD) per item for a 3-4 item bundle and $36 (USD) per item for all bundles with 5 or more items. Community members with a recurring membership can modify their
                                    membership and/or cancel at any time by calling <a href="tel:18882679320">1-888-267-9320</a>.
                                </p>
                                {/* <div className="footerBtm text-center">
                                    <div className="footerlinkwrap tac">
                                        <Link to="#" className="footerlink" onClick={(event) => {
                                            event.preventDefault();
                                            window.open('/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                        }}>Terms &amp; Conditions</Link>&nbsp;|&nbsp;
                                        <Link to="#" className="footerlink" onClick={(event) => {
                                            event.preventDefault();
                                            window.open('/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                                        }}>Privacy Policy</Link>&nbsp;|&nbsp;
                                        <Link to="/contact-us" className="footerlink">Contact Us</Link>
                                    </div>
                                    <p className="copyRightTxt">{currentYear} © Blue Forrest Goddess Eye Cream. All Rights Reserved.</p>
                                </div> */}
                                <Copyright/>
                            </div>
                        </div>
                    </div>

                    {/* Section 2 end  */}
               </div>
            </div>
            </>
            : <AccessDeniedPage/> }
        </>
    )
}

export default FaceCreamUpsell1