import React, { useState, useEffect } from "react";
import axios from "axios";
import { extendWith, omit } from "lodash";

function MembershipCheckout() {
    const [isPSubmit, setIsPSubmit] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [errors, setErrors] = useState({});
    const [isChecked, setIsChecked] = useState(true);
    const [memberInstance, setMemberInstance] = useState({
        billing_first_name: "",
        billing_last_name: "",
        company_name: "",
        billing_country: "",
        billing_address_1: "",
        billing_address_2: "",
        billing_state: "",
        city_name: "",
        billing_postcode: "",
        billing_phone: "",
        billing_email: "",
        name_on_card: "",
        cc_number: "",
        cc_expiry: ""
    });

    const handelInput = (e) => {
        e.persist();
        let name = e.target.name;
        let val = e.target.value;
        /*if (name == "terms" && val == "true") {
            setIsChecked(false);
        } else {
            setIsChecked(true);
        }*/
        //validate(e,name,val);
        setIsPSubmit(true);
        setMemberInstance({ ...memberInstance, [e.target.name]: e.target.value });
    }


    const confirmOrder = (e) => {
        e.preventDefault();
        console.log(e);
        /*setErrors(validateSubmit(orderInstance));
        if (Object.keys(errors).length == 0 && isPSubmit) {
            const formdata = new FormData();
            formdata.append('first_name', orderInstance.billing_first_name);
            formdata.append('last_name', orderInstance.billing_last_name);
            formdata.append('company_name', orderInstance.company_name);
            formdata.append('country', orderInstance.billing_country);
            formdata.append('address', orderInstance.billing_address_1);
            formdata.append('billing_address_2', orderInstance.billing_address_2);
            formdata.append('city', orderInstance.city_name);
            formdata.append('state', orderInstance.billing_state);
            formdata.append('zip', orderInstance.billing_postcode);
            formdata.append('phone', orderInstance.billing_phone);
            formdata.append('email', orderInstance.billing_email);
            formdata.append('name_on_card', orderInstance.name_on_card);
            formdata.append('creditCardNumber', orderInstance.cc_number);
            formdata.append('creditCardType', GetCardType(orderInstance.cc_number));
            formdata.append('expirationDate', orderInstance.cc_expiry);
            formdata.append('cvv', orderInstance.cc_cvc);
            formdata.append('is_membership', 41);
            formdata.append('cartData', JSON.stringify(cart));
            formdata.append('discount', discount);
            formdata.append('total_quantity', totalQuantity);
            //console.log(Object.fromEntries(formdata));
            setLoaded(true);
            axios.post(`api/place_shop_order`, formdata).then(res => {
                if (res.data.status === true) {
                    dispatch({
                        type: "EMPTY_CART",
                    });
                    setLoaded(false);
                    Swal.fire({
                        title: 'Success',
                        html: res.data.message,
                        icon: 'success',
                        showConfirmButton: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/final");
                        }
                    })
                }
            }).catch(err => {
                //console.log(err.response);
                Swal.fire({
                    title: 'Warning',
                    html: err.response.data.message,
                    icon: 'warning'
                })
            });
        }*/
    }

    return (
        <form >
            <div className='billing-fields mb-4 mb-md-5'>
                <h3 className='form_title'>Billing details</h3>
                <div className='field-wrapper'>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="billing_first_name" id="first_name" placeholder="First name" defaultValue={memberInstance.billing_first_name} onChange={handelInput} />
                        {errors.billing_first_name && <p className="errorMsg">{errors.billing_first_name}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="billing_last_name" id="last_name" placeholder="Last name" defaultValue={memberInstance.billing_last_name} onChange={handelInput} />
                        {errors.billing_last_name && <p className="errorMsg">{errors.billing_last_name}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="company_name" id="company_name" placeholder="Company name (optional)" onChange={handelInput} />
                    </div>
                    <div className='form-row'>
                        <select className="form-control input-text" name='billing_country' onChange={handelInput}>
                            <option value="0" selected>Select Billing Country</option>
                            <option value="US">UNITED STATES (US)</option>
                        </select>
                        {errors.billing_country && <p className="errorMsg">{errors.billing_country}</p>}
                    </div>
                    <div className='form-row'>
                        <select className="form-control input-text" name='billing_state' onChange={handelInput}>
                            <option value="0" selected>Select State</option>
                            <option value="AL">Alabama (AL)</option>
                            <option value="AK">Alaska (AK)</option>
                            <option value="AS">American Samoa (AS)</option>
                            <option value="AZ">Arizona (AZ)</option>
                            <option value="AR">Arkansas (AR)</option>
                            <option value="AE">Armed Forces (AE)</option>
                            <option value="AA">Armed Forces Americas (AA)</option>
                            <option value="AP">Armed Forces Pacific (AP)</option>
                            <option value="CA">California (CA)</option>
                            <option value="CO">Colorado (CO)</option>
                            <option value="CT">Connecticut (CT)</option>
                            <option value="DE">Delaware (DE)</option>
                            <option value="DC">District of Columbia (DC)</option>
                            <option value="FM">Federated States of Micronesia (FM)</option>
                            <option value="FL">Florida (FL)</option>
                            <option value="GA">Georgia (GA)</option>
                            <option value="GU">Guam (GU)</option>
                            <option value="HI">Hawaii (HI)</option>
                            <option value="ID">Idaho (ID)</option>
                            <option value="IL">Illinois (IL)</option>
                            <option value="IN">Indiana (IN)</option>
                            <option value="IA">Iowa (IA)</option>
                            <option value="KS">Kansas (KS)</option>
                            <option value="KY">Kentucky (KY)</option>
                            <option value="LA">Louisiana (LA)</option>
                            <option value="ME">Maine (ME)</option>
                            <option value="MD">Maryland (MD)</option>
                            <option value="MA">Massachusetts (MA)</option>
                            <option value="MI">Michigan (MI)</option>
                            <option value="MN">Minnesota (MN)</option>
                            <option value="MS">Mississippi (MS)</option>
                            <option value="MO">Missouri (MO)</option>
                            <option value="MT">Montana (MT)</option>
                            <option value="NE">Nebraska (NE)</option>
                            <option value="NV">Nevada (NV)</option>
                            <option value="NH">New Hampshire (NH)</option>
                            <option value="NJ">New Jersey (NJ)</option>
                            <option value="NM">New Mexico (NM)</option>
                            <option value="NY">New York (NY)</option>
                            <option value="NC">North Carolina (NC)</option>
                            <option value="ND">North Dakota (ND)</option>
                            <option value="MP">Northern Mariana Islands (MP)</option>
                            <option value="OH">Ohio (OH)</option>
                            <option value="OK">Oklahoma (OK)</option>
                            <option value="OR">Oregon (OR)</option>
                            <option value="PA">Pennsylvania (PA)</option>
                            <option value="PR">Puerto Rico (PR)</option>
                            <option value="MH">Republic of Marshall Islands (MH)</option>
                            <option value="RI">Rhode Island (RI)</option>
                            <option value="SC">South Carolina (SC)</option>
                            <option value="SD">South Dakota (SD)</option>
                            <option value="TN">Tennessee (TN)</option>
                            <option value="TX">Texas (TX)</option>
                            <option value="UT">Utah (UT)</option>
                            <option value="VT">Vermont (VT)</option>
                            <option value="VI">Virgin Islands of the U.S. (VI)</option>
                            <option value="VA">Virginia (VA)</option>
                            <option value="WA">Washington (WA)</option>
                            <option value="WV">West Virginia (WV)</option>
                            <option value="WI">Wisconsin (WI)</option>
                            <option value="WY">Wyoming (WY)</option>
                        </select>
                        {errors.billing_state && <p className="errorMsg">{errors.billing_state}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="billing_address_1" placeholder="House number and street name" onChange={handelInput} />
                        {errors.billing_address_1 && <p className="errorMsg">{errors.billing_address_1}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="billing_address_2" placeholder="Apartment, suite, unit, etc. (optional)" onChange={handelInput} />
                    </div>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="city_name" placeholder="Town / City" onChange={handelInput} />
                        {errors.city_name && <p className="errorMsg">{errors.city_name}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="text" className="form-control input-text" name="billing_postcode" placeholder="ZIP" onChange={handelInput} />
                        {errors.billing_postcode && <p className="errorMsg">{errors.billing_postcode}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="number" className="form-control input-text" name="billing_phone" placeholder="Phone" onChange={handelInput} />
                        {errors.billing_phone && <p className="errorMsg">{errors.billing_phone}</p>}
                    </div>
                    <div className='form-row'>
                        <input type="email" className="form-control input-text" name="billing_email" placeholder="Email address" onChange={handelInput} />
                        {errors.billing_email && <p className="errorMsg">{errors.billing_email}</p>}
                    </div>
                </div>
            </div>
            {/* additional field begin     */}
            <div className='additional-fields'>
                <h3 className='form_title'>ADDITIONAL INFORMATION</h3>
                <div className='field-wrapper'>
                    <div className='form-row'>
                        <label for="order_comments" className="form-label">Order notes&nbsp;<span className="optional">(optional)</span></label>
                        <textarea name="order_comments" className="input-text form-control" id="order_comments" placeholder="Notes about your order, e.g. special notes for delivery." rows="2" cols="5" onChange={handelInput}></textarea>
                    </div>
                </div>
            </div>
            {/* additional field End     */}
            {/* Payment Field  */}
            <div className='payment-field'>
                <h3 className='form_title'>PAYMENT INFORMATION</h3>
                <div className='checkout-payment'>
                    <div className='payment_methods'>
                        <div className='field-wrapper'>
                            <div className='form-row'>
                                <label for="name_on_card" className="form-label">Name on card  <span className="required">*</span> </label>
                                <input type="text" className="form-control input-text" name="name_on_card" id="wc-unify-name-on-card" placeholder="Name on card" onChange={handelInput} />
                                {errors.name_on_card && <p className="errorMsgCard">{errors.name_on_card}</p>}
                            </div>
                            <div className='form-row'>
                                <label for="cc_number" className="form-label">Card number  <span className="required">*</span> </label>
                                <input type="tel" maxLength={16} className="form-control input-text" name="cc_number" id="wc-unify-card-number" placeholder="•••• •••• •••• ••••" onChange={handelInput} />
                                {errors.cc_number && <p className="errorMsgCard">{errors.cc_number}</p>}
                            </div>
                            <div className='form-row'>
                                <label for="order_comments" className="form-label">Expiry (MMYY)   <span className="required">*</span> </label>
                                <input type="text" className="form-control input-text" name="cc_expiry" id="wc-unify-card-expiry" placeholder="MMYY" onChange={handelInput} />
                                {errors.cc_expiry && <p className="errorMsgCard">{errors.cc_expiry}</p>}
                            </div>
                            <div className='form-row'>
                                <label for="order_comments" className="form-label">Card Code  <span className="required">*</span> </label>
                                <input type="tel" className="form-control input-text" name="cc_cvc" id="wc-unify-card-cvc" maxLength={4} placeholder="CVC" onChange={handelInput} />
                                {errors.cc_cvc && <p className="errorMsgCard">{errors.cc_cvc}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Payment Field End  */}
            {/* Place order begin  */}
            <div className="form-row place-order">
                <div className="terms-and-conditions-wrapper">
                    <div className="privacy-policy-text"></div>
                    <p className="form-row validate-required">
                        <label className="form_label_checkbox">
                            <input type="checkbox" className="form__input input-checkbox" name="terms" id="terms" value={isChecked} onChange={handelInput} />
                            {errors.terms && <p className="errorMsgTerms">{errors.terms}</p>}
                            <span className="terms-and-conditions-checkbox-text">I agree with Wholesale Health Club’s <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.</span>&nbsp;<span className="required">*</span>
                        </label>
                        <input type="hidden" name="terms-field" value="1" />
                    </p>
                </div>
                <button type="submit" className="btn cartBtn2 cartBtn2-solidBlack w-100" name="checkout_place_order" id="place_order" value="Confirm Order" data-value="Confirm Order" onClick={confirmOrder}>Confirm Order</button>
            </div>
            {/* Place Oreder end  */}
        </form>
    );
}

export default MembershipCheckout;
