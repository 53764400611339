import { useNavigate } from "react-router-dom";
import useStore from "../../../store";
const CheckPermission = () => {
    const navigate = useNavigate();
    const userPermission = useStore((state) => state.userPermission)
    let permission = userPermission;
    var permissionModules = 0
    var permissionModulesArr
    var slug
    var slugArr
    var slugTempArr = []
    if(permission){
        permissionModules = userPermission;
    }
    if(permissionModules != 0){
        if(permissionModules != 'All'){
            permissionModulesArr = permissionModules.split(',')
            slugArr = window.location.href.split('/')
            
            
            
            var slugTempArr = []
            if (slugArr.length > 3) {
                slug = slugArr[4]
                slugTempArr = slug.split('?')
                if (slugTempArr.length > 0) {
                    slug = slugTempArr[0]
                }
            }
            if(slug == 'category' || slug == 'viewprods' || slug == 'productmapping' || slug == 'brand'){
                slug = 'Product'
            }
            if(slug == 'lander' || slug == 'checkoutfunnelslist' || slug == 'checkoutfunnelsedit' || slug == 'upsell' || slug == 'checkoutfunnels' || slug == 'confirmation'){
                slug = 'pagesetup'
            }
            if(slug == 'funnelflowlist' || slug == 'funnelflow' || slug == 'funnelflowedit'){
                slug = 'funnelflowsetup'
            }
            slugTempArr = slug.split('?')
            if(slugTempArr.length>0){
                slug =  slugTempArr[0]
            }
            if(slug == 'checkoutfunnelslist' || slug == 'checkoutfunnelsedit'){
                slug = 'checkoutfunnels'
            }
            // console.warn(permissionModulesArr.includes(slug))
            if(!permissionModulesArr.includes(slug)){
                navigate('/admin/accessdenied');
            }
        }
    }
}

export default CheckPermission;